import { Random } from 'random-js'


export const random = new Random() // uses the nativeMath engine

const getIntegerExcept = (min: number, max: number, except: number): number => {
  const res = random.integer(min, max)

  return res === except ? getIntegerExcept(min, max, except) : res
}
