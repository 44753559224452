import { createEffect, createEvent, createStore, sample, split } from 'effector'
import * as stat from 'statistics/statistics'
import { F, T, equals, last } from 'lodash/fp'
import { onKeydown } from 'model/document'
import { taskResolved } from 'statistics/model.events'
import { bindEventToAmplitude } from 'utils/amplitude'
import { persist } from 'effector-storage/local'

type SkipData = { id: number; traversal: number; timestamp: number }
export const toggleIsCurrentTaskLocked = createEvent<boolean | undefined>()
export const skipTask = createEvent()
export const undoTask = createEvent()
export const saveSkipTask = createEvent<SkipData>()
export const undoTaskData = createEvent<SkipData>()

export const $isRepeatOnboardingButtonShown = createStore(false).on(toggleIsCurrentTaskLocked, T)
persist({ store: $isRepeatOnboardingButtonShown, key: 'isRepeatOnboardingButtonShown' })

export const $isCurrentTaskLocked = createStore(false).on(
  toggleIsCurrentTaskLocked,
  (s, v) => v ?? !s,
)

export const $isCurrentTaskSkipped = createStore(false)
  .on(skipTask, T)
  .on(undoTask, T)
  .on(taskResolved, F)

export const $tasksQueue = createStore<SkipData[]>([])
  .on(taskResolved, (_, taskId) => [
    {
      id: taskId,
      traversal: stat.getTaskState(taskId).traversalNumber,
      timestamp: Date.now(),
    },
  ])
  .on(saveSkipTask, (store, data) => [...store, data])
  .on(undoTaskData, (store) => store.slice(0, -1))

sample({
  clock: undoTask,
  source: $tasksQueue,
  // TODO убрать !
  fn: (s) => last(s)!,
  target: undoTaskData,
})

sample({
  clock: undoTaskData,
  target: createEffect(({ id: taskId, traversal, timestamp }: SkipData) => {
    // TODO возможно для similarTasks не менять timestamp, только для выбранного задания
    // чтобы потом давалось нужное задание
    stat.updateWithSimilarTasks(taskId, { timestamp, traversalNumber: traversal, attempts: 0 })
  }),
})

split({
  source: onKeydown,
  match: {
    undoTask: equals('ArrowLeft'),
    skipTask: equals('ArrowRight'),
  },
  cases: { undoTask, skipTask },
})

bindEventToAmplitude(undoTask, 'undoTask')
bindEventToAmplitude(skipTask, 'skipTask')
bindEventToAmplitude(toggleIsCurrentTaskLocked, 'toggleIsCurrentTaskLocked')
