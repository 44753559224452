import { createStore } from 'effector'
// TODO BIG SIZE!!!!!!! 200kb
// import Highcharts from 'highcharts'
import { last } from 'lodash/fp'
import { memo, useEffect, useRef } from 'react'
const DATA_COUNT = 12
const labels = []
for (let i = 0; i < DATA_COUNT; ++i) {
  labels.push(i.toString())
}

const datapoints = [
  0, 20, 20, 60, 60, 120, 180, 120, 125, 105, 110, 170, 0, 20, 20, 60, 60, 120, 180, 120, 125, 105,
  110, 170,
]
const data = {
  labels: labels,
  datasets: [
    {
      label: '',
      data: datapoints,
      fill: false,
      cubicInterpolationMode: 'monotone',
      tension: 0.4,
    },
  ],
}

const config = {
  type: 'line',
  data: data,
  options: {
    responsive: true,
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Value',
        },
        suggestedMin: -10,
        suggestedMax: 200,
      },
    },
  },
}

// TODO make real store
const $solveDurations = createStore<number[]>([])

// TODO mb use this lib to live adding
// https://www.amcharts.com/demos/live-data/
export const ProgressChart = memo(() => {
  return null;
  const chartContainer = useRef<HTMLDivElement>(null)
  const highchartsSeriesRef = useRef<Highcharts.Chart>()

  console.log('rendeeer')

  useEffect(() => {
    const w = $solveDurations.watch((solveDurations) => {
      if (highchartsSeriesRef.current && solveDurations.length) {
        highchartsSeriesRef.current.series[0].addPoint(
          [solveDurations.length, last(solveDurations)],
          true,
          true,
        )
      }
    })

    return w.unsubscribe
  }, [])

  useEffect(() => {
    if (chartContainer.current) {
      highchartsSeriesRef.current = Highcharts.chart(chartContainer.current, {
        chart: {
          type: 'spline',
          animation: Highcharts.svg, // don't animate in old IE
          marginRight: 10,
        },

        time: {
          useUTC: false,
        },

        title: {
          text: 'Live random data',
        },

        accessibility: {
          announceNewData: {
            enabled: true,
            minAnnounceInterval: 15000,
            announcementFormatter: function (allSeries, newSeries, newPoint) {
              if (newPoint) {
                return 'New point added. Value: ' + newPoint.y
              }
              return false
            },
          },
        },

        xAxis: {
          type: 'number',
          tickPixelInterval: 1,
        },

        yAxis: {
          title: {
            text: 'Value',
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#808080',
            },
          ],
        },

        tooltip: {
          headerFormat: '<b>{series.name}</b><br/>',
          pointFormat: '{point.x:%Y-%m-%d %H:%M:%S}<br/>{point.y:.2f}',
        },

        legend: {
          enabled: false,
        },

        exporting: {
          enabled: false,
        },

        series: [
          {
            name: 'Random data',
            data: (function () {
              const solveDurations = $solveDurations.getState()
              const data = [
                {
                  x: 0,
                  y: 0,
                },
              ]

              for (let i = 0; i < solveDurations.length; i += 1) {
                data.push({
                  x: i + 1,
                  y: solveDurations[i],
                })
              }

              return data
            })(),
          },
        ],
      })

      // return () => {
      //   myChart.destroy()

      //   myChart.update()
      // }
    }
  }, [])

  return (
    <div style={{ width: 800, height: 400 }}>
      <div ref={chartContainer} />
    </div>
  )
})
