import { createGlobalStyle } from 'styled-components'
import styledMap from 'styled-map'

export const GlobalStyle = createGlobalStyle`
  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  body {
    background-color: ${styledMap('theme', {
      light: '#fff',
      dark: '#22272e',
    })};
   
   color: ${styledMap('theme', {
     light: '#000',
     dark: '#adbac7',
   })};

    .sh {
      border: ${styledMap('theme', {
        light: '1px solid white;',
        dark: '1px solid #22272e;',
      })};
    }
  }
`
