import styled from "styled-components";
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const Container = styled.div`
     margin: auto;
     width: 180px;
     display: flex;
     gap: 5px;
     align-items: center;
`
const TimeInput = styled.input`
     pointer-events: none;
     box-sizing: border-box;
     text-align: center;
     background-color: #e7e9e9ff !important;
     height: 40px;
     color: #606c6e;
     font-weight: 500;
     font-size: 30px;
     outline: 0 solid transparent;
     border: 0 solid transparent;
     width: 100%;
     border-radius: 4px;
     letter-spacing: -0.4px;
     padding: 10px 18px;
`

const IconStyles = {
    backgroundColor: '#e7e9e9ff',
    height: '40px',
    borderRadius: '4px'
}

type TimePickerProps = {
    defaultTime: number
    add: () => void
    minus: () => void
}

export const TimePicker = ({ defaultTime, add, minus }: TimePickerProps) => {
    const minMinutes = 5
    const maxMinutes = 60;

    const addMinute = () => {
        if (defaultTime < maxMinutes) {
            add()
        }
    };

    const minusMinute = () => {
        if (defaultTime > minMinutes) {
           minus()
        }
    };

    return (
        <Container>
            <TimeInput
                type='number'
                value={defaultTime}
                disabled
            />
            <ChevronLeft onClick={minusMinute} sx={IconStyles} />
            <ChevronRight onClick={addMinute} sx={IconStyles} />
        </Container>
    );
}