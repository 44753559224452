// written by ChatGPT :D
// durationString example = '2s 2m 3h 3d'
export const parseTimeString = (durationString: string): number => {
  const durationArray = durationString.split(' ')
  let durationMilliseconds = 0

  durationArray.forEach((value) => {
    const unit = value.slice(-1)
    const amount = parseInt(value.slice(0, -1))

    switch (unit) {
      case 's':
        durationMilliseconds += amount * 1000
        break
      case 'm':
        durationMilliseconds += amount * 60 * 1000
        break
      case 'h':
        durationMilliseconds += amount * 60 * 60 * 1000
        break
      case 'd':
        durationMilliseconds += amount * 24 * 60 * 60 * 1000
        break
      case 'y':
        durationMilliseconds += amount * 365 * 24 * 60 * 60 * 1000
        break
    }
  })

  return durationMilliseconds
}
