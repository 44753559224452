import { chunk, join, map, pipe, filter, isArray, compact, cloneDeep, identity } from 'lodash/fp'
import { AbcChord, IChord } from 'types'
import { random } from 'utils/random'
import { customNoteToAbcdjs } from 'utils/notesAdapters'
import Chord from '@tonaljs/chord'
import { chords5, MAJ_CHORDS, MIN_CHORDS } from './tasks.constants'
import { getChordName, tonalToAbc } from 'utils/abcjs-helpers'
import { sortObj } from 'utils/sortObject'
export const BASE_NOTES = 'C|E|D|F|G|A|B'.split('|')
export const BASE_ALL_NOTES = 'C|C#|D|Eb|E|F|F#|G|Ab|A|Bb|B'.split('|')
import md5 from 'md5'
import { IMelodyTask } from 'statistics/tasksUniqNames'

export const getMelodyTaskHash = (task: IMelodyTask) =>
  md5(task.type + JSON.stringify(task.notes))

type ITaskObj = {
  treble?: string | string[]
  bass?: string | string[]
}

export const stringifyTask = (taskObj: ITaskObj) => {
  if (taskObj.bass && !isArray(taskObj.bass)) taskObj.bass = [taskObj.bass]
  if (taskObj.treble && !isArray(taskObj.treble)) taskObj.treble = [taskObj.treble]

  if (taskObj.bass?.length === 0) delete taskObj.bass
  if (taskObj.treble?.length === 0) delete taskObj.treble

  return JSON.stringify(sortObj(cloneDeep(taskObj)))
}
export const parseTask = (str: string): ITaskObj => JSON.parse(str)

export const getTaskNotes = (str: string) => {
  if (!str) return []

  const { treble, bass } = parseTask(str)

  return compact([treble, bass]).flat()
}

export const stringifyTreble = (notes: string | string[]) =>
  stringifyTask({ treble: isArray(notes) ? notes : [notes] })

export const stringifyBass = (notes: string | string[]) =>
  stringifyTask({ bass: isArray(notes) ? notes : [notes] })

// const OUTER_CIRCLE = 'F|C|G|D|A|E|B|Gb|Db|Ab|Eb|Bb'.split('|')
// const INNER_CIRCLE = 'Dm|Am|Em|Bm|F#m|C#m|G#m|Ebm|Bbm|Fm|Cm|Gm'.split('|')

export const getSubFromGamma = (gamma: any[], sub: number[]) => sub.map((num) => gamma[num - 1])

// TODO добавить возможность встаить | в ноты
export const addOctave = (note = '') => (note.match(`,`) ? note.replace(`,`, '') : note + `'`)
export const subOctave = (note = '') => (note.match(`'`) ? note.replace(`'`, '') : note + ',')
export const clearOctave = (note: string) => note.replace(/[\,']/g, '')

export const addOctaves = (count: number) => (note: string) =>
  new Array(Math.abs(count)).fill(count > 0 ? addOctave : subOctave).reduce((n, fn) => fn(n), note)

// https://pereborom.ru/akkordy-v-tonalnosti/
// https://samesound.ru/write/78409-piano-scales-for-dummies
// http://www.musicfancy.net/ru/music-theory/theory/117
// Для мажорной гаммы – тон, тон, полутон, тон, тон, тон, полутон;

export const isElementInSequence = (seq: number[]) => (_: any, i: number) => seq.includes(i + 1)

// TODO grab all chords
// https://www.piano-keyboard-guide.com/keyboard-chords.html
// TODO add tags for select and sort
// TODO sort by popularity
// TODO add algoritm to memorize by popularity

export const AllNotes: IChord[] = [
  ...BASE_ALL_NOTES.slice(-5).map(subOctave),
  ...BASE_ALL_NOTES,
  ...BASE_ALL_NOTES.map(addOctave),
  // ].map((note) => [note, note])
].map((note) => ['', note])

/*
1 задать максимальный интервал между нотами в последовательности
2 сделать чтобы ноты брались только из гамм, но покрывались все ноты
3 добавить бас
*/

export const AllBassNotes: IChord[] = [
  ...BASE_ALL_NOTES.map(addOctaves(-2)),
  ...BASE_ALL_NOTES.map(subOctave),
  ...BASE_ALL_NOTES,
].map((note) => ['', note])

export const chordsThirdsNotes = chords5.map(([name, notes]) => [
  name.replace('5', ''),
  notes.split(' ')[1],
])

export const allChords: IChord[] = [...MAJ_CHORDS, ...MIN_CHORDS]

export const chordsSecondNotes = allChords.map(([name, notes]) => [name, notes.split(' ')[1]])

export const chordToAbcFormat = ([name, notes]: IChord): AbcChord => [
  name,
  notes.split(' ').map(customNoteToAbcdjs),
]

export const chordToTaskFormat = ([name, notes]: AbcChord) => `"${name}"\[${notes.join('')}\]`

export const withTitle = (title, notes) => `T: ${title}\n${notes}`
export const withBass = (notes) => `V: Tr clef=bass\n${notes}`
export const withTrebleAndBass = (treble, bass) => `\
X:1
// Q:1/4=120
L:1/4
V:V1 clef=treble
V:V2 clef=bass
[V:V1] ${treble}
[V:V2] ${bass}`

export const groupChords = (chordsStrings: string[]) => chunk(4, chordsStrings).map(join(' '))
// TODO refactor remove this method
export const groupChords2 = (chordsStrings: string[]) =>
  chordsStrings
    // TODO mb fix
    // .map((chord) => chance.pickset(chordsStrings, 2).flatMap((c) => [chord, c]))
    .map((chord) => chordsStrings.flatMap((c) => [chord, c]))
    .map(join(' '))

export const getRandom = (min: number, max: number, exept = -1): number => {
  const res = random.integer(min, max)

  return res === exept ? getRandom(min, max, exept) : res
}

// TODO move to common utils
export const prepareSequences =
  (elementsInChunk: number, maxDistance: number, countOfChanks: number) => (array: any[]) => {
    const result = []

    for (let chunkNum = 0; chunkNum < countOfChanks; chunkNum++) {
      const chunk: unknown[] = []
      result[chunkNum] = chunk
      let lastIndex = getRandom(0, array.length)

      for (let i = 0; i < elementsInChunk; i++) {
        chunk.push(array[lastIndex])

        lastIndex = getRandom(
          Math.max(lastIndex - maxDistance, 0),
          Math.min(lastIndex + maxDistance, array.length - 1),
          lastIndex,
        )
      }
    }

    return result
  }

export const basePrepareChords = pipe(
  // shuffle,
  map(chordToAbcFormat),
  map(chordToTaskFormat),
  prepareSequences(5, 5, 10),
  map(join(' ')),
)

export const prepareBassChords = pipe(
  map(chordToAbcFormat),
  map(([name, c]) => [name, c.map(subOctave)]),
  map(chordToTaskFormat),
  groupChords2,
  map(withBass),
)

export const filterDiez = filter(([_, note]) => !/[#b]/.test(note))

export const notesToChordStringWithName = (notes: string[]) =>
  `"${getChordName(notes)}"[${notes.join('')}]`

// TODO optimize
const simplify = (str: string) => {
  let signs = []
  let clean = str.replace(/[',]+/, (r) => {
    signs = r.split('')
    return ''
  })

  signs.forEach((sign) => {
    if (sign === `'`) clean = addOctave(clean)
    if (sign === `,`) clean = subOctave(clean)
  })

  return clean
}

// TODO refactor, добавить возможность менять ключ по ходу дела, в идеале и внутри [...]
export const splitNotesString = (str: string, chord: string[]) => {
  let stringify = identity

  const res = []
  for (const match of str.matchAll(/[^\[\]]+|\[([^\[\]]+)\]/g)) {
    if (match[1]) {
      const tempRes = []
      const treble = []
      const bass = []
      let innerStringify = stringify

      for (const m of match[1].matchAll(/.[,']*/g)) {
        const w = simplify(m[0])

        if (w === 't') {
          innerStringify = stringifyTreble
          continue
        }
        if (w === 'b') {
          innerStringify = stringifyBass
          continue
        }

        const arrToPush =
          innerStringify === identity ? tempRes : innerStringify === stringifyTreble ? treble : bass
        arrToPush.push(simplify(chord[w[0]] + w.slice(1)))
      }
      res.push(tempRes.length ? innerStringify(tempRes) : stringifyTask({ treble, bass }))
    } else {
      for (const m of match[0].matchAll(/.[,']*/g)) {
        const w = m[0]

        if (w === 't') {
          stringify = stringifyTreble
          continue
        }
        if (w === 'b') {
          stringify = stringifyBass
          continue
        }

        res.push(stringify(simplify(chord[w[0]] + w.slice(1))))
      }
    }
  }

  return res
}

export const getSeqFromChord = (f) => (chord) =>
  f((indexes?: number | string | ReadonlyArray<string>) =>
    indexes === undefined
      ? chord
      : typeof indexes === 'number'
      ? chord[indexes]
      : typeof indexes === 'string'
      ? splitNotesString(indexes, chord)
      : splitNotesString(indexes.join(''), chord),
  )
