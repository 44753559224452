import { Table } from 'dexie'

class DexieKeyValueSyncCache<T> {
  private table: Table<{ key: number; value: T }, number>
  private localCache: { [key: number]: T }
  private idKey: string

  constructor(table: Table<{ key: number; value: T }, number>, idKey: string) {
    this.localCache = {}
    this.table = table
    this.idKey = idKey
  }

  set(key: number, value: T) {
    this.localCache[key] = value
    this.table.put(value)
  }

  getAll(): Record<number, T> {
    return this.localCache
  }

  get(key: number): T | undefined {
    return this.localCache[key]

    // TODO обработать ошибки?
  }

  async fillCache() {
    const values = (await this.table.toArray()) as T[] // ошибка в типах в либе

    values.forEach((value) => {
      this.localCache[Number(value[this.idKey])] = value
    })
  }

  delete(key: number) {
    this.table.delete(key)
    delete this.localCache[key]
  }
}

export default DexieKeyValueSyncCache
