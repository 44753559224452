// TODO lazy load and init only if need
import KNN from 'ml-knn'
import { chunk } from 'lodash/fp'

// TODO использовать Uint8Array для более быстрой работы
// TODO нормализовать эталонные амплитуды, у высоких октав маленькие амплитуды

export const ETALONS_STRINGS = [
  // 'C4-E4: C4,E4,C4,E4,C5,C5,G5,F4,E5,E5,C6,E6',
  // 'D4-F4: D4,F4,D4,F4,D5,D5,C#4,A5,F5,F6,F#4,F6,D6',
  // 'E4-G4: E4,G4,G4,E4,F4,G5,E6,G#4,G6,E5,E5',
  // 'F4-A4: F4,F4,A4,A4,F5,A6,F6,A4,F6,A6,F#4',
  // 'G4-B4: G4,G4,B4,G5,B4,B4,G6,G#4',
  // 'F4-A4: F4,F4,A4,A4,F5,A6,F6,A4,F6,A6,F#4',
  // 'G4-B4: G4,G4,B4,G5,B4,B4,G6,G#4',

  // 'C2||C3 G3 C2 B2 A#1 G4 D2 A#6 A1 C#3 E5 A#2||51 13 13 12 9 7 7 6 6 6 6 6',
  // 'D2||D2 C#2 D3 C#3 E2 C2 C5 A3 D4 F#5 F2||73 62 53 25 23 12 9 8 7 6 6',
  // 'E2||E2 F2 E3 D2 D#3 D5 F#2 F3 B3 E4 G#5||13 1 48 26 12 8 8 8 7 7 6',
  // 'F2||F4 F3 F#2 E2 E3 C4 D#5 F2 G2 A5 C5||50 52 50 30 19 8 8 6 6 6 5',
  // 'G2||G3 G2 F#3 G#2 F#2 D4 G#3 F5 C2 G4 B5 A#1||58 26 18 15 12 10 7 7 6 5 4 4',
  // 'A2||A3 A2 E4 G#2 G5 C#2 A#2 A4 C#6||60 24 12 7 7 6 5 5 4',
  // 'B2||B3 B2 A#2 F#4 C3 A5 E2 A#3 B4 D2||60 24 16 11 9 6 6 4 4 4',

  'C2||C3 G3 G4 E5||60 30 7 6',
  'D2||D3 A3 D4 C5||60 30 7 9',
  'E2||E3 F3 B3 E4 D5||60 30 7 7 8',
  'F2||F3 C4 F4 C5 A5||60 10 50 5 6',
  'G2||G3 D4 G4 F5 B5||60 10 5 7 4',
  'A2||A3 E4 A4 G5||60 12 5 7',
  'B2||B3 B4 A5||60 4 6',

  'C3||C3 B3 C4 G4 C5||40 13 54 12 5',
  'D3||D3 E3 D4 A4 D5 C6||40 4 56 11 4 6',
  'E3||E3 F3 E4 E5 D6||40 8 9 5 6',
  'F3||F3 F4 C5 F5 A5||40 9 2 6 6',
  'G3||G3 G4 D5 G5 B5 F6||40 8 3 5 6 5',
  'A3||A3 A4 E5 A5 G6||40 6 3 5 5',
  'B3||B3 C4 B4 B5 A6||40 5 28 12 5',

  'A0||A1 A#1 G1 E2 C3 F2 F0 C#2 C#3 C0 C1 C2 D4 D1 A2||43 27 23 19 15 15 14 13 10 10 10 9 9 7 7',
  'A1||A2 A2 A1 E3 E3 G1 A#1 F1 E4 G6 C2 C#5 G#2 G2 G6||45 37 13 11 10 10 9 8 8 7 7 5 5 5 5',
  'A4||A4 A5 A6||26 9 7',
  'A5||A5 A6||13 5',
  'A6||A6||10',
  'Ad0||A#1 A1 F2 C2 D3 F#2 F0 D2 C#3 D1 C0 G1 A0 C#2 D#4||41 32 19 17 15 13 13 12 10 9 9 9 9 9 8',
  'Ad1||A#2 A2 F3 A#1 A1 G1 F3 B2 F4 C2 G#6 F4 F1 D5 G#6||45 39 13 13 11 9 8 8 8 6 6 6 5 5 5',
  'Ad2||A#3 A#3 A3 A#2 A2 F4 F4 G#5 D2 B2 G#5 F4 A#4 A#4 F5||61 41 24 21 21 11 8 6 6 6 5 4 4 4 4',
  'Ad3||A#3 A#3 A3 D6 A#4 A#4 A#5 G#6 D6 A#5 B3 F5 A#4 D2 C7||55 35 22 6 5 5 5 4 4 4 3 3 2 2 2',
  'Ad4||A#4 A#4 A#5 A#6 A#4 A#5 F7 F6 A#5 A#6 A#6 C2 F6 F7 F7||25 20 8 7 7 6 5 4 4 4 3 3 3 3 3',
  'Ad5||A#5 A#5 A#5 A#5 A#6 A#6 C2 F7 C#2 A#5 A#5 B5 A#5 A#5 A#6||38 34 13 12 4 3 3 3 3 2 2 2 2 2 1',
  'Ad6||A#6 A#6 D2 E2 A#6 C#2 F2 A#6 A#6 G1 F0 F1||9 8 4 4 3 1 1 1 1 1 1 1',
  'B0||A#1 C2 F#2 D3 A1 G2 D#3 D1 F0 E2 A0 C#2 E4 D2 B2||40 34 19 14 14 12 12 11 11 11 10 10 9 9 8',
  'B1||B2 A#2 C3 F#3 A#1 C2 A1 F#3 G1 F#4 F#4 A6 C#2 D#5 A6||52 27 19 14 13 9 8 8 7 7 7 6 6 5 5',
  'B4||B4 B5 B6||26 9 7',
  'B5||B5 B6||13 5',
  'B6||B6||10',
  'C1||C2 C#2 A#1 G2 E3 G#2 F0 D1 A0 E2 F2 E3 F#4 C0 C3||42 25 20 19 16 13 10 10 9 9 9 8 8 8 8',
  'C4||C4 C5 C6||26 9 7',
  'C5||C5 C6||13 5',
  'C6||C6||10',
  'C7||C7 C7 C7 F2 F#2 E2 C7 G2 A1 F0||8 5 4 3 3 2 1 1 1 1',
  'Cd1||C#2 C2 G#2 D2 F3 A2 F1 F0 C1 E3 F2 C#3 G4 F#2 C0||42 28 18 17 16 14 10 10 9 9 8 8 8 8 7',
  'Cd2||C#3 C3 D3 G#3 C#2 C2 G#3 G#4 A1 B6 E2 F5 A0 A#1 C3||51 38 14 13 11 11 10 8 6 6 6 5 5 5 5',
  'Cd3||C#4 C#4 C#3 C3 C4 G#4 G#4 B5 D4 G#4 F#2 F2 C#5 D3 D3||58 46 22 20 17 12 7 6 6 6 5 5 4 4 4',
  'Cd4||C#4 C#4 C#5 C#5 G#5 C#5 G#5 C#6 C#6 C4 F6 B6 F6 D4 D4||37 30 30 16 15 14 11 11 9 9 6 5 5 5 4',
  'Cd5||C#5 C#6 C#5 C#6 C#6 C#5 A#1 F7 F7 C2 C#5 A1 C#7 C5 D5||20 19 12 10 10 9 6 4 4 4 3 3 2 2 2',
  'Cd6||C#6 C#6 C#6 C#7 C#7 A#1 C2 C#7 C#6 C#6 A1 C#7 C#6 C#2 D6||21 17 8 6 5 4 3 3 2 2 2 1 1 1 1',
  'D1||D2 C#2 A2 A2 F#3 F1 F0 E2 F3 C1 G#4 D3 F#2 G2 C0||38 35 16 16 16 11 11 10 10 9 9 8 7 6 6',
  'D4||D4 D5 D6||26 9 7',
  'D5||D5 D6||13 5',
  'D6||D6||10',
  'Dd1||D2 E2 A#2 G3 A2 F0 G1 F#3 G#4 F1 D#3 C#2 C1 D1 C2||39 33 18 16 13 11 10 10 8 8 8 8 8 7 7',
  'Dd2||D2 E2 D#3 D3 C#2 E3 F2 A#3 C#5 D#4 G5 D3 C5 F#2 A#4||71 65 50 39 20 17 14 9 9 7 6 5 5 5 5',
  'Dd3||D#3 D3 E3 D#4 G5 D#4 C#6 D#5 G5 D#5 D#4 D3 C3 C#6 E3||51 35 18 9 6 6 6 6 5 5 4 3 3 3 3',
  'Dd4||D#4 D#4 D#5 D#5 A#5 A#5 D#6 D#6 G6 D#4 E4 D#5 D#5 C#7 C#7||36 28 26 24 14 12 11 9 7 6 6 6 5 4 4',
  'Dd5||D#5 D#6 D#5 D#6 D#5 D#6 C2 C#2 G7 D#5 D#5 G7 D#6 D#7 A#6||19 17 14 13 7 5 5 4 4 4 3 3 2 2 2',
  'Dd6||D#6 D#6 D#6 D#7 D#7 C2 C#2 D#6 D#7 D#6 D#7 D2 A#1 D#6||20 12 11 5 4 3 3 3 2 1 1 1 1 1',
  'E1||E2 F2 B2 G#3 D2 G1 F0 A0 A4 A#2 C3 G3 D1 G#3 E3||42 28 20 16 12 12 10 10 9 8 8 8 8 8 7',
  'E4||E4 E5 E6||26 9 7',
  'E5||E5 E6||13 5',
  'E6||E6||10',
  'F1||F2 F#2 C3 E2 A3 C3 A0 A3 A1 G1 A#4 D1 F0 F3 D2||43 25 19 16 15 14 13 11 11 10 9 8 8 8 6',
  'F4||F4 F5 F6||26 9 7',
  'F5||F5 F6||13 5',
  'F6||F6||10',
  'Fd1||F#2 G2 C#3 F2 A#3 A0 C3 A1 A3 D1 B4 G1 F#3 C1 C5||44 24 19 18 15 14 13 13 12 10 8 8 8 8 6',
  'Fd2||F#2 F#3 G2 F#3 F2 F3 C#4 E5 F#4 A#5 C#5 F#4 G#2 A#5 C#4||75 53 46 33 31 21 9 8 6 5 5 5 5 5 4',
  'Fd3||F#3 F#3 F3 F#4 A#5 F#4 F#5 E6 F#5 E6 A#5 F#4 C#5 A#5 F7||52 37 19 8 7 6 5 5 5 4 3 3 3 3 2',
  'Fd4||F#4 F#4 F#4 F#5 F#5 F#6 A#6 F#6 G4 C#6 A#6 C#6 F#6 G4 F#5||43 28 17 16 12 12 7 7 6 5 5 5 4 4 4',
  'Fd5||F#5 F#6 F#5 F#6 F#5 F#6 E2 D2 A#7 C#7 F#7 A#7 C#7 E3 F#5||13 11 8 8 4 3 3 2 1 1 1 1 1 1 1',
  'Fd6||F#6 F#6 F#6 E2 F#6 D2 F#7 F#7 D1 F2 C#2 F1 F#6 F#6 C1||28 16 13 4 4 4 3 3 2 2 1 1 1 1 1',
  'G1||G2 G#2 D3 F#2 B3 C1 D3 A0 A1 D1 B3 A#1 C5 G3 G1||44 25 20 18 16 15 13 13 11 11 11 10 8 8 7',
  'G4||G4 G5 G6||26 9 7',
  'G5||G5 G6||13 5',
  'G6||G6||10',
  'Gd1||G#2 A2 A1 D#3 G1 G2 F1 A#1 D3 D#4 F#6 D#4 D1 F#2 C5||48 30 13 12 11 11 10 9 8 7 7 6 6 6 6',
  'Gd2||G#3 G#3 G#2 A2 A3 G3 D#4 G2 D#4 F#5 C2 G#4 C#2 F#5 C6||54 53 25 17 12 12 11 10 9 7 5 5 5 4 4',
  'Gd3||G#3 G#3 G3 G#4 A3 C6 G#4 G#5 F#6 G#5 C6 G#4 D#5 F#6 C#2||48 45 12 7 7 6 5 5 5 4 4 3 3 3 2',
  'Gd4||G#4 G#4 G#4 G#5 G#6 G#5 D#7 D#6 D#7 D#6 G#5 G#6 A#1 G#6 C7||27 18 10 8 8 6 5 5 5 4 4 4 4 3 3',
  'Gd5||G#5 G#5 G#5 G#5 G#6 G#6 A#1 D#7 G#5 C2 D#7 G#5 A5 A5 A1||40 40 14 12 4 4 4 3 3 2 2 2 2 2 2',
  'Gd6||G#6 G#6 G#6 G#6 F2 F#2 G#6 G#7 E2 G#7 F1 G2 D1 G#6 G#6||24 19 7 3 3 3 3 2 2 2 2 1 1 1 1',

  'C2-D2||C3 G3 G4 E5 D3 A3 D4 C5||60 13 7 6 60 8 7 9',
  'C2-E2||C3 G3 G4 E5 E3 F3 B3 E4 D5||60 13 7 6 60 8 7 7 8',
  'C2-F2||C3 G3 G4 E5 F3 C4 F4 C5 A5||60 13 7 6 60 8 50 5 6',
  'C2-G2||C3 G3 G4 E5 G3 D4 G4 F5 B5||60 13 7 6 60 10 5 7 4',
  'C2-A2||C3 G3 G4 E5 A3 E4 A4 G5||60 13 7 6 60 12 5 7',
  'C2-B2||C3 G3 G4 E5 B3 B4 A5||60 13 7 6 60 4 6',
  'C2-C3||C3 G3 G4 E5 C3 B3 C4 G4 C5||60 13 7 6 40 13 54 12 5',
  'C2-D3||C3 G3 G4 E5 D3 E3 D4 A4 D5 C6||60 13 7 6 40 4 56 11 4 6',
  'C2-E3||C3 G3 G4 E5 E3 F3 E4 E5 D6||60 13 7 6 40 8 9 5 6',
  'C2-F3||C3 G3 G4 E5 F3 F4 C5 F5 A5||60 13 7 6 40 9 2 6 6',
  'C2-G3||C3 G3 G4 E5 G3 G4 D5 G5 B5 F6||60 13 7 6 40 8 3 5 6 5',
  'C2-A3||C3 G3 G4 E5 A3 A4 E5 A5 G6||60 13 7 6 40 6 3 5 5',
  'C2-B3||C3 G3 G4 E5 B3 C4 B4 B5 A6||60 13 7 6 40 5 28 12 5',
  'C2-A4||C3 G3 G4 E5 A4 A5 A6||60 13 7 6 26 9 7',
  'C2-A5||C3 G3 G4 E5 A5 A6||60 13 7 6 13 5',
  'C2-A6||C3 G3 G4 E5 A6||60 13 7 6 10',
  'C2-B4||C3 G3 G4 E5 B4 B5 B6||60 13 7 6 26 9 7',
  'C2-B5||C3 G3 G4 E5 B5 B6||60 13 7 6 13 5',
  'C2-B6||C3 G3 G4 E5 B6||60 13 7 6 10',
  'C2-C4||C3 G3 G4 E5 C4 C5 C6||60 13 7 6 26 9 7',
  'C2-C5||C3 G3 G4 E5 C5 C6||60 13 7 6 13 5',
  'C2-C6||C3 G3 G4 E5 C6||60 13 7 6 10',
  'C2-D4||C3 G3 G4 E5 D4 D5 D6||60 13 7 6 26 9 7',
  'C2-D5||C3 G3 G4 E5 D5 D6||60 13 7 6 13 5',
  'C2-D6||C3 G3 G4 E5 D6||60 13 7 6 10',
  'C2-E4||C3 G3 G4 E5 E4 E5 E6||60 13 7 6 26 9 7',
  'C2-E5||C3 G3 G4 E5 E5 E6||60 13 7 6 13 5',
  'C2-E6||C3 G3 G4 E5 E6||60 13 7 6 10',
  'C2-F4||C3 G3 G4 E5 F4 F5 F6||60 13 7 6 26 9 7',
  'C2-F5||C3 G3 G4 E5 F5 F6||60 13 7 6 13 5',
  'C2-F6||C3 G3 G4 E5 F6||60 13 7 6 10',
  'C2-G4||C3 G3 G4 E5 G4 G5 G6||60 13 7 6 26 9 7',
  'C2-G5||C3 G3 G4 E5 G5 G6||60 13 7 6 13 5',
  'C2-G6||C3 G3 G4 E5 G6||60 13 7 6 10',
  'D2-E2||D3 A3 D4 C5 E3 F3 B3 E4 D5||60 8 7 9 60 8 7 7 8',
  'D2-F2||D3 A3 D4 C5 F3 C4 F4 C5 A5||60 8 7 9 60 8 50 5 6',
  'D2-G2||D3 A3 D4 C5 G3 D4 G4 F5 B5||60 8 7 9 60 10 5 7 4',
  'D2-A2||D3 A3 D4 C5 A3 E4 A4 G5||60 8 7 9 60 12 5 7',
  'D2-B2||D3 A3 D4 C5 B3 B4 A5||60 8 7 9 60 4 6',
  'D2-C3||D3 A3 D4 C5 C3 B3 C4 G4 C5||60 8 7 9 40 13 54 12 5',
  'D2-D3||D3 A3 D4 C5 D3 E3 D4 A4 D5 C6||60 8 7 9 40 4 56 11 4 6',
  'D2-E3||D3 A3 D4 C5 E3 F3 E4 E5 D6||60 8 7 9 40 8 9 5 6',
  'D2-F3||D3 A3 D4 C5 F3 F4 C5 F5 A5||60 8 7 9 40 9 2 6 6',
  'D2-G3||D3 A3 D4 C5 G3 G4 D5 G5 B5 F6||60 8 7 9 40 8 3 5 6 5',
  'D2-A3||D3 A3 D4 C5 A3 A4 E5 A5 G6||60 8 7 9 40 6 3 5 5',
  'D2-B3||D3 A3 D4 C5 B3 C4 B4 B5 A6||60 8 7 9 40 5 28 12 5',
  'D2-A4||D3 A3 D4 C5 A4 A5 A6||60 8 7 9 26 9 7',
  'D2-A5||D3 A3 D4 C5 A5 A6||60 8 7 9 13 5',
  'D2-A6||D3 A3 D4 C5 A6||60 8 7 9 10',
  'D2-B4||D3 A3 D4 C5 B4 B5 B6||60 8 7 9 26 9 7',
  'D2-B5||D3 A3 D4 C5 B5 B6||60 8 7 9 13 5',
  'D2-B6||D3 A3 D4 C5 B6||60 8 7 9 10',
  'D2-C4||D3 A3 D4 C5 C4 C5 C6||60 8 7 9 26 9 7',
  'D2-C5||D3 A3 D4 C5 C5 C6||60 8 7 9 13 5',
  'D2-C6||D3 A3 D4 C5 C6||60 8 7 9 10',
  'D2-D4||D3 A3 D4 C5 D4 D5 D6||60 8 7 9 26 9 7',
  'D2-D5||D3 A3 D4 C5 D5 D6||60 8 7 9 13 5',
  'D2-D6||D3 A3 D4 C5 D6||60 8 7 9 10',
  'D2-E4||D3 A3 D4 C5 E4 E5 E6||60 8 7 9 26 9 7',
  'D2-E5||D3 A3 D4 C5 E5 E6||60 8 7 9 13 5',
  'D2-E6||D3 A3 D4 C5 E6||60 8 7 9 10',
  'D2-F4||D3 A3 D4 C5 F4 F5 F6||60 8 7 9 26 9 7',
  'D2-F5||D3 A3 D4 C5 F5 F6||60 8 7 9 13 5',
  'D2-F6||D3 A3 D4 C5 F6||60 8 7 9 10',
  'D2-G4||D3 A3 D4 C5 G4 G5 G6||60 8 7 9 26 9 7',
  'D2-G5||D3 A3 D4 C5 G5 G6||60 8 7 9 13 5',
  'D2-G6||D3 A3 D4 C5 G6||60 8 7 9 10',
  'E2-F2||E3 F3 B3 E4 D5 F3 C4 F4 C5 A5||60 8 7 7 8 60 8 50 5 6',
  'E2-G2||E3 F3 B3 E4 D5 G3 D4 G4 F5 B5||60 8 7 7 8 60 10 5 7 4',
  'E2-A2||E3 F3 B3 E4 D5 A3 E4 A4 G5||60 8 7 7 8 60 12 5 7',
  'E2-B2||E3 F3 B3 E4 D5 B3 B4 A5||60 8 7 7 8 60 4 6',
  'E2-C3||E3 F3 B3 E4 D5 C3 B3 C4 G4 C5||60 8 7 7 8 40 13 54 12 5',
  'E2-D3||E3 F3 B3 E4 D5 D3 E3 D4 A4 D5 C6||60 8 7 7 8 40 4 56 11 4 6',
  'E2-E3||E3 F3 B3 E4 D5 E3 F3 E4 E5 D6||60 8 7 7 8 40 8 9 5 6',
  'E2-F3||E3 F3 B3 E4 D5 F3 F4 C5 F5 A5||60 8 7 7 8 40 9 2 6 6',
  'E2-G3||E3 F3 B3 E4 D5 G3 G4 D5 G5 B5 F6||60 8 7 7 8 40 8 3 5 6 5',
  'E2-A3||E3 F3 B3 E4 D5 A3 A4 E5 A5 G6||60 8 7 7 8 40 6 3 5 5',
  'E2-B3||E3 F3 B3 E4 D5 B3 C4 B4 B5 A6||60 8 7 7 8 40 5 28 12 5',
  'E2-A4||E3 F3 B3 E4 D5 A4 A5 A6||60 8 7 7 8 26 9 7',
  'E2-A5||E3 F3 B3 E4 D5 A5 A6||60 8 7 7 8 13 5',
  'E2-A6||E3 F3 B3 E4 D5 A6||60 8 7 7 8 10',
  'E2-B4||E3 F3 B3 E4 D5 B4 B5 B6||60 8 7 7 8 26 9 7',
  'E2-B5||E3 F3 B3 E4 D5 B5 B6||60 8 7 7 8 13 5',
  'E2-B6||E3 F3 B3 E4 D5 B6||60 8 7 7 8 10',
  'E2-C4||E3 F3 B3 E4 D5 C4 C5 C6||60 8 7 7 8 26 9 7',
  'E2-C5||E3 F3 B3 E4 D5 C5 C6||60 8 7 7 8 13 5',
  'E2-C6||E3 F3 B3 E4 D5 C6||60 8 7 7 8 10',
  'E2-D4||E3 F3 B3 E4 D5 D4 D5 D6||60 8 7 7 8 26 9 7',
  'E2-D5||E3 F3 B3 E4 D5 D5 D6||60 8 7 7 8 13 5',
  'E2-D6||E3 F3 B3 E4 D5 D6||60 8 7 7 8 10',
  'E2-E4||E3 F3 B3 E4 D5 E4 E5 E6||60 8 7 7 8 26 9 7',
  'E2-E5||E3 F3 B3 E4 D5 E5 E6||60 8 7 7 8 13 5',
  'E2-E6||E3 F3 B3 E4 D5 E6||60 8 7 7 8 10',
  'E2-F4||E3 F3 B3 E4 D5 F4 F5 F6||60 8 7 7 8 26 9 7',
  'E2-F5||E3 F3 B3 E4 D5 F5 F6||60 8 7 7 8 13 5',
  'E2-F6||E3 F3 B3 E4 D5 F6||60 8 7 7 8 10',
  'E2-G4||E3 F3 B3 E4 D5 G4 G5 G6||60 8 7 7 8 26 9 7',
  'E2-G5||E3 F3 B3 E4 D5 G5 G6||60 8 7 7 8 13 5',
  'E2-G6||E3 F3 B3 E4 D5 G6||60 8 7 7 8 10',
  'F2-G2||F3 C4 F4 C5 A5 G3 D4 G4 F5 B5||60 8 50 5 6 60 10 5 7 4',
  'F2-A2||F3 C4 F4 C5 A5 A3 E4 A4 G5||60 8 50 5 6 60 12 5 7',
  'F2-B2||F3 C4 F4 C5 A5 B3 B4 A5||60 8 50 5 6 60 4 6',
  'F2-C3||F3 C4 F4 C5 A5 C3 B3 C4 G4 C5||60 8 50 5 6 40 13 54 12 5',
  'F2-D3||F3 C4 F4 C5 A5 D3 E3 D4 A4 D5 C6||60 8 50 5 6 40 4 56 11 4 6',
  'F2-E3||F3 C4 F4 C5 A5 E3 F3 E4 E5 D6||60 8 50 5 6 40 8 9 5 6',
  'F2-F3||F3 C4 F4 C5 A5 F3 F4 C5 F5 A5||60 8 50 5 6 40 9 2 6 6',
  'F2-G3||F3 C4 F4 C5 A5 G3 G4 D5 G5 B5 F6||60 8 50 5 6 40 8 3 5 6 5',
  'F2-A3||F3 C4 F4 C5 A5 A3 A4 E5 A5 G6||60 8 50 5 6 40 6 3 5 5',
  'F2-B3||F3 C4 F4 C5 A5 B3 C4 B4 B5 A6||60 8 50 5 6 40 5 28 12 5',
  'F2-A4||F3 C4 F4 C5 A5 A4 A5 A6||60 8 50 5 6 26 9 7',
  'F2-A5||F3 C4 F4 C5 A5 A5 A6||60 8 50 5 6 13 5',
  'F2-A6||F3 C4 F4 C5 A5 A6||60 8 50 5 6 10',
  'F2-B4||F3 C4 F4 C5 A5 B4 B5 B6||60 8 50 5 6 26 9 7',
  'F2-B5||F3 C4 F4 C5 A5 B5 B6||60 8 50 5 6 13 5',
  'F2-B6||F3 C4 F4 C5 A5 B6||60 8 50 5 6 10',
  'F2-C4||F3 C4 F4 C5 A5 C4 C5 C6||60 8 50 5 6 26 9 7',
  'F2-C5||F3 C4 F4 C5 A5 C5 C6||60 8 50 5 6 13 5',
  'F2-C6||F3 C4 F4 C5 A5 C6||60 8 50 5 6 10',
  'F2-D4||F3 C4 F4 C5 A5 D4 D5 D6||60 8 50 5 6 26 9 7',
  'F2-D5||F3 C4 F4 C5 A5 D5 D6||60 8 50 5 6 13 5',
  'F2-D6||F3 C4 F4 C5 A5 D6||60 8 50 5 6 10',
  'F2-E4||F3 C4 F4 C5 A5 E4 E5 E6||60 8 50 5 6 26 9 7',
  'F2-E5||F3 C4 F4 C5 A5 E5 E6||60 8 50 5 6 13 5',
  'F2-E6||F3 C4 F4 C5 A5 E6||60 8 50 5 6 10',
  'F2-F4||F3 C4 F4 C5 A5 F4 F5 F6||60 8 50 5 6 26 9 7',
  'F2-F5||F3 C4 F4 C5 A5 F5 F6||60 8 50 5 6 13 5',
  'F2-F6||F3 C4 F4 C5 A5 F6||60 8 50 5 6 10',
  'F2-G4||F3 C4 F4 C5 A5 G4 G5 G6||60 8 50 5 6 26 9 7',
  'F2-G5||F3 C4 F4 C5 A5 G5 G6||60 8 50 5 6 13 5',
  'F2-G6||F3 C4 F4 C5 A5 G6||60 8 50 5 6 10',
  'G2-A2||G3 D4 G4 F5 B5 A3 E4 A4 G5||60 10 5 7 4 60 12 5 7',
  'G2-B2||G3 D4 G4 F5 B5 B3 B4 A5||60 10 5 7 4 60 4 6',
  'G2-C3||G3 D4 G4 F5 B5 C3 B3 C4 G4 C5||60 10 5 7 4 40 13 54 12 5',
  'G2-D3||G3 D4 G4 F5 B5 D3 E3 D4 A4 D5 C6||60 10 5 7 4 40 4 56 11 4 6',
  'G2-E3||G3 D4 G4 F5 B5 E3 F3 E4 E5 D6||60 10 5 7 4 40 8 9 5 6',
  'G2-F3||G3 D4 G4 F5 B5 F3 F4 C5 F5 A5||60 10 5 7 4 40 9 2 6 6',
  'G2-G3||G3 D4 G4 F5 B5 G3 G4 D5 G5 B5 F6||60 10 5 7 4 40 8 3 5 6 5',
  'G2-A3||G3 D4 G4 F5 B5 A3 A4 E5 A5 G6||60 10 5 7 4 40 6 3 5 5',
  'G2-B3||G3 D4 G4 F5 B5 B3 C4 B4 B5 A6||60 10 5 7 4 40 5 28 12 5',
  'G2-A4||G3 D4 G4 F5 B5 A4 A5 A6||60 10 5 7 4 26 9 7',
  'G2-A5||G3 D4 G4 F5 B5 A5 A6||60 10 5 7 4 13 5',
  'G2-A6||G3 D4 G4 F5 B5 A6||60 10 5 7 4 10',
  'G2-B4||G3 D4 G4 F5 B5 B4 B5 B6||60 10 5 7 4 26 9 7',
  'G2-B5||G3 D4 G4 F5 B5 B5 B6||60 10 5 7 4 13 5',
  'G2-B6||G3 D4 G4 F5 B5 B6||60 10 5 7 4 10',
  'G2-C4||G3 D4 G4 F5 B5 C4 C5 C6||60 10 5 7 4 26 9 7',
  'G2-C5||G3 D4 G4 F5 B5 C5 C6||60 10 5 7 4 13 5',
  'G2-C6||G3 D4 G4 F5 B5 C6||60 10 5 7 4 10',
  'G2-D4||G3 D4 G4 F5 B5 D4 D5 D6||60 10 5 7 4 26 9 7',
  'G2-D5||G3 D4 G4 F5 B5 D5 D6||60 10 5 7 4 13 5',
  'G2-D6||G3 D4 G4 F5 B5 D6||60 10 5 7 4 10',
  'G2-E4||G3 D4 G4 F5 B5 E4 E5 E6||60 10 5 7 4 26 9 7',
  'G2-E5||G3 D4 G4 F5 B5 E5 E6||60 10 5 7 4 13 5',
  'G2-E6||G3 D4 G4 F5 B5 E6||60 10 5 7 4 10',
  'G2-F4||G3 D4 G4 F5 B5 F4 F5 F6||60 10 5 7 4 26 9 7',
  'G2-F5||G3 D4 G4 F5 B5 F5 F6||60 10 5 7 4 13 5',
  'G2-F6||G3 D4 G4 F5 B5 F6||60 10 5 7 4 10',
  'G2-G4||G3 D4 G4 F5 B5 G4 G5 G6||60 10 5 7 4 26 9 7',
  'G2-G5||G3 D4 G4 F5 B5 G5 G6||60 10 5 7 4 13 5',
  'G2-G6||G3 D4 G4 F5 B5 G6||60 10 5 7 4 10',
  'A2-B2||A3 E4 A4 G5 B3 B4 A5||60 12 5 7 60 4 6',
  'A2-C3||A3 E4 A4 G5 C3 B3 C4 G4 C5||60 12 5 7 40 13 54 12 5',
  'A2-D3||A3 E4 A4 G5 D3 E3 D4 A4 D5 C6||60 12 5 7 40 4 56 11 4 6',
  'A2-E3||A3 E4 A4 G5 E3 F3 E4 E5 D6||60 12 5 7 40 8 9 5 6',
  'A2-F3||A3 E4 A4 G5 F3 F4 C5 F5 A5||60 12 5 7 40 9 2 6 6',
  'A2-G3||A3 E4 A4 G5 G3 G4 D5 G5 B5 F6||60 12 5 7 40 8 3 5 6 5',
  'A2-A3||A3 E4 A4 G5 A3 A4 E5 A5 G6||60 12 5 7 40 6 3 5 5',
  'A2-B3||A3 E4 A4 G5 B3 C4 B4 B5 A6||60 12 5 7 40 5 28 12 5',
  'A2-A4||A3 E4 A4 G5 A4 A5 A6||60 12 5 7 26 9 7',
  'A2-A5||A3 E4 A4 G5 A5 A6||60 12 5 7 13 5',
  'A2-A6||A3 E4 A4 G5 A6||60 12 5 7 10',
  'A2-B4||A3 E4 A4 G5 B4 B5 B6||60 12 5 7 26 9 7',
  'A2-B5||A3 E4 A4 G5 B5 B6||60 12 5 7 13 5',
  'A2-B6||A3 E4 A4 G5 B6||60 12 5 7 10',
  'A2-C4||A3 E4 A4 G5 C4 C5 C6||60 12 5 7 26 9 7',
  'A2-C5||A3 E4 A4 G5 C5 C6||60 12 5 7 13 5',
  'A2-C6||A3 E4 A4 G5 C6||60 12 5 7 10',
  'A2-D4||A3 E4 A4 G5 D4 D5 D6||60 12 5 7 26 9 7',
  'A2-D5||A3 E4 A4 G5 D5 D6||60 12 5 7 13 5',
  'A2-D6||A3 E4 A4 G5 D6||60 12 5 7 10',
  'A2-E4||A3 E4 A4 G5 E4 E5 E6||60 12 5 7 26 9 7',
  'A2-E5||A3 E4 A4 G5 E5 E6||60 12 5 7 13 5',
  'A2-E6||A3 E4 A4 G5 E6||60 12 5 7 10',
  'A2-F4||A3 E4 A4 G5 F4 F5 F6||60 12 5 7 26 9 7',
  'A2-F5||A3 E4 A4 G5 F5 F6||60 12 5 7 13 5',
  'A2-F6||A3 E4 A4 G5 F6||60 12 5 7 10',
  'A2-G4||A3 E4 A4 G5 G4 G5 G6||60 12 5 7 26 9 7',
  'A2-G5||A3 E4 A4 G5 G5 G6||60 12 5 7 13 5',
  'A2-G6||A3 E4 A4 G5 G6||60 12 5 7 10',
  'B2-C3||B3 B4 A5 C3 B3 C4 G4 C5||60 4 6 40 13 54 12 5',
  'B2-D3||B3 B4 A5 D3 E3 D4 A4 D5 C6||60 4 6 40 4 56 11 4 6',
  'B2-E3||B3 B4 A5 E3 F3 E4 E5 D6||60 4 6 40 8 9 5 6',
  'B2-F3||B3 B4 A5 F3 F4 C5 F5 A5||60 4 6 40 9 2 6 6',
  'B2-G3||B3 B4 A5 G3 G4 D5 G5 B5 F6||60 4 6 40 8 3 5 6 5',
  'B2-A3||B3 B4 A5 A3 A4 E5 A5 G6||60 4 6 40 6 3 5 5',
  'B2-B3||B3 B4 A5 B3 C4 B4 B5 A6||60 4 6 40 5 28 12 5',
  'B2-A4||B3 B4 A5 A4 A5 A6||60 4 6 26 9 7',
  'B2-A5||B3 B4 A5 A5 A6||60 4 6 13 5',
  'B2-A6||B3 B4 A5 A6||60 4 6 10',
  'B2-B4||B3 B4 A5 B4 B5 B6||60 4 6 26 9 7',
  'B2-B5||B3 B4 A5 B5 B6||60 4 6 13 5',
  'B2-B6||B3 B4 A5 B6||60 4 6 10',
  'B2-C4||B3 B4 A5 C4 C5 C6||60 4 6 26 9 7',
  'B2-C5||B3 B4 A5 C5 C6||60 4 6 13 5',
  'B2-C6||B3 B4 A5 C6||60 4 6 10',
  'B2-D4||B3 B4 A5 D4 D5 D6||60 4 6 26 9 7',
  'B2-D5||B3 B4 A5 D5 D6||60 4 6 13 5',
  'B2-D6||B3 B4 A5 D6||60 4 6 10',
  'B2-E4||B3 B4 A5 E4 E5 E6||60 4 6 26 9 7',
  'B2-E5||B3 B4 A5 E5 E6||60 4 6 13 5',
  'B2-E6||B3 B4 A5 E6||60 4 6 10',
  'B2-F4||B3 B4 A5 F4 F5 F6||60 4 6 26 9 7',
  'B2-F5||B3 B4 A5 F5 F6||60 4 6 13 5',
  'B2-F6||B3 B4 A5 F6||60 4 6 10',
  'B2-G4||B3 B4 A5 G4 G5 G6||60 4 6 26 9 7',
  'B2-G5||B3 B4 A5 G5 G6||60 4 6 13 5',
  'B2-G6||B3 B4 A5 G6||60 4 6 10',
  'C3-D3||C3 B3 C4 G4 C5 D3 E3 D4 A4 D5 C6||40 13 54 12 5 40 4 56 11 4 6',
  'C3-E3||C3 B3 C4 G4 C5 E3 F3 E4 E5 D6||40 13 54 12 5 40 8 9 5 6',
  'C3-F3||C3 B3 C4 G4 C5 F3 F4 C5 F5 A5||40 13 54 12 5 40 9 2 6 6',
  'C3-G3||C3 B3 C4 G4 C5 G3 G4 D5 G5 B5 F6||40 13 54 12 5 40 8 3 5 6 5',
  'C3-A3||C3 B3 C4 G4 C5 A3 A4 E5 A5 G6||40 13 54 12 5 40 6 3 5 5',
  'C3-B3||C3 B3 C4 G4 C5 B3 C4 B4 B5 A6||40 13 54 12 5 40 5 28 12 5',
  'C3-A4||C3 B3 C4 G4 C5 A4 A5 A6||40 13 54 12 5 26 9 7',
  'C3-A5||C3 B3 C4 G4 C5 A5 A6||40 13 54 12 5 13 5',
  'C3-A6||C3 B3 C4 G4 C5 A6||40 13 54 12 5 10',
  'C3-B4||C3 B3 C4 G4 C5 B4 B5 B6||40 13 54 12 5 26 9 7',
  'C3-B5||C3 B3 C4 G4 C5 B5 B6||40 13 54 12 5 13 5',
  'C3-B6||C3 B3 C4 G4 C5 B6||40 13 54 12 5 10',
  'C3-C4||C3 B3 C4 G4 C5 C4 C5 C6||40 13 54 12 5 26 9 7',
  'C3-C5||C3 B3 C4 G4 C5 C5 C6||40 13 54 12 5 13 5',
  'C3-C6||C3 B3 C4 G4 C5 C6||40 13 54 12 5 10',
  'C3-D4||C3 B3 C4 G4 C5 D4 D5 D6||40 13 54 12 5 26 9 7',
  'C3-D5||C3 B3 C4 G4 C5 D5 D6||40 13 54 12 5 13 5',
  'C3-D6||C3 B3 C4 G4 C5 D6||40 13 54 12 5 10',
  'C3-E4||C3 B3 C4 G4 C5 E4 E5 E6||40 13 54 12 5 26 9 7',
  'C3-E5||C3 B3 C4 G4 C5 E5 E6||40 13 54 12 5 13 5',
  'C3-E6||C3 B3 C4 G4 C5 E6||40 13 54 12 5 10',
  'C3-F4||C3 B3 C4 G4 C5 F4 F5 F6||40 13 54 12 5 26 9 7',
  'C3-F5||C3 B3 C4 G4 C5 F5 F6||40 13 54 12 5 13 5',
  'C3-F6||C3 B3 C4 G4 C5 F6||40 13 54 12 5 10',
  'C3-G4||C3 B3 C4 G4 C5 G4 G5 G6||40 13 54 12 5 26 9 7',
  'C3-G5||C3 B3 C4 G4 C5 G5 G6||40 13 54 12 5 13 5',
  'C3-G6||C3 B3 C4 G4 C5 G6||40 13 54 12 5 10',
  'D3-E3||D3 E3 D4 A4 D5 C6 E3 F3 E4 E5 D6||40 4 56 11 4 6 40 8 9 5 6',
  'D3-F3||D3 E3 D4 A4 D5 C6 F3 F4 C5 F5 A5||40 4 56 11 4 6 40 9 2 6 6',
  'D3-G3||D3 E3 D4 A4 D5 C6 G3 G4 D5 G5 B5 F6||40 4 56 11 4 6 40 8 3 5 6 5',
  'D3-A3||D3 E3 D4 A4 D5 C6 A3 A4 E5 A5 G6||40 4 56 11 4 6 40 6 3 5 5',
  'D3-B3||D3 E3 D4 A4 D5 C6 B3 C4 B4 B5 A6||40 4 56 11 4 6 40 5 28 12 5',
  'D3-A4||D3 E3 D4 A4 D5 C6 A4 A5 A6||40 4 56 11 4 6 26 9 7',
  'D3-A5||D3 E3 D4 A4 D5 C6 A5 A6||40 4 56 11 4 6 13 5',
  'D3-A6||D3 E3 D4 A4 D5 C6 A6||40 4 56 11 4 6 10',
  'D3-B4||D3 E3 D4 A4 D5 C6 B4 B5 B6||40 4 56 11 4 6 26 9 7',
  'D3-B5||D3 E3 D4 A4 D5 C6 B5 B6||40 4 56 11 4 6 13 5',
  'D3-B6||D3 E3 D4 A4 D5 C6 B6||40 4 56 11 4 6 10',
  'D3-C4||D3 E3 D4 A4 D5 C6 C4 C5 C6||40 4 56 11 4 6 26 9 7',
  'D3-C5||D3 E3 D4 A4 D5 C6 C5 C6||40 4 56 11 4 6 13 5',
  'D3-C6||D3 E3 D4 A4 D5 C6 C6||40 4 56 11 4 6 10',
  'D3-D4||D3 E3 D4 A4 D5 C6 D4 D5 D6||40 4 56 11 4 6 26 9 7',
  'D3-D5||D3 E3 D4 A4 D5 C6 D5 D6||40 4 56 11 4 6 13 5',
  'D3-D6||D3 E3 D4 A4 D5 C6 D6||40 4 56 11 4 6 10',
  'D3-E4||D3 E3 D4 A4 D5 C6 E4 E5 E6||40 4 56 11 4 6 26 9 7',
  'D3-E5||D3 E3 D4 A4 D5 C6 E5 E6||40 4 56 11 4 6 13 5',
  'D3-E6||D3 E3 D4 A4 D5 C6 E6||40 4 56 11 4 6 10',
  'D3-F4||D3 E3 D4 A4 D5 C6 F4 F5 F6||40 4 56 11 4 6 26 9 7',
  'D3-F5||D3 E3 D4 A4 D5 C6 F5 F6||40 4 56 11 4 6 13 5',
  'D3-F6||D3 E3 D4 A4 D5 C6 F6||40 4 56 11 4 6 10',
  'D3-G4||D3 E3 D4 A4 D5 C6 G4 G5 G6||40 4 56 11 4 6 26 9 7',
  'D3-G5||D3 E3 D4 A4 D5 C6 G5 G6||40 4 56 11 4 6 13 5',
  'D3-G6||D3 E3 D4 A4 D5 C6 G6||40 4 56 11 4 6 10',
  'E3-F3||E3 F3 E4 E5 D6 F3 F4 C5 F5 A5||40 8 9 5 6 40 9 2 6 6',
  'E3-G3||E3 F3 E4 E5 D6 G3 G4 D5 G5 B5 F6||40 8 9 5 6 40 8 3 5 6 5',
  'E3-A3||E3 F3 E4 E5 D6 A3 A4 E5 A5 G6||40 8 9 5 6 40 6 3 5 5',
  'E3-B3||E3 F3 E4 E5 D6 B3 C4 B4 B5 A6||40 8 9 5 6 40 5 28 12 5',
  'E3-A4||E3 F3 E4 E5 D6 A4 A5 A6||40 8 9 5 6 26 9 7',
  'E3-A5||E3 F3 E4 E5 D6 A5 A6||40 8 9 5 6 13 5',
  'E3-A6||E3 F3 E4 E5 D6 A6||40 8 9 5 6 10',
  'E3-B4||E3 F3 E4 E5 D6 B4 B5 B6||40 8 9 5 6 26 9 7',
  'E3-B5||E3 F3 E4 E5 D6 B5 B6||40 8 9 5 6 13 5',
  'E3-B6||E3 F3 E4 E5 D6 B6||40 8 9 5 6 10',
  'E3-C4||E3 F3 E4 E5 D6 C4 C5 C6||40 8 9 5 6 26 9 7',
  'E3-C5||E3 F3 E4 E5 D6 C5 C6||40 8 9 5 6 13 5',
  'E3-C6||E3 F3 E4 E5 D6 C6||40 8 9 5 6 10',
  'E3-D4||E3 F3 E4 E5 D6 D4 D5 D6||40 8 9 5 6 26 9 7',
  'E3-D5||E3 F3 E4 E5 D6 D5 D6||40 8 9 5 6 13 5',
  'E3-D6||E3 F3 E4 E5 D6 D6||40 8 9 5 6 10',
  'E3-E4||E3 F3 E4 E5 D6 E4 E5 E6||40 8 9 5 6 26 9 7',
  'E3-E5||E3 F3 E4 E5 D6 E5 E6||40 8 9 5 6 13 5',
  'E3-E6||E3 F3 E4 E5 D6 E6||40 8 9 5 6 10',
  'E3-F4||E3 F3 E4 E5 D6 F4 F5 F6||40 8 9 5 6 26 9 7',
  'E3-F5||E3 F3 E4 E5 D6 F5 F6||40 8 9 5 6 13 5',
  'E3-F6||E3 F3 E4 E5 D6 F6||40 8 9 5 6 10',
  'E3-G4||E3 F3 E4 E5 D6 G4 G5 G6||40 8 9 5 6 26 9 7',
  'E3-G5||E3 F3 E4 E5 D6 G5 G6||40 8 9 5 6 13 5',
  'E3-G6||E3 F3 E4 E5 D6 G6||40 8 9 5 6 10',
  'F3-G3||F3 F4 C5 F5 A5 G3 G4 D5 G5 B5 F6||40 9 2 6 6 40 8 3 5 6 5',
  'F3-A3||F3 F4 C5 F5 A5 A3 A4 E5 A5 G6||40 9 2 6 6 40 6 3 5 5',
  'F3-B3||F3 F4 C5 F5 A5 B3 C4 B4 B5 A6||40 9 2 6 6 40 5 28 12 5',
  'F3-A4||F3 F4 C5 F5 A5 A4 A5 A6||40 9 2 6 6 26 9 7',
  'F3-A5||F3 F4 C5 F5 A5 A5 A6||40 9 2 6 6 13 5',
  'F3-A6||F3 F4 C5 F5 A5 A6||40 9 2 6 6 10',
  'F3-B4||F3 F4 C5 F5 A5 B4 B5 B6||40 9 2 6 6 26 9 7',
  'F3-B5||F3 F4 C5 F5 A5 B5 B6||40 9 2 6 6 13 5',
  'F3-B6||F3 F4 C5 F5 A5 B6||40 9 2 6 6 10',
  'F3-C4||F3 F4 C5 F5 A5 C4 C5 C6||40 9 2 6 6 26 9 7',
  'F3-C5||F3 F4 C5 F5 A5 C5 C6||40 9 2 6 6 13 5',
  'F3-C6||F3 F4 C5 F5 A5 C6||40 9 2 6 6 10',
  'F3-D4||F3 F4 C5 F5 A5 D4 D5 D6||40 9 2 6 6 26 9 7',
  'F3-D5||F3 F4 C5 F5 A5 D5 D6||40 9 2 6 6 13 5',
  'F3-D6||F3 F4 C5 F5 A5 D6||40 9 2 6 6 10',
  'F3-E4||F3 F4 C5 F5 A5 E4 E5 E6||40 9 2 6 6 26 9 7',
  'F3-E5||F3 F4 C5 F5 A5 E5 E6||40 9 2 6 6 13 5',
  'F3-E6||F3 F4 C5 F5 A5 E6||40 9 2 6 6 10',
  'F3-F4||F3 F4 C5 F5 A5 F4 F5 F6||40 9 2 6 6 26 9 7',
  'F3-F5||F3 F4 C5 F5 A5 F5 F6||40 9 2 6 6 13 5',
  'F3-F6||F3 F4 C5 F5 A5 F6||40 9 2 6 6 10',
  'F3-G4||F3 F4 C5 F5 A5 G4 G5 G6||40 9 2 6 6 26 9 7',
  'F3-G5||F3 F4 C5 F5 A5 G5 G6||40 9 2 6 6 13 5',
  'F3-G6||F3 F4 C5 F5 A5 G6||40 9 2 6 6 10',
  'G3-A3||G3 G4 D5 G5 B5 F6 A3 A4 E5 A5 G6||40 8 3 5 6 5 40 6 3 5 5',
  'G3-B3||G3 G4 D5 G5 B5 F6 B3 C4 B4 B5 A6||40 8 3 5 6 5 40 5 28 12 5',
  'G3-A4||G3 G4 D5 G5 B5 F6 A4 A5 A6||40 8 3 5 6 5 26 9 7',
  'G3-A5||G3 G4 D5 G5 B5 F6 A5 A6||40 8 3 5 6 5 13 5',
  'G3-A6||G3 G4 D5 G5 B5 F6 A6||40 8 3 5 6 5 10',
  'G3-B4||G3 G4 D5 G5 B5 F6 B4 B5 B6||40 8 3 5 6 5 26 9 7',
  'G3-B5||G3 G4 D5 G5 B5 F6 B5 B6||40 8 3 5 6 5 13 5',
  'G3-B6||G3 G4 D5 G5 B5 F6 B6||40 8 3 5 6 5 10',
  'G3-C4||G3 G4 D5 G5 B5 F6 C4 C5 C6||40 8 3 5 6 5 26 9 7',
  'G3-C5||G3 G4 D5 G5 B5 F6 C5 C6||40 8 3 5 6 5 13 5',
  'G3-C6||G3 G4 D5 G5 B5 F6 C6||40 8 3 5 6 5 10',
  'G3-D4||G3 G4 D5 G5 B5 F6 D4 D5 D6||40 8 3 5 6 5 26 9 7',
  'G3-D5||G3 G4 D5 G5 B5 F6 D5 D6||40 8 3 5 6 5 13 5',
  'G3-D6||G3 G4 D5 G5 B5 F6 D6||40 8 3 5 6 5 10',
  'G3-E4||G3 G4 D5 G5 B5 F6 E4 E5 E6||40 8 3 5 6 5 26 9 7',
  'G3-E5||G3 G4 D5 G5 B5 F6 E5 E6||40 8 3 5 6 5 13 5',
  'G3-E6||G3 G4 D5 G5 B5 F6 E6||40 8 3 5 6 5 10',
  'G3-F4||G3 G4 D5 G5 B5 F6 F4 F5 F6||40 8 3 5 6 5 26 9 7',
  'G3-F5||G3 G4 D5 G5 B5 F6 F5 F6||40 8 3 5 6 5 13 5',
  'G3-F6||G3 G4 D5 G5 B5 F6 F6||40 8 3 5 6 5 10',
  'G3-G4||G3 G4 D5 G5 B5 F6 G4 G5 G6||40 8 3 5 6 5 26 9 7',
  'G3-G5||G3 G4 D5 G5 B5 F6 G5 G6||40 8 3 5 6 5 13 5',
  'G3-G6||G3 G4 D5 G5 B5 F6 G6||40 8 3 5 6 5 10',
  'A3-B3||A3 A4 E5 A5 G6 B3 C4 B4 B5 A6||40 6 3 5 5 40 5 28 12 5',
  'A3-A4||A3 A4 E5 A5 G6 A4 A5 A6||40 6 3 5 5 26 9 7',
  'A3-A5||A3 A4 E5 A5 G6 A5 A6||40 6 3 5 5 13 5',
  'A3-A6||A3 A4 E5 A5 G6 A6||40 6 3 5 5 10',
  'A3-B4||A3 A4 E5 A5 G6 B4 B5 B6||40 6 3 5 5 26 9 7',
  'A3-B5||A3 A4 E5 A5 G6 B5 B6||40 6 3 5 5 13 5',
  'A3-B6||A3 A4 E5 A5 G6 B6||40 6 3 5 5 10',
  'A3-C4||A3 A4 E5 A5 G6 C4 C5 C6||40 6 3 5 5 26 9 7',
  'A3-C5||A3 A4 E5 A5 G6 C5 C6||40 6 3 5 5 13 5',
  'A3-C6||A3 A4 E5 A5 G6 C6||40 6 3 5 5 10',
  'A3-D4||A3 A4 E5 A5 G6 D4 D5 D6||40 6 3 5 5 26 9 7',
  'A3-D5||A3 A4 E5 A5 G6 D5 D6||40 6 3 5 5 13 5',
  'A3-D6||A3 A4 E5 A5 G6 D6||40 6 3 5 5 10',
  'A3-E4||A3 A4 E5 A5 G6 E4 E5 E6||40 6 3 5 5 26 9 7',
  'A3-E5||A3 A4 E5 A5 G6 E5 E6||40 6 3 5 5 13 5',
  'A3-E6||A3 A4 E5 A5 G6 E6||40 6 3 5 5 10',
  'A3-F4||A3 A4 E5 A5 G6 F4 F5 F6||40 6 3 5 5 26 9 7',
  'A3-F5||A3 A4 E5 A5 G6 F5 F6||40 6 3 5 5 13 5',
  'A3-F6||A3 A4 E5 A5 G6 F6||40 6 3 5 5 10',
  'A3-G4||A3 A4 E5 A5 G6 G4 G5 G6||40 6 3 5 5 26 9 7',
  'A3-G5||A3 A4 E5 A5 G6 G5 G6||40 6 3 5 5 13 5',
  'A3-G6||A3 A4 E5 A5 G6 G6||40 6 3 5 5 10',
  'B3-A4||B3 C4 B4 B5 A6 A4 A5 A6||40 5 28 12 5 26 9 7',
  'B3-A5||B3 C4 B4 B5 A6 A5 A6||40 5 28 12 5 13 5',
  'B3-A6||B3 C4 B4 B5 A6 A6||40 5 28 12 5 10',
  'B3-B4||B3 C4 B4 B5 A6 B4 B5 B6||40 5 28 12 5 26 9 7',
  'B3-B5||B3 C4 B4 B5 A6 B5 B6||40 5 28 12 5 13 5',
  'B3-B6||B3 C4 B4 B5 A6 B6||40 5 28 12 5 10',
  'B3-C4||B3 C4 B4 B5 A6 C4 C5 C6||40 5 28 12 5 26 9 7',
  'B3-C5||B3 C4 B4 B5 A6 C5 C6||40 5 28 12 5 13 5',
  'B3-C6||B3 C4 B4 B5 A6 C6||40 5 28 12 5 10',
  'B3-D4||B3 C4 B4 B5 A6 D4 D5 D6||40 5 28 12 5 26 9 7',
  'B3-D5||B3 C4 B4 B5 A6 D5 D6||40 5 28 12 5 13 5',
  'B3-D6||B3 C4 B4 B5 A6 D6||40 5 28 12 5 10',
  'B3-E4||B3 C4 B4 B5 A6 E4 E5 E6||40 5 28 12 5 26 9 7',
  'B3-E5||B3 C4 B4 B5 A6 E5 E6||40 5 28 12 5 13 5',
  'B3-E6||B3 C4 B4 B5 A6 E6||40 5 28 12 5 10',
  'B3-F4||B3 C4 B4 B5 A6 F4 F5 F6||40 5 28 12 5 26 9 7',
  'B3-F5||B3 C4 B4 B5 A6 F5 F6||40 5 28 12 5 13 5',
  'B3-F6||B3 C4 B4 B5 A6 F6||40 5 28 12 5 10',
  'B3-G4||B3 C4 B4 B5 A6 G4 G5 G6||40 5 28 12 5 26 9 7',
  'B3-G5||B3 C4 B4 B5 A6 G5 G6||40 5 28 12 5 13 5',
  'B3-G6||B3 C4 B4 B5 A6 G6||40 5 28 12 5 10',
  'A4-A5||A4 A5 A6 A5 A6||26 9 7 13 5',
  'A4-A6||A4 A5 A6 A6||26 9 7 10',
  'A4-B4||A4 A5 A6 B4 B5 B6||26 9 7 26 9 7',
  'A4-B5||A4 A5 A6 B5 B6||26 9 7 13 5',
  'A4-B6||A4 A5 A6 B6||26 9 7 10',
  'A4-C4||A4 A5 A6 C4 C5 C6||26 9 7 26 9 7',
  'A4-C5||A4 A5 A6 C5 C6||26 9 7 13 5',
  'A4-C6||A4 A5 A6 C6||26 9 7 10',
  'A4-D4||A4 A5 A6 D4 D5 D6||26 9 7 26 9 7',
  'A4-D5||A4 A5 A6 D5 D6||26 9 7 13 5',
  'A4-D6||A4 A5 A6 D6||26 9 7 10',
  'A4-E4||A4 A5 A6 E4 E5 E6||26 9 7 26 9 7',
  'A4-E5||A4 A5 A6 E5 E6||26 9 7 13 5',
  'A4-E6||A4 A5 A6 E6||26 9 7 10',
  'A4-F4||A4 A5 A6 F4 F5 F6||26 9 7 26 9 7',
  'A4-F5||A4 A5 A6 F5 F6||26 9 7 13 5',
  'A4-F6||A4 A5 A6 F6||26 9 7 10',
  'A4-G4||A4 A5 A6 G4 G5 G6||26 9 7 26 9 7',
  'A4-G5||A4 A5 A6 G5 G6||26 9 7 13 5',
  'A4-G6||A4 A5 A6 G6||26 9 7 10',
  'A5-A6||A5 A6 A6||13 5 10',
  'A5-B4||A5 A6 B4 B5 B6||13 5 26 9 7',
  'A5-B5||A5 A6 B5 B6||13 5 13 5',
  'A5-B6||A5 A6 B6||13 5 10',
  'A5-C4||A5 A6 C4 C5 C6||13 5 26 9 7',
  'A5-C5||A5 A6 C5 C6||13 5 13 5',
  'A5-C6||A5 A6 C6||13 5 10',
  'A5-D4||A5 A6 D4 D5 D6||13 5 26 9 7',
  'A5-D5||A5 A6 D5 D6||13 5 13 5',
  'A5-D6||A5 A6 D6||13 5 10',
  'A5-E4||A5 A6 E4 E5 E6||13 5 26 9 7',
  'A5-E5||A5 A6 E5 E6||13 5 13 5',
  'A5-E6||A5 A6 E6||13 5 10',
  'A5-F4||A5 A6 F4 F5 F6||13 5 26 9 7',
  'A5-F5||A5 A6 F5 F6||13 5 13 5',
  'A5-F6||A5 A6 F6||13 5 10',
  'A5-G4||A5 A6 G4 G5 G6||13 5 26 9 7',
  'A5-G5||A5 A6 G5 G6||13 5 13 5',
  'A5-G6||A5 A6 G6||13 5 10',
  'A6-B4||A6 B4 B5 B6||10 26 9 7',
  'A6-B5||A6 B5 B6||10 13 5',
  'A6-B6||A6 B6||10 10',
  'A6-C4||A6 C4 C5 C6||10 26 9 7',
  'A6-C5||A6 C5 C6||10 13 5',
  'A6-C6||A6 C6||10 10',
  'A6-D4||A6 D4 D5 D6||10 26 9 7',
  'A6-D5||A6 D5 D6||10 13 5',
  'A6-D6||A6 D6||10 10',
  'A6-E4||A6 E4 E5 E6||10 26 9 7',
  'A6-E5||A6 E5 E6||10 13 5',
  'A6-E6||A6 E6||10 10',
  'A6-F4||A6 F4 F5 F6||10 26 9 7',
  'A6-F5||A6 F5 F6||10 13 5',
  'A6-F6||A6 F6||10 10',
  'A6-G4||A6 G4 G5 G6||10 26 9 7',
  'A6-G5||A6 G5 G6||10 13 5',
  'A6-G6||A6 G6||10 10',
  'B4-B5||B4 B5 B6 B5 B6||26 9 7 13 5',
  'B4-B6||B4 B5 B6 B6||26 9 7 10',
  'B4-C4||B4 B5 B6 C4 C5 C6||26 9 7 26 9 7',
  'B4-C5||B4 B5 B6 C5 C6||26 9 7 13 5',
  'B4-C6||B4 B5 B6 C6||26 9 7 10',
  'B4-D4||B4 B5 B6 D4 D5 D6||26 9 7 26 9 7',
  'B4-D5||B4 B5 B6 D5 D6||26 9 7 13 5',
  'B4-D6||B4 B5 B6 D6||26 9 7 10',
  'B4-E4||B4 B5 B6 E4 E5 E6||26 9 7 26 9 7',
  'B4-E5||B4 B5 B6 E5 E6||26 9 7 13 5',
  'B4-E6||B4 B5 B6 E6||26 9 7 10',
  'B4-F4||B4 B5 B6 F4 F5 F6||26 9 7 26 9 7',
  'B4-F5||B4 B5 B6 F5 F6||26 9 7 13 5',
  'B4-F6||B4 B5 B6 F6||26 9 7 10',
  'B4-G4||B4 B5 B6 G4 G5 G6||26 9 7 26 9 7',
  'B4-G5||B4 B5 B6 G5 G6||26 9 7 13 5',
  'B4-G6||B4 B5 B6 G6||26 9 7 10',
  'B5-B6||B5 B6 B6||13 5 10',
  'B5-C4||B5 B6 C4 C5 C6||13 5 26 9 7',
  'B5-C5||B5 B6 C5 C6||13 5 13 5',
  'B5-C6||B5 B6 C6||13 5 10',
  'B5-D4||B5 B6 D4 D5 D6||13 5 26 9 7',
  'B5-D5||B5 B6 D5 D6||13 5 13 5',
  'B5-D6||B5 B6 D6||13 5 10',
  'B5-E4||B5 B6 E4 E5 E6||13 5 26 9 7',
  'B5-E5||B5 B6 E5 E6||13 5 13 5',
  'B5-E6||B5 B6 E6||13 5 10',
  'B5-F4||B5 B6 F4 F5 F6||13 5 26 9 7',
  'B5-F5||B5 B6 F5 F6||13 5 13 5',
  'B5-F6||B5 B6 F6||13 5 10',
  'B5-G4||B5 B6 G4 G5 G6||13 5 26 9 7',
  'B5-G5||B5 B6 G5 G6||13 5 13 5',
  'B5-G6||B5 B6 G6||13 5 10',
  'B6-C4||B6 C4 C5 C6||10 26 9 7',
  'B6-C5||B6 C5 C6||10 13 5',
  'B6-C6||B6 C6||10 10',
  'B6-D4||B6 D4 D5 D6||10 26 9 7',
  'B6-D5||B6 D5 D6||10 13 5',
  'B6-D6||B6 D6||10 10',
  'B6-E4||B6 E4 E5 E6||10 26 9 7',
  'B6-E5||B6 E5 E6||10 13 5',
  'B6-E6||B6 E6||10 10',
  'B6-F4||B6 F4 F5 F6||10 26 9 7',
  'B6-F5||B6 F5 F6||10 13 5',
  'B6-F6||B6 F6||10 10',
  'B6-G4||B6 G4 G5 G6||10 26 9 7',
  'B6-G5||B6 G5 G6||10 13 5',
  'B6-G6||B6 G6||10 10',
  'C4-C5||C4 C5 C6 C5 C6||26 9 7 13 5',
  'C4-C6||C4 C5 C6 C6||26 9 7 10',
  'C4-D4||C4 C5 C6 D4 D5 D6||26 9 7 26 9 7',
  'C4-D5||C4 C5 C6 D5 D6||26 9 7 13 5',
  'C4-D6||C4 C5 C6 D6||26 9 7 10',
  'C4-E4||C4 C5 C6 E4 E5 E6||26 9 7 26 9 7',
  'C4-E5||C4 C5 C6 E5 E6||26 9 7 13 5',
  'C4-E6||C4 C5 C6 E6||26 9 7 10',
  'C4-F4||C4 C5 C6 F4 F5 F6||26 9 7 26 9 7',
  'C4-F5||C4 C5 C6 F5 F6||26 9 7 13 5',
  'C4-F6||C4 C5 C6 F6||26 9 7 10',
  'C4-G4||C4 C5 C6 G4 G5 G6||26 9 7 26 9 7',
  'C4-G5||C4 C5 C6 G5 G6||26 9 7 13 5',
  'C4-G6||C4 C5 C6 G6||26 9 7 10',
  'C5-C6||C5 C6 C6||13 5 10',
  'C5-D4||C5 C6 D4 D5 D6||13 5 26 9 7',
  'C5-D5||C5 C6 D5 D6||13 5 13 5',
  'C5-D6||C5 C6 D6||13 5 10',
  'C5-E4||C5 C6 E4 E5 E6||13 5 26 9 7',
  'C5-E5||C5 C6 E5 E6||13 5 13 5',
  'C5-E6||C5 C6 E6||13 5 10',
  'C5-F4||C5 C6 F4 F5 F6||13 5 26 9 7',
  'C5-F5||C5 C6 F5 F6||13 5 13 5',
  'C5-F6||C5 C6 F6||13 5 10',
  'C5-G4||C5 C6 G4 G5 G6||13 5 26 9 7',
  'C5-G5||C5 C6 G5 G6||13 5 13 5',
  'C5-G6||C5 C6 G6||13 5 10',
  'C6-D4||C6 D4 D5 D6||10 26 9 7',
  'C6-D5||C6 D5 D6||10 13 5',
  'C6-D6||C6 D6||10 10',
  'C6-E4||C6 E4 E5 E6||10 26 9 7',
  'C6-E5||C6 E5 E6||10 13 5',
  'C6-E6||C6 E6||10 10',
  'C6-F4||C6 F4 F5 F6||10 26 9 7',
  'C6-F5||C6 F5 F6||10 13 5',
  'C6-F6||C6 F6||10 10',
  'C6-G4||C6 G4 G5 G6||10 26 9 7',
  'C6-G5||C6 G5 G6||10 13 5',
  'C6-G6||C6 G6||10 10',
  'D4-D5||D4 D5 D6 D5 D6||26 9 7 13 5',
  'D4-D6||D4 D5 D6 D6||26 9 7 10',
  'D4-E4||D4 D5 D6 E4 E5 E6||26 9 7 26 9 7',
  'D4-E5||D4 D5 D6 E5 E6||26 9 7 13 5',
  'D4-E6||D4 D5 D6 E6||26 9 7 10',
  'D4-F4||D4 D5 D6 F4 F5 F6||26 9 7 26 9 7',
  'D4-F5||D4 D5 D6 F5 F6||26 9 7 13 5',
  'D4-F6||D4 D5 D6 F6||26 9 7 10',
  'D4-G4||D4 D5 D6 G4 G5 G6||26 9 7 26 9 7',
  'D4-G5||D4 D5 D6 G5 G6||26 9 7 13 5',
  'D4-G6||D4 D5 D6 G6||26 9 7 10',
  'D5-D6||D5 D6 D6||13 5 10',
  'D5-E4||D5 D6 E4 E5 E6||13 5 26 9 7',
  'D5-E5||D5 D6 E5 E6||13 5 13 5',
  'D5-E6||D5 D6 E6||13 5 10',
  'D5-F4||D5 D6 F4 F5 F6||13 5 26 9 7',
  'D5-F5||D5 D6 F5 F6||13 5 13 5',
  'D5-F6||D5 D6 F6||13 5 10',
  'D5-G4||D5 D6 G4 G5 G6||13 5 26 9 7',
  'D5-G5||D5 D6 G5 G6||13 5 13 5',
  'D5-G6||D5 D6 G6||13 5 10',
  'D6-E4||D6 E4 E5 E6||10 26 9 7',
  'D6-E5||D6 E5 E6||10 13 5',
  'D6-E6||D6 E6||10 10',
  'D6-F4||D6 F4 F5 F6||10 26 9 7',
  'D6-F5||D6 F5 F6||10 13 5',
  'D6-F6||D6 F6||10 10',
  'D6-G4||D6 G4 G5 G6||10 26 9 7',
  'D6-G5||D6 G5 G6||10 13 5',
  'D6-G6||D6 G6||10 10',
  'E4-E5||E4 E5 E6 E5 E6||26 9 7 13 5',
  'E4-E6||E4 E5 E6 E6||26 9 7 10',
  'E4-F4||E4 E5 E6 F4 F5 F6||26 9 7 26 9 7',
  'E4-F5||E4 E5 E6 F5 F6||26 9 7 13 5',
  'E4-F6||E4 E5 E6 F6||26 9 7 10',
  'E4-G4||E4 E5 E6 G4 G5 G6||26 9 7 26 9 7',
  'E4-G5||E4 E5 E6 G5 G6||26 9 7 13 5',
  'E4-G6||E4 E5 E6 G6||26 9 7 10',
  'E5-E6||E5 E6 E6||13 5 10',
  'E5-F4||E5 E6 F4 F5 F6||13 5 26 9 7',
  'E5-F5||E5 E6 F5 F6||13 5 13 5',
  'E5-F6||E5 E6 F6||13 5 10',
  'E5-G4||E5 E6 G4 G5 G6||13 5 26 9 7',
  'E5-G5||E5 E6 G5 G6||13 5 13 5',
  'E5-G6||E5 E6 G6||13 5 10',
  'E6-F4||E6 F4 F5 F6||10 26 9 7',
  'E6-F5||E6 F5 F6||10 13 5',
  'E6-F6||E6 F6||10 10',
  'E6-G4||E6 G4 G5 G6||10 26 9 7',
  'E6-G5||E6 G5 G6||10 13 5',
  'E6-G6||E6 G6||10 10',
  'F4-F5||F4 F5 F6 F5 F6||26 9 7 13 5',
  'F4-F6||F4 F5 F6 F6||26 9 7 10',
  'F4-G4||F4 F5 F6 G4 G5 G6||26 9 7 26 9 7',
  'F4-G5||F4 F5 F6 G5 G6||26 9 7 13 5',
  'F4-G6||F4 F5 F6 G6||26 9 7 10',
  'F5-F6||F5 F6 F6||13 5 10',
  'F5-G4||F5 F6 G4 G5 G6||13 5 26 9 7',
  'F5-G5||F5 F6 G5 G6||13 5 13 5',
  'F5-G6||F5 F6 G6||13 5 10',
  'F6-G4||F6 G4 G5 G6||10 26 9 7',
  'F6-G5||F6 G5 G6||10 13 5',
  'F6-G6||F6 G6||10 10',
  'G4-G5||G4 G5 G6 G5 G6||26 9 7 13 5',
  'G4-G6||G4 G5 G6 G6||26 9 7 10',
  'G5-G6||G5 G6 G6||13 5 10',
  // 'A2-A3||A3 A3 A3 A2 A2 E4 C#6 C#6 G#3 E4 G#2 C#2 G5 D2 A#2||55 40 18 12 9 6 4 4 4 4 3 3 3 3 2',
  // 'A2-A4||A3 A3 A2 A3 A4 A2 A4 E4 G#2 A5 A4 C#2 A6 E4 G5||30 21 12 11 11 9 7 5 4 4 4 4 3 3 3',
  // 'A2-A5||A3 A3 A5 A5 A2 A3 A2 A5 E4 C#2 A5 E4 G#2 G5 A4||30 21 20 17 12 11 9 9 6 4 4 3 3 3 2',
  // 'A2-A6||A3 A3 A2 A3 A2 E4 A6 E4 G#2 G5 A6 E4 A#2 A4 G5||30 21 12 11 9 6 5 3 3 3 3 2 2 2 2',
  // 'A2-A7||A3 A3 A2 A3 A2 E4 G#2 E4 C#2 G5 E4 A4 G5 A#2 D2||30 21 12 11 9 6 3 3 3 3 2 2 2 2 2',
  // 'A2-B2||A3 B3 B3 A3 A2 B2 B3 A3 A2 A#2 E4 F#4 F#4 C3 G#2||30 30 22 21 12 12 10 10 10 9 6 5 4 4 4',
  // 'A2-B3||A3 A3 B3 B3 B4 A2 A3 B4 A2 F#5 B3 E4 B5 F#5 G#2||31 22 20 14 14 12 12 10 10 8 7 6 5 5 4',
  // 'A2-B4||A3 A3 B4 A2 A3 A2 B4 E4 B4 B5 G#2 G5 E4 B6 A#2||30 21 13 12 11 10 8 6 4 4 3 3 3 3 3',
  // 'A2-B5||A3 A3 B5 B5 A2 A3 A2 B5 E4 E4 G#2 G5 B5 E4 A#2||30 21 19 15 12 11 9 8 6 3 3 3 3 2 2',
  // 'A2-B6||A3 A3 A2 A3 A2 E4 B6 E4 G#2 G5 C#2 B6 E4 A#2 A4||30 21 12 11 9 6 4 3 3 3 3 3 2 2 2',
  // 'A2-B7||A3 A3 A2 A3 A2 E4 E4 G5 C#2 G#2 A#2 E4 A4 G5 C#6||30 21 11 11 9 6 3 3 3 3 2 2 2 2 2',
  // 'A2-C3||A3 C4 C4 A3 C3 A2 A3 A2 C3 B3 E4 B2 G4 C4 G4||30 27 26 22 12 12 11 9 6 6 6 6 6 5 4',
  // 'A2-C4||A3 A3 C4 C4 C5 A2 A3 C5 A2 G5 C6 E4 C5 C6 B3||29 21 17 17 14 11 11 9 9 8 6 6 5 4 4',
  // 'A2-C5||A3 A3 A2 A3 C5 C6 A2 C6 C5 E4 G#2 E4 C#2 G5 C6||30 21 12 11 9 9 8 8 7 6 4 3 3 3 3',
  // 'A2-C6||A3 A3 C6 C6 A2 A3 A2 C6 E4 G#2 E4 C6 G5 C#2 D2||30 21 17 15 12 11 9 7 6 4 3 3 3 3 3',
  // 'A2-C7||A3 A3 A2 A3 A2 E4 C7 G#2 E4 G5 C#2 E4 C7 A4 G5||30 21 12 11 9 6 4 4 3 3 3 2 2 2 2',
  // 'A2-D3||A3 D4 D4 A3 D3 A2 A3 A2 D4 D3 A4 E4 A4 C#3 C#2||30 28 24 22 13 12 11 9 7 6 6 6 5 5 4',
  // 'A2-D4||A3 A3 D4 D4 D5 A2 A3 A2 D5 A5 D5 E4 D6 D6 A5||30 22 17 15 15 12 11 9 8 7 7 6 5 4 4',
  // 'A2-D5||A3 A3 A2 A3 A2 D5 D6 D5 D6 E4 C#2 D5 E4 G5 G#2||30 21 12 11 10 9 8 7 6 6 4 3 3 3 3',
  // 'A2-D6||A3 A3 A2 A3 D6 A2 D6 E4 D6 C#2 E4 G#2 G5 D7 E4||30 21 12 11 10 9 6 6 5 4 3 3 3 2 2',
  // 'A2-D7||A3 A3 A2 A3 A2 E4 E4 G5 C#2 D7 E4 G#2 A4 A#2 G5||30 21 12 11 9 6 3 3 3 3 2 2 2 2 2',
  // 'A2-E3||A3 E3 E3 A3 A2 A3 A2 E4 E4 D#3 F3 G#2 G5 G#5 E4||30 23 22 21 12 11 10 8 7 5 4 3 3 3 3',
  // 'A2-E4||A3 A3 E4 E4 A2 A3 A2 E4 E6 E5 G#2 E5 G#6 C#2 E4||30 22 22 16 12 10 9 7 6 6 4 4 3 3 3',
  // 'A2-E5||A3 A3 A2 A3 A2 E6 E4 E5 E6 E4 G#2 G5 E4 A#2 E6||30 21 12 11 9 6 6 4 3 3 3 3 2 2 2',
  // 'A2-E6||A3 A3 A2 A3 A2 E6 E6 E4 E6 E4 G#2 G5 E4 A#2 A4||30 21 12 11 9 8 6 6 3 3 3 3 2 2 2',
  // 'A2-E7||A3 A3 A2 A3 A2 E4 G#2 E4 G5 C#2 D2 A#2 E4 A4 G5||30 21 12 11 9 6 4 3 3 3 2 2 2 2 2',
  // 'A2-F3||A3 F3 A3 F3 A2 A3 A2 E3 E4 F4 G#2 G5 E4 C#2 A5||30 25 22 20 12 10 9 8 5 4 3 3 3 3 3',
  // 'A2-F4||A3 F4 A3 F4 A3 A2 F4 A2 F5 E4 F6 F6 F5 E4 C#2||30 22 21 12 11 11 11 9 8 6 5 4 4 4 4',
  // 'A2-F5||A3 A3 A2 A3 A2 F5 E4 F6 F6 G#2 E4 G5 C#2 D2 F5||30 21 12 11 9 6 6 5 4 4 3 3 3 3 3',
  // 'A2-F6||A3 A3 F6 A2 F6 A3 A2 E4 G#2 E4 G5 F6 F6 E4 A#2||30 21 12 12 12 10 9 6 4 3 3 3 3 2 2',
  // 'A2-F7||A3 A3 A2 A3 A2 E4 E4 C#2 G#2 G5 D2 E4 A4 A#2 G5||30 21 12 11 9 6 3 3 3 3 3 2 2 2 2',
  // 'A2-G3||A3 G3 A3 G3 A2 A3 A2 F#3 E4 G4 G#2 E4 G5 B5 G5||29 26 21 19 12 10 9 8 6 4 3 3 3 3 2',
  // 'A2-G4||A3 A3 G4 G4 A2 A3 G4 A2 G5 E4 G5 G6 G6 C#2 D2||30 21 21 12 12 11 9 9 8 6 5 5 4 4 3',
  // 'A2-G5||A3 A3 A2 A3 A2 G5 E4 G6 G#2 G6 E4 G5 C#2 G5 G5||29 21 12 11 9 6 6 5 4 3 3 3 3 3 2',
  // 'A2-G6||A3 A3 G6 A2 A3 G6 A2 E4 G6 E4 G#2 G5 C#2 A#2 E4||30 21 12 12 11 10 9 6 4 3 3 3 3 2 2',
  // 'A2-G7||A3 A3 A2 A3 A2 E4 G#2 E4 G5 C#2 E4 D2 A4 A#2 G5||30 21 12 11 9 6 3 3 3 3 2 2 2 2 2',
  // 'A3-A4||A3 A3 A4 A4 A3 A5 A4 A5 A6 A5 C#6 A6 G#3 E7 C#6||26 19 16 11 8 6 6 4 4 3 3 3 2 2 2',
  // 'A3-A5||A3 A3 A5 A5 A3 A5 A5 A6 A4 C#6 G#3 C#6 A4 G6 A5||26 19 18 15 8 8 3 3 3 3 2 2 2 2 1',
  // 'A3-A6||A3 A3 A3 A6 A6 A4 C#6 A4 G#3 C#6 G6 A5 A5 A6 C#2||26 19 8 4 3 3 3 2 2 2 2 2 2 2 2',
  // 'A3-A7||A3 A3 A3 A4 C#6 A4 G#3 C#6 G6 A5 A5 A4 A#3 E5 A7||26 19 8 3 3 2 2 2 2 2 2 1 1 1 1',
  // 'A3-B3||A3 B3 A3 B3 B4 B4 A3 F#5 B3 B5 F#5 B4 B5 D#6 C#2||27 20 20 14 14 10 9 8 7 6 5 4 3 3 3',
  // 'A3-B4||A3 A3 B4 A3 B4 B4 B6 B5 A4 B6 C#6 B5 A4 G#3 C#6||26 19 13 8 8 4 4 4 3 3 3 3 2 2 2',
  // 'A3-B5||A3 A3 B5 B5 A3 B5 B5 A4 C#6 A4 A5 C#6 G6 G#3 B5||26 19 19 15 8 8 3 3 3 2 2 2 2 2 2',
  // 'A3-B6||A3 A3 A3 B6 A4 C#6 A4 G#3 C#6 A5 G6 D2 A5 B6 E2||26 19 8 4 3 3 2 2 2 2 2 2 2 2 2',
  // 'A3-B7||A3 A3 A3 A4 C#6 A4 G#3 C#6 G6 A5 A5 A#3 A4 E5 C#2||26 19 8 3 3 2 2 2 2 2 2 1 1 1 1',
  // 'A3-C4||A3 A3 C4 C4 C5 C5 G5 A3 C6 C5 B3 C6 G5 G5 C4||26 19 18 17 14 9 8 8 6 5 4 4 4 3 3',
  // 'A3-C5||A3 A3 C5 C6 A3 C6 C5 A4 A4 C#6 A#1 C6 A1 C5 G6||26 19 9 9 8 8 7 3 3 3 3 2 2 2 2',
  // 'A3-C6||A3 A3 C6 C6 A3 C6 C6 A4 C#6 A4 G#3 G6 C6 A5 A5||26 19 17 15 8 7 3 3 3 2 2 2 2 2 2',
  // 'A3-C7||A3 A3 A3 C7 A4 C#6 A4 C7 G#3 C#6 A5 G6 A5 C7 A#3||26 19 8 4 3 3 2 2 2 2 2 2 2 2 1',
  // 'A3-D4||A3 A3 D4 D4 D5 A3 D5 D5 A5 D6 D6 D4 A5 A4 F#6||27 19 17 15 15 8 8 7 6 5 4 4 3 3 3',
  // 'A3-D5||A3 A3 D5 D6 A3 D5 D6 D5 A4 D6 C#6 A4 A5 G6 C#6||26 19 9 8 8 7 6 3 3 3 3 2 2 2 2',
  // 'A3-D6||A3 A3 D6 A3 D6 D6 A4 D7 C#6 A4 G#3 G6 D7 A5 C#6||26 19 10 8 6 5 3 2 2 2 2 2 2 2 2',
  // 'A3-D7||A3 A3 A3 A4 D7 C#6 A4 C#6 G6 G#3 A5 A5 F#2 D7 D7||26 19 8 3 3 3 2 2 2 2 2 2 2 1 1',
  // 'A3-E4||A3 E4 A3 E4 A3 E6 E5 E4 E5 G#6 E4 E6 E6 B5 C#6||27 20 19 17 8 7 7 6 5 4 4 3 3 3 3',
  // 'A3-E5||A3 A3 A3 E6 E5 E5 E6 A4 C#6 E6 C#2 C#6 A4 G6 A5||26 19 8 6 6 4 4 3 3 3 2 2 2 2 2',
  // 'A3-E6||A3 A3 A3 E6 E6 E6 A4 C#6 A4 C#2 G6 C#6 A5 G#3 E7||26 19 8 8 7 3 3 3 2 2 2 2 2 2 2',
  // 'A3-E7||A3 A3 A3 A4 C#6 E7 A4 G#3 C#6 G6 A5 A5 E7 A#3 A4||26 19 8 3 3 2 2 2 2 2 2 2 2 1 1',
  // 'A3-F4||A3 F4 A3 F4 F4 A3 F5 F6 F6 F5 A6 F5 F#4 A4 C6||26 22 19 12 11 8 8 5 4 4 3 3 3 3 3',
  // 'A3-F5||A3 A3 A3 F5 F6 F6 A4 C#6 F5 G#3 A4 F5 G6 C#6 A5||26 19 8 6 5 4 3 3 3 2 2 2 2 2 2',
  // 'A3-F6||A3 A3 F6 F6 A3 F6 F6 A4 C#6 G#3 C#6 A4 G6 A5 A5||26 19 12 12 8 3 3 3 3 2 2 2 2 2 2',
  // 'A3-F7||A3 A3 A3 A4 C#6 A4 C#6 G6 G#3 A5 A5 D2 F7 F7 C#2||26 19 8 3 3 2 2 2 2 2 2 2 2 1 1',
  // 'A3-G4||A3 G4 A3 G4 G4 A3 G5 G5 G6 G6 A4 B6 A4 G5 C#6||26 21 19 12 9 9 8 5 5 4 3 3 3 3 3',
  // 'A3-G5||A3 A3 A3 G5 G6 G6 G5 A4 C#6 G5 A4 G#3 C#6 A5 G6||26 19 8 6 5 4 3 3 3 2 2 2 2 2 2',
  // 'A3-G6||A3 A3 G6 G6 A3 G6 A4 C#6 A4 G#3 C#6 A5 G6 G6 A5||26 19 12 10 8 4 3 3 2 2 2 2 2 2 2',
  // 'A3-G7||A3 A3 A3 A4 C#6 G#3 A4 C#6 G6 A5 A5 A#3 G7 A4 E5||26 19 8 3 3 2 2 2 2 2 2 1 1 1 1',
  // 'A4-A5||A5 A5 A4 A5 A4 A4 A6 A5 C2 A6 A#1 E7 E7 A5 A6||18 15 13 8 8 4 4 4 3 3 3 2 2 2 2',
  // 'A4-A6||A4 A4 A4 A5 A6 A6 A5 E7 E7 A5 E6 E6 D2 A#1 C2||13 9 5 4 3 3 2 2 2 2 2 2 2 1 1',
  // 'A4-A7||A4 A4 A4 A5 A6 A6 A5 E7 E7 A5 E6 E6 C2 G#2 A#1||13 9 5 4 3 3 2 2 2 2 2 2 1 1 1',
  // 'A4-B4||A4 B4 A4 B4 A4 B4 A5 B5 A6 B6 B5 A6 A5 B6 E7||13 13 9 9 5 4 4 4 3 3 3 3 2 2 2',
  // 'A4-B5||B5 B5 A4 A4 B5 A4 A5 A6 B5 A6 A5 E7 B5 E7 E6||19 15 13 9 8 5 4 3 3 3 2 2 2 2 2',
  // 'A4-B6||A4 A4 A4 A5 B6 A6 A6 B6 A5 E7 E7 A5 E6 E6 B6||13 9 4 4 4 3 3 2 2 2 2 2 2 2 2',
  // 'A4-B7||A4 A4 A4 A5 A6 A6 A5 E7 E7 A5 E6 E6 C2 A#1 C#7||13 9 5 4 3 3 2 2 2 2 2 2 1 1 1',
  // 'A4-C5||A4 A4 C5 C6 C6 C5 A4 A5 A6 A6 C6 E7 A5 A1 E7||13 9 9 9 8 7 4 4 3 3 2 2 2 2 2',
  // 'A4-C6||C6 C6 A4 A4 C6 A4 A5 C6 A6 A6 A5 E7 C6 E7 A5||17 15 13 9 7 5 4 3 3 3 2 2 2 2 2',
  // 'A4-C7||A4 A4 A4 A5 C7 A6 A6 A5 E7 C7 E7 A5 E6 E6 C7||13 9 5 4 4 3 3 2 2 2 2 2 2 2 2',
  // 'A4-D5||A4 D5 A4 D6 D5 D6 A4 A5 A6 A6 C2 D5 A#1 D6 A5||13 9 9 8 7 6 4 4 4 4 4 4 3 3 2',
  // 'A4-D6||A4 D6 A4 D6 D6 A4 A5 C2 A6 A6 A#1 D7 A5 E7 D7||13 10 9 6 5 5 4 3 3 3 2 2 2 2 2',
  // 'A4-D7||A4 A4 A4 A5 A6 D7 A6 A5 E7 E7 A5 E6 E6 D7 D7||13 9 5 4 3 3 3 2 2 2 2 2 2 1 1',
  // 'A4-E5||A4 A4 E6 E5 E6 A4 A5 E5 A6 E6 A6 E7 A5 A5 E7||13 8 8 6 6 5 4 4 3 3 3 2 2 2 2',
  // 'A4-E6||A4 E6 A4 E6 E6 A4 A5 A6 A6 E7 A5 E7 A5 E7 E7||13 9 9 7 5 5 4 3 3 2 2 2 2 2 2',
  // 'A4-E7||A4 A4 A4 A5 A6 A6 E7 A5 E7 A5 E6 E7 E6 C2 E7||13 9 5 4 3 3 2 2 2 2 2 2 2 2 1',
  // 'A4-F5||A4 A4 F5 F6 A4 A5 F6 A6 F5 A6 A5 E7 F5 A5 E7||13 9 6 5 4 4 4 3 3 3 2 2 2 2 2',
  // 'A4-F6||A4 F6 F6 A4 A4 A5 A6 F6 F6 A6 A5 E7 E7 E6 A5||13 12 12 8 4 4 3 3 3 3 2 2 2 2 2',
  // 'A4-F7||A4 A4 A4 A5 A6 A6 A5 E7 E7 A5 E6 E6 F7 F7 C2||13 9 5 4 3 3 2 2 2 2 2 2 2 1 1',
  // 'A4-G5||A4 A4 G5 G6 A4 A5 G6 A6 G5 A6 G5 A5 E7 E7 A5||13 9 6 5 4 4 3 3 3 3 2 2 2 2 2',
  // 'A4-G6||A4 G6 G6 A4 A4 A5 G6 A6 A6 A5 E7 E7 A5 G6 E6||13 12 10 9 5 4 4 3 2 2 2 2 2 2 2',
  // 'A4-G7||A4 A4 A4 A5 A6 A6 A5 E7 E7 A5 E6 E6 G7 C2 C#7||13 9 5 4 3 3 2 2 2 2 2 2 1 1 1',
  // 'A5-A6||A5 A5 A5 A6 A5 A6 A6 A6 D2 C2 A5 A#1 C#2 E2 E7||20 17 9 5 4 3 3 2 2 1 1 1 1 1 1',
  // 'A5-A7||A5 A5 A5 A5 A6 C2 A5 A#1 A6 E7 E7 A7 A#5 A5 C#2||20 17 9 4 2 2 1 1 1 1 1 1 1 1 1',
  // 'A5-B5||A5 B5 A5 B5 A5 B5 A5 B5 B5 A6 B6 A5 A6 E7 E7||20 19 17 15 8 8 4 3 2 2 2 1 1 1 1',
  // 'A5-B6||A5 A5 A5 B6 A5 B6 A6 B6 A5 C2 A#1 E2 A6 E7 F2||20 17 9 4 4 2 2 2 1 1 1 1 1 1 1',
  // 'A5-B7||A5 A5 A5 A5 A6 A5 C2 A#1 A6 E7 E7 A#5 A5 B7 A6||21 17 9 4 2 1 1 1 1 1 1 1 1 1 1',
  // 'A5-C6||A5 C6 A5 C6 A5 C6 A5 C6 C6 A6 C2 C7 A5 C#2 D2||21 17 17 15 9 7 4 4 2 2 2 1 1 1 1',
  // 'A5-C7||A5 A5 A5 A5 C7 C7 A6 F2 C7 A5 C2 F#2 E2 A6 A#1||20 17 9 4 4 2 2 2 2 1 1 1 1 1 1',
  // 'A5-D6||A5 A5 D6 A5 D6 D6 A5 C2 A#1 D7 D7 A6 C#2 A5 A6||21 17 10 9 6 5 4 3 3 2 2 2 2 1 1',
  // 'A5-D7||A5 A5 A5 A5 D7 A6 C2 D7 A5 D7 A#1 A6 G#2 G2 E7||20 17 9 4 3 2 1 1 1 1 1 1 1 1 1',
  // 'A5-E6||A5 A5 E6 A5 E6 A5 E6 E7 A6 E7 A5 E7 A6 A#1 E6||21 17 9 9 7 4 4 2 2 2 1 1 1 1 1',
  // 'A5-E7||A5 A5 A5 A5 E7 E7 A6 C2 A5 A#1 C#2 A6 A#5 A5 D2||20 17 9 4 3 2 2 2 1 1 1 1 1 1 1',
  // 'A5-F6||A5 A5 F6 F6 A5 A5 F6 F6 D2 A6 C2 E2 F7 C#2 A5||20 17 12 12 9 4 3 3 2 2 2 2 2 1 1',
  // 'A5-F7||A5 A5 A5 A5 A6 F7 A5 F7 A#1 C2 A6 E7 E7 A#5 D2||20 17 9 4 2 2 1 1 1 1 1 1 1 1 1',
  // 'A5-G6||A5 A5 G6 G6 A5 A5 G6 A6 G6 A5 C2 E2 F2 A#1 G6||20 17 12 10 9 4 4 2 2 1 1 1 1 1 1',
  // 'A5-G7||A5 A5 A5 A5 A6 A5 G7 C2 A6 E7 E7 G7 A#5 A#1 A5||20 17 9 4 2 1 1 1 1 1 1 1 1 1 1',
  // 'B2-A3||B3 A3 B3 A3 B2 B3 A3 A#2 F#4 F#4 C3 A4 A5 E2 A4||29 27 21 19 12 10 8 8 5 4 4 3 3 3 2',
  // 'B2-A4||B3 B3 A4 B2 B3 A4 A#2 F#4 A4 C3 F#4 A5 A6 A5 E2||29 22 13 12 10 9 8 5 5 4 4 4 3 3 3',
  // 'B2-A5||B3 B3 A5 A5 B2 B3 A5 A#2 F#4 F#4 A5 C3 A5 E2 A5||30 22 21 17 12 10 9 8 5 4 4 4 3 3 3',
  // 'B2-A6||B3 B3 B2 B3 A#2 F#4 A6 F#4 C3 E2 D2 A6 A5 A5 A#3||30 22 12 10 7 5 5 4 4 4 4 3 3 2 2',
  // 'B2-A7||B3 B3 B2 B3 A#2 F#4 F#4 C3 A5 E2 A5 A#3 B4 D2 B4||30 22 12 10 8 5 4 4 3 3 2 2 2 2 2',
  // 'B2-B3||B3 B3 B2 B4 B4 B3 A#2 F#5 B5 F#4 D#6 F#5 B4 F#4 A#3||33 27 12 12 8 8 8 7 6 5 4 4 4 4 4',
  // 'B2-B4||B3 B3 B2 B4 B3 A#2 B4 F#4 F#4 B5 C3 B4 E2 A5 B6||30 22 12 10 10 8 7 5 4 4 4 4 3 3 3',
  // 'B2-B5||B3 B3 B5 B5 B2 B3 B5 A#2 F#4 F#4 C3 E2 B5 A5 D2||30 22 18 15 12 10 8 8 5 4 4 3 3 3 2',
  // 'B2-B6||B3 B3 B2 B3 A#2 F#4 F#4 C3 B6 A5 B6 A5 A#3 B4 B4||30 22 12 10 8 5 4 4 4 3 2 2 2 2 2',
  // 'B2-B7||B3 B3 B2 B3 A#2 F#4 F#4 C3 E2 A5 A5 A#3 B4 D2 B4||30 22 12 10 8 5 4 4 3 3 2 2 2 2 2',
  // 'B2-C3||B3 C4 C4 B3 B2 C3 B3 A#2 G4 C3 F#4 C4 F#4 G4 A5||30 28 25 21 10 10 9 8 6 5 5 5 4 4 3',
  // 'B2-C4||B3 B3 C4 C4 C5 B2 C5 G5 A#2 B3 C6 C5 F#4 F#4 C3||30 22 18 17 14 11 9 8 8 6 6 5 5 4 4',
  // 'B2-C5||B3 B3 B2 B3 C6 C5 A#2 C6 C5 F#4 F#4 A#1 C3 A1 A5||30 22 12 10 9 9 8 8 7 5 4 4 4 4 3',
  // 'B2-C6||B3 B3 C6 C6 B2 B3 A#2 C6 F#4 F#4 C3 C6 E2 D2 A5||30 22 17 15 12 10 8 7 5 4 4 3 3 3 3',
  // 'B2-C7||B3 B3 B2 B3 A#2 F#4 F#4 C3 E2 C7 A5 F2 D2 A5 B4||30 22 12 10 8 5 4 4 4 4 3 2 2 2 2',
  // 'B2-D3||B3 D4 D4 B3 D3 B2 B3 D4 A#2 C#3 D3 A4 F#4 F#4 A4||30 28 24 22 12 11 10 8 8 6 6 5 5 4 4',
  // 'B2-D4||B3 B3 D4 D4 D5 B2 B3 D5 A5 A#2 D5 D6 F#4 F#4 C3||29 22 17 15 15 11 10 8 7 7 7 5 5 4 4',
  // 'B2-D5||B3 B3 B2 B3 D5 D6 A#2 D5 D6 F#4 F#4 E2 D5 A5 C3||30 22 11 10 9 8 8 7 6 5 4 3 3 3 3',
  // 'B2-D6||B3 B3 B2 B3 D6 A#2 D6 D6 F#4 F#4 C3 E2 A5 D7 D7||30 22 12 10 10 8 6 5 5 4 4 3 3 3 2',
  // 'B2-D7||B3 B3 B2 B3 A#2 F#4 F#4 C3 D7 A5 E2 A5 A#3 B4 D2||30 22 12 10 8 5 4 4 3 3 3 2 2 2 2',
  // 'B2-E3||B3 E3 E3 B3 B2 B3 A#2 F#4 D#3 F#4 E4 C3 F3 G#5 A5||30 22 22 21 12 11 8 5 5 4 4 4 4 3 3',
  // 'B2-E4||B3 B3 E4 E4 B2 B3 A#2 E5 E4 E5 E6 F#4 C3 F#4 E4||29 21 21 17 12 10 8 7 6 6 6 5 5 4 4',
  // 'B2-E5||B3 B3 B2 B3 A#2 E6 E5 F#4 F#4 C3 E5 E6 E2 A5 E6||30 22 12 10 8 6 6 5 4 4 4 3 3 3 2',
  // 'B2-E6||B3 B3 B2 B3 E6 A#2 E6 F#4 F#4 C3 E6 E2 A5 D2 A5||30 22 12 10 9 8 7 5 4 4 4 3 3 2 2',
  // 'B2-E7||B3 B3 B2 B3 A#2 F#4 F#4 C3 E2 A5 D2 A#3 A5 B4 E7||30 22 12 10 8 5 4 4 3 3 3 2 2 2 2',
  // 'B2-F3||B3 F3 B3 F3 B2 B3 A#2 E3 F#4 F#4 C3 F4 E2 A5 A5||29 25 22 19 12 10 8 8 6 5 4 4 3 3 3',
  // 'B2-F4||B3 F4 B3 B2 F4 F4 B3 A#2 F5 F#4 F#4 F6 F6 F5 C3||30 22 22 12 12 11 11 8 8 6 5 5 4 4 4',
  // 'B2-F5||B3 B3 B2 B3 A#2 F5 F6 F#4 F#4 C3 F6 D2 E2 A5 F5||30 22 12 10 8 6 5 5 4 4 4 3 3 3 3',
  // 'B2-F6||B3 B3 F6 B2 F6 B3 A#2 F#4 E2 F#4 D2 C3 F6 A5 F6||30 22 13 12 12 10 8 5 4 4 4 4 3 3 3',
  // 'B2-F7||B3 B3 B2 B3 A#2 F#4 F#4 C3 A5 A5 B4 E2 A#3 B4 D#6||30 21 12 10 8 5 4 4 3 2 2 2 2 2 2',
  // 'B2-G3||B3 G3 B3 G3 B2 B3 F#3 A#2 F#4 F#4 G4 C3 E2 A5 B5||30 26 22 20 12 10 9 8 5 4 4 3 3 3 3',
  // 'B2-G4||B3 B3 G4 G4 B2 B3 G4 G5 A#2 F#4 G5 G6 G6 F#4 C3||30 22 21 12 11 10 9 8 7 5 5 5 4 4 4',
  // 'B2-G5||B3 B3 B2 B3 A#2 G5 F#4 G6 F#4 C3 G6 E2 A5 G5 G5||30 22 12 10 8 6 5 5 4 4 3 3 3 3 2',
  // 'B2-G6||B3 B3 G6 B2 B3 G6 A#2 F#4 F#4 C3 G6 A5 A5 A#3 B4||30 22 12 12 10 10 8 5 4 4 4 3 2 2 2',
  // 'B2-G7||B3 B3 B2 B3 A#2 F#4 F#4 C3 E2 A5 A5 A#3 D2 B4 B4||30 22 12 10 8 5 4 4 3 3 2 2 2 2 2',
  // 'B3-A4||B3 B3 B4 A4 B4 A4 F#5 B3 B5 F#5 A4 B4 A5 B5 A6||20 14 14 13 10 8 8 7 6 5 4 4 4 3 3',
  // 'B3-A5||A5 B3 A5 B3 B4 B4 A5 F#5 B3 B5 F#5 B4 A5 B5 D#6||20 20 17 14 14 10 9 8 7 6 5 4 4 4 3',
  // 'B3-A6||B3 B3 B4 B4 F#5 B3 B5 F#5 A6 B4 B5 D#6 C#2 A6 F#5||20 14 14 10 8 7 6 5 5 4 3 3 3 3 2',
  // 'B3-A7||B3 B3 B4 B4 F#5 B3 B5 F#5 B4 B5 D#6 A6 C4 F#5 B5||20 14 14 10 8 7 6 5 4 3 3 2 2 2 2',
  // 'B3-B4||B4 B3 B4 B3 B4 F#5 B3 F#5 B6 D#6 B5 C#2 F#5 A6 B6||25 20 18 14 8 8 7 5 3 3 3 3 2 2 2',
  // 'B3-B5||B5 B3 B5 B3 B4 B4 B5 F#5 B3 F#5 B4 C#2 B5 D#6 C2||22 20 17 14 14 10 8 8 7 5 4 3 3 3 3',
  // 'B3-B6||B3 B3 B4 B4 F#5 B3 B5 F#5 B4 B6 B5 D#6 A6 F#5 C4||20 14 14 10 8 7 6 5 4 4 3 3 2 2 2',
  // 'B3-B7||B3 B3 B4 B4 F#5 B3 B5 F#5 B4 B5 D#6 A6 F#5 B5 C4||20 14 14 10 8 7 6 5 4 3 3 2 2 2 2',
  // 'B3-C4||C4 B3 C4 C5 B4 B3 B4 B3 C5 G5 F#5 C6 B5 C5 F#5||20 20 17 14 14 13 10 10 9 8 8 6 6 5 5',
  // 'B3-C5||B3 B3 B4 B4 C5 C6 F#5 C6 C5 B3 B5 F#5 B4 B5 D#6||20 14 13 11 9 9 8 7 7 7 6 5 4 3 3',
  // 'B3-C6||B3 C6 C6 B3 B4 B4 F#5 B3 C6 B5 F#5 B4 C6 B5 D#6||20 17 15 14 14 10 8 7 7 6 5 4 3 3 3',
  // 'B3-C7||B3 B3 B4 B4 F#5 B3 B5 F#5 B4 C7 B5 D#6 A6 F#5 C4||20 14 14 10 8 7 6 5 4 4 3 3 2 2 2',
  // 'B3-D4||B3 D4 D4 B3 D5 B4 B4 D5 F#5 B3 A5 D5 B5 D6 F#5||21 18 15 15 15 14 10 8 8 8 7 7 6 5 5',
  // 'B3-D5||B3 B3 B4 B4 D5 D6 F#5 B3 D5 D6 B5 F#5 B4 D5 B5||20 14 14 10 9 8 8 7 7 6 6 5 4 4 3',
  // 'B3-D6||B3 B3 B4 D6 B4 F#5 B3 B5 D6 D6 F#5 B4 B5 D#6 F#5||20 14 14 10 10 8 7 6 6 5 5 4 4 3 3',
  // 'B3-D7||B3 B3 B4 B4 F#5 B3 B5 F#5 B4 B5 D#6 D7 A6 F#5 C4||20 14 14 10 8 7 6 5 4 3 3 3 2 2 2',
  // 'B3-E4||E4 B3 E4 B4 B3 B4 B5 F#5 E5 B3 E4 E5 E6 B5 F#5||20 20 17 14 13 10 9 8 7 7 7 6 6 5 5',
  // 'B3-E5||B3 B3 B4 B4 F#5 B3 B5 E6 E5 F#5 B4 E5 C#2 B5 E6||20 14 14 10 8 7 6 6 6 5 4 4 3 3 3',
  // 'B3-E6||B3 B3 B4 B4 E6 F#5 E6 B3 B5 F#5 B4 E6 C#2 B5 D#6||20 14 14 10 9 8 7 7 6 5 4 4 4 3 3',
  // 'B3-E7||B3 B3 B4 B4 F#5 B3 B5 F#5 B4 B5 D#6 A6 F#5 C4 B5||20 14 14 10 8 7 6 5 4 3 3 2 2 2 2',
  // 'B3-F4||F4 B3 B3 B4 F4 F4 B4 F5 F#5 B3 B5 F6 F#5 F6 F5||22 20 15 14 12 11 10 8 8 6 6 5 5 4 4',
  // 'B3-F5||B3 B3 B4 B4 F#5 B3 F5 B5 F6 F#5 B4 F6 B5 D#6 F#5||20 14 14 10 8 7 6 6 5 5 4 4 3 3 3',
  // 'B3-F6||B3 B3 B4 F6 F6 B4 F#5 B3 B5 F#5 B4 B5 D#6 F6 F6||20 14 14 12 12 10 8 7 6 5 4 3 3 3 3',
  // 'B3-F7||B3 B3 B4 B4 F#5 B3 B5 F#5 B4 B5 D#6 A6 F#5 C4 B5||20 14 14 10 8 7 6 5 4 3 3 2 2 2 2',
  // 'B3-G4||G4 B3 B3 B4 G4 B4 G4 G5 F#5 B3 B5 F#5 G5 G6 G6||21 20 14 14 12 10 9 8 8 7 6 5 5 5 4',
  // 'B3-G5||B3 B3 B4 B4 F#5 B3 G5 B5 G6 F#5 B4 G6 B5 D#6 G5||20 14 14 10 8 7 6 6 5 5 4 3 3 3 3',
  // 'B3-G6||B3 B3 B4 G6 B4 G6 F#5 B3 B5 F#5 B4 G6 B5 D#6 C4||20 14 14 12 10 10 8 7 6 5 4 4 3 3 3',
  // 'B3-G7||B3 B3 B4 B4 F#5 B3 B5 F#5 B4 B5 D#6 A6 F#5 C4 B5||20 14 14 10 8 7 6 5 4 3 3 2 2 2 2',
  // 'B4-A5||A5 A5 B4 A5 B4 B4 A5 B5 B6 B5 B6 A6 F#7 F#6 F#6||20 17 12 9 8 4 4 4 3 2 2 2 2 2 2',
  // 'B4-A6||B4 B4 A6 B4 B5 C#2 A6 D2 B6 B5 B6 A6 F#7 F#6 B5||13 8 5 4 4 3 3 3 3 3 2 2 2 2 2',
  // 'B4-A7||B4 B4 B4 B5 B6 B5 B6 F#7 F#6 B5 F#6 F#7 A7 C#2 D#7||13 8 4 4 3 3 2 2 2 2 1 1 1 1 1',
  // 'B4-B5||B5 B5 B4 B4 B5 B4 B6 B5 C#2 B5 B6 B6 F#7 D2 F#6||16 13 12 8 8 4 3 3 3 2 2 2 2 2 2',
  // 'B4-B6||B4 B4 B4 B5 B6 B5 B6 F#7 F#6 B5 F#6 F#7 C#2 E2 F2||13 8 4 4 3 3 2 2 2 2 2 1 1 1 1',
  // 'B4-B7||B4 B4 B4 B5 B6 B5 B6 F#7 F#6 B5 F#6 F#7 C#2 A2 B7||13 8 4 4 3 3 2 2 2 2 2 1 1 1 1',
  // 'B4-C5||B4 C5 B4 C6 C6 C5 B5 B4 C5 B6 A#1 E7 C6 B5 A1||12 9 9 9 8 7 4 4 3 3 3 3 2 2 2',
  // 'B4-C6||C6 C6 B4 B4 C6 B4 B5 C6 B6 B5 B6 C6 F#7 D2 C#2||17 15 13 8 7 4 4 4 3 3 2 2 2 2 2',
  // 'B4-C7||B4 B4 B4 B5 C7 B6 B5 B6 C7 F#7 F#6 B5 C7 F#6 F2||13 8 4 4 4 3 3 2 2 2 2 2 2 1 1',
  // 'B4-D5||B4 D5 B4 D6 D5 D6 B4 B5 D5 B6 D6 B5 B6 C2 F#7||13 9 9 8 7 6 4 4 3 3 3 3 2 2 2',
  // 'B4-D6||B4 D6 B4 D6 D6 B4 B5 B6 B5 D7 B6 D7 F#7 F#6 F#6||12 10 8 6 5 4 4 3 3 2 2 2 2 2 2',
  // 'B4-D7||B4 B4 B4 B5 B6 D7 B5 B6 F#7 F#6 B5 F#6 F#7 D7 D7||13 8 4 4 3 3 3 2 2 2 2 2 1 1 1',
  // 'B4-E5||B4 B4 E6 E5 B4 E5 B5 E6 C#2 B5 E6 B6 B6 F#7 F#6||13 8 6 6 4 4 4 3 3 3 2 2 2 2 2',
  // 'B4-E6||B4 E6 B4 E6 B4 B5 E6 B6 C#2 B5 B6 E7 D2 F#7 E7||13 9 8 7 4 4 4 3 3 3 2 2 2 2 2',
  // 'B4-E7||B4 B4 B4 B5 B6 B5 B6 E7 F#7 F#6 B5 C#2 F#6 F#7 D2||13 8 4 4 3 3 2 2 2 2 2 2 1 1 1',
  // 'B4-F5||B4 B4 F5 F6 B4 B5 F6 B6 B5 F5 B6 F5 D2 F#7 C#2||13 9 6 5 4 4 4 3 3 3 2 2 2 2 2',
  // 'B4-F6||B4 F6 F6 B4 B4 B5 C#2 D2 F6 B6 F6 B5 B6 F#7 E2||13 12 12 8 4 4 3 3 3 3 3 3 2 2 2',
  // 'B4-F7||B4 B4 B4 B5 B6 B5 B6 F#7 F#6 F7 B5 F#6 F#7 F7 C#2||13 8 4 4 3 3 2 2 2 2 2 2 1 1 1',
  // 'B4-G5||B4 B4 G5 G6 B4 B5 G6 B6 G5 B5 G5 B6 F#7 B5 F#6||13 8 6 5 4 4 3 3 3 3 3 2 2 2 2',
  // 'B4-G6||B4 G6 G6 B4 B4 B5 G6 B6 B5 B6 G6 F#7 F#6 B5 F#6||13 12 10 8 4 4 4 3 3 2 2 2 2 1 1',
  // 'B4-G7||B4 B4 B4 B5 B6 B5 B6 F#7 F#6 B5 F#6 F#7 G7 C#2 G7||13 8 4 4 3 3 2 2 2 2 2 1 1 1 1',
  // 'B5-A6||B5 B5 B5 A6 A6 C#2 D2 B5 B5 A6 B6 E2 C2 F#7 A6||19 15 8 5 3 3 3 3 2 2 2 1 1 1 1',
  // 'B5-A7||B5 B5 B5 B5 B5 B6 C#2 A7 F#7 B5 D2 F#2 B6 B6 A7||19 15 8 3 2 2 1 1 1 1 1 1 1 1 1',
  // 'B5-B6||B5 B5 B5 B6 B6 B5 B5 B6 C#2 B6 F2 D2 E2 F#7 B5||19 15 8 3 3 3 2 2 1 1 1 1 1 1 1',
  // 'B5-B7||B5 B5 B5 B5 B5 B6 C#2 F#7 B5 D2 B7 B6 B6 C2||19 15 8 3 2 2 1 1 1 1 1 1 1 1',
  // 'B5-C6||B5 C6 C6 B5 B5 C6 B5 C6 C6 B5 B6 C7 C#2 D2 F#7||19 17 15 15 8 7 3 3 2 2 2 1 1 1 1',
  // 'B5-C7||B5 B5 B5 C7 B5 C7 B5 F2 C7 B6 E2 F#2 C#2 F#7 D2||19 15 8 4 3 2 2 2 2 2 1 1 1 1 1',
  // 'B5-D6||B5 B5 D6 B5 D6 D6 B5 D7 D7 B5 B6 A#1 C2 F#7 D6||19 15 10 8 6 5 3 2 2 2 2 1 1 1 1',
  // 'B5-D7||B5 B5 B5 B5 D7 B5 B6 D7 D7 G2 F#2 C#2 G#2 F#7 B5||19 15 8 3 3 2 2 1 1 1 1 1 1 1 1',
  // 'B5-E6||B5 B5 E6 B5 E6 E6 B5 C#2 D2 B5 E7 B6 C2 E7 F#7||19 15 9 8 7 4 3 3 2 2 2 2 2 2 1',
  // 'B5-E7||B5 B5 B5 B5 B5 E7 B6 E7 C#2 D2 F#7 B5 E7 B6 B6||19 15 8 3 2 2 2 1 1 1 1 1 1 1 1',
  // 'B5-F6||B5 B5 F6 F6 B5 B5 D2 F6 C#2 F6 B5 E2 B6 F7 D1||19 15 12 12 8 3 3 3 3 3 2 2 2 2 1',
  // 'B5-F7||B5 B5 B5 B5 B5 B6 F7 F7 C#2 F#7 B5 C2 B6 B6||19 15 8 3 2 2 2 1 1 1 1 1 1 1',
  // 'B5-G6||B5 B5 G6 G6 B5 G6 B5 B5 G6 B6 F2 C#2 D2 E2 G6||19 15 12 10 8 4 3 2 2 2 1 1 1 1 1',
  // 'B5-G7||B5 B5 B5 B5 B5 B6 G7 C#2 F#7 G7 B5 C2 G7 B6 E2||19 15 8 3 2 2 1 1 1 1 1 1 1 1 1',
  // 'C2-A2||A3 C3 A3 C3 A2 A3 A2 C2 G3 A#1 B2 G#2 E4 G3 A#2||30 25 21 18 12 11 8 6 6 5 4 4 4 4 4',
  // 'C2-A3||A3 C3 A3 C3 A3 G3 C2 B2 A#1 G3 G4 G4 A#6 A4 A1||26 25 19 17 8 7 7 6 5 4 3 3 3 3 3',
  // 'C2-A4||C3 C3 A4 A4 G3 B2 C2 A4 A5 G3 G4 A6 A0 G4 A#1||25 17 13 8 6 6 5 4 4 4 3 3 3 3 3',
  // 'C2-A5||C3 A5 A5 C3 A5 G3 B2 C2 G3 A5 G4 A#1 D2 G4 C#3||25 21 17 17 9 7 6 4 4 4 3 3 3 3 3',
  // 'C2-A6||C3 C3 G3 C2 B2 D2 A6 A#1 G3 C#2 G4 A6 G1 G4 A1||25 17 6 6 6 5 5 4 4 3 3 3 3 3 3',
  // 'C2-A7||C3 C3 G3 C2 B2 A#1 G3 G4 D2 G4 A#6 A1 C#3 A0 E5||25 17 6 6 6 4 4 3 3 3 3 3 3 3 3',
  // 'C2-B2||B3 C3 B3 C3 B2 B3 C2 G3 D2 F#4 A#2 A#1 F#4 A1 G3||30 29 21 19 15 11 7 6 5 5 5 5 4 4 4',
  // 'C2-B3||C3 B3 C3 B3 B4 B4 C2 F#5 B3 G3 B2 B5 A#1 F#5 G3||25 20 17 14 14 10 8 8 7 7 6 6 5 5 5',
  // 'C2-B4||C3 C3 B4 B4 C2 G3 B2 A#1 B4 D2 B5 G3 B6 G4 G4||25 17 13 8 7 6 6 5 4 4 4 4 3 3 3',
  // 'C2-B5||C3 B5 C3 B5 B5 C2 G3 B2 A#1 G3 D2 B5 G4 C#2 G4||25 19 17 15 8 7 6 6 5 4 4 3 3 3 3',
  // 'C2-B6||C3 C3 G3 C2 B2 A#1 G3 B6 G4 G4 A#6 F2 C#3 E5 A#2||25 17 6 6 6 4 4 3 3 3 3 3 3 3 3',
  // 'C2-B7||C3 C3 G3 C2 B2 A#1 G3 G4 D2 G4 A1 A#6 C#3 G1 A#2||25 17 6 6 6 4 4 3 3 3 3 3 3 3 3',
  // 'C2-C3||C4 C4 C3 C3 G4 G4 C2 G3 B3 A#1 C4 C#3 G3 E5 A#5||27 26 13 10 9 7 7 6 6 6 5 4 4 3 3',
  // 'C2-C4||C3 C3 C4 C4 C5 C5 G5 B2 G3 C2 C6 C5 D2 G3 A#1||25 17 17 17 14 9 8 6 6 6 5 5 5 4 4',
  // 'C2-C5||C3 C3 C5 C6 C2 C6 C5 B2 A#1 G3 A1 G3 G1 G4 C#3||25 17 9 9 8 8 7 7 7 6 5 4 3 3 3',
  // 'C2-C6||C3 C6 C3 C6 C6 G3 B2 C2 D2 A#1 G3 C6 G4 G4 A#6||25 17 17 15 7 7 6 6 4 4 4 3 3 3 3',
  // 'C2-C7||C3 C3 G3 C2 B2 A#1 G3 C7 D2 G4 G4 A#6 A1 E5 C#3||25 17 6 6 6 4 4 4 3 3 3 3 3 3 2',
  // 'C2-D2||D2 C#2 D3 C3 C3 C#3 D3 E2 C2 G3 B2 A#1 G3 C5 G1||37 33 27 26 16 15 15 11 9 7 6 5 4 4 4',
  // 'C2-D3||D4 C3 D4 C3 D3 D4 G3 C2 C#3 D3 B2 A4 A#1 G2 A4||28 25 24 16 13 8 7 7 6 6 6 5 5 4 4',
  // 'C2-D4||C3 D4 C3 D4 D5 D5 A5 D5 C2 G3 B2 D6 A#1 A5 D6||25 17 17 15 15 8 7 7 6 6 5 5 5 4 4',
  // 'C2-D5||C3 C3 D6 D5 G3 D6 B2 D5 G3 C2 G4 A#1 G4 D2 D6||25 16 9 8 7 6 6 6 4 3 3 3 3 3 3',
  // 'C2-D6||C3 C3 D6 G3 B2 D6 D6 C2 G3 A#1 G4 G4 D2 A#6 G1||25 17 10 7 6 6 6 4 4 3 3 3 3 3 3',
  // 'C2-D7||C3 C3 G3 C2 B2 A#1 G3 G4 D2 G4 A1 G1 A#6 D7 A0||25 17 6 6 6 5 4 3 3 3 3 3 3 3 3',
  // 'C2-E2||E2 F2 C3 E3 E3 C3 D2 G3 D#3 B2 C2 G3 D5 F3 A#6||37 28 26 24 22 18 9 7 6 6 6 4 4 4 4',
  // 'C2-E3||C3 E3 E3 C3 C2 G3 B2 A#1 D#3 C#3 E4 G3 A#6 G4 F3||25 23 22 17 7 7 6 5 5 4 4 4 3 3 3',
  // 'C2-E4||C3 E4 C3 E4 E5 E4 E6 B2 G3 E5 C2 E4 G3 G#6 A0||25 20 18 17 7 7 6 6 6 5 4 4 4 4 3',
  // 'C2-E5||C3 C3 C2 G3 B2 E6 E5 A#1 G3 E6 D2 G4 C#2 C#3 G4||25 17 7 6 6 6 6 4 4 3 3 3 3 3 3',
  // 'C2-E6||C3 C3 E6 E6 C2 G3 B2 A#1 G3 E6 D2 G4 C#2 G4 C#3||25 17 9 7 7 6 6 4 4 4 4 3 3 3 3',
  // 'C2-E7||C3 C3 G3 C2 B2 A#1 D2 G3 G4 G4 A#6 A1 G1 A#2 C#3||25 17 7 6 6 4 4 4 3 3 3 3 3 3 3',
  // 'C2-F2||F2 F3 F#2 C3 F3 C3 E2 E3 G3 C2 B2 G3 A#1 G2 D#5||37 26 25 24 18 16 15 10 7 7 6 4 4 4 4',
  // 'C2-F3||C3 F3 F3 C3 E3 G3 B2 C2 G3 D2 F4 A#1 G4 G4 A5||25 24 20 17 7 6 6 5 4 4 4 3 3 3 3',
  // 'C2-F4||C3 F4 C3 F4 F4 F5 C2 G3 B2 F6 A#1 F6 F5 G3 F5||25 22 17 12 11 8 8 6 6 5 5 4 4 4 3',
  // 'C2-F5||C3 C3 G3 B2 F5 C2 F6 D2 A#1 G3 F6 G4 G4 A#6 E5||25 17 7 6 6 6 5 5 4 4 4 3 3 3 3',
  // 'C2-F6||C3 C3 F6 F6 G3 C2 B2 D2 A#1 G3 G4 F6 C#2 G4 F6||25 17 12 12 7 6 6 6 4 4 3 3 3 3 3',
  // 'C2-F7||C3 C3 G3 C2 B2 A#1 G3 G4 G4 A#6 C#3 A0 E5 A1 A#2||25 17 6 6 6 4 4 3 3 3 3 3 3 2 2',
  // 'C2-G2||G3 C3 G3 C3 G2 C2 G#2 F#3 A#1 B2 G4 F#2 D4 G4 D4||27 25 23 17 14 9 9 8 7 6 6 5 5 4 4',
  // 'C2-G3||C3 G3 G3 C3 F#3 B2 C2 G4 A#1 B5 A0 C#3 G4 A#6 D2||25 22 17 17 7 6 6 5 4 3 3 3 3 3 3',
  // 'C2-G4||C3 G4 C3 G4 G4 G5 G3 C2 B2 G5 G6 G6 D2 A#1 G3||25 23 17 14 9 8 6 6 6 5 5 4 4 4 4',
  // 'C2-G5||C3 C3 G5 G3 C2 B2 G6 A#1 G3 G6 G4 G5 G4 A#6 D2||25 17 7 7 6 6 5 4 4 4 3 3 3 3 3',
  // 'C2-G6||C3 C3 G6 G6 G3 C2 B2 A#1 G3 G6 G4 G4 F2 A#6 G1||25 17 12 10 7 6 6 4 4 4 3 3 3 3 3',
  // 'C2-G7||C3 C3 G3 C2 B2 A#1 G3 G4 D2 G4 A#6 A1 A#2 C#3 E5||25 17 6 6 6 5 4 3 3 3 3 3 3 3 3',
  // 'C3-A3||C4 A3 C4 A3 C3 A3 C3 B3 G4 C4 B2 G4 F2 A#5 E2||27 26 26 19 12 8 7 6 6 5 4 4 3 3 3',
  // 'C3-A4||C4 C4 A4 C3 A4 C3 B3 G4 C4 A4 B2 G4 A5 A6 A#5||27 25 13 12 8 7 6 6 5 5 4 4 4 3 3',
  // 'C3-A5||C4 C4 A5 A5 C3 A5 C3 B3 G4 C4 B2 G4 A5 A#5 F2||27 25 21 17 12 9 7 6 6 5 4 4 4 4 3',
  // 'C3-A6||C4 C4 C3 C3 B3 G4 A6 C4 B2 G4 A6 A#5 F2 C#2 C5||27 25 12 7 6 6 5 5 4 4 3 3 3 2 2',
  // 'C3-A7||C4 C4 C3 C3 B3 G4 C4 G4 B2 A#5 F2 C5 E2 G4 A#5||27 25 12 7 6 6 5 4 4 3 2 2 2 2 2',
  // 'C3-B3||C4 C4 B3 B3 B4 B3 C3 B4 F#5 C3 B5 G4 C4 F#5 B4||27 26 19 14 14 14 12 10 8 7 6 5 5 5 4',
  // 'C3-B4||C4 C4 B4 C3 B4 C3 B3 G4 C4 B4 G4 B2 B5 A#5 B6||27 25 13 12 8 7 6 5 5 4 4 4 4 3 3',
  // 'C3-B5||C4 C4 B5 B5 C3 B5 C3 B3 G4 C4 G4 B2 B5 A#5 F2||27 26 19 15 12 8 7 6 6 5 4 4 3 3 2',
  // 'C3-B6||C4 C4 C3 C3 B3 G4 C4 B2 G4 B6 E2 A#5 F2 B6 C5||27 25 12 7 6 5 5 4 4 4 4 3 3 2 2',
  // 'C3-B7||C4 C4 C3 C3 B3 G4 C4 G4 B2 A#5 F2 C5 E2 G4 A#5||27 25 12 7 6 6 5 4 4 3 3 2 2 2 2',
  // 'C3-C4||C4 C4 C3 C5 B3 C5 G5 C3 C6 G4 C5 B2 E6 G4 C6||32 27 12 12 9 8 7 7 6 5 4 4 4 4 3',
  // 'C3-C5||C4 C4 C3 C5 C6 C6 C3 C5 B3 G4 C4 G4 B2 C5 A#5||27 25 12 9 9 8 7 7 6 6 5 4 3 3 3',
  // 'C3-C6||C4 C4 C6 C6 C3 C3 C6 B3 G4 C4 G4 B2 C6 F2 A#5||27 25 17 15 12 7 7 6 6 5 4 4 3 3 3',
  // 'C3-C7||C4 C4 C3 C3 B3 G4 C4 B2 G4 C7 A#5 C5 C7 G4 A#5||27 25 12 7 6 6 5 4 4 3 3 2 2 2 2',
  // 'C3-D3||D4 C4 C4 D4 C3 D3 C3 D4 B3 D3 G4 A4 C#3 C4 B2||28 27 26 24 12 12 8 7 6 6 5 5 5 5 4',
  // 'C3-D4||C4 C4 D4 D4 D5 C3 D5 A5 C3 B3 D5 G4 C4 B2 D6||28 26 17 15 15 12 8 7 7 7 7 6 5 5 5',
  // 'C3-D5||C4 C4 C3 D5 D6 C3 D5 B3 D6 G4 C4 B2 G4 D5 A#5||27 26 13 9 8 8 7 6 6 6 5 4 4 4 3',
  // 'C3-D6||C4 C4 C3 D6 C3 D6 B3 G4 D6 C4 B2 G4 A#5 F2 C5||27 25 12 11 7 7 6 6 6 5 4 4 3 2 2',
  // 'C3-D7||C4 C4 C3 C3 B3 G4 C4 G4 B2 A#5 D7 F2 C5 E2 G4||27 25 12 7 6 6 5 4 4 3 3 2 2 2 2',
  // 'C3-E3||C4 C4 E3 E3 C3 C3 B3 G4 C4 D#3 B2 F3 G4 E4 G#5||27 26 23 22 13 8 6 6 5 5 4 4 4 4 3',
  // 'C3-E4||C4 C4 E4 E4 C3 E5 C3 E4 B3 E6 E5 G4 C4 E4 B2||27 25 21 17 12 7 7 6 6 6 6 5 5 4 4',
  // 'C3-E5||C4 C4 C3 C3 B3 E6 E5 G4 C4 G4 B2 E6 E5 A#5 F2||27 26 12 7 6 6 6 6 5 4 4 3 3 3 3',
  // 'C3-E6||C4 C4 C3 E6 C3 E6 B3 G4 C4 B2 G4 E6 A#5 F2 E6||27 25 12 9 7 7 6 6 5 4 4 4 3 3 2',
  // 'C3-E7||C4 C4 C3 C3 B3 G4 C4 B2 G4 F2 A#5 E2 C5 G4 A#5||27 25 12 7 6 6 5 4 4 3 3 2 2 2 2',
  // 'C3-F3||C4 C4 F3 F3 C3 E3 C3 B3 G4 C4 B2 G4 F4 A#5 A5||27 26 25 19 12 8 7 6 5 5 4 4 4 3 3',
  // 'C3-F4||C4 C4 F4 C3 F4 F4 F5 C3 B3 G4 F6 C4 F6 F5 B2||27 25 22 13 12 11 8 7 6 5 5 5 4 4 4',
  // 'C3-F5||C4 C4 C3 C3 F5 B3 G4 F6 C4 G4 B2 F6 A#5 F2 F5||27 25 12 7 6 6 6 5 5 4 4 4 3 3 3',
  // 'C3-F6||C4 C4 F6 C3 F6 C3 B3 G4 C4 B2 G4 F6 A#5 F2 F6||27 25 12 12 12 7 6 6 5 4 4 3 3 3 3',
  // 'C3-F7||C4 C4 C3 C3 B3 G4 C4 G4 B2 E2 A#5 F2 C5 G4 A#5||27 25 12 7 6 5 5 4 4 3 3 3 2 2 2',
  // 'C3-G3||C4 G3 C4 G3 C3 F#3 G4 C3 B3 G4 C4 G4 B2 A#5 B5||27 26 25 19 12 9 8 8 7 5 5 4 4 3 3',
  // 'C3-G4||C4 C4 G4 G4 C3 G4 C3 B3 G5 G6 G6 B2 C4 G5 A#5||27 26 21 13 12 8 7 6 6 5 5 5 4 3 3',
  // 'C3-G5||C4 C4 C3 C3 B3 G4 G6 G5 C4 G4 B2 G6 A#5 C5 G5||27 25 12 7 6 6 5 5 5 4 4 4 3 2 2',
  // 'C3-G6||C4 C4 C3 G6 G6 C3 B3 G4 C4 G4 B2 E2 G6 A#5 F2||27 25 12 11 9 7 6 6 5 4 4 3 3 3 2',
  // 'C3-G7||C4 C4 C3 C3 B3 G4 C4 B2 G4 A#5 F2 C5 E2 G4 A#5||27 25 12 7 6 6 5 4 4 3 3 2 2 2 2',
  // 'C4-A4||C4 C4 C5 A4 C5 A4 G5 C6 C5 A4 A5 C6 B3 G5 G5||17 17 14 12 9 8 8 6 6 4 4 4 4 4 3',
  // 'C4-A5||A5 C4 A5 C4 C5 C5 A5 G5 C6 C5 A5 C6 B3 G5 G5||20 17 17 17 14 9 9 7 6 5 4 4 4 4 3',
  // 'C4-A6||C4 C4 C5 C5 G5 C6 C5 A6 D2 C6 C#2 B3 G5 G5 C4||17 17 14 9 8 6 5 5 4 4 4 4 4 3 3',
  // 'C4-A7||C4 C4 C5 C5 G5 C6 C5 C6 B3 G5 G5 C4 E6 A#6 A#6||17 17 14 9 8 6 5 4 4 4 3 3 3 2 2',
  // 'C4-B4||C4 C4 C5 B4 C5 B4 G5 C6 C5 B4 B5 C6 B3 G5 G5||17 17 14 13 9 9 8 6 5 4 4 4 4 4 3',
  // 'C4-B5||B5 C4 C4 B5 C5 C5 B5 G5 C6 C5 C6 B3 G5 G5 C4||19 17 17 15 14 9 8 8 6 5 4 4 3 3 3',
  // 'C4-B6||C4 C4 C5 C5 G5 C6 C5 B6 C6 G5 B3 G5 C4 E6 B6||17 17 14 9 8 6 5 4 4 4 3 3 3 3 2',
  // 'C4-B7||C4 C4 C5 C5 G5 C6 C5 C6 G5 B3 G5 C4 E6 A#6 A#6||17 17 14 9 8 6 5 4 4 3 3 3 3 2 2',
  // 'C4-C5||C4 C4 C5 C5 C6 G5 C6 C5 G5 G5 C4 B3 E6 C6 E7||17 17 16 11 8 8 7 5 4 3 3 3 3 3 2',
  // 'C4-C6||C6 C6 C4 C4 C5 C5 G5 C6 C5 C6 G5 B3 C4 G5 E6||20 18 17 17 14 9 8 6 5 4 4 3 3 3 3',
  // 'C4-C7||C4 C4 C5 C5 G5 C6 C5 C6 C7 B3 G5 G5 E6 C4 A#6||17 17 14 9 8 6 5 4 4 4 4 3 3 3 2',
  // 'C4-D4||C4 D4 C4 D4 D5 C5 C5 D5 G5 A5 D5 C6 D6 C5 B3||17 17 17 15 15 14 9 8 8 7 6 6 5 5 4',
  // 'C4-D5||C4 C4 C5 D5 C5 D6 G5 D5 D6 C6 C5 D5 C6 G5 G5||17 17 14 9 9 8 8 7 6 6 6 4 4 4 3',
  // 'C4-D6||C4 C4 C5 D6 C5 G5 D6 C6 D6 C5 B3 C6 G5 G5 C4||17 17 14 10 9 8 6 6 5 5 4 4 4 3 3',
  // 'C4-D7||C4 C4 C5 C5 G5 C6 C5 C6 B3 G5 G5 C4 E6 D7 A#6||17 17 14 9 8 6 5 4 4 4 3 3 3 3 2',
  // 'C4-E4||E4 C4 E4 C4 C5 C5 G5 E5 E4 E5 E6 C6 C5 E6 G#6||21 17 17 16 14 9 8 7 6 6 6 6 6 4 4',
  // 'C4-E5||C4 C4 C5 C5 G5 C6 E5 E6 C5 E5 C6 B3 E6 G5 E6||17 17 14 9 8 6 6 6 5 4 4 4 4 3 3',
  // 'C4-E6||C4 C4 C5 C5 E6 G5 C6 E6 C5 C6 E6 B3 G5 G5 C4||17 17 14 9 9 8 6 6 5 4 4 4 4 3 3',
  // 'C4-E7||C4 C4 C5 C5 G5 C6 C5 C6 B3 G5 G5 C4 E6 C#2 D2||17 17 14 9 8 6 5 4 4 4 3 3 3 3 3',
  // 'C4-F4||F4 C4 C4 C5 F4 F4 C5 C6 F5 G5 C6 C5 F6 F6 F5||21 17 17 14 12 11 9 9 8 8 6 5 5 4 4',
  // 'C4-F5||C4 C4 C5 C5 G5 F5 C6 F6 C5 C6 F6 G5 G5 B3 C4||17 17 14 9 8 6 6 5 5 4 4 4 3 3 3',
  // 'C4-F6||C4 C4 C5 F6 F6 C5 G5 C6 C5 D2 C#2 C6 G5 B3 G5||17 17 14 13 12 9 8 6 5 4 4 4 4 3 3',
  // 'C4-F7||C4 C4 C5 C5 G5 C6 C5 C6 B3 G5 G5 C4 E6 A#6 A#6||17 17 14 9 8 6 5 4 4 4 3 3 3 2 2',
  // 'C4-G4||G4 C4 C4 C5 G5 G4 C5 G4 G5 C6 C5 G6 G5 G6 C6||21 17 17 14 13 12 9 9 7 6 5 5 5 4 4',
  // 'C4-G5||C4 C4 C5 G5 C5 G5 C6 G6 G5 C5 C6 G6 B3 E6 C4||17 17 14 13 9 6 6 5 5 5 4 4 3 3 3',
  // 'C4-G6||C4 C4 C5 G6 G6 C5 G5 C6 C5 C6 B3 G6 G5 G5 E6||17 17 14 12 10 9 8 6 5 4 4 4 4 3 3',
  // 'C4-G7||C4 C4 C5 C5 G5 C6 C5 C6 B3 G5 G5 E6 C4 A#6 A#6||17 17 14 9 8 6 5 4 4 4 3 3 3 2 2',
  // 'C5-A5||A5 A5 C5 C6 A5 C6 C5 A5 E7 C5 C6 A1 A6 E7 C5||21 17 9 9 9 7 7 4 3 2 2 2 2 2 2',
  // 'C5-A6||C5 C6 C6 C5 A6 A6 A1 A#1 E7 C6 C5 A6 C#2 C5 D2||9 9 8 7 5 3 3 3 3 3 2 2 2 2 1',
  // 'C5-A7||C5 C6 C6 C5 A1 E7 A#1 C6 C5 C5 C#5 G6 C7 C6 E7||9 9 8 7 3 3 3 2 2 2 1 1 1 1 1',
  // 'C5-B5||B5 B5 C5 C6 B5 C6 C5 B5 A#1 C6 A1 E7 C5 B5 C2||19 15 9 9 8 8 7 3 3 3 3 3 3 2 2',
  // 'C5-B6||C5 C6 C6 C5 B6 A#1 E7 C6 B6 C5 A1 B6 E2 C5 F2||9 9 8 7 4 3 2 2 2 2 2 2 2 2 1',
  // 'C5-B7||C5 C6 C6 C5 A#1 E7 C6 A1 C5 C5 C#5 G6 C7 C6 E7||9 9 8 7 3 3 2 2 2 2 1 1 1 1 1',
  // 'C5-C6||C6 C5 C6 C5 C6 C6 A#1 A1 E7 C5 C6 C5 C7 D2 G6||12 9 8 7 5 3 3 3 3 2 2 2 1 1 1',
  // 'C5-C7||C5 C6 C6 C5 C7 A#1 A1 C6 E7 C5 C7 F2 F#2 C7 C5||9 9 8 7 4 3 3 3 2 2 2 2 2 2 2',
  // 'C5-D5||D5 C6 C5 D6 C6 C5 D5 D6 D5 D6 E7 C6 C5 A1 F#7||9 9 9 8 8 7 7 6 4 3 3 2 2 2 2',
  // 'C5-D6||D6 C6 C5 C6 C5 D6 D6 C5 E7 C6 D7 A1 D7 C5 G6||10 9 9 8 7 6 5 3 3 2 2 2 2 1 1',
  // 'C5-D7||C5 C6 C6 C5 D7 A1 E7 C6 A#1 C5 C5 D7 D7 C#5 G6||9 9 8 7 3 3 3 2 2 2 2 1 1 1 1',
  // 'C5-E5||C5 C6 C6 C5 E6 E5 E5 E6 A#1 E7 C5 C6 A1 E6 C2||9 9 8 7 6 6 4 3 3 3 3 3 2 2 2',
  // 'C5-E6||C5 C6 E6 C6 C5 E6 E6 A#1 C6 A1 C5 C2 C5 C#5 G6||9 9 9 7 7 7 4 3 3 2 2 2 2 1 1',
  // 'C5-E7||C5 C6 C6 C5 A#1 C6 C5 A1 C5 E7 C#5 G6 C7 C6 D2||9 9 7 7 3 2 2 2 1 1 1 1 1 1 1',
  // 'C5-F5||C5 C6 C6 C5 F5 F6 F6 F5 A#1 A1 E7 C5 C6 F5 C5||9 9 8 7 6 5 4 3 3 3 3 2 2 2 2',
  // 'C5-F6||F6 F6 C5 C6 C6 C5 F6 F6 A#1 E7 C6 C5 A1 D2 C#2||12 12 9 9 8 7 3 3 3 3 3 2 2 2 2',
  // 'C5-F7||C5 C6 C6 C5 A#1 C6 C5 E7 A1 F7 C5 F7 C#5 G6 C2||9 9 8 7 3 3 2 2 2 1 1 1 1 1 1',
  // 'C5-G5||C5 C6 C6 C5 G5 G6 G6 G5 A#1 G5 E7 C6 A1 C5 C5||9 9 8 7 6 4 4 3 3 3 3 2 2 2 2',
  // 'C5-G6||G6 G6 C5 C6 C6 C5 G6 A#1 E7 C6 C5 A1 G6 E2 C5||12 10 9 9 7 7 3 3 3 2 2 2 2 2 2',
  // 'C5-G7||C5 C6 C6 C5 A#1 A1 C6 E7 C5 C5 G7 C#5 C2 G6 C7||9 9 8 7 3 3 2 2 2 2 1 1 1 1 1',
  // 'C6-A6||C6 C6 C6 A6 C6 A6 D2 C#2 C6 A6 C7 E2 A6 G7 C7||17 15 7 5 3 3 3 2 2 2 1 1 1 1 1',
  // 'C6-A7||C6 C6 C6 C6 C6 C7 D2 A7 G7 C#2 C7 A7 C6 A7||17 15 7 3 2 1 1 1 1 1 1 1 1 1',
  // 'C6-B6||C6 C6 C6 B6 C6 B6 C6 B6 C7 F2 E2 G7 C7 C6 B6||17 15 7 4 3 2 2 2 2 1 1 1 1 1 1',
  // 'C6-B7||C6 C6 C6 C6 C6 C7 D2 G7 B7 C7 C6 E2||17 15 7 3 2 1 1 1 1 1 1 1',
  // 'C6-C7||C6 C6 C6 C6 C7 C7 C6 C7 D2 C7 E2 F#2 F2 G7 C#2||17 15 7 3 3 3 2 1 1 1 1 1 1 1 1',
  // 'C6-D6||C6 C6 D6 C6 D6 D6 C6 D7 D7 C6 C2 C#2 C7 A#1 D2||17 15 10 6 6 5 4 2 2 2 2 2 1 1 1',
  // 'C6-D7||C6 C6 C6 C6 D7 C6 C7 D7 D7 D2 C#2 G7 F#2 C7 G#2||17 15 7 3 3 2 1 1 1 1 1 1 1 1 1',
  // 'C6-E6||C6 C6 E6 E6 C6 E6 C6 C6 E7 E7 C7 D2 C#2 E6 E2||17 15 9 7 7 4 3 2 2 2 1 1 1 1 1',
  // 'C6-E7||C6 C6 C6 C6 D2 C6 E7 C#2 C7 E7 E2 E7 G7 C7 C6||17 15 7 3 2 2 2 2 2 1 1 1 1 1 1',
  // 'C6-F6||C6 C6 F6 F6 C6 C6 D2 F6 F6 C#2 C6 E2 F7 C7 D1||17 15 12 12 7 3 3 3 3 2 2 2 2 1 1',
  // 'C6-F7||C6 C6 C6 C6 C6 F7 C7 F7 D2 G7 E2 C7 C6||17 15 7 3 2 2 1 1 1 1 1 1 1',
  // 'C6-G6||C6 C6 G6 G6 C6 G6 C6 C6 G6 C7 F2 G6 D1 F1 E2||17 15 12 10 7 4 3 2 2 1 1 1 1 1 1',
  // 'C6-G7||C6 C6 C6 C6 G7 C6 C7 G7 D2 E2 G7 C#2 C7 C6||17 15 7 3 2 2 1 1 1 1 1 1 1 1',
  // 'D2-A2||D2 C#2 D3 A3 A3 D3 C#3 A2 E2 A2 A3 E4 C2 C5 G#2||34 28 27 26 18 16 13 13 11 11 10 6 5 4 4',
  // 'D2-A3||D2 C#2 D3 A3 D3 A3 C#3 E2 A3 C2 A4 A4 C5 A5 D4||36 31 27 22 16 15 13 10 8 6 6 5 4 3 3',
  // 'D2-A4||D2 C#2 D3 A4 D3 C#3 A4 E2 A4 A5 C2 C5 A3 A6 A5||36 30 26 16 15 12 11 11 5 5 5 4 3 3 3',
  // 'D2-A5||D2 C#2 D3 A5 A5 D3 C#3 E2 A5 C2 C5 A5 A3 D4 F2||36 30 26 20 16 16 13 11 8 6 4 4 4 3 3',
  // 'D2-A6||D2 C#2 D3 D3 C#3 E2 C2 A6 C5 A3 D4 A6 A3 F2 F#5||38 33 26 16 12 12 7 5 4 4 3 3 3 3 3',
  // 'D2-A7||D2 C#2 D3 D3 C#3 E2 C2 C5 A3 D4 F2 A3 F#5 D4 C5||36 31 26 16 13 11 6 4 4 3 3 3 3 3 2',
  // 'D2-B2||D2 C#2 B3 D3 B3 D3 C#3 B2 E2 B3 A#2 F#4 C2 F#4 C3||36 31 30 26 22 16 13 13 12 10 8 6 5 5 4',
  // 'D2-B3||D2 C#2 D3 B3 D3 B4 B3 C#3 E2 B4 F#5 B3 C2 B5 C5||37 32 26 20 15 14 14 12 11 10 7 7 7 6 4',
  // 'D2-B4||D2 C#2 D3 D3 B4 C#3 E2 B4 C2 C5 B4 B5 D4 A3 A3||37 33 26 15 13 12 11 8 7 4 4 4 3 3 3',
  // 'D2-B5||D2 C#2 D3 B5 D3 B5 C#3 E2 B5 C2 C5 A3 D4 A3 B5||38 33 26 19 16 15 13 11 8 7 4 3 3 3 3',
  // 'D2-B6||D2 C#2 D3 D3 C#3 E2 C2 C5 B6 A3 D4 A3 F#5 D4 C5||37 31 26 15 12 12 6 4 4 4 3 3 3 3 2',
  // 'D2-B7||D2 C#2 D3 D3 C#3 E2 C2 C5 A3 D4 A3 F#5 F2 D4 C5||37 31 26 15 12 11 6 4 4 3 3 3 3 3 2',
  // 'D2-C3||D2 C#2 D3 C4 C4 D3 C#3 C3 E2 C3 C2 B3 G4 C4 C5||37 32 28 27 25 16 14 11 10 8 7 6 5 5 4',
  // 'D2-C4||D2 C#2 D3 C4 C4 D3 C5 C#3 E2 C5 G5 C2 C6 A3 C5||37 33 26 18 17 16 14 12 11 9 8 7 6 4 4',
  // 'D2-C5||D2 C#2 D3 D3 C#3 E2 C5 C6 C6 C5 C2 C5 C5 A#1 A3||36 31 27 16 13 11 9 9 8 7 6 6 5 3 3',
  // 'D2-C6||D2 C#2 D3 C6 D3 C6 C#3 E2 C6 C2 C5 A3 D4 C6 A3||36 31 26 17 16 15 13 10 7 6 4 4 3 3 3',
  // 'D2-C7||D2 C#2 D3 D3 C#3 E2 C2 F2 C5 C7 A3 D4 A3 F#5 D4||37 31 26 16 12 12 6 4 4 4 4 3 3 3 3',
  // 'D2-D3||D2 C#2 D3 D4 D4 D3 C#3 E2 D4 A4 A4 C2 D4 C5 C6||36 30 29 28 25 16 13 11 8 6 5 5 5 4 4',
  // 'D2-D4||D2 C#2 D3 D3 D5 D4 C#3 E2 D4 D5 D5 A5 C2 D6 C5||38 31 26 16 15 14 13 13 11 8 7 6 6 5 4',
  // 'D2-D5||D2 C#2 D3 D3 C#3 E2 D5 D6 C2 D5 D6 C5 D4 A3 D5||36 30 27 16 13 11 9 8 7 7 6 4 3 3 3',
  // 'D2-D6||D2 C#2 D3 D3 C#3 E2 D6 C2 D6 D6 C5 A3 D4 F2 A3||36 30 26 16 13 11 11 6 6 6 4 4 3 3 3',
  // 'D2-D7||D2 C#2 D3 D3 C#3 E2 C2 C5 A3 D4 A3 F#5 D7 F2 D4||36 31 26 16 12 11 6 4 4 3 3 3 3 3 3',
  // 'D2-E2||D2 E2 C#2 D3 F2 E3 E3 D3 C#3 C2 D#3 F3 A3 C5 D5||42 38 30 26 25 24 22 16 12 6 5 5 4 4 4',
  // 'D2-E3||D2 C#2 D3 E3 E3 D3 C#3 E2 C2 D#3 C5 D4 E4 E5 A3||37 32 27 22 22 15 14 11 7 5 4 4 4 4 4',
  // 'D2-E4||D2 C#2 D3 E4 E4 D3 C#3 E2 E5 E5 E4 E6 C2 C5 E4||37 31 26 21 17 15 12 11 9 7 6 6 5 4 4',
  // 'D2-E5||D2 C#2 D3 D3 C#3 E2 E5 C2 E6 E5 C5 A3 E6 D4 A3||38 32 27 16 13 11 7 6 6 5 4 4 3 3 3',
  // 'D2-E6||D2 C#2 D3 D3 C#3 E2 E6 E6 C2 C5 A3 D4 A3 F#5 F2||38 32 27 16 13 11 8 7 6 4 4 3 3 3 3',
  // 'D2-E7||D2 C#2 D3 D3 C#3 E2 C2 C5 A3 D4 A3 F#5 D4 F2 C5||36 31 26 16 12 11 6 4 4 3 3 3 3 3 2',
  // 'D2-F2||F2 D2 C#2 D3 F3 F#2 E2 F3 D3 C#3 E3 C2 G2 C5 D#5||40 38 31 27 27 25 22 18 15 14 9 5 5 4 4',
  // 'D2-F3||D2 C#2 D3 F3 F3 D3 C#3 E2 E3 C2 F4 C5 A3 D4 F2||37 31 26 24 19 17 12 11 7 6 4 4 4 3 3',
  // 'D2-F4||D2 C#2 D3 F4 D3 C#3 F4 E2 F4 F5 C2 F5 F6 F6 C5||36 30 26 22 15 12 12 11 11 8 5 4 4 4 4',
  // 'D2-F5||D2 C#2 D3 D3 C#3 E2 F5 C2 F6 C5 A3 D4 A3 F6 D4||36 31 26 16 12 11 6 6 4 4 4 3 3 3 3',
  // 'D2-F6||D2 C#2 D3 D3 F6 C#3 F6 E2 C2 C5 A3 D4 A3 F2 F#5||38 33 26 16 13 13 12 11 6 4 4 3 3 3 3',
  // 'D2-F7||D2 C#2 D3 D3 C#3 E2 C2 C5 A3 D4 A3 F#5 D4 C5 A4||36 31 26 15 12 11 6 4 4 3 3 3 3 2 2',
  // 'D2-G2||D2 C#2 G3 D3 G3 D3 C#3 G2 E2 F#3 G#2 C2 D4 D4 F#2||36 31 29 26 22 16 12 12 11 9 8 8 7 6 5',
  // 'D2-G3||D2 C#2 D3 G3 G3 D3 C#3 E2 F#3 C2 A3 C5 G4 D4 A3||37 32 27 25 18 16 13 12 9 7 4 4 4 4 3',
  // 'D2-G4||D2 C#2 D3 G4 D3 G4 C#3 E2 G4 G5 C2 G5 G6 G6 C5||35 31 26 21 16 12 12 10 9 8 6 5 5 4 4',
  // 'D2-G5||D2 C#2 D3 D3 C#3 E2 G5 C2 G6 C5 A3 F2 G6 D4 A3||37 31 26 15 12 12 7 6 5 4 4 3 3 3 3',
  // 'D2-G6||D2 C#2 D3 D3 G6 C#3 E2 G6 C2 C5 G6 A3 D4 A3 F#5||37 31 26 15 12 12 12 10 6 4 4 4 3 3 3',
  // 'D2-G7||D2 C#2 D3 D3 C#3 E2 C2 C5 A3 D4 F2 A3 F#5 D4 C5||36 31 26 16 12 11 6 4 4 3 3 3 3 3 3',
  // 'D3-A3||D4 A3 D4 A3 D3 A3 D4 A4 D3 A4 C#3 D4 A4 C#6 C6||28 26 24 19 13 8 8 8 7 5 4 3 3 3 3',
  // 'D3-A4||D4 D4 A4 D3 A4 D4 D3 A4 C#3 A5 D4 G2 A6 A6 C6||28 24 16 13 11 7 6 6 5 4 3 3 3 3 3',
  // 'D3-A5||D4 D4 A5 A5 D3 A5 D4 D3 A4 C#3 A4 A5 D4 C6 G2||28 24 22 18 13 9 7 6 5 4 4 4 3 3 2',
  // 'D3-A6||D4 D4 D3 D4 D3 A4 A6 C#3 A4 A6 D4 C6 G2 F#2 C6||28 24 13 7 6 5 5 5 4 4 3 3 3 2 2',
  // 'D3-A7||D4 D4 D3 D4 D3 A4 C#3 A4 D4 C6 G2 C6 F#2 D5 E3||28 24 13 7 6 5 5 4 4 3 3 2 2 2 2',
  // 'D3-B3||D4 D4 B3 B3 B4 D3 B4 D4 F#5 B3 D3 B5 A4 C#3 F#5||28 23 20 14 14 13 10 8 8 7 7 6 5 5 5',
  // 'D3-B4||D4 D4 D3 B4 B4 D4 D3 A4 C#3 B4 A4 B5 D4 B6 G2||28 24 13 12 8 7 6 5 5 4 4 4 3 3 3',
  // 'D3-B5||D4 D4 B5 B5 D3 B5 D4 D3 A4 C#3 A4 D4 C6 B5 C6||28 24 19 15 13 8 7 7 5 5 4 3 3 3 3',
  // 'D3-B6||D4 D4 D3 D4 D3 A4 C#3 A4 B6 D4 C6 G2 B6 C6 D5||28 24 13 7 6 5 5 4 4 3 3 3 2 2 2',
  // 'D3-B7||D4 D4 D3 D4 D3 A4 C#3 A4 D4 C6 C6 G2 F#2 D5 E3||28 24 13 8 6 5 5 4 3 3 2 2 2 2 2',
  // 'D3-C4||D4 D4 C4 C4 C5 D3 C5 G5 D4 D3 C6 C5 A4 C#3 A4||28 24 17 16 14 13 9 8 7 7 6 5 5 5 5',
  // 'D3-C5||D4 D4 D3 C5 C6 C6 D4 C5 D3 A4 C#3 A4 A#1 D4 A1||28 24 13 9 9 8 7 7 7 5 5 5 4 3 3',
  // 'D3-C6||D4 D4 C6 C6 D3 D4 C6 D3 A4 C#3 A4 C6 D4 C6 C6||28 24 17 15 13 7 7 6 5 5 4 4 3 3 3',
  // 'D3-C7||D4 D4 D3 D4 D3 A4 C#3 A4 C7 D4 F#2 G2 C6 C6 C7||28 24 12 7 6 5 4 4 4 3 3 3 3 2 2',
  // 'D3-D4||D4 D4 D3 D5 D4 D5 A5 D3 D5 D6 A4 C#3 A5 A4 D6||32 25 13 12 10 7 7 6 5 5 5 4 4 4 4',
  // 'D3-D5||D4 D4 D3 D5 D6 D4 D5 D6 D3 A4 A4 C#3 D5 D4 D6||28 24 13 10 9 7 6 6 6 5 4 4 4 3 3',
  // 'D3-D6||D4 D4 D3 D6 D4 D3 D6 D6 A4 A4 C#3 D4 C6 C6 G2||28 24 12 10 7 6 6 5 5 4 4 3 3 3 2',
  // 'D3-D7||D4 D4 D3 D4 D3 A4 C#3 A4 D4 D7 C6 C6 D5 E3 A4||28 24 13 7 6 5 5 4 3 3 3 2 2 2 2',
  // 'D3-E3||D4 D4 E3 E3 D3 D4 D3 C#3 A4 D#3 A4 F3 E4 D4 E4||28 24 22 22 14 8 6 5 5 5 4 4 4 3 3',
  // 'D3-E4||D4 D4 E4 E4 D3 D4 E5 D3 E4 E5 E6 A4 C#3 A4 E4||28 23 21 17 12 8 7 6 6 6 6 5 5 4 4',
  // 'D3-E5||D4 D4 D3 D4 D3 E6 E5 A4 C#3 A4 E5 E6 D4 C6 E6||28 24 13 7 7 6 6 5 5 4 4 3 3 3 2',
  // 'D3-E6||D4 D4 D3 E6 E6 D4 D3 A4 C#3 A4 E6 D4 C6 C6 G2||28 24 13 9 8 7 7 5 5 4 4 3 3 2 2',
  // 'D3-E7||D4 D4 D3 D4 D3 A4 C#3 A4 D4 C6 G2 C6 F#2 D5 E7||28 24 13 8 6 5 5 4 3 3 2 2 2 2 2',
  // 'D3-F3||D4 F3 D4 F3 D3 D4 E3 D3 A4 A4 C#3 F4 A5 D4 A5||28 25 24 19 11 7 7 5 5 4 4 4 3 3 3',
  // 'D3-F4||D4 D4 F4 D3 F4 F4 F5 D4 D3 A4 F6 F6 A4 F5 C#3||28 24 22 13 12 11 8 7 7 5 5 4 4 4 4',
  // 'D3-F5||D4 D4 D3 D4 F5 D3 F6 A4 C#3 A4 F6 D4 G2 F5 C6||28 24 12 7 6 6 5 5 5 4 4 3 3 3 3',
  // 'D3-F6||D4 D4 D3 F6 F6 D4 D3 A4 C#3 A4 D4 F6 F6 G2 C6||28 24 12 12 12 7 6 5 5 4 3 3 3 3 3',
  // 'D3-F7||D4 D4 D3 D4 D3 A4 C#3 A4 D4 C6 C6 G2 D5 F#2 E3||28 24 13 8 6 5 5 4 3 3 2 2 2 2 2',
  // 'D3-G3||D4 G3 D4 G3 D3 F#3 D4 D3 A4 C#3 A4 D4 G4 B5 C6||28 26 24 19 13 9 7 6 5 5 4 4 3 3 3',
  // 'D3-G4||D4 D4 G4 D3 G4 G4 G5 D4 D3 A4 C#3 G5 G6 G6 A4||27 24 21 13 12 9 8 7 7 5 5 5 5 4 4',
  // 'D3-G5||D4 D4 D3 D4 G5 D3 A4 G6 C#3 A4 G6 D4 G5 G2 C6||28 24 13 7 6 6 5 5 4 4 3 3 3 3 3',
  // 'D3-G6||D4 D4 D3 G6 G6 D4 D3 A4 C#3 A4 G6 D4 G2 C6 C6||28 24 13 12 10 7 6 5 5 4 4 3 3 3 2',
  // 'D3-G7||D4 D4 D3 D4 D3 A4 C#3 A4 D4 F#2 C6 G2 C6 D5 E3||28 24 13 8 6 5 4 4 3 3 3 2 2 2 2',
  // 'D4-A4||D4 D4 D5 A4 A4 D5 D5 D6 A4 D6 A5 D4 A6 F#6 A6||17 16 15 13 9 8 7 5 5 4 4 3 3 3 3',
  // 'D4-A5||A5 A5 D4 D4 D5 A5 D5 D5 D6 D6 A5 D4 F#6 D#4 F#6||24 19 17 15 15 9 8 7 5 4 4 3 3 2 2',
  // 'D4-A6||D4 D4 D5 D5 A5 D5 A6 D6 D6 A5 A6 D4 F#6 A5 D2||17 15 15 8 7 7 5 5 4 4 3 3 3 3 2',
  // 'D4-A7||D4 D4 D5 D5 A5 D5 D6 D6 A5 D4 F#6 A5 D#4 F#6 C7||17 15 15 8 7 7 5 4 4 3 3 3 2 2 2',
  // 'D4-B4||D4 D4 D5 B4 B4 D5 A5 D5 D6 B4 A5 D6 B5 D4 B6||17 15 15 12 8 8 7 7 5 4 4 4 4 4 3',
  // 'D4-B5||B5 D4 D4 B5 D5 D5 B5 A5 D5 D6 A5 D6 D4 B5 F#6||19 17 15 15 15 8 8 7 6 5 4 4 3 3 3',
  // 'D4-B6||D4 D4 D5 D5 A5 D5 D6 D6 A5 B6 E2 D4 F#6 A5 F2||17 15 15 8 7 7 5 4 4 4 3 3 3 3 3',
  // 'D4-B7||D4 D4 D5 D5 A5 D5 D6 D6 A5 D4 F#6 A5 D#4 F#6 C7||17 15 15 8 7 7 5 4 4 3 3 3 2 2 2',
  // 'D4-C5||D4 D4 D5 C5 C6 D5 C6 C5 A5 D5 D6 A5 D6 D4 A#1||17 15 14 9 9 8 8 8 7 6 5 4 4 3 3',
  // 'D4-C6||D4 C6 D4 C6 D5 D5 A5 D5 C6 D6 A5 D6 C6 D4 F#6||17 17 15 15 14 8 7 7 6 5 4 4 3 3 3',
  // 'D4-C7||D4 D4 D5 D5 A5 D5 D6 D6 A5 C7 D4 F#6 A5 D#4 C7||17 15 15 8 7 7 5 4 4 4 3 3 3 2 2',
  // 'D4-D5||D4 D5 D4 D5 D6 A5 D5 D6 A5 D6 D4 F#6 A5 D#4 C2||17 15 15 10 8 7 6 6 4 4 3 3 3 2 2',
  // 'D4-D6||D4 D4 D5 D6 D5 A5 D6 D5 D6 A5 D4 F#6 A5 D7 D#4||17 15 14 11 8 7 7 7 5 4 3 3 3 3 2',
  // 'D4-D7||D4 D4 D5 D5 A5 D5 D6 D6 A5 D4 F#6 D7 A5 D#4 F#6||17 15 15 8 7 7 5 4 4 3 3 3 3 2 2',
  // 'D4-E4||E4 D4 E4 D4 D5 D5 A5 E5 D5 E4 E6 E5 D6 A5 D6||20 17 17 15 15 8 7 7 7 6 6 6 5 4 4',
  // 'D4-E5||D4 D4 D5 D5 A5 D5 E6 E5 D6 D6 A5 E5 E6 D4 F#6||17 15 15 8 7 7 6 6 5 4 4 4 3 3 3',
  // 'D4-E6||D4 D4 D5 E6 D5 A5 E6 D5 D6 A5 D6 E6 D4 F#6 A5||17 15 15 9 8 7 7 7 5 4 4 4 3 3 3',
  // 'D4-E7||D4 D4 D5 D5 A5 D5 D6 D6 A5 D4 F#6 A5 D#4 E7 F#6||17 15 15 8 7 7 5 4 4 3 3 3 2 2 2',
  // 'D4-F4||F4 D4 D4 D5 F4 F4 D5 F5 A5 D5 F6 D6 F6 F5 A5||22 17 15 15 12 10 8 8 7 7 5 5 4 4 4',
  // 'D4-F5||D4 D4 D5 D5 A5 D5 F5 F6 D6 D6 A5 F6 D4 F#6 A5||17 15 14 8 7 6 6 5 5 4 4 4 3 3 3',
  // 'D4-F6||D4 D4 D5 F6 F6 D5 A5 D5 D6 D6 A5 D4 F6 F#6 F6||17 15 14 12 12 8 7 7 5 4 4 3 3 3 3',
  // 'D4-F7||D4 D4 D5 D5 A5 D5 D6 D6 A5 D4 F#6 A5 D#4 F#6 E2||17 15 15 8 7 7 5 4 4 3 3 3 2 2 2',
  // 'D4-G4||G4 D4 D4 D5 G4 G4 D6 D5 G5 A5 D5 D6 G5 G6 G6||21 17 15 15 12 9 8 8 8 7 7 6 5 5 4',
  // 'D4-G5||D4 D4 D5 D5 A5 D5 G5 D6 G6 A5 D6 G6 D4 F#6 G5||17 15 15 8 7 7 6 5 5 4 4 3 3 3 3',
  // 'D4-G6||D4 D4 D5 G6 G6 D5 A5 D5 D6 D6 A5 G6 E2 D4 F#6||17 15 15 12 10 8 7 7 5 4 4 4 4 3 3',
  // 'D4-G7||D4 D4 D5 D5 A5 D5 D6 D6 A5 D4 F#6 A5 D#4 F#6 C7||17 15 15 8 7 7 5 4 4 3 3 3 2 2 2',
  // 'D5-A5||A5 A5 D5 A5 D6 D5 D6 C2 A5 D5 A#1 D6 F#7 C#2 A6||20 17 9 9 8 7 6 4 4 3 3 3 2 2 2',
  // 'D5-A6||D5 D6 D5 D6 A6 D5 A6 D6 C2 F#7 A#1 E2 D2 F#7 D5||9 8 7 6 4 4 3 3 3 2 2 1 1 1 1',
  // 'D5-A7||D5 D6 D5 D6 D5 D6 C2 A#1 F#7 F#7 D5 C#2 D5 A6 A7||9 8 7 6 3 3 3 2 2 1 1 1 1 1 1',
  // 'D5-B5||B5 B5 D5 D6 B5 D5 D6 D5 B5 D6 F#7 B5 C2 A#1 F#7||19 15 9 9 8 7 6 4 3 3 2 2 2 2 2',
  // 'D5-B6||D5 D6 D5 D6 B6 D5 D6 B6 C2 F#7 A#1 B6 F#7 D5 F2||9 8 7 6 4 3 3 2 2 2 2 2 1 1 1',
  // 'D5-B7||D5 D6 D5 D6 D5 D6 C2 F#7 A#1 F#7 D5 C3 A6 D5 D7||9 8 7 6 3 3 2 2 2 1 1 1 1 1 1',
  // 'D5-C6||C6 C6 D5 D6 D5 C6 D6 D5 C6 C2 D6 C6 F#7 A#1 C#2||17 15 9 8 7 6 6 4 3 3 3 2 2 2 2',
  // 'D5-C7||D5 D6 D5 D6 C7 D5 D6 C2 C7 F#7 F2 A#1 C7 E2 F#7||9 8 7 6 4 3 3 2 2 2 2 2 2 2 1',
  // 'D5-D6||D5 D5 D6 C2 D6 D6 D5 A#1 D7 C#2 F#7 F#7 D7 D5 C3||9 7 5 4 4 4 4 3 2 2 2 1 1 1 1',
  // 'D5-D7||D5 D6 D5 D6 D5 D6 D7 C2 A#1 F#7 D7 F#7 D5 D7 G2||9 8 7 6 3 3 3 3 2 2 1 1 1 1 1',
  // 'D5-E5||D5 D6 D5 D6 E5 E6 E5 E6 D5 D6 E6 F#7 C2 A#1 F#7||9 8 7 6 6 6 4 3 3 3 2 2 2 2 1',
  // 'D5-E6||D5 E6 D6 E6 D5 D6 E6 D5 D6 E7 F#7 A#1 E7 C2 F#7||9 9 9 7 7 6 4 4 3 2 2 2 2 1 1',
  // 'D5-E7||D5 D6 D5 D6 D5 C2 D6 A#1 F#7 E7 C#2 F#7 E7 D5 A6||9 8 7 6 3 3 3 2 2 2 2 1 1 1 1',
  // 'D5-F5||D5 D6 D5 D6 F5 F6 D5 F6 F5 D6 C2 F5 F#7 A#1 C#2||9 8 7 6 6 5 4 4 3 3 3 2 2 2 2',
  // 'D5-F6||F6 F6 D5 D6 D5 D6 D5 F6 F6 C2 D6 A#1 F#7 D2 E2||12 12 9 8 7 6 4 3 3 3 3 2 2 2 2',
  // 'D5-F7||D5 D6 D5 D6 D5 D6 C2 A#1 F#7 F7 F#7 F7 D5 C3 C#2||9 8 7 6 3 3 2 2 2 2 1 1 1 1 1',
  // 'D5-G5||D5 D6 D5 G5 D6 G6 D5 G6 G5 D6 G5 C2 F#7 A#1 F#7||9 8 7 6 6 5 4 3 3 3 3 2 2 2 1',
  // 'D5-G6||G6 G6 D5 D6 D5 D6 G6 D5 D6 C2 G6 F#7 A#1 F#7 F2||12 10 9 8 7 6 4 3 3 2 2 2 2 1 1',
  // 'D5-G7||D5 D6 D5 D6 D5 D6 C2 F#7 A#1 F#7 G7 D5 A6 D5 C3||9 8 7 6 3 3 2 2 2 1 1 1 1 1 1',
  // 'D6-A6||D6 D6 D6 A6 A6 D7 D7 C2 A6 D2 A#1 C#2 E2 D6 A6||10 6 5 5 3 2 2 2 2 2 1 1 1 1 1',
  // 'D6-A7||D6 D6 D6 D7 D7 C2 A#1 A7 C#2 D6 D7 A7 A7 D6||10 6 5 2 2 2 1 1 1 1 1 1 1 1',
  // 'D6-B6||D6 D6 D6 B6 B6 D7 D7 B6 C2 E2 A#1 F2 D2 D6 D7||10 6 5 4 2 2 2 2 2 1 1 1 1 1 1',
  // 'D6-B7||D6 D6 D6 D7 D7 C2 A#1 D6 D7 B7 C#2 D7 D6||10 6 5 2 2 2 1 1 1 1 1 1 1',
  // 'D6-C7||D6 D6 D6 C7 D7 C7 D7 F2 C7 C2 F#2 E2 A#1 D6 C#2||10 6 5 4 2 2 2 2 2 2 1 1 1 1 1',
  // 'D6-D7||D6 D6 D6 D7 D7 C2 A#1 D7 D6 C#2 D7 G#2 G2 D7 F#2||10 6 5 2 2 2 1 1 1 1 1 1 1 1 1',
  // 'D6-E6||D6 E6 E6 D6 D6 E6 D7 D7 E7 E7 A#1 D6 D7 E6 C2||10 9 7 6 5 4 2 2 2 2 1 1 1 1 1',
  // 'D6-E7||D6 D6 D6 D7 C2 D7 E7 C#2 E7 A#1 D6 E7 D7 D2 D7||10 6 5 2 2 2 2 1 1 1 1 1 1 1 1',
  // 'D6-F6||F6 F6 D6 D6 D6 F6 F6 D7 D7 C2 D2 C#2 F7 E2 A#1||12 12 10 6 5 3 3 2 2 2 2 2 2 1 1',
  // 'D6-F7||D6 D6 D6 D7 D7 F7 C2 F7 A#1 D2 C#2 D6 D7 D7 D6||10 6 5 2 2 2 1 1 1 1 1 1 1 1 1',
  // 'D6-G6||G6 D6 G6 D6 D6 G6 D7 D7 G6 C2 E2 F2 A#1 G6 G7||12 10 10 6 5 4 2 2 2 2 1 1 1 1 1',
  // 'D6-G7||D6 D6 D6 D7 D7 C2 G7 G7 A#1 D6 D7 C#2 F2 G7 D7||10 6 5 2 2 1 1 1 1 1 1 1 1 1 1',
  // 'E2-A2||E2 A3 F2 E3 E3 A3 D2 A2 A3 A2 E4 D#3 E4 F#2 D5||36 29 27 24 22 21 12 11 10 9 8 6 6 4 4',
  // 'E2-A3||E2 F2 A3 E3 E3 A3 D2 A3 D#3 F#2 B3 D5 F3 B3 E4||37 28 25 24 22 19 14 8 6 5 4 4 4 4 3',
  // 'E2-A4||E2 F2 E3 E3 D2 A4 A4 D#3 A4 A5 D5 B3 F#2 F3 B3||37 28 24 21 13 13 8 6 4 4 4 4 4 4 3',
  // 'E2-A5||E2 F2 E3 E3 A5 A5 D2 A5 D#3 D5 A5 F#2 B3 F3 E4||36 27 24 21 20 17 13 9 6 4 4 4 4 3 3',
  // 'E2-A6||E2 F2 E3 E3 D2 D#3 A6 D5 F#2 F3 B3 E4 A6 B3 G#5||35 27 24 21 12 6 5 4 4 4 3 3 3 3 3',
  // 'E2-A7||E2 F2 E3 E3 D2 D#3 D5 F3 B3 F#2 E4 B3 G#5 D5 E4||37 27 24 21 13 6 4 4 3 3 3 3 3 2 2',
  // 'E2-B2||E2 F2 B3 E3 E3 B3 B2 D2 B3 A#2 D#3 C3 F#4 F#4 F#2||33 27 26 25 22 18 13 10 10 9 7 6 5 4 4',
  // 'E2-B3||E2 F2 E3 B3 E3 B4 B3 D2 B4 F#5 B3 D#3 F#5 B5 B4||37 27 23 22 21 16 16 14 12 8 7 6 5 5 5',
  // 'E2-B4||E2 F2 E3 E3 B4 D2 B4 D#3 B5 B4 D5 F3 F#2 B3 B3||36 27 23 21 15 12 11 6 5 5 4 4 3 3 3',
  // 'E2-B5||E2 F2 E3 E3 B5 B5 D2 B5 D#3 D5 F#2 F3 B3 E4 B3||36 27 24 21 18 14 13 8 6 4 4 4 4 3 3',
  // 'E2-B6||E2 F2 E3 E3 D2 D#3 F#2 D5 B6 F3 B3 E4 B3 G#5 B6||39 29 24 21 14 6 4 4 4 4 3 3 3 3 2',
  // 'E2-B7||E2 F2 E3 E3 D2 D#3 D5 F#2 F3 B3 E4 B3 G#5 E4 D5||36 28 24 21 13 6 4 4 4 3 3 3 3 2 2',
  // 'E2-C3||E2 F2 C4 C4 E3 E3 D2 C3 D#3 B3 C3 G4 C4 G4 D5||39 30 27 26 24 22 13 11 7 7 6 5 5 4 4',
  // 'E2-C4||E2 F2 E3 E3 C4 C4 C5 D2 C5 G5 C6 D#3 C5 D5 F#2||36 28 24 22 17 17 14 11 9 8 6 6 5 4 4',
  // 'E2-C5||E2 F2 E3 E3 D2 C6 C5 C6 C5 D#3 F3 D5 F#2 E4 B3||37 27 24 22 12 9 9 8 7 6 4 4 3 3 3',
  // 'E2-C6||E2 F2 E3 E3 C6 C6 D2 C6 D#3 D5 F3 F#2 E4 C6 B3||36 28 24 21 17 15 11 7 6 4 4 4 3 3 3',
  // 'E2-C7||E2 F2 E3 E3 D2 D#3 D5 F3 C7 B3 B3 E4 G#5 F#2 D5||36 26 24 21 12 6 4 4 4 3 3 3 3 2 2',
  // 'E2-D3||E2 D4 F2 E3 D4 E3 D2 D3 D#3 D3 D4 A4 C#3 A4 D5||37 28 28 26 24 22 13 12 8 8 7 5 5 4 4',
  // 'E2-D4||E2 F2 E3 E3 D4 D4 D5 D2 D5 A5 D#3 D5 D6 F#2 D6||38 28 24 22 17 15 15 14 8 7 6 5 5 4 4',
  // 'E2-D5||E2 F2 E3 E3 D2 D5 D6 D5 D6 D#3 D5 D5 F#2 D5 F3||36 27 25 22 13 9 8 7 6 6 6 5 4 4 4',
  // 'E2-D6||E2 F2 E3 E3 D2 D6 D#3 D6 D6 D5 F#2 F3 B3 E4 B3||36 27 24 22 13 10 6 6 5 4 4 4 4 3 3',
  // 'E2-D7||E2 F2 E3 E3 D2 D#3 F#2 D5 F3 B3 G2 E4 B3 G#5 D7||36 28 24 21 13 6 5 4 4 3 3 3 3 3 3',
  // 'E2-E3||E2 F2 E3 E3 D2 E4 D#3 E4 D5 F#2 B4 F3 B3 B3 D6||37 27 23 21 13 8 7 6 4 4 4 3 3 3 3',
  // 'E2-E4||E2 F2 E3 E3 E4 E4 D2 E5 E5 D#3 E6 E4 D5 F#2 G#6||37 28 24 21 17 15 13 8 6 6 6 5 4 4 4',
  // 'E2-E5||E2 F2 E3 E3 D2 D#3 E5 E6 E5 F#2 D5 F3 B3 E6 E4||36 28 24 21 13 6 6 6 4 4 4 4 3 3 3',
  // 'E2-E6||E2 F2 E3 E3 D2 E6 E6 D#3 D5 E6 F#2 F3 B3 E4 B3||36 28 24 22 13 9 7 6 4 4 4 4 3 3 3',
  // 'E2-E7||E2 F2 E3 E3 D2 D#3 D5 F#2 B3 F3 E4 B3 G#5 E4 D5||36 28 24 21 12 6 4 4 3 3 3 3 3 2 2',
  // 'E2-F2||E3 F3 F#2 E2 E3 F2 F3 D2 D#3 D5 D#5 B3 G2 C4 B3||29 29 24 24 22 17 17 13 7 4 4 4 4 4 3',
  // 'E2-F3||E2 F2 E3 F3 F3 E3 D2 D#3 F4 D5 F#2 B3 B3 E4 A5||36 27 24 21 20 15 12 5 4 4 4 4 3 3 3',
  // 'E2-F4||E2 F2 E3 F4 E3 D2 F4 F4 F5 D#3 F6 F6 F5 E4 D5||36 28 24 22 21 13 12 11 8 6 5 4 4 4 4',
  // 'E2-F5||E2 F2 E3 E3 D2 F5 D#3 F6 D5 F3 F#2 F6 B3 E4 B3||36 28 24 21 11 6 6 5 4 4 4 4 3 3 3',
  // 'E2-F6||E2 F2 E3 E3 F6 F6 D2 D#3 D5 F#2 F3 B3 E4 F6 B3||35 27 24 21 12 12 11 6 4 4 4 3 3 3 3',
  // 'E2-F7||E2 F2 E3 E3 D2 D#3 F#2 D5 F3 B3 B3 E4 G#5 D5 E4||37 28 24 21 13 6 4 4 4 4 3 3 3 2 2',
  // 'E2-G2||E2 G3 F2 E3 G3 E3 D2 G2 F#2 F#3 G#2 D#3 D4 D4 D5||37 28 28 24 23 22 12 11 9 8 7 6 5 4 4',
  // 'E2-G3||E2 F2 G3 E3 E3 G3 D2 F#3 D#3 F3 D5 F#2 B3 G4 E4||37 28 25 24 22 19 13 8 6 5 4 4 4 4 3',
  // 'E2-G4||E2 F2 E3 E3 G4 G4 D2 G4 G5 D#3 G5 F#2 D5 G6 G6||36 28 24 21 21 12 11 9 8 6 5 4 4 4 4',
  // 'E2-G5||E2 F2 E3 E3 D2 G5 D#3 F3 D5 G6 F#2 B3 B3 E4 G6||37 27 24 22 13 6 6 4 4 4 4 4 3 3 3',
  // 'E2-G6||E2 F2 E3 E3 D2 G6 G6 D#3 D5 F#2 F3 G6 B3 E4 B3||38 28 24 21 14 13 10 6 4 4 4 4 4 3 3',
  // 'E2-G7||E2 F2 E3 E3 D2 D#3 D5 F3 F#2 B3 B3 E4 G#5 D5 E4||36 27 24 21 12 6 4 4 4 3 3 3 3 2 2',
  // 'E3-A3||A3 E3 E3 A3 A3 D#3 E4 F3 E4 G#5 A4 C#6 D6 G#3 G#5||26 22 22 19 8 5 4 4 3 3 3 3 3 2 2',
  // 'E3-A4||E3 E3 A4 A4 D#3 A4 A5 F3 E4 A6 G#5 E4 A6 D6 E6||23 22 13 9 5 5 4 4 4 3 3 3 3 3 2',
  // 'E3-A5||E3 E3 A5 A5 A5 D#3 E4 F3 A5 G#5 E4 D6 E5 G#5 E5||22 22 20 17 9 5 4 4 4 3 3 3 2 2 2',
  // 'E3-A6||E3 E3 A6 D#3 E4 F3 A6 E4 G#5 C#2 D6 E5 E5 G#5 D2||22 22 5 5 4 4 3 3 3 3 3 2 2 2 2',
  // 'E3-A7||E3 E3 D#3 E4 F3 E4 G#5 D6 E5 E5 G#5 C#3 E4 D6 B4||22 22 5 4 4 3 3 3 2 2 2 1 1 1 1',
  // 'E3-B3||E3 E3 B3 B4 B3 B4 F#5 B3 B5 B4 F#5 D#3 E4 E4 F3||23 22 20 15 14 11 8 6 6 5 5 5 4 4 3',
  // 'E3-B4||E3 E3 B4 B4 B4 D#3 B5 E4 F3 B6 G#5 B5 E4 D6 E5||23 22 14 9 5 5 5 4 4 3 3 3 3 3 2',
  // 'E3-B5||E3 E3 B5 B5 B5 D#3 E4 F3 B5 E4 G#5 D6 C#2 E5 G#5||22 22 18 14 7 5 4 4 3 3 3 3 2 2 2',
  // 'E3-B6||E3 E3 D#3 E4 B6 F3 E4 G#5 D6 E5 E5 G#5 B6 E2 B6||22 22 5 4 4 4 3 3 3 2 2 2 2 2 2',
  // 'E3-B7||E3 E3 D#3 E4 F3 E4 G#5 D6 E5 E5 G#5 D6 C#3 E4 B4||22 22 5 4 4 3 3 3 2 2 2 1 1 1 1',
  // 'E3-C4||E3 E3 C4 C4 C5 C5 G5 C6 C5 D#3 E4 C6 G5 G5 F3||23 22 18 17 14 9 8 6 5 5 4 4 4 3 3',
  // 'E3-C5||E3 E3 C5 C6 C6 C5 D#3 E4 F3 E4 G#5 A#1 E7 D6 C6||22 22 9 9 8 7 5 4 3 3 3 3 3 3 2',
  // 'E3-C6||E3 E3 C6 C6 C6 D#3 E4 F3 C6 E4 G#5 D6 E5 E5 G#5||22 22 17 15 7 5 4 4 3 3 3 3 2 2 2',
  // 'E3-C7||E3 E3 D#3 E4 F3 C7 E4 G#5 D6 E5 E5 G#5 C7 F2 C7||22 22 5 4 4 4 3 3 3 2 2 2 2 2 2',
  // 'E3-D4||E3 E3 D4 D5 D4 D5 A5 D5 D6 D#3 E4 A5 D6 D4 F3||22 22 17 15 15 8 7 7 5 5 4 4 4 3 3',
  // 'E3-D5||E3 E3 D5 D6 D5 D6 D#3 E4 F3 D5 E4 G#5 D6 D6 E5||22 22 9 9 7 6 5 4 4 3 3 3 3 2 2',
  // 'E3-D6||E3 E3 D6 D6 D6 D#3 E4 F3 E4 G#5 D6 D7 E5 E5 G#5||22 22 10 6 5 5 4 4 3 3 3 2 2 2 2',
  // 'E3-D7||E3 E3 D#3 E4 F3 E4 G#5 D7 D6 E5 E5 G#5 D7 D7 E4||23 22 5 4 4 3 3 3 3 2 2 2 1 1 1',
  // 'E3-E4||E3 E3 E4 E4 E5 E5 E6 D#3 E4 G#6 F3 E4 F4 G#5 G#6||23 23 16 14 10 8 5 5 5 4 4 4 3 3 3',
  // 'E3-E5||E3 E3 E5 E6 E5 D#3 E4 F3 E6 E4 G#5 D6 C#2 E6 G#5||22 22 7 6 5 5 4 4 3 3 3 3 3 2 2',
  // 'E3-E6||E3 E3 E6 E6 D#3 E6 E4 F3 G#5 E4 D6 C#2 E5 E5 G#5||23 22 10 8 5 4 4 4 3 3 3 3 2 2 2',
  // 'E3-E7||E3 E3 D#3 E4 F3 E4 G#5 D6 E5 E5 G#5 E7 E4 E7 D6||22 22 5 4 4 3 3 3 2 2 2 2 1 1 1',
  // 'E3-F3||E3 F3 E3 F3 D#3 F4 E4 E4 F4 G#5 A5 F5 D6 A5 D#6||30 28 22 18 5 4 4 3 3 3 3 3 3 2 2',
  // 'E3-F4||E3 E3 F4 F4 F4 F5 F6 D#3 E4 F6 F5 E4 F3 A6 F5||23 23 22 12 11 8 5 5 5 4 4 4 4 3 3',
  // 'E3-F5||E3 E3 F5 F6 D#3 E4 F3 F6 G#5 E4 F5 D6 E5 G#5 E5||23 22 6 5 4 4 4 4 3 3 3 3 3 2 2',
  // 'E3-F6||E3 E3 F6 F6 D#3 E4 F3 E4 G#5 F6 F6 D6 E5 C#2 E5||22 22 12 12 5 4 4 3 3 3 3 3 2 2 2',
  // 'E3-F7||E3 E3 D#3 E4 F3 E4 G#5 D6 E5 E5 G#5 F7 F7 E4 C#3||22 22 5 4 4 3 3 3 2 2 2 2 1 1 1',
  // 'E3-G3||G3 E3 E3 G3 F#3 D#3 E4 G4 F3 G#5 E4 B5 D6 G5 E5||25 23 23 19 7 5 4 4 3 3 3 3 3 2 2',
  // 'E3-G4||E3 E3 G4 G4 G4 G5 D#3 G5 G6 G6 E4 F3 G#5 B6 E4||22 22 21 12 9 8 5 5 5 4 4 4 3 3 3',
  // 'E3-G5||E3 E3 G5 G6 D#3 E4 G6 F3 G#5 E4 G5 D6 G5 E5 E5||22 22 6 5 5 4 3 3 3 3 3 3 2 2 2',
  // 'E3-G6||E3 E3 G6 G6 D#3 E4 F3 G6 E4 G#5 D6 E5 E5 G#5 E2||22 22 12 10 5 4 4 4 3 3 3 2 2 2 2',
  // 'E3-G7||E3 E3 D#3 E4 F3 E4 G#5 D6 E5 E5 G#5 G7 C#3 D6 E4||22 22 5 4 4 3 3 3 2 2 2 1 1 1 1',
  // 'E4-A4||E4 E4 A4 A4 E6 E5 E4 E5 E6 A4 A5 G#6 E4 E7 C2||21 17 13 8 8 7 6 6 5 4 4 4 4 3 3',
  // 'E4-A5||E4 A5 E4 A5 A5 E5 E4 E5 E6 E4 A5 G#6 C2 E6 E6||21 21 17 17 9 7 6 6 6 4 4 4 3 3 3',
  // 'E4-A6||E4 E4 E5 E4 E5 E6 A6 E4 G#6 A6 E6 E6 B5 F4 D2||20 17 7 6 6 6 5 4 4 3 3 3 3 3 2',
  // 'E4-A7||E4 E4 E5 E4 E5 E6 E4 G#6 E6 E6 B5 F4 G#6 C2 G#6||21 17 7 6 6 6 4 4 3 3 3 2 2 2 2',
  // 'E4-B4||E4 E4 B4 B4 E5 E4 E5 E6 B4 E4 G#6 E6 E6 B6 F4||21 17 13 8 7 6 6 6 4 4 4 3 3 3 2',
  // 'E4-B5||E4 B5 E4 B5 B5 E5 E4 E5 E6 E4 G#6 B5 E6 E6 F4||21 19 17 16 7 7 6 6 6 4 4 3 3 3 2',
  // 'E4-B6||E4 E4 E5 E4 E5 E6 B6 E4 G#6 B6 E6 E6 B5 F4 G#6||20 17 7 6 6 6 4 4 4 3 3 3 3 3 2',
  // 'E4-B7||E4 E4 E5 E4 E5 E6 E4 G#6 E6 E6 B5 F4 G#6 C2 G#6||20 17 7 6 6 6 4 4 3 3 3 2 2 2 2',
  // 'E4-C5||E4 E4 C5 C6 C6 C5 E5 E4 E6 E5 G#6 E4 E6 E6 B5||21 17 9 9 8 7 7 6 6 6 4 4 3 3 3',
  // 'E4-C6||E4 C6 E4 C6 E5 C6 E4 E5 E6 E4 G#6 C6 B5 E6 E6||20 17 17 15 7 7 6 6 6 4 4 4 3 3 3',
  // 'E4-C7||E4 E4 E5 E4 E5 E6 E4 G#6 C7 E6 E6 B5 F4 C7 G#6||21 17 7 6 6 6 4 4 4 3 3 3 3 2 2',
  // 'E4-D5||E4 E4 D5 D6 E5 D5 D6 E4 E5 E6 C2 E4 G#6 D5 A#1||20 17 9 8 7 7 6 6 6 6 4 4 4 3 3',
  // 'E4-D6||E4 E4 D6 E5 E4 E5 E6 D6 D6 E4 G#6 C2 E6 B5 A#1||20 17 10 7 6 6 6 6 5 4 4 4 3 3 3',
  // 'E4-D7||E4 E4 E5 E4 E5 E6 E4 G#6 D7 E6 E6 B5 F4 G#6 G#6||20 17 7 6 6 6 4 4 3 3 3 3 2 2 2',
  // 'E4-E5||E4 E4 E5 E6 E5 E6 E4 E6 E4 G#6 E5 B5 F4 G#6 G#6||21 17 13 12 10 7 6 5 4 4 3 3 3 2 2',
  // 'E4-E6||E4 E4 E5 E4 E5 E6 E6 E4 G#6 E6 B5 F4 G#6 E7 D7||21 17 7 6 6 6 5 4 4 3 3 3 2 2 2',
  // 'E4-E7||E4 E4 E5 E4 E5 E6 E4 G#6 E6 E6 B5 F4 C2 G#6 E7||20 17 7 6 6 6 4 4 3 3 3 2 2 2 2',
  // 'E4-F4||F4 E4 E4 F4 F4 F5 E5 E4 E6 E5 F6 F#4 F6 F5 G#6||21 20 16 11 9 8 8 6 6 6 5 5 5 4 4',
  // 'E4-F5||E4 E4 E5 E4 E5 F5 E6 F6 E4 G#6 F6 E6 E6 B5 F4||21 17 7 6 6 6 6 5 4 4 4 3 3 3 3',
  // 'E4-F6||E4 E4 F6 F6 E5 E4 E5 E6 E4 G#6 F6 E6 E6 F6 B5||20 17 12 12 7 6 6 6 4 4 3 3 3 3 3',
  // 'E4-F7||E4 E4 E5 E4 E5 E6 E4 G#6 E6 E6 B5 F4 G#6 G#6 D7||20 17 7 6 6 6 4 4 3 3 3 3 2 2 2',
  // 'E4-G4||G4 E4 E4 G4 G4 G5 E5 E4 E6 E5 G5 G6 G6 E4 G#6||21 20 17 12 9 8 7 6 6 6 5 5 4 4 4',
  // 'E4-G5||E4 E4 E5 G5 E4 E5 E6 G6 E4 G#6 G6 G5 E6 E6 B5||21 17 8 6 6 6 6 5 4 4 3 3 3 3 3',
  // 'E4-G6||E4 E4 G6 G6 E5 E4 E5 E6 G#6 E4 G6 E6 E6 B5 F4||20 17 12 10 7 6 6 6 4 4 4 3 3 3 3',
  // 'E4-G7||E4 E4 E5 E4 E5 E6 E4 G#6 E6 E6 B5 F4 G#6 G#6 D7||21 17 7 6 6 6 4 4 3 3 3 3 2 2 2',
  // 'E5-A5||A5 A5 A5 E6 E5 A5 E5 E6 E6 A6 A5 A6 A#1 E5 A#5||21 17 9 6 6 4 4 3 2 2 1 1 1 1 1',
  // 'E5-A6||E6 E5 A6 E5 E6 C#2 A6 D2 E6 A6 E2 E5 C2 A6 G#7||6 6 5 4 3 3 3 3 2 2 1 1 1 1 1',
  // 'E5-A7||E6 E5 E5 E6 E6 C#2 E5 A7 G#7 D2 A7 F#2 A7||6 6 4 3 2 1 1 1 1 1 1 1 1',
  // 'E5-B5||B5 B5 B5 E6 E5 E5 E6 B5 C#2 E6 B5 D2 B6 C2 B6||19 15 8 6 6 4 3 3 3 2 2 2 2 1 1',
  // 'E5-B6||E6 E5 B6 E5 E6 B6 E6 C#2 B6 D2 F2 E2 E5 G#7 F#2||6 6 4 4 3 3 2 2 1 1 1 1 1 1 1',
  // 'E5-B7||E6 E5 E5 E6 E6 C#2 E5 G#7 D2 B7||6 6 4 3 2 1 1 1 1 1',
  // 'E5-C6||C6 C6 C6 E5 E6 E5 C6 E6 E6 C6 C7 C#2 E5 D2 G#7||17 15 7 6 6 4 3 3 2 2 1 1 1 1 1',
  // 'E5-C7||E6 E5 E5 C7 E6 E6 C7 C7 F2 F#2 C#2 E2 E5 G#7 D2||6 6 4 4 3 2 2 2 1 1 1 1 1 1 1',
  // 'E5-D6||D6 D6 E5 E6 D6 E5 E6 D7 E6 D7 A#1 E5 C2 D6 D7||10 6 6 6 5 4 3 2 2 2 1 1 1 1 1',
  // 'E5-D7||E6 E5 E5 E6 D7 E6 D7 D7 C#2 F#2 E5 G2 G#2 G#7 D2||6 6 4 3 3 2 1 1 1 1 1 1 1 1 1',
  // 'E5-E6||E6 E6 E5 E6 E5 C#2 D2 E7 C2 E7 E5 E7 G#7 E6 D3||8 6 6 5 4 3 2 2 1 1 1 1 1 1 1',
  // 'E5-E7||E6 E5 E5 E6 E6 E7 C#2 E5 D2 G#7 E7 E7||6 6 4 3 2 2 1 1 1 1 1 1',
  // 'E5-F5||F5 E5 E6 F6 E5 F6 E6 F5 E6 F5 F6 C#2 D2 E5 G#7||6 6 6 5 4 4 3 3 2 2 2 1 1 1 1',
  // 'E5-F6||F6 F6 E5 E6 E5 E6 F6 C#2 D2 F6 E6 F7 E2 D1 F7||12 12 6 6 4 4 3 3 3 3 2 2 2 1 1',
  // 'E5-F7||E6 E5 E5 E6 E6 F7 F7 C#2 E5 G#7 C2||6 6 4 3 2 2 1 1 1 1 1',
  // 'E5-G5||G5 E5 E6 G6 E5 G6 E6 G5 G5 E6 C#2 D2 G6 E5 F2||6 6 6 5 4 3 3 3 2 2 1 1 1 1 1',
  // 'E5-G6||G6 G6 E6 E5 E5 G6 E6 E6 G6 C#2 D2 F2 E2 G6 E5||12 10 6 6 4 4 3 2 2 1 1 1 1 1 1',
  // 'E5-G7||E6 E5 E5 E6 E6 G7 C#2 E5 G7 G#7 G7 C2 E2||6 6 4 3 2 1 1 1 1 1 1 1 1',
  // 'E6-A6||E6 E6 A6 E6 D2 A6 C#2 E7 A6 E7 E2 C2 E6 A6 E7||9 7 5 4 3 3 3 2 2 2 1 1 1 1 1',
  // 'E6-A7||E6 E6 E6 E7 E7 C#2 A7 D2 E6 E7 A7 E6 A7||9 7 4 2 2 1 1 1 1 1 1 1 1',
  // 'E6-B6||E6 E6 B6 E6 B6 E7 B6 C#2 E7 D2 F2 E2 E6 F#2 E7||9 7 4 4 2 2 2 2 2 1 1 1 1 1 1',
  // 'E6-B7||E6 E6 E6 E7 E7 C#2 D2 E6 B7 E7 C2 E6||9 7 4 2 1 1 1 1 1 1 1 1',
  // 'E6-C7||E6 E6 C7 E6 C7 E7 C7 E7 E2 F2 C#2 F#2 D2 E6 C2||9 7 4 4 2 2 2 2 2 1 1 1 1 1 1',
  // 'E6-D7||E6 E6 E6 D7 E7 E7 D7 D7 C#2 G2 F#2 G#2 D2 E6 C2||9 7 4 3 2 2 1 1 1 1 1 1 1 1 1',
  // 'E6-E7||E6 E6 E7 E6 E7 E7 D2 C#2 E6 E7 E2 E6||9 7 4 4 3 2 1 1 1 1 1 1',
  // 'E6-F6||F6 F6 E6 E6 E6 D2 F6 C#2 F6 E7 E2 E7 F7 D1 F7||12 12 9 7 4 3 3 3 3 2 2 2 1 1 1',
  // 'E6-F7||E6 E6 E6 E7 E7 F7 F7 C#2 E6 C2 E7 E6||9 7 4 2 2 2 1 1 1 1 1 1',
  // 'E6-G6||G6 G6 E6 E6 G6 E6 E7 G6 E7 D2 C#2 F2 E2 G6 G7||12 10 9 7 4 4 2 2 2 2 1 1 1 1 1',
  // 'E6-G7||E6 E6 E6 E7 E7 G7 C#2 G7 E6 C2 E2 G7 D2 E7 E6||9 7 4 2 2 1 1 1 1 1 1 1 1 1 1',
  // 'F2-A2||F2 A3 F3 F#2 A3 F3 E2 A2 A3 A2 E3 E4 G#2 D#5 C4||38 30 25 24 21 18 15 13 10 10 9 6 5 4 4',
  // 'F2-A3||F2 A3 F3 F#2 A3 F3 E2 E3 A3 C4 D#5 A5 A4 A5 F4||37 26 26 24 19 17 14 9 8 4 4 3 3 3 3',
  // 'F2-A4||F2 F3 F#2 F3 E2 A4 A4 E3 A4 A5 C4 D#5 G2 A5 A6||37 26 24 18 15 13 9 9 5 4 4 4 3 3 3',
  // 'F2-A5||F2 F3 F#2 A5 F3 A5 E2 E3 A5 C4 D#5 A5 F4 G2 D#5||38 25 25 20 18 17 15 9 9 4 4 3 3 3 3',
  // 'F2-A6||F2 F3 F#2 F3 E2 E3 A6 C4 D#5 A6 D2 F4 G2 A5 D#5||37 26 24 18 16 9 5 4 4 3 3 3 3 3 2',
  // 'F2-A7||F2 F3 F#2 F3 E2 E3 D#5 C4 G2 F4 A5 D#5 C4 C5 G#2||38 26 25 18 15 9 4 4 3 3 3 3 2 2 2',
  // 'F2-B2||F2 B3 F3 F#2 B3 E2 F3 B2 B3 E3 A#2 F#4 F#4 D#5 C3||39 30 26 25 21 18 18 11 10 9 8 5 4 4 3',
  // 'F2-B3||F2 F3 F#2 B3 F3 E2 B3 B4 B4 E3 F#5 B3 B5 C4 F#5||38 26 25 20 18 15 14 14 10 9 8 6 6 6 5',
  // 'F2-B4||F2 F3 F#2 F3 E2 B4 E3 B4 B4 D#5 B5 C4 F4 G2 B6||38 26 25 18 15 12 9 8 4 4 4 4 3 3 3',
  // 'F2-B5||F2 F3 F#2 B5 F3 E2 B5 E3 B5 D#5 C4 B5 F4 G2 A5||38 26 25 19 18 15 15 9 8 4 4 3 3 3 3',
  // 'F2-B6||F2 F3 F#2 F3 E2 E3 C4 B6 D#5 G2 F4 A5 D#5 C4 B6||37 26 25 18 13 9 4 4 4 3 3 3 2 2 2',
  // 'F2-B7||F2 F3 F#2 F3 E2 E3 D#5 C4 G2 F4 A5 D#5 C5 C4 F4||38 26 25 18 15 9 4 4 3 3 3 2 2 2 2',
  // 'F2-C3||F2 F3 F#2 C4 C4 F3 C3 E2 E3 C3 G4 B3 C4 B2 G4||35 26 24 23 23 18 13 12 10 8 5 5 5 5 4',
  // 'F2-C4||F2 F3 F#2 C4 C4 F3 C5 E2 C5 E3 G5 C5 C6 D#5 B3||38 25 24 19 18 18 17 16 11 9 7 7 5 4 4',
  // 'F2-C5||F2 F3 F#2 F3 E2 C5 C6 E3 C5 C6 D#5 C4 C6 G2 F4||38 26 25 18 15 11 10 9 8 8 4 4 4 3 3',
  // 'F2-C6||F2 F3 F#2 F3 C6 E2 C6 E3 C6 D#5 C4 C6 F4 G2 A5||37 26 24 18 16 15 14 9 6 4 4 3 3 3 3',
  // 'F2-C7||F2 F3 F#2 F3 E2 E3 C4 D#5 C7 G2 F4 A5 D#5 C5 C4||39 26 25 18 16 9 4 4 4 3 3 3 3 2 2',
  // 'F2-D3||F2 D4 F3 D4 F#2 F3 E2 D3 E3 D4 D3 A4 A4 G2 C#3||37 28 26 24 23 18 15 13 10 8 6 6 5 5 4',
  // 'F2-D4||F2 F3 F#2 F3 D4 D4 D5 E2 E3 D5 A5 D5 D6 D6 D#5||38 26 25 18 17 15 15 14 9 8 7 7 5 4 4',
  // 'F2-D5||F2 F3 F#2 F3 E2 E3 D5 D6 D5 D6 D#5 C4 D5 F4 G2||38 26 25 18 16 9 9 9 7 6 5 4 3 3 3',
  // 'F2-D6||F2 F3 F#2 F3 E2 D6 E3 D6 D6 D#5 C4 F4 A5 G2 D#5||38 26 24 18 15 10 9 6 5 4 4 3 3 3 3',
  // 'F2-D7||F2 F3 F#2 F3 E2 E3 D#5 C4 G2 F4 A5 D7 D#5 C5 C4||38 26 25 18 15 9 4 4 3 3 3 3 2 2 2',
  // 'F2-E3||F2 F3 E3 F#2 E3 F3 E2 D#3 C4 D#5 E4 G#5 F4 E4 A5||38 26 26 25 23 18 15 5 4 4 4 3 3 3 3',
  // 'F2-E4||F2 F3 F#2 E4 F3 E4 E2 E3 E5 E4 E5 E6 F4 D#5 E4||37 26 24 21 18 17 14 9 7 7 6 6 5 4 4',
  // 'F2-E5||F2 F3 F#2 F3 E2 E3 E5 E6 D#5 C4 E5 E6 F4 G2 A5||38 26 25 18 15 9 6 6 4 4 4 3 3 3 3',
  // 'F2-E6||F2 F3 F#2 F3 E2 E3 E6 E6 D#5 C4 E6 G2 F4 A5 D#5||38 26 25 18 16 9 9 8 4 4 4 3 3 3 2',
  // 'F2-E7||F2 F3 F#2 F3 E2 E3 C4 D#5 F4 G2 A5 C4 D#5 C5 E7||37 26 24 18 15 9 4 4 3 3 3 3 3 2 2',
  // 'F2-F3||F2 F3 F#2 F3 E2 E3 F4 F4 C4 D#5 C5 F4 F5 G2 D#5||38 25 24 18 15 10 8 5 4 4 4 3 3 3 3',
  // 'F2-F4||F2 F3 F#2 F4 F3 E2 F4 F4 E3 F5 F6 F5 F6 D#5 C4||38 26 25 19 18 15 10 9 9 8 5 5 4 4 4',
  // 'F2-F5||F2 F3 F#2 F3 E2 E3 F5 F6 C4 D#5 F6 F4 A5 G2 F5||37 26 24 18 15 9 6 5 4 4 4 3 3 3 3',
  // 'F2-F6||F2 F3 F#2 F3 E2 F6 F6 E3 D#5 C4 F6 G2 D2 F4 F6||37 26 25 18 16 13 12 9 4 4 3 3 3 3 3',
  // 'F2-F7||F2 F3 F#2 F3 E2 E3 C4 D#5 F4 A5 G2 D#5 C4 C5 F4||37 26 25 18 14 9 4 4 3 3 3 3 2 2 2',
  // 'F2-G2||F2 G3 F3 G3 F#2 F3 E2 G2 G#2 E3 F#3 D4 D4 D#5 C4||37 29 25 23 19 18 15 11 9 9 9 5 5 4 4',
  // 'F2-G3||F2 F3 G3 F#2 G3 F3 E2 E3 F#3 C4 D#5 G5 G4 F4 B5||38 26 25 25 18 18 15 10 9 4 4 4 4 3 3',
  // 'F2-G4||F2 F3 F#2 G4 F3 E2 G4 G5 G4 E3 G5 G6 G6 D#5 C4||38 25 25 21 18 15 12 9 9 9 6 4 4 4 3',
  // 'F2-G5||F2 F3 F#2 F3 E2 E3 G5 G6 C4 D#5 G5 G6 F4 A5 G5||39 26 25 18 15 9 7 5 4 4 3 3 3 3 3',
  // 'F2-G6||F2 F3 F#2 F3 E2 G6 G6 E3 C4 D#5 G6 G2 F4 A5 D#5||38 26 26 18 14 12 9 9 4 4 4 3 3 3 2',
  // 'F2-G7||F2 F3 F#2 F3 E2 E3 C4 D#5 F4 A5 D#5 G2 C4 C5 F4||38 26 24 18 15 9 4 4 3 3 3 3 2 2 2',
  // 'F3-A3||A3 F3 F3 A3 A3 E3 F4 A4 C#6 F5 G#3 D#6 A5 C#6 G6||26 25 20 19 8 7 4 3 3 3 3 2 2 2 2',
  // 'F3-A4||F3 F3 A4 A4 E3 A4 F4 A5 A6 F5 A5 A5 A6 E7 D#6||25 19 13 8 8 5 4 4 3 3 3 3 3 2 2',
  // 'F3-A5||F3 A5 A5 F3 A5 E3 A5 F4 F5 D#6 F4 E7 E7 C2 A#1||25 21 19 19 9 8 7 4 3 2 2 2 2 2 2',
  // 'F3-A6||F3 F3 E3 A6 F4 A6 A5 D2 F5 C#2 A5 D#6 F4 F4 A6||25 19 8 5 4 4 3 3 3 2 2 2 2 2 2',
  // 'F3-A7||F3 F3 E3 F4 A5 F5 A5 D#6 F4 F4 F5 D#6 F#3 D3 C5||25 19 8 4 3 3 2 2 2 2 2 1 1 1 1',
  // 'F3-B3||F3 B3 F3 B3 B4 B4 E3 F#5 B3 B5 F#5 B4 F4 B5 D#6||24 20 19 14 14 10 8 8 7 6 5 4 4 3 3',
  // 'F3-B4||F3 F3 B4 B4 E3 B4 F4 B5 A5 B6 F5 A5 B5 D#6 B6||25 19 13 8 8 4 4 4 3 3 3 3 3 2 2',
  // 'F3-B5||F3 F3 B5 B5 B5 E3 F4 B5 A5 F5 A5 D#6 F4 B5 F4||25 19 19 15 8 8 4 3 3 3 3 2 2 2 2',
  // 'F3-B6||F3 F3 E3 F4 B6 A5 F5 A5 B6 D#6 F4 F4 B6 F5 D#6||25 19 8 4 4 3 3 2 2 2 2 2 2 2 1',
  // 'F3-B7||F3 F3 E3 F4 A5 F5 A5 D#6 F4 F4 F5 D#6 F#3 F#3 C5||25 19 8 4 3 3 2 2 2 2 2 1 1 1 1',
  // 'F3-C4||F3 F3 C4 C4 C5 C5 G5 E3 C6 C5 F4 C6 G5 B3 G5||25 20 17 16 16 11 8 7 6 6 4 4 4 3 3',
  // 'F3-C5||F3 F3 C6 C5 C6 C5 E3 F4 A1 C6 A5 F5 A5 A#1 G6||24 19 10 9 8 8 7 4 3 3 3 3 3 2 2',
  // 'F3-C6||F3 F3 C6 C6 E3 C6 F4 C6 A5 F5 A5 D#6 F4 D2 C6||25 19 16 15 8 6 4 3 3 3 3 2 2 2 2',
  // 'F3-C7||F3 F3 E3 F4 C7 A5 F5 A5 D#6 F4 C7 F4 F5 F2 C7||25 19 8 4 4 3 3 2 2 2 2 2 2 2 2',
  // 'F3-D4||F3 F3 D4 D4 D5 D5 A5 E3 A5 D5 D6 F4 D6 D4 F#6||25 19 17 15 15 8 8 7 7 7 5 4 4 3 3',
  // 'F3-D5||F3 F3 D5 D6 E3 D5 D6 F4 D5 C2 A5 F5 D6 A#1 D#6||25 19 9 8 7 7 6 4 3 3 3 3 3 3 3',
  // 'F3-D6||F3 F3 D6 E3 D6 D6 F4 A5 F5 A5 C2 D7 F4 D#6 D7||25 19 10 7 6 5 4 3 3 2 2 2 2 2 2',
  // 'F3-D7||F3 F3 E3 F4 A5 D7 F5 A5 D#6 F4 F4 F5 D7 D#6 D7||25 19 8 4 3 3 3 2 2 2 2 2 1 1 1',
  // 'F3-E4||F3 E4 F3 E4 E3 E5 E4 E5 E6 F4 F4 E4 G#6 A5 E6||25 21 19 17 8 7 7 6 6 5 5 4 4 3 3',
  // 'F3-E5||F3 F3 E3 E6 E5 F4 E5 E6 A5 F5 A5 D#6 F4 E6 F4||25 19 8 6 6 4 4 3 3 3 2 2 2 2 2',
  // 'F3-E6||F3 F3 E6 E3 E6 F4 E6 A5 F5 E7 A5 D#6 F4 E7 F4||25 19 9 8 7 4 4 3 3 3 2 2 2 2 2',
  // 'F3-E7||F3 F3 E3 F4 E7 A5 F5 A5 D#6 E7 F4 F4 C#2 D2 F5||25 19 8 4 3 3 3 2 2 2 2 2 2 2 2',
  // 'F3-F4||F3 F3 F4 F5 F4 F4 E3 F5 F6 F5 F6 A6 A6 F#4 C6||25 19 18 11 9 9 8 6 5 4 4 3 3 3 3',
  // 'F3-F5||F3 F3 E3 F5 F6 F4 F6 F5 F5 A5 A5 D#6 F4 D2 F4||25 19 8 8 5 4 4 3 3 3 2 2 2 2 2',
  // 'F3-F6||F3 F3 F6 F6 E3 F4 F6 D2 F6 A5 F5 C#2 A5 D#6 F4||25 19 13 13 8 4 3 3 3 3 3 3 3 2 2',
  // 'F3-F7||F3 F3 E3 F4 A5 F5 A5 D#6 F4 F4 F5 F7 D#6 F7 F#3||25 19 8 4 3 3 2 2 2 2 2 2 1 1 1',
  // 'F3-G3||G3 F3 F3 G3 E3 F#3 F4 G4 B5 A5 F5 A5 F4 D#6 G5||25 24 20 18 8 7 5 4 3 3 3 3 2 2 2',
  // 'F3-G4||F3 G4 F3 G4 G4 G5 E3 G5 G6 F4 G6 B6 G5 A5 F5||25 21 19 12 9 8 8 5 4 4 4 3 3 3 3',
  // 'F3-G5||F3 F3 E3 G5 G6 F4 G6 G5 A5 F5 A5 G5 D#6 F4 F4||24 19 8 6 4 4 3 3 3 3 3 2 2 2 2',
  // 'F3-G6||F3 F3 G6 G6 E3 F4 G6 A5 F5 A5 D#6 F4 F4 F5 G6||25 19 12 10 8 4 4 3 3 2 2 2 2 2 1',
  // 'F3-G7||F3 F3 E3 F4 A5 F5 A5 D#6 F4 F4 F5 D#6 G7 F#3 D3||25 19 8 4 3 3 2 2 2 2 2 1 1 1 1',
  // 'F4-A4||F4 A4 F4 F4 A4 F5 F6 F6 F5 A4 A5 A6 F5 A6 A6||22 13 12 10 9 8 5 4 4 4 4 3 3 3 3',
  // 'F4-A5||F4 A5 A5 F4 F4 A5 F5 F6 F6 F5 A5 F5 A6 F#4 C6||22 20 17 12 11 9 8 5 4 4 4 3 3 3 3',
  // 'F4-A6||F4 F4 F4 F5 F6 A6 F6 F5 A6 A6 A6 F5 F#4 C6 F#4||22 12 11 8 5 5 4 4 4 4 3 3 3 3 2',
  // 'F4-A7||F4 F4 F4 F5 F6 F6 F5 A6 F5 F#4 C6 A6 F#4 D#7 C6||22 12 11 8 5 4 4 3 3 3 3 3 2 1 1',
  // 'F4-B4||F4 B4 F4 F4 B4 F5 F6 F6 F5 B4 B5 A6 F5 B6 F#4||22 12 12 11 8 8 5 4 4 4 4 3 3 3 3',
  // 'F4-B5||F4 B5 B5 F4 F4 B5 F5 F6 F6 F5 F5 A6 B5 C6 F#4||22 19 15 12 11 8 8 5 4 4 3 3 3 3 3',
  // 'F4-B6||F4 F4 F4 F5 F6 F6 F5 B6 A6 F5 F#4 C6 A6 B6 F#4||22 12 11 8 5 4 4 4 3 3 3 3 3 2 2',
  // 'F4-B7||F4 F4 F4 F5 F6 F6 F5 A6 F5 F#4 C6 A6 F#4 C2 D#7||22 12 11 8 5 4 4 3 3 3 3 3 2 2 1',
  // 'F4-C5||F4 F4 F4 C5 C6 F5 C5 C6 F6 F6 F5 F5 A6 A#1 F#4||22 12 11 9 8 8 7 7 5 4 4 3 3 3 3',
  // 'F4-C6||F4 C6 C6 F4 F4 F5 C6 F6 F6 F5 C6 F5 A6 F#4 A6||22 18 16 12 11 8 6 5 4 4 3 3 3 3 3',
  // 'F4-C7||F4 F4 F4 F5 F6 F6 F5 C7 A6 F5 F#4 C6 C7 A6 F#4||22 12 11 8 5 4 4 4 3 3 3 3 3 3 2',
  // 'F4-D5||F4 F4 F4 D5 D6 F5 D5 D6 F6 F6 F5 F5 A6 D5 F#4||22 11 11 9 8 8 7 6 5 4 4 3 3 3 3',
  // 'F4-D6||F4 F4 F4 D6 F5 D6 D6 F6 F6 F5 A6 F5 F#4 C6 A6||22 12 11 10 8 6 5 5 5 4 3 3 3 3 3',
  // 'F4-D7||F4 F4 F4 F5 F6 F6 F5 A6 F5 F#4 D7 C6 A6 F#4 D#7||22 12 11 8 5 4 4 3 3 3 3 3 3 2 1',
  // 'F4-E5||F4 F4 F4 F5 E6 E5 F6 F6 F5 E5 A6 F5 E6 F#4 C6||22 12 11 8 6 6 5 4 4 4 3 3 3 3 3',
  // 'F4-E6||F4 F4 F4 E6 F5 E6 F6 F6 F5 E6 F5 A6 F#4 C6 A6||22 12 11 9 8 7 5 4 4 4 3 3 3 3 3',
  // 'F4-E7||F4 F4 F4 F5 F6 F6 F5 A6 F5 F#4 C6 A6 E7 F#4 D#7||22 12 11 8 5 4 4 3 3 3 3 3 2 2 1',
  // 'F4-F5||F4 F5 F4 F6 F4 F6 F5 F5 A6 F6 F#4 C6 A6 F#4 D#7||22 14 12 11 11 8 7 6 3 3 3 3 3 2 1',
  // 'F4-F6||F4 F4 F6 F4 F6 F5 F5 A6 F5 F6 F#4 F6 C6 A6 D2||22 12 11 11 11 8 4 3 3 3 3 3 3 3 2',
  // 'F4-F7||F4 F4 F4 F5 F6 F6 F5 A6 F5 F#4 C6 A6 F#4 C#2 C2||22 12 11 8 5 4 4 3 3 3 3 3 2 2 2',
  // 'F4-G4||F4 G4 F4 G4 F4 G4 F5 G5 F6 G5 G6 F6 G6 F5 F5||22 20 12 11 10 9 8 8 5 5 5 4 4 4 3',
  // 'F4-G5||F4 F4 F4 F5 G5 F6 G6 F6 F5 G6 A6 F5 F#4 G5 C6||22 12 11 8 6 5 5 4 4 3 3 3 3 3 3',
  // 'F4-G6||F4 G6 F4 F4 G6 F5 F6 F6 F5 G6 F5 A6 F#4 C6 A6||22 12 12 11 10 8 5 4 4 4 3 3 3 3 3',
  // 'F4-G7||F4 F4 F4 F5 F6 F6 F5 A6 F5 F#4 C6 A6 F#4 C2 D#7||22 12 11 8 5 4 4 3 3 3 3 3 2 1 1',
  // 'F5-A5||A5 A5 A5 F5 F6 A5 F6 F5 F5 A6 C2 F6 A5 C#2 D2||20 17 9 6 5 4 4 3 2 2 2 2 1 1 1',
  // 'F5-A6||F5 F6 A6 F6 A6 D2 F5 C#2 F5 A6 F6 E2 A6||6 5 5 4 3 3 3 2 2 2 2 1 1',
  // 'F5-A7||F5 F6 F6 F5 F5 A7 F6 D2 A7 A7 C#2||6 5 4 3 2 2 2 1 1 1 1',
  // 'F5-B5||B5 B5 B5 F5 F6 F6 B5 F5 F5 B5 B6 F6 D2 C#2 F#7||19 15 8 6 5 4 3 3 2 2 2 2 1 1 1',
  // 'F5-B6||F5 F6 B6 F6 F5 B6 F5 B6 F6 F2 E2 A7 B6||6 5 4 4 3 2 2 2 2 1 1 1 1',
  // 'F5-B7||F5 F6 F6 F5 F5 F6 D2 B7 A7||6 5 4 3 2 2 1 1 1',
  // 'F5-C6||C6 C6 C6 F5 F6 F6 C6 F5 D2 F5 C6 C#2 C7 F6 C7||17 15 7 6 5 4 3 3 3 2 2 2 2 2 1',
  // 'F5-C7||F5 F6 C7 F6 C7 F5 F5 F6 D2 C7 E2 F#2 F2 C#2 A7||6 5 4 4 3 3 2 2 2 1 1 1 1 1 1',
  // 'F5-D6||D6 F5 D6 D6 F6 F6 F5 D7 F5 D7 C2 F6 C#2 D2 A#1||10 6 6 5 5 4 3 2 2 2 2 2 2 1 1',
  // 'F5-D7||F5 F6 F6 D7 F5 F5 F6 D7 D7 D2 F#2 C#2 G#2 A7 G2||6 5 4 3 3 2 2 1 1 1 1 1 1 1 1',
  // 'F5-E6||E6 E6 F5 F6 E6 F6 F5 F5 E7 E7 D2 F6 C#2 E6 E2||9 7 6 5 4 3 3 2 2 2 1 1 1 1 1',
  // 'F5-E7||F5 F6 F6 F5 D2 F5 E7 C#2 F6 E7 E2 E7 A7||6 5 4 3 2 2 2 2 2 1 1 1 1',
  // 'F5-F6||F6 F6 F5 F6 D2 F6 F5 C#2 F5 E2 F7 F7 D1 C1 F7||13 13 6 4 3 3 3 3 2 2 2 2 1 1 1',
  // 'F5-F7||F5 F6 F6 F5 F5 F6 F7 F7 D2 A7 E2||6 5 4 3 2 2 1 1 1 1 1',
  // 'F5-G5||G5 F5 F6 G6 F6 G6 G5 F5 G5 F5 F6 G6 D2 F2 A7||6 6 5 5 4 3 3 3 3 2 2 1 1 1 1',
  // 'F5-G6||G6 G6 F5 F6 G6 F6 F5 F5 G6 F6 F2 G6 G7 G7 E2||12 10 6 5 4 4 3 2 2 1 1 1 1 1 1',
  // 'F5-G7||F5 F6 F6 F5 F5 F6 G7 D2 E2 G7 G7 C#2 A7||6 5 4 3 2 2 1 1 1 1 1 1 1',
  // 'F6-A6||F6 F6 A6 D2 C#2 A6 F6 F6 E2 A6 F7 F7 F6 A6 C2||12 12 5 4 4 3 3 3 2 2 2 1 1 1 1',
  // 'F6-A7||F6 F6 F6 F6 D2 C#2 F7 E2 F7 A7 F6 D1 A7 A7||12 12 3 3 2 2 2 1 1 1 1 1 1 1',
  // 'F6-B6||F6 F6 B6 F6 F6 B6 C#2 B6 F7 D2 F7 F6 D1 F2 C1||12 12 4 3 3 2 2 2 2 1 1 1 1 1 1',
  // 'F6-B7||F6 F6 F6 F6 D2 C#2 F7 E2 F7 D1 F6 C1 B7||12 12 3 3 2 2 2 1 1 1 1 1 1',
  // 'F6-C7||F6 F6 C7 F6 F6 D2 E2 C7 C#2 C7 F7 F2 F#2 D1 F7||12 12 4 3 3 2 2 2 2 2 2 1 1 1 1',
  // 'F6-D7||F6 F6 F6 F6 D7 D2 C#2 F7 D7 D7 E2 F7 F6 G#2 G2||12 12 3 3 3 2 2 2 1 1 1 1 1 1 1',
  // 'F6-E7||F6 F6 F6 D2 F6 C#2 E7 E2 F7 E7 F7 D1 F6 E7 C1||12 12 3 3 3 2 2 2 1 1 1 1 1 1 1',
  // 'F6-F7||F6 F6 F6 F6 F7 F7 D2 C#2 F6 F7 D1 C1||12 12 3 3 3 2 1 1 1 1 1 1',
  // 'F6-G6||F6 G6 F6 G6 G6 F6 F6 G6 C#2 D2 F7 G6 F7 G7 F6||12 12 12 10 4 3 3 2 2 2 2 1 1 1 1',
  // 'F6-G7||F6 F6 F6 F6 D2 E2 C#2 F7 G7 F7 F2 G7 F6 D1 G7||12 12 3 3 2 2 2 1 1 1 1 1 1 1 1',
  // 'G2-A2||A3 G3 G3 A3 G2 A2 G#2 A3 A2 F#3 F#2 E4 D4 D4 F5||30 29 23 21 13 12 11 11 9 8 6 6 5 4 3',
  // 'G2-A3||G3 A3 G3 A3 G2 F#3 A3 G#2 F#2 D4 D4 G#3 C2 F5 A4||28 26 23 19 13 9 8 7 7 5 4 4 3 3 3',
  // 'G2-A4||G3 G3 G2 A4 F#3 A4 G#2 F#2 D4 A5 A4 D4 G#3 A6 F5||29 23 13 13 9 9 8 6 5 5 4 4 4 3 3',
  // 'G2-A5||G3 G3 A5 A5 G2 A5 F#3 G#2 F#2 D4 D4 A5 G#3 F5 G4||29 23 21 17 12 9 8 7 6 5 4 4 3 3 2',
  // 'G2-A6||G3 G3 G2 F#3 G#2 F#2 A6 D4 D4 G#3 A6 F5 C2 G4 D2||29 23 13 9 7 6 5 5 4 3 3 3 3 2 2',
  // 'G2-A7||G3 G3 G2 F#3 G#2 F#2 D4 D4 G#3 F5 C2 G4 F5 A#1 B5||29 23 12 9 8 5 5 4 3 3 3 2 2 2 2',
  // 'G2-B2||B3 G3 G3 B3 G2 B2 B3 F#3 A#2 G#2 F#2 F#4 D4 D4 F#4||30 29 24 22 13 11 10 9 8 8 6 5 5 4 4',
  // 'G2-B3||G3 G3 B3 B3 B4 G2 F#3 B4 G#2 F#5 B3 B5 F#2 C2 F#5||30 23 20 14 14 13 10 10 8 8 7 6 5 5 5',
  // 'G2-B4||G3 G3 G2 B4 F#3 B4 G#2 F#2 D4 D4 B4 B5 C2 G#3 F5||29 23 13 12 9 8 8 5 5 4 4 4 4 3 3',
  // 'G2-B5||G3 G3 B5 B5 G2 F#3 B5 G#2 F#2 B5 D4 D4 C2 G#3 F5||29 23 19 15 12 8 8 7 5 5 5 4 4 3 3',
  // 'G2-B6||G3 G3 G2 F#3 G#2 F#2 D4 D4 B6 G#3 F5 C2 B6 G4 F5||29 23 12 9 7 5 5 4 4 3 3 3 2 2 2',
  // 'G2-B7||G3 G3 G2 F#3 G#2 F#2 D4 D4 G#3 F5 C2 G4 F5 B5 A#1||29 23 12 9 7 6 5 4 3 3 3 2 2 2 2',
  // 'G2-C3||G3 C4 C4 G3 G2 C3 F#3 G#2 C3 F#2 G4 B3 D4 G4 C4||29 27 26 23 13 12 9 8 7 6 6 6 5 5 4',
  // 'G2-C4||G3 G3 C4 C4 C5 G2 C5 F#3 G5 G#2 F#2 C6 C5 D4 D4||30 24 17 17 14 13 9 8 7 7 6 6 5 5 4',
  // 'G2-C5||G3 G3 G2 C5 C6 F#3 G#2 C6 C5 F#2 D4 D4 C2 A#1 G#3||29 23 13 9 9 8 8 8 7 5 5 4 4 4 3',
  // 'G2-C6||G3 G3 C6 C6 G2 F#3 G#2 C6 F#2 D4 D4 C6 G#3 F5 C2||29 23 17 15 13 9 8 7 6 5 4 3 3 3 3',
  // 'G2-C7||G3 G3 G2 F#3 G#2 D4 F#2 D4 C7 G#3 F5 C2 G4 C7 F5||29 23 13 8 8 5 5 4 4 3 3 3 2 2 2',
  // 'G2-D3||D4 G3 D4 G3 G2 D3 F#3 D4 G#2 F#2 D3 A4 C#3 A4 D4||33 29 28 23 14 12 9 9 8 7 6 5 5 4 4',
  // 'G2-D4||G3 G3 D4 D5 G2 D4 D5 F#3 G#2 D5 A5 D6 F#2 D6 D4||29 23 16 15 13 12 8 8 8 7 7 6 6 5 4',
  // 'G2-D5||G3 G3 G2 D5 D6 F#3 D5 G#2 D6 F#2 D4 D5 D4 F5 G#3||29 23 12 12 9 8 8 7 7 6 5 5 4 3 3',
  // 'G2-D6||G3 G3 G2 D6 F#3 G#2 F#2 D6 D6 D4 D4 G#3 F5 D7 G4||29 23 13 9 8 7 6 5 5 5 4 3 3 2 2',
  // 'G2-D7||G3 G3 G2 F#3 G#2 F#2 D4 D4 G#3 F5 C2 D7 G4 A#1 A2||29 23 12 9 6 6 5 4 3 3 3 3 2 2 2',
  // 'G2-E3||G3 G3 E3 E3 G2 G#2 F#3 D#3 F#2 D4 D4 C2 G#3 E4 F3||29 24 23 22 13 8 7 5 5 5 5 4 4 4 4',
  // 'G2-E4||G3 G3 E4 E4 G2 F#3 G#2 E5 E4 E5 E6 F#2 D4 E4 G#6||29 24 21 17 13 9 8 7 7 6 6 6 5 4 4',
  // 'G2-E5||G3 G3 G2 F#3 G#2 E6 E5 F#2 D4 D4 C2 E5 E6 G#3 G4||29 23 12 8 7 6 6 5 5 4 4 4 3 3 3',
  // 'G2-E6||G3 G3 G2 E6 F#3 G#2 E6 F#2 D4 D4 C2 E6 G#3 F5 G4||29 23 12 9 8 7 7 5 5 4 4 4 3 3 2',
  // 'G2-E7||G3 G3 G2 F#3 G#2 F#2 D4 D4 G#3 F5 C2 G4 F5 B5 D5||29 23 13 9 8 6 5 4 3 3 2 2 2 2 2',
  // 'G2-F3||G3 F3 G3 F3 G2 E3 G#2 F#3 F#2 D4 F4 D4 F5 G#3 F5||28 25 22 19 13 7 7 7 6 5 4 4 3 3 3',
  // 'G2-F4||G3 G3 F4 G2 F4 F4 F#3 F5 G#2 F#2 F6 D4 F6 C2 F5||29 23 22 12 12 11 9 8 7 5 5 5 5 4 4',
  // 'G2-F5||G3 G3 G2 F#3 G#2 F5 F#2 F6 D4 D4 F6 G#3 F5 C2 F5||29 23 13 9 8 6 6 5 5 4 4 3 3 3 3',
  // 'G2-F6||G3 G3 G2 F6 F6 F#3 G#2 F#2 D4 D4 G#3 F5 F6 C2 F6||29 23 13 12 12 9 8 6 5 4 3 3 3 3 3',
  // 'G2-F7||G3 G3 G2 F#3 G#2 F#2 D4 D4 G#3 F5 C2 G4 B5 F5 D5||29 23 13 9 7 6 5 4 3 3 3 2 2 2 2',
  // 'G2-G3||G3 G3 F#3 G2 G#2 F#2 B5 D4 D4 G#3 C2 B5 F5 G5 D5||54 42 17 12 8 6 5 5 5 4 4 3 3 3 3',
  // 'G2-G4||G3 G4 G3 G4 G2 G4 F#3 G5 G#2 F#2 G5 D4 D4 G6 G6||29 24 23 14 13 10 9 8 7 6 5 5 4 4 4',
  // 'G2-G5||G3 G3 G2 F#3 G#2 G5 F#2 D4 G6 D4 G#3 G6 F5 C2 G5||29 23 13 9 8 6 5 5 4 4 3 3 3 3 3',
  // 'G2-G6||G3 G3 G2 G6 G6 F#3 G#2 F#2 D4 D4 G6 G#3 F5 C2 G4||29 23 12 12 10 9 7 5 5 4 4 3 3 3 2',
  // 'G2-G7||G3 G3 G2 F#3 G#2 F#2 D4 D4 G#3 F5 C2 G4 A#1 F5 B5||29 23 13 9 7 6 5 4 3 3 3 2 2 2 2',
  // 'G3-A3||A3 G3 G3 A3 F#3 A3 G4 B5 A4 C#6 G5 G4 C#6 B5 A4||26 25 19 19 8 8 4 3 3 3 2 2 2 2 2',
  // 'G3-A4||G3 G3 A4 F#3 A4 A4 A6 A5 G4 A6 B5 G4 A5 G5 E7||26 19 13 9 8 5 4 4 4 4 3 2 2 2 2',
  // 'G3-A5||G3 A5 G3 A5 A5 F#3 A5 G4 B5 G5 G4 B5 F6 A6 C2||26 21 19 17 9 8 4 4 3 3 2 2 2 2 2',
  // 'G3-A6||G3 G3 F#3 A6 G4 A6 B5 G5 C#2 G4 D2 B5 F6 C2 G5||26 19 9 4 4 3 3 2 2 2 2 2 2 2 2',
  // 'G3-A7||G3 G3 F#3 G4 B5 G5 G4 B5 F6 G5 G4 F6 G#3 C2 D5||26 19 9 4 3 2 2 2 2 2 2 1 1 1 1',
  // 'G3-B3||G3 B3 G3 B3 B4 B4 F#3 F#5 B3 B5 B5 F#5 B4 G4 D#6||26 20 19 14 14 10 10 8 7 6 6 5 4 4 3',
  // 'G3-B4||G3 G3 B4 F#3 B4 B4 B5 G4 B6 B5 B5 G5 B6 G4 F6||26 19 13 9 8 4 4 4 3 3 3 2 2 2 2',
  // 'G3-B5||G3 G3 B5 B5 F#3 B5 B5 G4 G5 G4 F#7 C#2 F6 B5 C2||26 19 19 16 8 8 6 4 2 2 2 2 2 2 2',
  // 'G3-B6||G3 G3 F#3 B6 G4 B5 G5 B6 B6 G4 E2 F2 B5 F6 G5||26 19 9 4 4 3 2 2 2 2 2 2 2 2 2',
  // 'G3-B7||G3 G3 F#3 G4 B5 G5 G4 B5 F6 G5 G4 F6 G#3 D5 C2||26 19 8 4 3 2 2 2 2 2 2 2 1 1 1',
  // 'G3-C4||G3 G3 C4 C4 C5 C5 F#3 G5 C6 C5 B3 G4 C6 C4 G5||27 20 18 17 14 9 9 7 6 5 4 4 4 3 3',
  // 'G3-C5||G3 G3 C5 C6 F#3 C6 C5 G4 B5 A1 G5 C6 E7 C5 G4||26 19 9 9 8 8 7 4 3 3 3 3 2 2 2',
  // 'G3-C6||G3 G3 C6 C6 F#3 C6 G4 C6 B5 G5 G4 B5 F6 C6 G5||26 19 17 15 8 7 4 3 3 2 2 2 2 2 2',
  // 'G3-C7||G3 G3 F#3 C7 G4 B5 G5 G4 C7 B5 F6 G5 E2 F2 G4||26 19 8 4 4 3 2 2 2 2 2 2 2 2 2',
  // 'G3-D4||G3 G3 D4 D5 D4 D5 F#3 A5 D5 D6 A5 D6 G4 D4 F#6||26 19 17 16 15 9 8 7 7 5 4 4 4 3 3',
  // 'G3-D5||G3 G3 D5 D6 F#3 D5 D6 G4 D5 D6 C2 B5 A#1 G5 G4||25 19 10 9 8 7 7 4 3 3 3 3 2 2 2',
  // 'G3-D6||G3 G3 D6 F#3 D6 D6 G4 B5 D7 D7 G5 G4 B5 F6 C2||26 19 9 8 5 5 4 3 3 2 2 2 2 2 2',
  // 'G3-D7||G3 G3 F#3 G4 B5 D7 G5 G4 F6 B5 G5 G4 D7 F6 D7||26 19 9 4 3 3 2 2 2 2 2 2 2 1 1',
  // 'G3-E4||G3 E4 G3 E4 F#3 E5 E5 E4 E6 E4 G#6 B5 G4 B5 B5||26 21 19 17 9 7 6 6 6 4 4 4 4 3 3',
  // 'G3-E5||G3 G3 F#3 E6 E5 E5 G4 E6 B5 G5 G4 C#2 E6 B5 F6||26 19 8 6 6 4 4 3 3 2 2 2 2 2 2',
  // 'G3-E6||G3 G3 E6 F#3 E6 G4 E6 B5 G5 G4 C#2 B5 F6 E7 G5||26 19 9 8 7 4 4 3 2 2 2 2 2 2 2',
  // 'G3-E7||G3 G3 F#3 G4 B5 G5 G4 B5 F6 E7 G5 G4 F6 E7 G#3||26 19 9 4 3 2 2 2 2 2 2 2 1 1 1',
  // 'G3-F4||G3 F4 G3 F4 F4 F#3 F5 F6 F6 F5 G4 A6 F5 G4 B5||26 21 19 12 10 9 8 5 4 4 4 3 3 3 3',
  // 'G3-F5||G3 G3 F#3 F5 F6 F6 G4 B5 F5 G5 G4 F5 B5 F6 G5||25 19 8 6 5 4 4 3 3 2 2 2 2 2 2',
  // 'G3-F6||G3 G3 F6 F6 F#3 G4 F6 F6 B5 G5 G4 C#2 D2 B5 F6||26 19 12 12 9 4 3 3 3 2 2 2 2 2 2',
  // 'G3-F7||G3 G3 F#3 G4 B5 G5 G4 B5 F6 G5 G4 F7 F6 F7 D5||26 19 9 4 3 2 2 2 2 2 2 2 1 1 1',
  // 'G3-G4||G3 G3 G4 G5 G4 F#3 G4 G5 G6 G6 G5 B6 B5 D6 B6||26 19 17 10 10 9 7 6 4 4 4 3 3 3 2',
  // 'G3-G5||G3 G3 F#3 G5 G6 G4 G6 G5 G5 B5 G4 F6 B5 E2 G4||26 19 9 8 5 4 3 3 3 3 2 2 2 2 2',
  // 'G3-G6||G3 G3 G6 G6 F#3 G6 G4 B5 E2 G5 G4 F2 B5 F6 G6||26 19 13 11 9 4 4 3 2 2 2 2 2 2 2',
  // 'G3-G7||G3 G3 F#3 G4 B5 G5 G4 B5 F6 G5 G4 F6 G7 G#3 C2||26 19 9 4 3 2 2 2 2 2 2 1 1 1 1',
  // 'G4-A4||G4 A4 G4 A4 G4 G5 G5 A4 G6 G6 A5 A6 B6 G5 A6||21 13 12 9 9 8 5 5 5 4 4 3 3 3 3',
  // 'G4-A5||G4 A5 A5 G4 G4 A5 G5 G5 G6 G6 A5 G5 B6 D6 G#4||21 20 17 12 9 9 8 5 5 4 4 3 3 3 2',
  // 'G4-A6||G4 G4 G4 G5 A6 G5 G6 G6 A6 B6 G5 D6 G#4 D2 B6||21 12 9 8 5 5 5 4 3 3 3 3 2 2 2',
  // 'G4-A7||G4 G4 G4 G5 G5 G6 G6 B6 G5 D6 G#4 B6 G#4 D6 D2||21 12 9 8 5 5 4 3 3 3 2 2 2 2 1',
  // 'G4-B4||G4 G4 B4 G4 B4 G5 G5 G6 G6 B4 B5 B6 G5 B6 B5||21 12 12 9 8 8 5 5 4 4 4 3 3 3 3',
  // 'G4-B5||G4 B5 B5 G4 G4 G5 B5 G5 G6 G6 B5 G5 B6 D6 B6||21 19 15 12 9 8 8 5 5 4 3 3 3 3 2',
  // 'G4-B6||G4 G4 G4 G5 G5 G6 G6 B6 B6 B6 G5 D6 B6 G#4 B6||21 12 9 8 5 5 4 4 3 3 3 3 2 2 2',
  // 'G4-B7||G4 G4 G4 G5 G5 G6 G6 B6 G5 D6 G#4 B6 G#4 D6 D2||21 12 9 8 5 5 4 3 3 3 2 2 2 2 1',
  // 'G4-C5||G4 G4 C5 G4 C6 G5 C6 C5 G5 G6 G6 G5 B6 C5 A#1||21 12 9 9 9 8 8 7 5 5 4 3 3 3 3',
  // 'G4-C6||G4 C6 C6 G4 G4 G5 C6 G5 G6 G6 C6 B6 D2 G5 D6||21 17 15 12 9 8 7 5 5 4 3 3 3 3 3',
  // 'G4-C7||G4 G4 G4 G5 G5 G6 G6 C7 B6 G5 D6 G#4 C7 B6 G#4||21 12 9 8 5 5 4 4 3 3 3 2 2 2 2',
  // 'G4-D5||G4 G4 D5 G4 D6 G5 D5 D6 G5 G6 G6 D5 B6 G5 C2||21 12 9 9 8 8 7 6 5 5 4 4 3 3 3',
  // 'G4-D6||G4 G4 D6 G4 G5 D6 D6 G5 G6 G6 B6 G5 G#4 D7 B6||21 12 10 9 8 6 5 5 5 4 3 3 2 2 2',
  // 'G4-D7||G4 G4 G4 G5 G5 G6 G6 B6 G5 D7 D6 G#4 B6 D7 G#4||21 12 9 8 5 5 4 3 3 3 3 2 2 2 2',
  // 'G4-E5||G4 G4 G4 G5 E6 E5 G5 G6 G6 E5 E6 B6 G5 D6 G#4||21 12 9 8 6 6 5 5 4 4 3 3 3 3 2',
  // 'G4-E6||G4 G4 G4 E6 G5 E6 G5 G6 G6 E6 B6 G5 D6 G#4 B6||21 12 9 9 8 7 5 5 4 4 3 3 2 2 2',
  // 'G4-E7||G4 G4 G4 G5 G5 G6 G6 B6 G5 D6 D2 G#4 B6 E7 G#4||21 12 9 8 5 5 4 3 3 3 2 2 2 2 2',
  // 'G4-F5||G4 G4 G4 G5 F5 F6 G5 G6 G6 F6 B6 G5 D2 F5 D6||21 12 9 8 6 5 5 5 4 4 3 3 3 3 3',
  // 'G4-F6||G4 F6 G4 F6 G4 G5 G5 G6 G6 F6 D2 B6 F6 G5 D6||21 12 12 12 9 8 5 5 4 3 3 3 3 3 3',
  // 'G4-F7||G4 G4 G4 G5 G5 G6 G6 B6 G5 D6 G#4 B6 G#4 D2 F7||21 12 9 8 5 5 4 3 3 3 2 2 2 2 2',
  // 'G4-G5||G4 G5 G4 G6 G4 G6 G5 G5 B6 D6 G#4 G6 B6 G#4 D6||21 14 12 10 9 8 8 6 3 3 2 2 2 2 2',
  // 'G4-G6||G4 G4 G6 G4 G6 G5 G5 G6 B6 G5 D6 G6 G#4 B6 G#4||21 12 11 9 9 8 5 3 3 3 3 2 2 2 2',
  // 'G4-G7||G4 G4 G4 G5 G5 G6 G6 B6 G5 D6 G#4 B6 G#4 D2 D6||21 12 9 8 5 5 4 3 3 3 2 2 2 2 2',
  // 'G5-A5||A5 A5 A5 G5 G6 A5 G6 G5 G5 A6 A5 C2 A6 A#1 G6||20 17 9 6 5 4 3 3 3 2 1 1 1 1 1',
  // 'G5-A6||G5 G6 A6 G6 A6 G5 G5 D2 A6 C#2 E2 G6 A6 F2 F1||6 5 5 3 3 3 2 2 2 2 2 1 1 1 1',
  // 'G5-A7||G5 G6 G6 G5 G5 F2 G6 A7 F#2 E2 A7 A7||6 5 3 3 2 1 1 1 1 1 1 1',
  // 'G5-B5||B5 B5 B5 G5 G6 G6 B5 G5 G5 B5 B6 C#2 D2 G6 F2||19 15 8 6 5 3 3 3 3 2 2 1 1 1 1',
  // 'G5-B6||G5 G6 B6 G6 G5 G5 B6 F2 E2 B6 D2 G6 F#2 B6||6 5 4 3 3 2 2 2 2 2 1 1 1 1',
  // 'G5-C6||C6 C6 C6 G5 G6 G6 C6 G5 G5 C6 C7 G6 C7 C#2 D2||17 15 7 6 5 3 3 3 2 2 1 1 1 1 1',
  // 'G5-C7||G5 G6 C7 G6 G5 G5 F2 C7 E2 C7 F#2 G6||6 5 4 3 3 2 2 2 2 2 1 1',
  // 'G5-D6||D6 G5 D6 D6 G6 G6 G5 G5 D7 D7 C2 A#1 G6 F2 D6||10 7 6 5 5 3 3 2 2 2 2 1 1 1 1',
  // 'G5-D7||G5 G6 G6 D7 G5 G5 D7 F2 D7 F#2 G6 G#2 E2 G2 D7||6 5 3 3 3 2 2 1 1 1 1 1 1 1 1',
  // 'G5-E6||E6 E6 G5 G6 E6 G6 G5 G5 E7 E7 C#2 D2 G6 E2 F2||9 7 6 5 4 3 3 2 2 2 1 1 1 1 1',
  // 'G5-E7||G5 G6 G6 G5 G5 E7 E7 G6 E7||6 5 3 3 2 2 1 1 1',
  // 'G5-F6||F6 F6 G5 G6 G6 F6 F6 G5 G5 D2 C#2 F7 E2 F7 G6||12 12 6 5 3 3 3 3 2 2 2 2 1 1 1',
  // 'G5-F7||G5 G6 G6 G5 G5 F7 F7 G6 F2 E2 D2||6 5 3 3 2 2 1 1 1 1 1',
  // 'G5-G6||G6 G6 G5 G6 G6 G5 F2 G5 E2 G7 D2 D1 G7 F#2 F1||12 10 6 4 3 3 3 2 2 1 1 1 1 1 1',
  // 'G6-A6||G6 G6 A6 G6 A6 D2 G6 C#2 A6 E2 F1 D1 G6 F2 G7||12 10 5 4 3 2 2 2 2 1 1 1 1 1 1',
  // 'G6-A7||G6 G6 G6 G6 E2 F2 F#2 G6 A7 G7 G7 D2 D1 G2 A7||12 10 4 2 2 1 1 1 1 1 1 1 1 1 1',
  // 'G6-B6||G6 G6 B6 G6 E2 F2 B6 D2 G6 B6 F#2 G6 G7 G7 B6||12 10 4 4 4 3 2 2 2 2 1 1 1 1 1',
  // 'G6-B7||G6 G6 G6 G6 E2 F2 G6 G7 G7 B7 D2 F#2||12 10 4 2 1 1 1 1 1 1 1 1',
  // 'G6-C7||G6 G6 G6 C7 C7 F2 G6 E2 C7 F#2 G6 G7 G7 G2||12 10 4 4 2 2 2 2 2 2 1 1 1 1',
  // 'G6-D7||G6 G6 G6 D7 F2 G6 F#2 E2 D7 D7 G6 G2 G#2 G7 F1||12 10 4 3 2 2 2 2 1 1 1 1 1 1 1',
  // 'G6-E7||G6 G6 G6 G6 E7 E7 F2 G6 E2 G7 G7 F1 D1 E7||12 10 4 2 2 1 1 1 1 1 1 1 1 1',
  // 'G6-F7||G6 G6 G6 E2 G6 F2 F7 F7 D2 G6 G7 D1 F#2 G7 F1||12 10 4 2 2 2 2 1 1 1 1 1 1 1 1',
  // 'G6-G7||G6 G6 G6 G7 G6 G7 G6 E2 F2 D1||12 10 4 2 2 2 1 1 1 1',
]

export const ETALONS = ETALONS_STRINGS.map((str) => {
  const [label, notesString, amplitudes] = str.split('||')
  if (label === 'G5')
    console.log({ label, data: notesString.split(' '), amplitudes: amplitudes.split(' ') })
  return { label, data: notesString.split(' '), amplitudes: amplitudes.split(' ') }
})
