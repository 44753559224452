import { createEffect } from 'effector'

export const preventScreenSleep = createEffect(() => {
  const wakeLockEnabled = 'wakeLock' in navigator

  let wakeLock = null
  async function enableWakeLock() {
    try {
      if (wakeLockEnabled) {
        wakeLock = await navigator.wakeLock.request('screen')
      }
    } catch (err) {
      console.error('Failed to request wake lock:', err)
    }
  }

  enableWakeLock()

  return () => {
    if (wakeLockEnabled && wakeLock) {
      wakeLock.release()
    }
  }
})
