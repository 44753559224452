import { sum, values } from 'lodash/fp'

export const countOfNotPressedNotes = (
  expected: string[],
  pressed: string[],
  uniteNotes = false,
) => {
  const notesMap: Record<string, number> = {}
  expected.forEach((note) => {
    notesMap[note] = (notesMap[note] || 0) + 1
  })

  pressed.forEach((note) => {
    if (notesMap[note]) {
      notesMap[note] = uniteNotes ? 0 : Math.max(0, notesMap[note] - 1)
    }
  })

  return sum(values(notesMap))
}
