import {
  createStore,
  combine,
  createEvent,
  sample,
  guard,
  split,
  attach,
  createEffect,
} from 'effector'
import { delay } from 'patronum/delay'
import { add, eq, equals, F, uniq, identity, sum, T } from 'lodash/fp'
import { PIANO_KEYS_MAP } from '../constants'
import { $isMidiEnabled, playNoteByMidi, releaseNoteByMidi } from '../utils/midi'
import {
  noteByMic,
  setTargetNotes,
  startPitchDetect,
  stopPitchDetect,
} from '../utils/pitchdetect/pitchdetect'
import { $settings } from './settings'
import { ITask } from '../types'
import { customNoteToAbcdjs } from '../utils/notesAdapters'
import { initGate, onKeydown, onKeyup } from './document'

import { countOfNotPressedNotes } from 'utils/data'
import * as stat from 'statistics/statistics'
import { getRecommendedTask, initRecommenderFx, updateRecommenderFx } from './tasks/recommender'
import { playAbcStringFx } from './sound'
import {
  testTasksToggle,
  isDropNotesMode,
  isHardModeMistakesToggle,
  isProVersion,
  micToggle,
  soundToggle,
  toggleHint,
} from './ui'
import { clearOctave, getTaskNotes } from './tasks/tasks.utils'
import { IMelodyTask, ITaskType, TASK_CONTROL_TYPES, TASK_TYPES } from 'statistics/tasksUniqNames'
import { ITaskState } from 'statistics/db'
import { preventScreenSleep } from 'features/prevent-screen-sleep/model'
import { trackFx } from 'utils/amplitude'
import { hapticFeedbackError, pause } from 'utils/utils'
import { startGlobalTimer } from 'utils/effector'
import { getChordName, getChordNotes, getCleanChordName } from 'utils/abcjs-helpers'

export const playFullTask = createEvent('playFullTask')
export const init = createEvent('init')
export const next = createEvent('next')
const setTask = createEvent<ITask>('setTask')
const setCurrentTaskProgress = createEvent<number>('setCurrentTaskProgress')
const incCurrentTaskProgress = createEvent('incCurrentTaskProgress')
export const pressKey = createEvent<string>('pressKey')
export const setExpectedNotesCount = createEvent<number>('setExpectedNotesCount')
export const pressChord = createEvent<string>('pressChord')
export const releaseChord = createEvent<string>('releaseChord')
export const clearHighlightFromNote = createEvent<string>('clearHighlightFromNote')
export const playCurrentNote = createEvent('playCurrentNote')
export const check = createEvent('check')
export const debugSkipTask = createEffect('debugSkipTask')
export const releaseNote = createEvent<string>('releaseNote')
export const releaseNoteByMic = createEvent<string>('releaseNoteByMic')
export const releaseAbcNoteByMidi = createEvent<string>('releaseAbcNoteByMidi')
export const releaseIncorrectNote = createEvent<string>('releaseIncorrectNote')
const releaseAllKeys = createEvent('releaseAllKeys')
const addSolvePartDuration = createEvent<number>('addSolvePartDuration')
const setTaskStartTime = createEvent<number>('addSolveDuration')
export const addMistake = createEvent('addMistake')
export const failTaskByCursor = createEvent('failTaskByCursor')
export const setPrevTaskProgressDiff = createEvent<number>('setPrevTaskProgressDiff')

const paramSetUpMode = [
  { paramName: 'hardMode', toggle: isHardModeMistakesToggle.toggle },
  { paramName: 'dropNote', toggle: isDropNotesMode.toggle },
  { paramName: 'pro', toggle: isProVersion.toggle },
]

const URL_VALUES = ['true', 'false', '1', '0'] as const
export const initSetUpModes = createEffect(() => {
  const urlParams = new URLSearchParams(window.location.search)
  paramSetUpMode.forEach((paramMode) => {
    const param = urlParams.get(`${paramMode.paramName}`)
    // @ts-ignore
    if (URL_VALUES.includes(param)) {
      paramMode.toggle(param === 'true' || param === '1')
    }
  })
})

export const debugSkipTaskFx = createEffect(async ({ taskId, percents }) => {
  await stat.resolveTaskFx({ percents, taskId })
  next()
})

initGate.open.watch(async () => {
  await initRecommenderFx()
  next()
  init()
  preventScreenSleep()
})

sample({
  clock: init,
  target: [initSetUpModes, startGlobalTimer],
})

sample({
  clock: micToggle.$val,
  filter: (clk) => !!clk,
  target: createEffect(startPitchDetect),
})

sample({
  clock: micToggle.$val,
  filter: micToggle.$val,
  fn: () => ({ name: 'switch on micro' }),
  target: trackFx,
})

sample({
  clock: micToggle.$val,
  filter: (clk) => !clk,
  target: createEffect(stopPitchDetect),
})

// TODO uncomment to play all notes but not correct
// guard({ source: pressKey, filter: soundToggle.$val, target: playAbcStringFx })

sample({ clock: playNoteByMidi, fn: ({ note }) => customNoteToAbcdjs(note), target: pressKey })
sample({
  clock: noteByMic,
  fn: customNoteToAbcdjs,
  target: createEffect((note: string) => {
    pressKey(note)
    setTimeout(() => releaseNote(note), 100)
  }),
})

sample({
  clock: releaseNoteByMidi,
  fn: (note) => customNoteToAbcdjs(note),
  target: [releaseNote, releaseAbcNoteByMidi],
})

const toggleTestMode = createEvent<boolean>()
export const $mode = createStore<ITaskType>(TASK_TYPES.SCREEN_KEYBOARD)
  .on(micToggle.$val, (_, isMicEnabled) =>
    isMicEnabled ? TASK_TYPES.MIC : TASK_TYPES.SCREEN_KEYBOARD,
  )
  .on($isMidiEnabled, (_, isMidiConnected) =>
    isMidiConnected ? TASK_TYPES.MIDI : TASK_TYPES.SCREEN_KEYBOARD,
  )
  .on(toggleTestMode, (_, val) => (val ? TASK_TYPES.TEST : TASK_TYPES.SCREEN_KEYBOARD))

sample({
  clock: [init, testTasksToggle.toggle],
  source: testTasksToggle.$val,
  target: toggleTestMode,
})

// TODO тест мод включать по кнопке
// мб это отдельным режимом сделать?
// setTimeout(toggleTestMode, 1000)

export const $pressedChords = createStore<string[]>([])
  .on(pressChord, (chords, chord) => uniq([...chords, chord]))
  .on(releaseChord, (chords, chord) => chords.filter((c) => c !== chord))

export const $pressedNotes = createStore<string[]>([])
  .on(pressKey, (notes, note) => uniq([...notes, note]))
  // TODO maybe return it (clear note after release)
  .on(releaseAbcNoteByMidi, (notes, note) => notes.filter((n) => n !== note))
  .on(releaseIncorrectNote, (notes, note) => notes.filter((n) => n !== note))
  .on(releaseAllKeys, () => [])

export const $lastResolvePartTime = createStore(Date.now())
  .on(addSolvePartDuration, Date.now)
  .on(init, Date.now)
export const $taskStartTime = createStore(Date.now())
  .on(setTaskStartTime, Date.now)
  .on(init, Date.now)

export const $partMistakesCount = createStore(0)
  .on(addMistake, add(1))
  .reset(incCurrentTaskProgress)
export const $mistakesCount = createStore(0).on(addMistake, add(1)).reset(next)
export const $isTaskFailedByCursor = createStore(false).on(failTaskByCursor, T).reset(next)
export const $mistakesSum = createStore(0).on(addMistake, add(1))

export const $isTaskFailed = combine(
  $isTaskFailedByCursor,
  $mistakesCount,
  (isTaskFailedByCursor, mistakesCount) => isTaskFailedByCursor || mistakesCount > 0,
)
export const $currentTaskIndex = createStore(0).on(next, add(1))

// TODO сделать чтобы не было пустого обьекта при инициализации
export const $currentTask = createStore<IMelodyTask & ITaskState>({}).on(setTask, (_, val) => val)
export const $prevTaskProgressDiff = createStore(0).on(setPrevTaskProgressDiff, (_, val) => val)
export const $currentTaskNotes = $currentTask.map((task) => task?.notes || [])
export const $currentTaskId = $currentTask.map((task) => task?.taskId)
export const $currentTaskComplexity = $currentTask.map((task) => task?.complexity || 1)

export const $currentTaskStep = createStore(0)
  .on(next, () => 0)
  .on(setCurrentTaskProgress, (_, progress) => progress)
  .on(incCurrentTaskProgress, (progress) => progress + 1)

export const $isTaskStarted = $currentTaskStep.map((progress) => progress > 0)

export const $currentTaskPart = combine(
  $currentTaskStep,
  $currentTaskNotes,
  (step, currentTask): string => currentTask?.[step],
)
export const $currentTaskStepNotes = createStore<string[]>([]).on($currentTaskPart, (_, val) =>
  getTaskNotes(val),
)

export const $isChordsKeyboardsActive = combine(
  $currentTask,
  (task) => task.controls === TASK_CONTROL_TYPES.CHORDS_KEYBOARD,
)

const delayedClearHighlightFromNote = delay({
  source: sample({
    clock: clearHighlightFromNote,
    source: { $currentTaskStepNotes },
    filter: ({ $currentTaskStepNotes }, note) =>
      !$currentTaskStepNotes.map(clearOctave).includes(note),
    fn: (_, note) => note,
  }),
  timeout: 300,
})

const delayedClearAll = delay({
  source: sample({ clock: $currentTaskStepNotes }),
  timeout: 300,
})

export const $highlightedNotes = createStore<string[]>([])
  .on(pressKey, (notes, note) => [...notes, note])
  .on(delayedClearHighlightFromNote, (notes, note) => notes.filter((n) => n !== note))
  .on(delayedClearAll, () => [])

sample({ clock: addMistake, target: hapticFeedbackError })
sample({ clock: failTaskByCursor, target: hapticFeedbackError })

export const $expectedNotesCount = createStore(1)
  .on(setExpectedNotesCount, (val, count) => count || val)
  .on(pressKey, () => 1)

sample({
  clock: guard(pressKey, { filter: $currentTaskPart.map(Boolean) }),
  source: {
    $pressedNotes,
    $currentTaskNotes: $currentTaskStepNotes,
    $isMidiConnected: $isMidiEnabled,
    isMicEnabled: micToggle.$val,
    $lastResolvePartTime,
  },
  fn: (p, pressedKey) => ({ ...p, pressedKey }),
  target: createEffect((p) => {
    const notesModifier = p.$isMidiConnected || p.isMicEnabled ? identity : clearOctave
    // const notesModifier = identity
    // const notesModifier = p.$isMidiConnected ? identity : clearOctave

    const pressed = p.$pressedNotes.map(notesModifier)
    const expected = p.$currentTaskNotes.map(notesModifier)

    // console.log('expected, pressed', expected, pressed)

    // TODO смотреть не на то, подключена ли МИДИ клавиатура, а смотреть откуда нажата нота
    if (countOfNotPressedNotes(expected, pressed, !p.$isMidiConnected) === 0) {
      resolveFx()
    } else if (
      Date.now() - p.$lastResolvePartTime > 100 &&
      (p.$isMidiConnected
        ? !expected.includes(p.pressedKey)
        : !expected.map(clearOctave).includes(clearOctave(p.pressedKey)))
    ) {
      addMistake()
    }
  }),
})

export const resolveFx = attach({
  source: {
    $lastResolvePartTime,
    $taskStartTime,
    $partMistakesCount,
    $mistakesCount,
    $currentTaskPart,
    $currentTaskProgress: $currentTaskStep,
    $currentTaskNotes,
    $currentTask,
    $currentTaskComplexity,
    isHardModeMistakes: isHardModeMistakesToggle.$val,
    $isTaskFailed,
  },
  effect: async (p, { skipFail }: { skipFail?: boolean } = {}) => {
    if (p.$currentTaskProgress === p.$currentTask.notes?.length) return
    const resolvePartTime = new Date().getTime() - p.$lastResolvePartTime

    // console.time('ef')
    addSolvePartDuration(resolvePartTime)
    // console.timeEnd('ef')

    incCurrentTaskProgress()
    releaseAllKeys()

    if (p.$currentTaskProgress === 0) {
      setTaskStartTime(Date.now())
    }

    if (p.$isTaskFailed && !skipFail) {
      // TODO mistakesCount так не будет учитываться в стате, подумать что с этим делать
      stat.failTaskFx(p.$currentTask.id)
      setTimeout(next, 300)
    } else if (p.$currentTaskProgress + 1 === p.$currentTaskNotes.length) {
      const resolveTime = new Date().getTime() - p.$taskStartTime

      // не правильно считается, пока костыльно ошибки прикрутил
      const partsCount = p.$currentTaskNotes.length
      const progress = stat.getMelodyTaskProgressByStat(resolveTime, p.$mistakesCount, partsCount)

      setPrevTaskProgressDiff(progress - (stat.getTaskState(p.$currentTask.id) || 0))

      // TODO подсчитывать прогресс для незавершенной мелодии, и максимальный прогресс для незавершенной мелодии
      // TODO мб не среднее считать а хотябы медиану, или наоборот приблизить к минимуму
      await stat.resolveTaskFx({ percents: progress, taskId: p.$currentTask.id })
      setTimeout(next, 300)
    }
  },
})

export const $nextNotes = combine({ $currentTaskNotes, $currentTaskStep }, (p) =>
  p.$currentTaskNotes.slice(p.$currentTaskStep).map(getTaskNotes),
)

export const $nextChord = combine({ $nextNotes }, (p) => {
  const expected = []

  for (let nextNote of p.$nextNotes) {
    if (expected.length < 3) {
      expected.push(...nextNote.map(clearOctave))
    }
  }

  return expected.length ? getCleanChordName(expected) : null
})

// TODO
// решить что делать когда нужно сыграть 5 нот аккорда за одно нажатие, мб не делать такие задания просто
// нагенерить задания с арпеджио для клавиатуры с аккордами
// сделать анбординг/подсказки для таких заданий
// мб играть ноты, не понятно как играть в разных октавах, мб играть ближайший аккорд
// добавить широкие аккорды
// добавить все обращения
// добавить задания без обращений как самые основные
// добавить диезы бемоли
sample({
  clock: pressChord,
  source: { $pressedChords, $isTaskFailed, $nextNotes },
  target: createEffect(async (p) => {
    const pressed = p.$pressedChords.flatMap(getChordNotes)
    const expected = []
    let i = 0
    for (let nextNote of p.$nextNotes) {
      if (pressed.length > expected.length) {
        i++
        expected.push(...nextNote.map(clearOctave))
      }
    }
    setExpectedNotesCount(i)

    if (countOfNotPressedNotes(expected, pressed, !p.$isMidiConnected) === 0) {
      while (i > 0) {
        await resolveFx({ skipFail: i > 1 })
        await pause(200)
        i--
      }
    } else {
      addMistake()
    }
  }),
})

sample({
  clock: resolveFx,
  filter: soundToggle.$val,
  source: $currentTaskStepNotes,
  fn: (notes) => `[${notes.join(' ')}]`,
  target: playAbcStringFx,
})

sample({
  clock: $isMidiEnabled,
  filter: initRecommenderFx.pending.map((v) => !v),
  target: next,
})

sample({
  clock: next,
  fn: getRecommendedTask,
  target: setTask,
})

// TODO fix after remove abc string from store
// mb use util from musiq notation
sample({
  clock: playFullTask,
  source: $currentTaskNotes,
  target: playAbcStringFx,
})

sample({
  clock: debugSkipTask,
  source: $currentTaskId,
  fn: (taskId) => ({ percents: 0.9, taskId }),
  target: debugSkipTaskFx,
})

sample({
  clock: onKeyup,
  fn: (code) => PIANO_KEYS_MAP[code],
  target: releaseNote,
})

sample({
  clock: releaseNote,
  source: $currentTaskStepNotes,
  filter: (currentTaskNotes, code) =>
    !currentTaskNotes.map(clearOctave).includes(clearOctave(code)),
  fn: (_, code) => code,
  target: releaseIncorrectNote,
})

sample({
  clock: onKeydown,
  source: { $currentTask, $currentTaskStep },
  filter: (p, code) =>
    !!PIANO_KEYS_MAP[code] && p.$currentTaskStep !== p.$currentTask.notes?.length,
  fn: (_, code) => PIANO_KEYS_MAP[code],
  target: pressKey,
})

// TODO hide for prod
split({
  source: onKeydown,
  match: {
    playCurrentNote: equals('ArrowDown'),
    check: equals('ArrowUp'),
    resolveFx: equals('Enter'),
  },
  cases: {
    playCurrentNote,
    check,
    resolveFx,
  },
})

const $hintTimeout = createStore(4000)

let timeout: NodeJS.Timeout
sample({
  clock: [resolveFx.done, next],
  target: attach({
    source: { $hintTimeout },
    effect: ({ $hintTimeout }) => {
      clearTimeout(timeout)
      toggleHint(false)
      timeout = setTimeout(() => toggleHint(true), $hintTimeout)
    },
  }),
})

sample({
  clock: $currentTaskStepNotes,
  target: setTargetNotes,
})

sample({
  clock: $mode,
  target: updateRecommenderFx,
})

sample({
  clock: updateRecommenderFx.done,
  target: next,
})

sample({
  clock: pressKey,
  target: clearHighlightFromNote,
})

export { $settings, initGate }
