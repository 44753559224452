import { orderBy, values, maxBy, isNumber } from 'lodash/fp'

import {
  initStat,
  getTraversalNumber,
  setTraversalNumber,
  getInitTaskState,
  updateStat,
} from 'statistics/statistics'
import {
  AdjacencyList,
  getNextTasks,
  getPrevTasks,
  getSimilarTasks,
  tasksData,
  TaskId,
  MELODY_TASKS_BY_ID,
  ITaskType,
  TASK_TYPES,
  IMelodyTask,
} from 'statistics/tasksUniqNames'
import { db, isDbReady, ITaskState } from 'statistics/db'
import { createEffect, createStore } from 'effector'
import DexieKeyValueSyncCache from 'utils/DexieKeyValueSyncCache'
import {
  undoTaskData,
  $isCurrentTaskLocked,
  $isCurrentTaskSkipped,
} from 'features/tasks-controls/model'

let taskStatesStore: DexieKeyValueSyncCache<ITaskState>

export const getTaskStatesStore = () => taskStatesStore

// TODO grab all chords
// https://www.piano-keyboard-guide.com/keyboard-chords.html
// TODO add tags for select and sort
// TODO sort by popularity
// TODO add algoritm to memorize by popularity

// TODO сделать динамическим
// бывает когда добавляется 2 новые ноты, у них прогресс 0, но
// есть еще 3 задания с хорошим прогрессом, и они часто попадаются только из-за того что их больше
// нужно возвращать не просто массив с NORMAL_TASKS_COUNT элементов, но еще и % их вероятности отдавать
// можно сократить NORMAL_TASKS_COUNT до 1, и тогда всегда будет показываться набор с самой хуевой нотой
// но лучше просчитывать так, чтобы сумма всех нот была оптимальнее, может быть 1 нота с 0% а остальные с 99, а может 0 / 50

// учитывать количество выполнений задания за последнее время и в пианинке можно сделать
// чтобы все задания +- одинаково раз решались, мб все задания +- одного типа можно одинаковое кличество раз решать

// прям сейчас учитывая время которое затрачено на задание (1 секунда или 10 минут)
// а для пианинки должна выдавать набор из заданий с процентами - частота с которой дожно выпадать задание
// могут быть 2 модуля, statistics & recommender(потом можно в ML превратить)
// показатели для ML - прогресс за последние короткие / длинные промежутки времени

let startTaskId = tasksData[TASK_TYPES.SCREEN_KEYBOARD].START_TASK
let currentTaskId = tasksData[TASK_TYPES.SCREEN_KEYBOARD].START_TASK

undoTaskData.watch(({ id }) => {
  currentTaskId = id
})

let tasksSequenceAdjacencyList: AdjacencyList = tasksData[TASK_TYPES.SCREEN_KEYBOARD].adjacencyList

const findWithMinProgress = (states: ITaskState[]) => {
  const minProgress = Math.min(...states.map((state) => state.progress || 0))

  const tasks = orderBy(
    (s) => s.timestamp || 0,
    'asc',
    states.filter((state) => (state.progress || 0) === minProgress),
  )

  return tasks[0]!
}

const findNextTask = (adjacencyList, startNode, getIsNodeDesired: (id: TaskId) => boolean) => {
  const visitedSet = new Set()
  const stack = [startNode]

  while (stack.length > 0) {
    const currentNode = stack.pop()
    if (visitedSet.has(currentNode)) {
      continue
    }

    visitedSet.add(currentNode)

    if (getIsNodeDesired(currentNode)) {
      return currentNode
    }

    // TODO отсортировать по важности
    const neighbors = getNextTasks(adjacencyList, currentNode)
    for (const neighbor of neighbors) {
      if (!visitedSet.has(Number(neighbor))) {
        stack.push(Number(neighbor))
      }
    }
  }

  return null
}

export const getNextTaskId = (params: {
  currentTaskId: TaskId
  adjacencyList: AdjacencyList
  tasksStates: Record<TaskId, ITaskState>
  // traversalNumber не передавать, а брать его из currentTaskId
  traversalNumber: number
  startNode: number
}): TaskId | null => {
  // TODO refactor
  const getTaskState = (taskId: TaskId): ITaskState =>
    tasksStates[taskId] || getInitTaskState(taskId)
  const { adjacencyList, currentTaskId, tasksStates, traversalNumber, startNode } = params
  const taskState = getTaskState(currentTaskId)

  const checkTask = (taskState: ITaskState) => {
    const { targetProgress } = taskState

    if (
      // когда isCurrentTaskLocked то могут появиться задания с неподходящим прогрессом 
      ($isCurrentTaskLocked.getState() || $isCurrentTaskSkipped.getState()) &&
      (taskState.traversalNumber || 0) <= traversalNumber
    )
      return true

    // TODO теперь из-за этих пропусков может не возвращаться задание назад
    // TODO и теперь подумать как скипать еще не пройденные задания
    // можно попробовать смотреть isCurrentTaskSkipped
    // мб делать пометочку что задания было пропущено, и если все пройденные задания пропущены
    // переходить к новым, но сначала без флага, тогда не будут возвращаться задания из начала графа
    // но пока пихуй
    // и наверно нужен алгоритм ВШИРИНУ, чтобы слишком далеко не проходить по одной ветке(
    if (
      // проверка traversalNumber для того чтобы пропускать задания с большим прогрессом почаще
      (targetProgress > 0.9 && traversalNumber % 4 > 0) ||
      (targetProgress > 0.8 && targetProgress <= 0.9 && traversalNumber % 3 > 0) ||
      (targetProgress > 0.6 && targetProgress <= 0.8 && traversalNumber % 2 > 0)
    )
      return false

    return (
      (taskState.traversalNumber || 0) <= traversalNumber &&
      taskState.progress < taskState.targetProgress
    )
  }

  if (checkTask(taskState)) {
    const similarAdjacency = getSimilarTasks(adjacencyList, currentTaskId).map(getTaskState)
    similarAdjacency.push(taskState)

    return findWithMinProgress(similarAdjacency).taskId!
  } else {
    const nextAdjacency = getNextTasks(adjacencyList, currentTaskId).map(getTaskState)

    const isPrevProgressLess = (current: ITaskState) =>
      $isCurrentTaskSkipped.getState() ||
      getPrevTasks(adjacencyList, current.taskId).every(
        (id) => getTaskState(id).progress >= current.targetProgress,
      )

    const nextOpenedTasks = nextAdjacency.filter(isPrevProgressLess)

    if (nextOpenedTasks.length && nextOpenedTasks.some(checkTask)) {
      return findWithMinProgress(nextOpenedTasks).taskId!
    }

    // TODO findWithMinProgress
    return findNextTask(adjacencyList, startNode, (id) => {
      const state = getTaskState(id)
      return isPrevProgressLess(state) && checkTask(state)
    })
  }
}

export const getRecommendedTask = (): IMelodyTask & ITaskState => {
  const taskId = getNextTaskId({
    currentTaskId,
    traversalNumber: getTraversalNumber(),
    adjacencyList: tasksSequenceAdjacencyList,
    startNode: startTaskId,
    tasksStates: taskStatesStore.getAll(),
  })
  // console.log(traversalNumber, taskId, taskStatesStore.getAll())

  if (isNumber(taskId)) {
    currentTaskId = taskId

    return {
      ...MELODY_TASKS_BY_ID[taskId],
      ...(taskStatesStore.get(taskId) || getInitTaskState(taskId)),
    }
  } else {
    // TODO на всякий случай решить что делать если прогресс везде 1, и traversalNumber++ вынести отсюда наверн в resolve
    currentTaskId = startTaskId
    // Увеличиваем traversalNumber чтобы перезапустить поиск по графу
    setTraversalNumber(getTraversalNumber() + 1)
    return getRecommendedTask()
  }
}

// TODO передавать sequence в параметрах, чтобы рекоммендер не знал ничего о заданиях
export const updateRecommenderFx = createEffect(async (mode: ITaskType) => {
  currentTaskId = tasksData[mode].START_TASK
  startTaskId = tasksData[mode].START_TASK
  tasksSequenceAdjacencyList = tasksData[mode].adjacencyList
  updateStat(mode)
})

export const initRecommenderFx = createEffect(async () => {
  await isDbReady
  taskStatesStore = new DexieKeyValueSyncCache<ITaskState>(db.tasksStates, 'taskId')
  await initStat(tasksSequenceAdjacencyList, taskStatesStore)

  const withMaxTraversalNumber = maxBy('traversalNumber', values(taskStatesStore.getAll()))

  setTraversalNumber(withMaxTraversalNumber?.traversalNumber || 0)
  // TODO TRY AND TET PERF https://github.com/Daninet/hash-wasm

  // // TODO OPTIMIZEEEEEEE, результаты смотреть на телефоне!
  // console.time('initRecommenderTest')
  // const testArray = new Array(10).fill(MELODY_TASKS).flat()
  // testArray.map(({ notes, complexity, fingers }) => {
  //   const hash = getMelodyTaskId(notes)
  //   const tasksCount = countArrayElements(notes.flat())
  //   const tasksPercents = mapValues((val) => val / notes.length, tasksCount)
  // })
  // console.timeEnd('initRecommenderTest')
})

export const $isRecommenderInited = createStore(false).on(initRecommenderFx.done, () => true)
