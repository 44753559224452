export class RMSDetector {
  constructor(bufferSize = 5, dropThreshold = 0.05) {
    this.bufferSize = bufferSize;
    this.dropThreshold = dropThreshold;
    this.rmsBuffer = [];
    this.peakDetected = false;
  }

  reset() {
    this.rmsBuffer = []
  }

  pushRms(rmsValue) {
    if (this.rmsBuffer.length === this.bufferSize) {
      const lastRms = this.rmsBuffer[this.bufferSize - 1];
      const delta = rmsValue - lastRms;

      this.peakDetected = delta < -this.dropThreshold;
    }

    if (this.rmsBuffer.length >= this.bufferSize) {
      this.rmsBuffer.shift();
    }
    this.rmsBuffer.push(rmsValue);
  }

  isPeakDetected() {
    return this.peakDetected;
  }
}