import styled from 'styled-components'
import {
  $isFirstTaskModalOpened,
  $isFiveTasksFailedModalOpen,
  $isModalAfterTimerOpened,
  $isSubscribeModalAfterTimerOpened,
  $savedScores,
  $timerValues,
  closePraiseModal,
} from '../../features/timer/model'
import { useStore } from 'effector-react'
import { $scores } from 'model/scores'
import { PopupWindow } from 'components/PopupWindow/PopupWindow'

const Emoji = styled.div`
  font-size: 40px;
`

const Title = styled.div`
  font-size: 25px;
  margin-bottom: 16px;
  line-height: 30px;
`

const Description = styled.div`
  margin-bottom: 16px;
`

const ContinueButton = styled.button`
  margin: 5px 0;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 500;
  background: linear-gradient(to right, orange, yellow);
`

export const PraiseContent = () => {
  const currentScores = useStore($scores)
  const timerValues = useStore($timerValues)
  const savedScores = useStore($savedScores)
  const difference = Math.floor((currentScores - savedScores) * 100) / 100
  const isModalAfterTimerOpened = useStore($isModalAfterTimerOpened)
  const isSubscribeModalAfterTimerOpened = useStore($isSubscribeModalAfterTimerOpened)
  const isPraiseAfterFirstTask = useStore($isFirstTaskModalOpened)
  const isFiveFailedTasks = useStore($isFiveTasksFailedModalOpen)
  const isOpen = isModalAfterTimerOpened && !isSubscribeModalAfterTimerOpened && !isPraiseAfterFirstTask && !isFiveFailedTasks

  return (
    <PopupWindow isOpen={isOpen} mesToAmpl={`showAfter${timerValues[0] / 60}MinModal`} confettiActive={true}>
      <Emoji>🎉</Emoji>
      <Title>Отлично! Вы провели с пользой {timerValues[0] / 60} минут</Title>
      <Description>
        {difference === 0
          ? 'Продолжайте в том же духе!'
          : difference > 0
            ? `Вы продвинулись на ${difference}%, продолжайте в том же духе!`
            : `Прогресс уменьшился на ${difference}%, так бывает, продолжайте заниматься, и прогресс будет
          только расти!`}
      </Description>
      <ContinueButton onClick={() => closePraiseModal()}>Продолжить</ContinueButton>
    </PopupWindow>
  )
}
