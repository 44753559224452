import { createEffect, createEvent, createStore, sample } from 'effector'
import Meyda from 'meyda'
import { customNoteNumberToAbcdjs } from 'utils/notesAdapters'

import { getTopNIndices } from 'utils/utils'
import {
  clearOctave,
  frequencyToNote,
  getKnnNameByNotes,
  notesToFingerprint,
} from './utils.pitchdetect'
import AbcNotation from '@tonaljs/abc-notation'
import { KNNSet } from './knn-sets'
import { RMSDetector } from './RMSDetector'
import { MeydaAnalyzer } from 'meyda'

const expectedNotes = { notes: [] }

// TODO сделать динамическим и на нижних октавах увеличивать
export const bufferSize = 8192 // 200 милисек
// export const bufferSize = 4096 // 100 милисек задержка
// export const bufferSize = 2048
// export const bufferSize = 1024

let audioContext: AudioContext | null = null
let mediaStreamSource: MediaStreamAudioSourceNode | null = null
let activeMeydaAnalyzer: MeydaAnalyzer
let meydaAnalyzer4096: MeydaAnalyzer
let meydaAnalyzer8192: MeydaAnalyzer

export const noteByMic = createEvent<string>('noteByMic')
export const setTargetNotes = createEvent<string[]>('noteByMic')
export const setPredicatedNotes = createEvent<string[]>('setPredicatedNotes')

export const getOctave = (note: string) => +note.slice(-1)

export const addOctave = (n: string) => n.slice(0, n.length - 1) + (+n.slice(-1) + 1)
export const subOctave = (n: string) => n.slice(0, n.length - 1) + (+n.slice(-1) - 1)

const streamNotes = createEvent<string[]>('noteByMic')
const $targetNotes = createStore<string[]>([]).on(setTargetNotes, (_, value) => value)
export const $predicatedNotes = createStore<string[]>([]).on(
  setPredicatedNotes,
  (_, value) => value,
)

$targetNotes.watch((notes) => {
  if (notes[0]) {
    if (activeMeydaAnalyzer) {
      const octave = getOctave(AbcNotation.abcToScientificNotation(notes[0]))
      if (octave === 2 && activeMeydaAnalyzer !== meydaAnalyzer8192) {
        activeMeydaAnalyzer.stop()
        activeMeydaAnalyzer = meydaAnalyzer8192
        activeMeydaAnalyzer.start()
      } else if (octave > 2 && activeMeydaAnalyzer !== meydaAnalyzer4096) {
        activeMeydaAnalyzer.stop()
        activeMeydaAnalyzer = meydaAnalyzer4096
        activeMeydaAnalyzer.start()
      }
    }

    expectedNotes.notes = notes.map((note) => AbcNotation.abcToScientificNotation(note))
  }
})

sample({
  source: $targetNotes,
  clock: streamNotes,
  fn: (targetNotes, recognisedNotes) => ({
    targetNotes,
    recognisedNotes,
  }),
  target: createEffect((d) => {
    // const cleanStreamedNotes = d.activeNotes.map((el) => el.slice(0, el.length - 1))
    const cleanStreamedNotes = d.recognisedNotes.map(customNoteNumberToAbcdjs)
    const cleanTargetNotes = d.targetNotes

    // TODO optimise
    if (cleanTargetNotes.every((note) => cleanStreamedNotes.includes(note))) {
      cleanStreamedNotes.forEach(noteByMic)
    }
  }),
})

export function stopPitchDetect(): void {
  if (mediaStreamSource !== null && mediaStreamSource.mediaStream) {
    const tracks = mediaStreamSource.mediaStream.getTracks()
    tracks.forEach((track) => track.stop())
    mediaStreamSource.disconnect()
    mediaStreamSource = null
  }

  if (audioContext !== null) {
    audioContext.close()
    audioContext = null
  }
}

export function startPitchDetect() {
  window.AudioContext = window.AudioContext || window.webkitAudioContext
  audioContext = new AudioContext()

  navigator.mediaDevices
    .getUserMedia({ audio: true })
    .then(processMediaStream)
    .catch((err) => {
      console.error(`${err.name}: ${err.message}`)
      alert('Stream generation failed.')
    })
}

export type FeaturesAnalyzerOptions = {
  rmsThreshold?: number
  amplThreshold?: number
  topNIndexes?: number
}

export const createFeaturesAnalyzer = (
  expected: { notes: string[] },
  callback: (notes: string[], meta?: any) => void,
  { rmsThreshold, amplThreshold, topNIndexes }: FeaturesAnalyzerOptions = {},
) => {
  // TODO уменьшить для 5 октавы
  // const RMS_THRESHOLD = rmsThreshold || 0.005
  const RMS_THRESHOLD = rmsThreshold || 0.001
  // const RMS_THRESHOLD = 0.02
  // const AMPL_THRESHOLD = amplThreshold || 0.01
  const AMPL_THRESHOLD = amplThreshold || 1

  let lastRms = 0
  let lockDetect = false
  const rmsDetector = new RMSDetector(2, 0.01)
  let lastNotes = []
  const lastNotesRms: Record<string, number> = {}
  // TODO save prevbuffer and pass to extract?

  return (buffer: number[], meydaAnalyzer) => {
    const expectedOctav = expected.notes?.length ? getOctave(expected.notes[0]) : 2

    // if (
    //   (expectedOctav === 2 && buffer.length !== 8192) ||
    //   (expectedOctav > 2 && buffer.length !== 4096)
    // )
    //   return

    const rms = meydaAnalyzer._m.extract('rms', buffer)

    rmsDetector.pushRms(rms)

    if (rmsDetector.isPeakDetected()) {
      lockDetect = false
    }

    // TODO для нескольких нот из разных октав придумать что делать
    // TODO придумать общее решение
    // для высших октав берем меньше выборку
    const TOP_N_INDEXES = topNIndexes || (expectedOctav > 4 ? 3 : 10)

    // if (rms > RMS_THRESHOLD && !lockDetect) {
    if (rms > RMS_THRESHOLD && rms > lastRms) {
      const amplitudeSpectrum = meydaAnalyzer._m.extract(
        'amplitudeSpectrum',
        // expectedOctav > 2 ? buffer : [...prevBuffer, ...buffer],
        buffer,
      )

      const dominantFrequencyIndexes = getTopNIndices(amplitudeSpectrum, TOP_N_INDEXES).filter(
        (i) => amplitudeSpectrum[i] > AMPL_THRESHOLD,
      )
      const getFreqByIndex = (frequencyIndex: number) =>
        (frequencyIndex * meydaAnalyzer._m.sampleRate) / (2 * amplitudeSpectrum.length)

      // const mean = calculateMean(dominantFrequencies)
      // const stdDev = calculateStandardDeviation(dominantFrequencies)

      // const threshold = mean + 0.1 * stdDev // Установите порог, который должен превышать уровень мощности частоты, чтобы считаться активной нотой

      const notesSpectre = dominantFrequencyIndexes.map((i) => frequencyToNote(getFreqByIndex(i)))
      const freqs = dominantFrequencyIndexes.map((i) => getFreqByIndex(i))
      const amplitudes = dominantFrequencyIndexes.map((i) => amplitudeSpectrum[i] >> 0)

      if (expected.notes === null) {
        callback('', {
          notes: notesSpectre,
          amplitudes,
          freqs,
          amplitudeSpectrum,
        })
      }

      if (expected.notes?.length) {
        const knnName = getKnnNameByNotes(expected.notes)

        const knn = KNNSet[knnName]

        const predicatedNotesString =
          knn.predict(notesToFingerprint(notesSpectre, amplitudes), 2) || ''

        console.log(5556, predicatedNotesString, notesSpectre, amplitudes)

        const predicatedNotes = predicatedNotesString.split('-')

        // console.log(6666, rms)
        // console.log(111, lockDetect, 'predicatedNotes', predicatedNotes, lastRms, rms)
        setPredicatedNotes(predicatedNotes)

        const isSameNote = clearOctave(lastNotes[0]) === clearOctave(expected.notes[0])

        const isNeedLock = isSameNote
        // const isNeedLock = true
        if (
          predicatedNotes.length &&
          // TODO optimize
          // remove clearOctave ? это для кейсов когда С3F4 и С4F3
          expected.notes.map(clearOctave).sort().join('') ===
            predicatedNotes.map(clearOctave).sort().join('') &&
          // (lastNotesRms[predicatedNotes] || 0) < RMS_THRESHOLD &&
          // lastRms < rms
          // (lastRms < rms ||
          ((isNeedLock && !lockDetect) || !isNeedLock)
        ) {
          lastRms = rms
          lastNotes = expected.notes
          lockDetect = true
          rmsDetector.reset()
          callback(expected.notes, {
            knnName,
            expected: expected.notes,
            notes: notesSpectre,
            amplitudes,
            freqs,
            amplitudeSpectrum,
          })
        }
        // чекнуть распознавание октав
        // распознавать что новый звук появился а не конкретную ноту
        // плохо работает если раскомментить
        // и ноты из нижней октавы всёравно потом распознаются как верхние и несколько подряд
        // lastNotesRms[predicatedNotes] = rms
      }
    } else if (lastRms >= RMS_THRESHOLD) {
      Object.keys(lastNotesRms).forEach((note) => {
        lastNotesRms[note] = 0
      })
    }

    if (lastRms > rms) {
      lastRms = rms
    }
  }
}

const processMediaStream = async (stream) => {
  mediaStreamSource = audioContext!.createMediaStreamSource(stream)

  // const response = await fetch('/test-samples/record1.wav')
  // const audioData = await response.arrayBuffer()
  // const audioBuffer = await audioContext.decodeAudioData(audioData)
  // const bufferSource = audioContext.createBufferSource()
  // bufferSource.buffer = audioBuffer
  // bufferSource.start()

  const analyzeFeatures = createFeaturesAnalyzer(expectedNotes, streamNotes)

  meydaAnalyzer8192 = Meyda.createMeydaAnalyzer({
    audioContext,
    source: mediaStreamSource,
    bufferSize: 8192,
    featureExtractors: ['buffer'],
    callback: ({ buffer }) => analyzeFeatures(buffer, meydaAnalyzer8192),
  })

  meydaAnalyzer4096 = Meyda.createMeydaAnalyzer({
    audioContext,
    source: mediaStreamSource,
    bufferSize: 4096,
    featureExtractors: ['buffer'],
    callback: ({ buffer }) => analyzeFeatures(buffer, meydaAnalyzer4096),
  })

  activeMeydaAnalyzer = meydaAnalyzer8192
  activeMeydaAnalyzer.start()
}
