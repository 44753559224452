import { IChord } from 'types'
import { Mode, Note } from '@tonaljs/tonal'
import Chord from '@tonaljs/chord'
import { tonalToAbc } from 'utils/abcjs-helpers'
import { orderBy, values } from 'lodash/fp'
import { addOctaves } from './tasks.utils'

export const BASE_NOTES = ['C', 'D', 'E', 'F', 'G', 'A', 'B'] as const

export const CHORDS = {
  Am: Mode.triads('minor', 'A'),
  C: Mode.triads('major', 'C'),
  Am7: Mode.seventhChords('minor', 'A'),
  C7: Mode.seventhChords('major', 'C'),
}

export const minorSubsequences = [
  // most popular
  [1, 5, 6, 4],
  // most popular with little changes
  [1, 3, 6, 4],
  // subsequence of strong woman (one of us)
  [6, 4, 1, 5],
]

// https://blog.pedalzoo.ru/music-theory/chord-progressions/
export const subsequences = [
  // most popular
  [1, 5, 6, 4],
  // most popular with little changes
  [1, 3, 6, 4],
  // [1, 1, 6, 4],
  // всё портит в минорной тональности 2 ступень это Bdim
  [1, 2, 6, 4],
  // [1, 4, 6, 4],
  // сам склепал чтобы добавить 7 ступень
  // но пока убрал везде 7 ступень, чтобы задания с аккордами не усложнять
  // [1, 4, 7, 4],
  // subsequence of strong woman (one of us)
  [6, 4, 1, 5],
]

export const allSubsequences = [
  // most popular
  [1, 5, 6, 4],
  // most popular with little changes
  [1, 3, 6, 4],
  // [1, 1, 6, 4],
  [1, 2, 6, 4],
  [1, 4, 6, 4],
  // subsequence of strong woman (one of us)
  [6, 4, 1, 5],
  // subsequence of strong woman with little changes
  [4, 1, 5, 6],
  [5, 6, 4, 1],
  // 1950-x
  [1, 6, 4, 5],
  // // 12 тактов блюз
  // [1, 4, 1, 1, 4, 4, 1, 1, 5, 4, 1, 5],
  // простая блюзовая
  [1, 4, 5, 1],
  // рок и фолк
  [1, 4, 5, 4],
  // блюз фолк кантри рок
  [1, 5, 4, 5],
  // // русский рок и барды
  // // TODO последняя 5 должна быть семеркой
  // [1, 4, 5, 5],
  // // TODO для минора
  // [5, 6, 1],
  //
  [1, 6, 2, 5],
  // сам склепал чтобы добавить 7 ступень
  [1, 4, 7, 4],
  // золотая секвенция
  [1, 4, 7, 3, 6, 2, 5, 1],
]

export const chords5: IChord[] = [
  ['C5', 'C G'],
  ['C#5', 'C# G#'],
  ['D5', 'D A'],
  ['Eb5', 'Eb Bb'],
  ['E5', 'E B'],
  ['F5', 'F C'],
  ['F#5', 'F# C#'],
  ['G5', 'G D'],
  ['Ab5', 'Ab Eb'],
  ['A5', 'A E'],
  ['Bb5', 'Bb F'],
  ['B5', 'B F#'],
]

export const octavs: IChord[] = [
  ['C octav', `C C'`],
  ['C# octav', `C# C#'`],
  ['D octav', `D D'`],
  ['Eb octav', `Eb Eb'`],
  ['E octav', `E E'`],
  ['F octav', `F F'`],
  ['F# octav', `F# F#'`],
  ['G octav', `G G'`],
  ['Ab octav', `Ab Ab'`],
  ['A octav', `A A'`],
  ['Bb octav', `Bb Bb'`],
  ['B octav', `B B'`],
]

export const AdeleChords: IChord[] = [
  ['Cm', 'C Eb G'],
  ['Bb', 'Bb D F'],
  ['Gm', 'G Bb D'],
  ['Ab', 'Ab C Eb'],
  ['Ab major 7', 'Ab C Eb G'],
  ['G', 'G B D'],
]

export const MaximOtpuskayChords: IChord[] = [
  ['Eb', 'Eb G Bb'],
  ['F', 'F A C'],
  ['Bb', 'Bb D F'],
  ['Bm', 'B D F#'],
  ['Db', 'C# F G#'],
  ['Gb', 'F# A# C#'],
  ['Ebm', 'Eb Gb Bb'],
  ['Fm', 'F Ab C'],
  ['Bbm', 'Bb Db F'],
  ['Ab', 'Ab C Eb'],
  ['Abm', 'Ab B Eb'],
  ['Gbm', 'F# A C#'],
]

export const MAJ_CHORDS: IChord[] = [
  ['C', 'C E G'],
  ['C#', 'C# F G#'],
  ['D', 'D F# A'],
  ['Eb', 'Eb G Bb'],
  ['E', 'E G# B'],
  ['F', 'F A C'],
  ['F#', 'F# A# C#'],
  ['G', 'G B D'],
  ['Ab', 'Ab C Eb'],
  ['A', 'A C# E'],
  ['Bb', 'Bb D F'],
  ['B', 'B D# F#'],
]

export const MIN_CHORDS: IChord[] = [
  ['Cm', 'C Eb G'],
  ['C#m', 'C# E G#'],
  ['Dm', 'D F A'],
  ['Ebm', 'Eb Gb Bb'],
  ['Em', 'E G B'],
  ['Fm', 'F Ab C'],
  ['F#m', 'F# A C#'],
  ['Gm', 'G Bb D'],
  ['Abm', 'Ab B Eb'],
  ['Am', 'A C E'],
  ['Bbm', 'Bb Db F'],
  ['Bm', 'B D F#'],
]

export const FIVE_NOTES_SEQUENCES = [
  [0.1, '01234', [1, 2, 3, 4, 5]],
  [0.2, '43210', [5, 4, 3, 2, 1]],
  [0.9, '021304', [1, 3, 2, 4, 1, 5]],
  [0.9, '01020304', [1]],
  [0.9, '10121314', [2]],
] as const

export const FIVE_NOTES_SEQUENCES_GROUPED = [
  [[0.1, '01234', [1, 2, 3, 4, 5]]],
  [[0.2, '43210', [5, 4, 3, 2, 1]]],
  [
    // TODO почему тут только одна последовательность используется, пофиксить и добавить новых и тогда будет полезно
    [0.9, '021304', [1, 3, 2, 4, 1, 5]],
    [0.9, '01020304', [1]],
    [0.9, '10121314', [2]],
  ],
] as const
