import { $currentTaskId } from 'model/model'
import { updateSteps } from './model'
import { trebleBassClefOnboardingSteps } from './onboardingSteps'
import { useEffect } from 'react'
import { useStore } from 'effector-react'
import { useTour } from '@reactour/tour'

export const TrebleBasClefOnboarding = () => {
  // TODO доделать
  return false
  const currentTaskId = useStore($currentTaskId)
  const { setCurrentStep } = useTour()
  const trebleClef = document.querySelector('.abcjs-staff-extra.abcjs-v0')
  const basClef = document.querySelector('.abcjs-staff-extra.abcjs-v1')

  const startOnboarding = () => {
    updateSteps([])
    updateSteps(trebleBassClefOnboardingSteps)
  }

  const showBasClef = () => {
    updateSteps([])
    updateSteps(trebleBassClefOnboardingSteps)
    setCurrentStep(1)
  }

  useEffect(() => {
    if (trebleClef) {
      trebleClef.addEventListener('click', startOnboarding)
    }
    if (basClef) {
      basClef.addEventListener('click', showBasClef)
    }

    return () => {
      if (trebleClef) {
        trebleClef.removeEventListener('click', startOnboarding)
      }
      if (basClef) {
        basClef.removeEventListener('click', showBasClef)
      }
    }
  }, [trebleClef, basClef, currentTaskId])

  return null
}
