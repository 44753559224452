import {
  attach,
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector'
import {
  getLastTasksStatsSum,
  getTaskState,
  getMelodyTaskProgressByStat,
} from 'statistics/statistics'
import { tasksData } from 'statistics/tasksUniqNames'
import {
  $partMistakesCount,
  next,
  init,
  $currentTaskStep,
  $currentTask,
  $taskStartTime,
  $mistakesCount,
  $mode,
  $currentTaskNotes,
} from './model'
import { attachLogger } from 'effector-logger'
import { updateDataScoresFx } from 'features/statistic/model'

type ICurrentTaskScores = { init: number; recalculated: number }
// attachLogger()

// TODO удалить лишнее
// TODO время отнимать по чуть чуть начиная с первой ноты, считать что если ты за 200 мс не прошел задание

// для каждого задания берем текущий прогресс, по каждой ноте кроме первой считаем результат, и берем среднее?
//  получается прогресс для каждой ноты нужно считать
// но если над какой-то одной ооочень долго сидишь, но само задание длинное, прогресса отнимется совсем чуть чуть
// первые 200 милисек при нажатии на ноту не отнимать баллы и полоску! т.е. долго думаешь над одной нотой - снимается много времени, но начинаешь
// потом быстро проходить - оно замедляется

export const updateScores = createEvent<number>()
const updateCurrentTaskScores = createEvent<Partial<ICurrentTaskScores>>()
const changeCurrentTaskScores = createEvent()

let timeIntervalId: NodeJS.Timer
const updateCurrentTaskScoreFx = attach({
  source: {
    $mistakesCount,
    $taskStartTime,
    $currentTask,
    $currentTaskStep,
  },
  effect: (e) => {
    const taskId = e.$currentTask.id
    const groupProgress = getTaskState(taskId)?.groupProgress || 0
    const time = new Date().getTime() - e.$taskStartTime
    const calculatedProgress = getMelodyTaskProgressByStat(
      e.$currentTaskStep === 0 ? 0 : time,
      e.$mistakesCount,
      e.$currentTask.notes.length,
      e.$currentTaskStep,
    )

    updateCurrentTaskScores({
      init: groupProgress,
      recalculated: calculatedProgress,
    })
  },
})

export const updateScoreFx = createEffect(() => {
  const scores = getLastTasksStatsSum()

  clearInterval(timeIntervalId)
  timeIntervalId = setInterval(changeCurrentTaskScores, 100)

  updateScores(scores)
})

export const $currentTaskScores = createStore<ICurrentTaskScores>({
  init: 0,
  recalculated: 0,
}).on(updateCurrentTaskScores, (state, newScores) => ({ ...state, ...newScores }))

sample({
  filter: combine(
    $currentTaskStep,
    $currentTaskNotes,
    (currentTaskStep, currentTaskNotes) => currentTaskStep < currentTaskNotes.length,
  ),
  clock: [$partMistakesCount, $currentTask, changeCurrentTaskScores],
  target: updateCurrentTaskScoreFx,
})

sample({
  clock: init,
  target: updateScoreFx,
})

// TODO use durty scores for calc progressbar!!!
export const $durtyScores = createStore(0).on(updateScores, (_, newScores) => newScores)
// export const $scores = $durtyScores.map(state => state / tasksSequence.length)

export const $scores = combine(
  $durtyScores,
  $mode,
  (durtyScores, mode) => (durtyScores / tasksData[mode].mainGroupTasksIds.length) * 100,
)

// export const $scores = combine(
//   $durtyScores,
//   $currentTaskPartScores,
//   (durtyScores, currentTaskPartScores) =>
//     durtyScores - currentTaskPartScores.init + currentTaskPartScores.recalculated,
// )

export const $bestScores = createStore(0).on($scores, Math.max)

sample({
  clock: next,
  target: updateScoreFx,
})

// TODO move to another file, resolve circular deps
// TODO это должно зависеть не только от очков, первые пару минут, каждый раз нужно всёравно показывать
export const $isNotesOnKeysVisible = $scores.map((scores) => scores < 50)
