import { useTour } from '@reactour/tour'
import { useStore } from 'effector-react'
import { $clockValForNextStep, $steps, closeOnboarding, openOnboarding } from 'features/onboarding/model'
import { useUpdateEffect } from 'usehooks-ts'

export const OnboardingController: React.FC = () => {
  const { setIsOpen, setSteps, isOpen, setCurrentStep, currentStep } = useTour()
  const steps = useStore($steps)
  const clockNextStepOnboarding = useStore($clockValForNextStep)

  useUpdateEffect(() => {
    setCurrentStep(0)
    setSteps(steps)
    setIsOpen(true)
  }, [steps])

  useUpdateEffect(() => {
    if (isOpen) {
      openOnboarding()
    } else {
      closeOnboarding()
    }
  }, [isOpen])

  useUpdateEffect(() => {
    setCurrentStep(currentStep + 1)
  }, [clockNextStepOnboarding])

  return null
}
