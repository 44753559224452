import { combine, createEffect, createEvent, createStore, guard, sample } from 'effector'
import { persist } from 'effector-storage/local'
import { $isOnboardingOpened } from 'features/onboarding/model'
import { $completedTaskToday, $dataScores } from 'features/statistic/model'
import { F, keys, T } from 'lodash/fp'
import { $currentTaskStep, $isTaskFailed, init } from 'model/model'
import { $scores } from 'model/scores'
import { isProVersion } from 'model/ui'
import { resolveTaskFx } from 'statistics/statistics'
import { trackFx } from 'utils/amplitude'
import { $currentDate, $isCurrentDateSynced, dayChangedAfterInit, tick } from 'utils/effector'
import { DATE_FORMAT, getISODate, getISODateNow } from 'utils/utils'
import dayjs from 'dayjs'

const CURRENT_TIMER_KEY = 'currentTimer'

const updateTimerValues = createEvent()

// const INIT_TIMER_VALUES = [7, 9, 11, 15]
const INIT_TIMER_VALUES = [5 * 60, 10 * 60, 30 * 60, 60 * 60]

export const $timerValues = createStore<number[]>(INIT_TIMER_VALUES)
  .on(updateTimerValues, (_, val) => val)
  .on(dayChangedAfterInit, () => INIT_TIMER_VALUES)

export const $isFirtTimerPassed = $timerValues.map(
  (timerValues) => timerValues.length < INIT_TIMER_VALUES.length,
)

persist({ store: $timerValues, key: 'timerValues' })

export const stopTimer = createEvent()
export const startTimer = createEvent()
export const $isActiveTimer = createStore<boolean>(true).on(stopTimer, F).on(startTimer, T)

const newCurrentTimer = createEvent<number>()
const tickTimer = createEvent()

export const $currentTimer = createStore(
  Number(localStorage.getItem(CURRENT_TIMER_KEY) || INIT_TIMER_VALUES[0]),
)
  .on(tickTimer, (state) => Math.max(state - 1, 0))
  .on(newCurrentTimer, (_, val) => val)

export const closePraiseModal = createEvent()

const updateSavedScores = createEvent()
export const $savedScores = createStore(0).on(updateSavedScores, (_, val) => val)

export const $isModalAfterTimerOpened = combine(
  $currentTimer,
  $currentTaskStep,
  $isCurrentDateSynced,
  (currentTimer, currentTaskStep, isCurrentDateSynced) =>
    currentTimer === 0 && currentTaskStep === 0 && isCurrentDateSynced,
)

export const $isSubscribeModalAfterTimerOpened = combine(
  {
    $isModalAfterTimerOpened,
    $isProVersionEnabled: isProVersion.$val,
    $isFirtTimerPassed,
    $dataScores,
  },
  (p) =>
    (p.$isModalAfterTimerOpened || p.$isFirtTimerPassed) &&
    keys(p.$dataScores).length > 0 &&
    !p.$isProVersionEnabled,
)

export const closeSuccessFirstTask = createEvent()
export const successFirstTask = createEvent()
export const $isFirstTaskDoneToday = createStore<boolean>(false)
  .on(successFirstTask, T)
  .on(closeSuccessFirstTask, F)

export const $isFirstTaskModalOpened = combine(
  $isFirstTaskDoneToday,
  $isOnboardingOpened,
  (isFirstTaskDone, isOnboardingOpened) => isFirstTaskDone && !isOnboardingOpened,
)

sample({
  clock: tick,
  filter: $isActiveTimer,
  target: tickTimer,
})

sample({
  source: $timerValues,
  clock: [updateTimerValues, dayChangedAfterInit],
  fn: ($timerValues) => $timerValues[0],
  target: newCurrentTimer,
})

sample({
  source: $timerValues,
  clock: closePraiseModal,
  fn: ($timerValues) => ($timerValues.length > 1 ? $timerValues.slice(1) : $timerValues),
  target: updateTimerValues,
})

sample({
  source: $scores,
  clock: [init, closePraiseModal],
  target: updateSavedScores,
})

sample({
  source: $currentTimer,
  filter: ($currentTimer) => $currentTimer % 3 === 0,
  fn: ($currentTimer) => localStorage.setItem(CURRENT_TIMER_KEY, $currentTimer.toString()),
})


sample({
  source: $completedTaskToday,
  filter: ($completedTaskToday) => $completedTaskToday === 1,
  fn: () => ({ name: 'firstTaskDone' }),
  target: [trackFx, successFirstTask],
})


export const incFailedTask = createEvent()
export const resetFiveFailedTask = createEvent()
export const $countFailedTasks = createStore<number>(0)
  .on(dayChangedAfterInit, () => 0)
  .on(incFailedTask, (s) => s + 1)

export const $isFiveFailedModeActive = createStore<boolean>(false)
  .on(dayChangedAfterInit, T)
  .on(resolveTaskFx, F)
  .on(resetFiveFailedTask, F)

export const $isFiveTasksFailedModalOpen = combine(
  $countFailedTasks,
  $currentTaskStep,
  $isFiveFailedModeActive,
  (countFailedTasks, currentTaskProgress, isFiveFailedModeActive) =>
    countFailedTasks === 3 && currentTaskProgress === 0 && isFiveFailedModeActive,
)

sample({
  source: { $isFiveFailedModeActive, $isTaskFailed },
  clock: $isTaskFailed,
  filter: ($isFiveFailedModeActive, $isTaskFailed) => $isFiveFailedModeActive && $isTaskFailed,
  target: incFailedTask,
})

const STREAK_DAYS_KEY = 'STREAK_DAYS'
const LAST_STREAK_DATE_KEY = 'LAST_STREAK_DATE'

export const addStreakDay = createEvent()
export const resetStreakDays = createEvent()
export const $streakDays = createStore<number>(0)
  .on(addStreakDay, (s) => s + 1)
  .on(resetStreakDays, () => 0)

export const $lastStreakDate = createStore(getISODate(0)).on(addStreakDay, getISODateNow)

persist({ store: $streakDays, key: STREAK_DAYS_KEY })
persist({ store: $lastStreakDate, key: LAST_STREAK_DATE_KEY })

sample({
  source: { $lastStreakDate, $currentDate },
  clock: init,
  filter: ({ $lastStreakDate, $currentDate }) =>
    dayjs($lastStreakDate).add(1, 'd').format(DATE_FORMAT) !== $currentDate &&
    $lastStreakDate !== $currentDate,
  target: resetStreakDays,
})

// юзер заходит в аппку - видит модалку с тем что ему нужно пройти
// хотябы 1 задание и прозаниматься 5 минут чтобы продолжить/начать страйк

// проходит задание - видит модалку что осталось прозаниматься x минут
// проходит 5 минут - видит модалку что нужно решить 1 задание
// ИЛИ
// проходит задание - видит модалку что он молодец
// проходит 5 минут - видит модалку что он молодец

// в конце анбординга показывать отдельную модалку
