import { MonthOfYear } from '../../constants'
import { attach, combine, createEffect, createEvent, createStore, sample } from 'effector'
import { persist } from 'effector-storage/local'
import { values } from 'lodash/fp'
import { $isAppActive } from 'model/document'
// import { attachLogger } from 'effector-logger/attach'
import { $scores, updateScoreFx } from 'model/scores'
import { isDeveloperModeToggle } from 'model/ui'
import { resolveTaskFx } from 'statistics/statistics'
import { $currentDate, dayChanged, dayChangedAfterInit, tick } from 'utils/effector'
import { identifyMissingDays } from 'utils/utils'

export const changeStatisticsFormat = createEvent<string>()
export const $statisticsFormat = createStore<string>('week').on(changeStatisticsFormat, (_, val) => val)
// attachLogger(domainScores)


// const localStorageMock = (function () {
//   let store = {}

//   return {
//     getItem(key) {
//       return store[key]
//     },
//     setItem(key, value) {
//       store[key] = value
//     },
//   }
// })()

// globalThis.localStorage = localStorageMock

// замокать localStorage перед тестами 


const DATA_SCORES_KEY = 'dataScores'
const TODAY_SCORES_KEY = 'todayScores'
const DATA_KEY = 'trackingTimeData'
const TODAY_TIME_KEY = 'todayTime'
const MONTHLY_STAT_KEY = 'monthlyStat'
const LAST_ACTIVE_DATE_KEY = 'lastActiveDate'

type DATA = {
  [x: string]: number
}
type MonthlyStatType = {
  [x: string]: number[]
}

export const resetAllData = createEvent()
export const updateStatistics = createEvent('updateStatistics')

export const updateTodayScores = createEvent<number>('updateTodayScores')
export const updateTrackedTime = createEvent<number>('updateTrackedTime')
const updateTimeInSecond = createEvent()
const resetTimeInSecond = createEvent()

export const updateAllDataScores = createEvent<DATA>()
export const updateDataTrackedTime = createEvent<DATA>()
const updateLastActiveDate = createEvent()

export const $todayScores = createStore(0).on(updateTodayScores, (_, val) => val)
const $trackedTimeInSecond = createStore(0).on(updateTimeInSecond, (s) => s + 1).on(resetTimeInSecond, () => 0)
export const $trackedTime = createStore<number>(0).on(updateTrackedTime, (state, val) => state + val).reset(updateDataTrackedTime)
const $isStatisticsWorking = combine(
  { $isAppActive, isDevModeEnable: isDeveloperModeToggle.$val },
  ({ $isAppActive, isDevModeEnable }) =>
    $isAppActive && isDevModeEnable
);

const $lastActiveDate = createStore(new Date(0).toISOString().substring(0, 10)).on(updateLastActiveDate, () => new Date().toISOString().substring(0, 10))
export const $dataScores = createStore<DATA>({}).on(updateAllDataScores, (s, val) => ({ ...s, ...val }))
export const $dataTrackedTime = createStore<DATA>({}).on(updateDataTrackedTime, (s, val) => ({ ...s, ...val })).on(resetAllData, () => { })


export const updateMonthStat = createEvent<MonthlyStatType>();
export const $monthlyStatistics = createStore<MonthlyStatType>({}).on(updateMonthStat, (state, values) => ({ ...state, ...values }));


persist({ store: $todayScores, key: TODAY_SCORES_KEY })
persist({ store: $trackedTime, key: TODAY_TIME_KEY })

persist({ store: $lastActiveDate, key: LAST_ACTIVE_DATE_KEY })
persist({ store: $dataScores, key: DATA_SCORES_KEY })
persist({ store: $dataTrackedTime, key: DATA_KEY })

persist({ store: $monthlyStatistics, key: MONTHLY_STAT_KEY })

sample({
  source: $scores,
  clock: updateScoreFx.done,
  target: updateTodayScores,
})

sample({
  source: $lastActiveDate,
  clock: [dayChangedAfterInit, dayChanged],
  filter: (lastActiveDate) => lastActiveDate === '01.01.1970',
  target: updateLastActiveDate
})

sample({
  // при инициализации $currentDate 1970 год хотя должно было обновится так как clock dayChanged
  source: { lastActiveDate: $lastActiveDate, trackedTime: $trackedTime, currentDate: $currentDate },
  clock: [dayChangedAfterInit, dayChanged],
  filter: ({ lastActiveDate, currentDate }) => lastActiveDate !== currentDate,
  fn: ({ lastActiveDate, trackedTime, currentDate }) => {
    const missingDatesForTrackingTime = identifyMissingDays(lastActiveDate, currentDate)
    return { ...missingDatesForTrackingTime, [lastActiveDate]: trackedTime }
  },
  target: updateDataTrackedTime,
})

sample({
  source: { lastActiveDate: $lastActiveDate, todayScores: $todayScores, currentDate: $currentDate, currentScores: $todayScores },
  clock: [dayChangedAfterInit, dayChanged],
  filter: ({ lastActiveDate, currentDate }) => lastActiveDate !== currentDate,
  fn: ({ lastActiveDate, todayScores, currentDate, currentScores }) => {
    const missingDatesForScores = identifyMissingDays(lastActiveDate, currentDate, currentScores)
    return { ...missingDatesForScores, [lastActiveDate]: todayScores }
  },
  target: updateAllDataScores,
})

sample({
  clock: [updateDataTrackedTime, updateAllDataScores],
  target: updateLastActiveDate
})

sample({
  clock: tick,
  filter: $isStatisticsWorking,
  target: updateTimeInSecond,
})

sample({
  source: $trackedTimeInSecond,
  filter: (trackedTimeInSecond) => trackedTimeInSecond % 10 === 0,
  fn: () => Math.round(10 / 60 * 100) / 100, // каджые 10 сек добавляется 10 сек(в минутах) к предыдущему значению 
  target: updateTrackedTime
})

sample({
  clock: [dayChangedAfterInit, dayChanged],
  target: resetTimeInSecond
})

const DAILY_GOAL_KEY = 'dailyGoal'
const COMPLETED_TASK_TODAY_KEY = 'completedTaskToday'

export const addMinute = createEvent()
export const minusMinute = createEvent()
export const $dailyGoal = createStore<number>(5).on(addMinute, (s) => s + 1).on(minusMinute, (s) => s - 1)

persist({ store: $dailyGoal, key: DAILY_GOAL_KEY })

export const addCompletedTask = createEvent()
export const $completedTaskToday = createStore<number>(0).on(addCompletedTask, (state) => state + 1).reset(dayChangedAfterInit)

persist({ store: $completedTaskToday, key: COMPLETED_TASK_TODAY_KEY })

sample({
  clock: resolveTaskFx,
  target: addCompletedTask
})

// sample({
//   source: {
//     $dataTrackedTime,
//     $todayScores,
//     $currentDate
//   },
//   clock: $currentDate,
//   filter: () => new Date().getDate() === 1,
//   fn: (p) => {
//     const sumTime = values(p.$dataTrackedTime)
//     const prevMonthIndex = new Date().getMonth() === 0 ? 11 : new Date().getMonth() - 1
//     const lastMonth = MonthOfYear[prevMonthIndex]
//     return { lastMonth: [sumTime, p.$todayScores] }
//   },
//   target: updateMonthStat,
// })

// поломается для недельной статистики
// данные обновятся так как 1-е число, но это может быть среди недели
// sample({
//   clock: updateMonthStat,
//   target: resetAllData
// })

// TODO использовать интервал из патронуса (либа для эффектора)
// TODO isAppActive === false сохранять дифф времени или сохранять время чаще (каждые 5 сек например)
