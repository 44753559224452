export const handleButtonClick = (buttonRef, height = 300, width = 500) => {

    if (!buttonRef || !buttonRef.current) {
        return {}
    }
    const addButton = buttonRef.current

    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    const centerX = (screenWidth - width) / 2 - addButton.getBoundingClientRect().left;
    const centerY = (screenHeight - height) / 2 - addButton.getBoundingClientRect().top;

    return {
        width: `${width}px`,
        height: `${height}px`,
        transform: `translate(${centerX}px, ${centerY}px)`,
        // position: 'absolute'
    };
};


export const handleButtonClickOnPhone = (buttonRef) => {
    const height = window.innerHeight - 30 + 'px'
    // const height = "100%"
    const width = window.innerWidth - 30 + 'px'
    // const width = "100%"
    if (!buttonRef || !buttonRef.current) {
        return {}
    }
    const addButton = buttonRef.current
    const pointX = addButton?.offsetLeft - 15
    const pointY = addButton?.offsetTop - 15
    return {
        width: width,
        height: height,
        transform: `translate(${-pointX}px, ${-pointY}px)`,
        // position: 'fixed'
    }
}
