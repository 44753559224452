import { Note } from '@tonaljs/tonal'

export const frequencyToNote = (frequency: number) => {
  // const noteStrings = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B']

  // const A4 = 440
  const A4Index = 57 // A4 is the 57th key on the piano

  // const noteIndex = Math.round(12 * (Math.log(frequency / A4) / Math.log(2))) + A4Index

  // const note = noteIndex % 12
  // const octave = Math.floor(noteIndex / 12) - 1 // Piano starts at A0 which is considered the 1st key

  // return `${noteStrings[note]}${octave}`

  const noteStrings = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B']

  const A4 = 440

  const noteIndex = Math.round(12 * (Math.log(frequency / A4) / Math.log(2))) + A4Index

  const note = noteIndex % 12
  const octave = Math.floor(noteIndex / 12)

  return `${noteStrings[note]}${octave}`
}

export const noteWithObertones = (freq: number) =>
  new Array(3)
    .fill(freq)
    .map((freq, i) => freq * (i + 1))
    .map(frequencyToNote)

export const getNextFrequency = (f0, n = 1) => {
  const SEMITONE_RATIO = Math.pow(2, 1 / 12)
  return f0 * Math.pow(SEMITONE_RATIO, n)
}

const getOctave = (str: string) => +str.slice(-1)
export const clearOctave = (note?: string) => note?.slice?.(0, -1)

function calculateMean(arr) {
  const sum = arr.reduce((acc, value) => acc + value, 0)
  return sum / arr.length
}

function calculateStandardDeviation(arr) {
  const mean = calculateMean(arr)
  const variance = arr.reduce((acc, value) => acc + Math.pow(value - mean, 2), 0) / arr.length
  return Math.sqrt(variance)
}

export const NOTES_VECTORS = new Array(12 * 5)
  .fill(Note.freq('C2'))
  .map((freq, i) => getNextFrequency(freq, i))
  .map(noteWithObertones)

// NOTES_VECTORS.push(['C23', ...'C3|G4|C4|G3|E4'.split('|')])
// NOTES_VECTORS.push(['C34', ...'C4|C5|G4|C3'.split('|')])

export const NOTES_VECTORS_WITHOUT_DIEZ = NOTES_VECTORS.filter((vec) => !vec[0].includes('#'))

// TODO можно сравнивать ближайшие ноты, и если текущая нота лучше, то брать ее
export const checkNoteByVector = (expectedNote: string, vector: string[]): boolean => {
  const index = Note.midi(expectedNote) - Note.midi('C2')

  let max = 0
  let maxIndex = 0

  // const etalons = minOctave >= 4 ? NOTES_VECTORS_WITHOUT_DIEZ.slice(14) : NOTES_VECTORS_WITHOUT_DIEZ
  const etalons = NOTES_VECTORS.slice(Math.max(index - 10, 0), index + 10)

  etalons.map((etalon, index) => {
    // const res = vector.reduce((res, note) => (etalon.includes(note) ? res + 1 : res), 0)
    const res = etalon.reduce((res, note, i) => {
      return vector.includes(note) ? res + 1 : res
    }, 0)

    if (res > max) {
      max = res
      maxIndex = index
    }
  })

  return etalons[maxIndex][0] === expectedNote
}

export const findNoteByVector = (vector: string[]) => {
  // TODO обработать если несколько максимумов
  let max = 0
  let maxIndex = 0

  const firstOctave = getOctave(vector[0])

  // durty hack
  // if (firstOctave === 4 && vector[0] === vector[1]) return vector[0]

  const minOctave = Math.min(...vector.map((n) => +getOctave(n)))

  // const etalons = minOctave >= 4 ? NOTES_VECTORS_WITHOUT_DIEZ.slice(14) : NOTES_VECTORS_WITHOUT_DIEZ
  const etalons = NOTES_VECTORS_WITHOUT_DIEZ

  etalons.map((etalon, index) => {
    // const res = vector.reduce((res, note) => (etalon.includes(note) ? res + 1 : res), 0)
    const res = etalon.reduce((res, note, i) => {
      // при полном совпадении нот еще больше увеличиваем коэфициент
      // if (firstOctave >= 5 && note === etalon[0]) res++
      return vector.includes(note) ? res + 1 : res
    }, 0)
    // if (
    //   res > max ||
    //   (res === max &&
    //     clearOctave(etalons[maxIndex][0]) === clearOctave(etalons[index][0]))
    // ) {
    if (res > max || (firstOctave >= 5 && res === max)) {
      max = res
      maxIndex = index
    }
  })

  console.log('detected', etalons[maxIndex][0])

  // return etalons[maxIndex][0] + ' ' + midOctave
  return etalons[maxIndex][0]
}

export const getKnnNameByNotes = (notes: string[]) => {
  const sorted = notes.sort((a, b) => Note.midi(a) - Note.midi(b))
  const isOctave =
    notes.length === 2 &&
    getOctave(sorted[1]) - getOctave(sorted[0]) === 1 &&
    clearOctave(sorted[1]) === clearOctave(sorted[0])

  return (
    sorted
      .map((note) => {
        const o = Note.octave(note)
        return `C${o}B${o}`
      })
      .join('_') + (isOctave ? 'octave' : '')
  )
}

export const notesToFingerprint = (notes: string[], ampl: number[] = []) => {
  const fingerptint = new Array(Note.midi('B6') - Note.midi('C2')).fill(0)
  notes.forEach((note, i) => {
    const index = Note.midi(note) - Note.midi('C2')
    // fingerptint[index] = (fingerptint[index] || 0) + (ampl[i] || 1)
    fingerptint[index] = Math.max(fingerptint[index] || 0, ampl[i] || 1)
    // fingerptint[index] = 1
  })

  return fingerptint
}
