import { useStore } from 'effector-react'
import styled from 'styled-components'
import { $currentTimer } from './model'

const TimerComponent = styled.div`
  pointer-events: none;
  color: #776e65;
  font-size: 27px;
  position: absolute;
  top: 10px;
  left: 10px;
`

const calculateTime = (time: number): string => {
  const minutes = Math.floor((time % 3600) / 60)
  const seconds = time % 60

  const formattedMinutes = String(minutes)
  const formattedSeconds = String(seconds).padStart(2, '0')
  const formattedTime = `${formattedMinutes}:${formattedSeconds}`

  return formattedTime
}

export const Timer = () => {
  const currentTimeInSeconds = useStore($currentTimer)
  const timer = calculateTime(currentTimeInSeconds)

  return <TimerComponent className="timer">{timer}</TimerComponent>
}
