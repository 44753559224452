import { Box, CircularProgress } from "@mui/material"
import { secondaryCircularColor, mainCircularColor } from "./../constants"


const CircularProgressContainerStyles = { position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }
const CircularProgressStyles = { position: 'absolute', zIndex: 1, color: mainCircularColor }
const CircularProgressBackground = { color: secondaryCircularColor, opacity: 0.5 }
const TitleStyles = { position: 'absolute', fontSize: '40px', textAlign: 'center'}
const DescriptionStyles = { fontSize: '20px'}

export const CustomCircularProgress = ({ title, description, size, progress }: any) => {
    return (
        <Box sx={CircularProgressContainerStyles}>
            <div style={TitleStyles}>
                {title}
                <div style={DescriptionStyles}>
                    {description}
                </div>
            </div>
            <CircularProgress
                sx={CircularProgressStyles}
                variant="determinate"
                size={size}
                thickness={2}
                value={progress} />
            <CircularProgress
                sx={CircularProgressBackground}
                variant="determinate"
                disableShrink 
                size={size}
                thickness={2}
                value={100} />
        </Box>
    )
}