import Dexie, { Table } from 'dexie'

export type ITaskStat = {
  id?: number
  taskId: number
  time?: number
  mistakes?: number
  modifier?: number
  timestamp: number
  percents?: number
}

export type ITaskState = {
  taskId: number
  timestamp: number
  spacedRepetitionGroup: number
  targetProgress: number
  progress: number
  // номер волны обхода графа
  traversalNumber: number
  // TODO добавить в бд и в функцию сохранения
  attempts?: number
  // TODO создать отдельную сущность группы со своим прогрессом и мб своей логикой подсчёта
  groupProgress: number
}

export type ITaskStatStandart = {
  taskId?: number
  time: number[]
  mistakes?: number[]
  percents?: number
}

export type ITask = {
  id?: number
  uniqName: string
  description: string
}

class StatsDB extends Dexie {
  public tasksStats!: Table<ITaskStat, number> // id is number in this case
  public tasksStates!: Table<ITaskState, number> // id is number in this case

  public constructor() {
    super('TasksStatsDB')
    this.version(20)
      .stores({
        tasksStats: '++id,taskId,time,mistakes,percents,modifier,timestamp',
        tasksStates:
          '&taskId,timestamp,attempts,traversalNumber,progress,spacedRepetitionGroup,targetProgress,groupProgress',
      })
      .upgrade((tx) => {
        tx.table('tasksStates').clear()
        tx.table('tasksStats').clear()
      })
  }
}

// есть варик сделать отдельные таблицы по статистике для разных режимов пианинки
// но какие могут быть проблемы

export const db = new StatsDB()

export const isDbReady = new Promise((res) => db.on('ready', res))

db.open()
