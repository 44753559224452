import { useStore } from 'effector-react'
import { $mistakesSum } from 'model/model'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;

  background-color: red;
  pointer-events: none;
`

export const ErrorShutter = () => {
  const appRef = useRef<HTMLDivElement>(null)
  const mistakesSum = useStore($mistakesSum)

  useEffect(() => {
    if (mistakesSum && appRef.current) {
      appRef.current.animate(
        [{ opacity: 0, easing: 'ease-out' }, { opacity: 0.3, easing: 'ease-in' }, { opacity: 0 }],
        {
          duration: 300,
          easing: 'ease-in-out',
        },
      )
    }
  }, [mistakesSum, appRef])

  return <Container ref={appRef}></Container>
}
