import { WebApp } from '@grammyjs/web-app'
import { Event, createEffect, sample } from 'effector'

export const track = async (name: string, b?: any) => {
  if (import.meta.env.MODE === 'development') {
    console.log('track', name, b)
    return Promise.resolve()
  }

  const { track: trackAmpl } = await import(`@amplitude/analytics-browser`)

  const res = trackAmpl(name, b)
  return res.promise
}

export const trackFx = createEffect(({ name, params }: { name: string; params?: any }) => {
  track(name, params)
})

export const bindEventToAmplitude = (event: Event<any>, eventName: string) => {
  sample({
    clock: event,
    fn: (params) => ({ name: eventName, params }),
    // fn: (params) => ({ name: event.shortName, params }),
    target: trackFx,
  })
}

export const initAmplitude = async () => {
  if (import.meta.env.MODE === 'development') return

  const {
    init,
    Identify,
    identify,
    track: trackAmpl,
  } = await import(`@amplitude/analytics-browser`)

  const userInfo = WebApp.initDataUnsafe.user
  const urlParams = new URLSearchParams(window.location.search)
  const from = urlParams.get('from')

  init('8beefbd8aba420b0f44c374810250056', userInfo?.id ? `tg-${userInfo.id}` : undefined)

  if (userInfo) {
    const id = new Identify()
    id.set('tg_username', userInfo.username)
    id.set('tg_id', userInfo.id)
    identify(id)
  }

  const initParams: Record<string, any> = {}

  if (from) initParams.from = from

  trackAmpl('init', { from })
}
