import { ReactNode, useEffect } from 'react'
import { Layout } from './Layout'
import Portal from './Portal'
import { useMount } from './useMount'
import { trackFx } from 'utils/amplitude'
import { toggleConfetti } from 'model/ui'
import { startTimer, stopTimer } from 'features/timer/model'
import { usePrevious } from 'hooks/usePrevious'

export const PopupWindow = ({
  isOpen,
  children,
  mesToAmpl,
  confettiActive = false,
}: {
  isOpen: boolean
  children: ReactNode
  mesToAmpl?: string
  confettiActive?: boolean
}) => {
  const { mounted } = useMount({ isOpen })
  const prevIsOpen = usePrevious(isOpen)

  useEffect(() => {
    if (isOpen) {
      stopTimer()
      if (mesToAmpl) {
        trackFx({ name: mesToAmpl })
      }
      if (confettiActive) {
        toggleConfetti(true)
      }
    } else if(prevIsOpen) {
      startTimer()
    }
  }, [isOpen, prevIsOpen])

  if (!mounted) {
    return null
  }

  return (
    <Portal>
      <Layout isOpen={isOpen}>{children}</Layout>
    </Portal>
  )
}
