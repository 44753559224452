import { useStore } from 'effector-react'
import { BASE_NOTES, NAME_BUTTON_NOTE, RU_NOTES } from '../constants'
import { $isNotesOnKeysVisible } from 'model/scores'
import { PianoKeyboardWrapper, PianoKeyboardContainer, PianoKey } from 'components/ButtonsKeyboard'
import { isRuNotesMode } from 'model/ui'
import { useWindowSize } from 'hooks/useWindowSize'

export const PianoKeyboard = () => {
  const size = useWindowSize()
  const isNotesOnKeysVisible = useStore($isNotesOnKeysVisible)
  const isRuNotesNames = useStore(isRuNotesMode.$val)
  const NOTES = BASE_NOTES
  const NOTES_LABELS = isRuNotesNames ? RU_NOTES : BASE_NOTES

  const isTwoOctavKeyboard = size.width > size.height

  return (
    <PianoKeyboardWrapper className="first-step">
      <PianoKeyboardContainer isZoomed={size.width > size.height && size.height < 600}>
        {[...(isTwoOctavKeyboard ? NOTES.map((n) => `${n},`) : []), ...NOTES].map((name, index) => (
          <PianoKey
            isTwoOctavKeyboard={isTwoOctavKeyboard}
            isNotesOnKeysVisible={isNotesOnKeysVisible}
            key={name}
            name={name}
            label={NOTES_LABELS[index % NOTES_LABELS.length]}
            keyName={NAME_BUTTON_NOTE[index % NAME_BUTTON_NOTE.length]}
            isRuNotes={isRuNotesNames}
          />
        ))}
      </PianoKeyboardContainer>
    </PianoKeyboardWrapper>
  )
}
