import { useStore } from 'effector-react'
import { usePrevious } from 'hooks/usePrevious'
import { $currentTaskIndex } from 'model/model'
import { $scores } from 'model/scores'
import { memo, useEffect, useState } from 'react'
import { AiOutlineInfoCircle } from '@react-icons/all-files/ai/AiOutlineInfoCircle'
import styled, { keyframes } from 'styled-components'
import { enqueueSnackbar } from 'utils/effector'
import { isDeveloperModeToggle } from 'model/ui'
import { Statistics } from 'features/statistic/Statistics'

const ScoreNumber = styled.div`
  color: #776e65;
  font-size: 46px;
  font-weight: 300;
  margin: 0;
  display: flex;
  align-items: baseline;
  float: left;
  position: absolute;
  top: 0;
  user-select: none;
  z-index: 1;
  cursor: pointer;

  .info-icon {
    position: absolute;
    right: -16px;
    top: 10px;
    font-size: 18px;
  }
`

const appear = keyframes`
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const AddPart = styled.span<{ isNegative: boolean }>`
  opacity: 0;
  font-size: 18px;
  margin-left: 4px;
  font-weight: 600;
  color: ${(p) => (p.isNegative ? 'red' : '#28c38a')};
  animation: ${appear} 2.5s ease-in-out;
  position: absolute;
  top: 50%;
`

const getFirstAfterDot = (n: number) =>
  `${Math.floor(n * 100) - Math.floor(n) * 100}`.padEnd(2, '0')

const AnimatedAddPart = memo(() => {
  const scores = useStore($scores)
  const prevScores = usePrevious(scores)
  const diff = Math.floor((scores - prevScores) * 100) / 100

  const hasShowDiff = $currentTaskIndex.getState() > 1 && !!diff && Math.abs(diff) >= 0.01

  return hasShowDiff ? (
    <div>
      <AddPart isNegative={diff < 0} key={Math.random()}>
        {diff > 0 && '+'}
        {diff}
      </AddPart>
    </div>
  ) : null
})

AnimatedAddPart.displayName = 'AnimatedAddPart'

export const Scores = () => {
  const [renderedScores, setRenderedScores] = useState(0)
  const scores = useStore($scores)
  const isDevModeEnabled = useStore(isDeveloperModeToggle.$val)

  // TODO move to component "плавно изменяющиеся цифры"
  // TODO optimize, clear interval after complete
  useEffect(() => {
    const interval = setInterval(() => {
      setRenderedScores((renderedScores) =>
        renderedScores < scores
          ? Math.min(renderedScores + Math.max((scores - renderedScores) / 10, 0.2), scores)
          : Math.max(renderedScores + Math.min((scores - renderedScores) / 10, -0.2), scores),
      )
    }, 1000 / 30)

    return () => clearInterval(interval)
  }, [scores, setRenderedScores])

  const showStatistics = () => {
    if (!isDevModeEnabled) {
      enqueueSnackbar('подробная статистика в разработке :)')
    } else if (scores < 10) {
      enqueueSnackbar('подробная статистика откроется после прогресса в 10% :)')
    }
  }

  return (
    <ScoreNumber className='scores' onClick={showStatistics}>
      <AiOutlineInfoCircle className="info-icon" />
      {isDevModeEnabled && scores > 10 && <Statistics />}
      {Math.floor(renderedScores)}
      <span className="text-3xl">.{getFirstAfterDot(renderedScores)}%</span>
      <AnimatedAddPart />
    </ScoreNumber>
  )
}
