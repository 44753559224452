import 'utils/initSentry'
import './i18n'
import ReactDOM from 'react-dom'
import { SnackbarProvider } from 'notistack'
import App from './App'
import { EffectorSnackbarProvider } from 'components/EffectorSnackbarProvider'
import { StyleSheetManager } from 'styled-components'

import 'features/statistic/model'
import { TourProviderWrapper } from 'features/onboarding/TourProviderWrapper'
import { ModalWindows } from 'components/ModalWindows/modalWindows'

// TODO
const isDev = true

const rootElement = document.getElementById('root')
ReactDOM.render(
  <StyleSheetManager disableVendorPrefixes={isDev}>
    <SnackbarProvider
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      dense
      autoHideDuration={1500}
      maxSnack={2}
    >
      <EffectorSnackbarProvider />
      <TourProviderWrapper>
        <ModalWindows />
        <App />
      </TourProviderWrapper>
    </SnackbarProvider>
  </StyleSheetManager>,
  rootElement,
)
