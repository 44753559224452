import { useState, useEffect, useRef } from 'react'
import { handleButtonClick, handleButtonClickOnPhone } from './auxiliaryFn'
import styled, { keyframes, css } from 'styled-components'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Portal } from '@mui/material'
import { F } from 'lodash/fp'
import { getIsMobile } from 'hooks/useIsMobile'

type StylesType = {
  isOpen: boolean
  width: string
  height: string
  transform: string
  position: string
}

const BUTTON_SIZE = '44px'
const DURATION = 0.3
const ModalContainer = styled.div<StylesType>`
  position: fixed;
  /* position: ${(props) => props.position}; не рабоатет в веб в дебаг */
  width: ${(props) => (props.isOpen ? props.width : BUTTON_SIZE)};
  height: ${(props) => (props.isOpen ? props.height : BUTTON_SIZE)};
  background-color: ${(props) => (props.isOpen ? 'white' : 'transparent')};
  border-radius: ${(props) => (props.isOpen ? '10px' : '100%')};
  margin: 15px;
  box-shadow: ${(props) => (props.isOpen ? 'rgba(0, 0, 0, 0.16) 0px 1px 4px;' : 'none')};

  transition: ${(props) =>
    props.isOpen
      ? `all ${DURATION}s ease-out, border-radius ${DURATION / 4}s, background-color ${
          DURATION / 3
        }s ease-out `
      : `all ${DURATION}s ease-in, border-radius ${DURATION / 4}s linear ${
          (DURATION / 4) * 3
        }s, background-color ${DURATION}s cubic-bezier(1,0, 1, 1)`};
  z-index: 10;
  transform: ${(props) => (props.isOpen ? props.transform : 'none')};

  .content {
    opacity: ${(props) => (props.isOpen ? 1 : 0)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    height: 100%;
    width: 100%;
    pointer-events: ${(props) => (props.isOpen ? 'all' : 'none')};
    transition: ${(props) =>
      props.isOpen
        ? `opacity ${DURATION}s linear ${(DURATION / 4) * 3}s`
        : `opacity ${DURATION / 4}s`};
  }
`
const Button = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  background-color: transparent;
`
const fadeIn = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `
const fadeOut = keyframes`
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  `

const ModalContainerBackdrop = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.3);
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  pointer-events: ${(props) => (props.isOpen ? 'all' : 'none')};
  animation: ${(props) =>
    props.isOpen
      ? css`
          ${fadeIn} ${DURATION}s ease
        `
      : css`
          ${fadeOut} ${DURATION}s ease
        `};
  z-index: 10;
`

type AssistantProps = {
  children: React.ReactNode
  width?: number
  height?: number
}

export const AssistantButton: React.FC<AssistantProps> = ({ children, width, height }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [styles, setStyles] = useState({})
  const buttonRef = useRef(null)

  useEffect(() => {
    const isMobile = getIsMobile()
    const newStyles = !isMobile
      ? handleButtonClick(buttonRef, width, height)
      : handleButtonClickOnPhone(buttonRef)
    setStyles(newStyles)
  }, [isModalOpen])

  const handleClick = () => {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <Button onClick={isModalOpen ? F : handleClick}>
      <ModalContainerBackdrop onClick={handleClick} isOpen={isModalOpen} />
      <ModalContainer
        ref={buttonRef}
        transform={styles?.transform}
        width={styles?.width}
        height={styles.height}
        // position={styles?.position}
        isOpen={isModalOpen}
        id="addButton"
        // onClick={handleClick}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
            display: `${isModalOpen ? '' : 'none'}`,
          }}
          onClick={handleClick}
        >
          <CloseIcon />
        </IconButton>
        <div className="content">{children}</div>
      </ModalContainer>
    </Button>
  )
}
