import { createEvent, createStore, guard, sample } from 'effector'
import { persist } from 'effector-storage/local'
import { F, T } from 'lodash/fp'
import { $isChordsKeyboardsActive, init, resolveFx } from 'model/model'
import { StepType } from '@reactour/tour'
import {
  advancedAfterFirstTask,
  beginnerAfterFirstTask,
  chordsOnboardingSteps,
  onboardingWithUnselectedLevel,
} from './onboardingSteps'
import { resolveTaskFx } from 'statistics/statistics'
import { isHardModeMistakesToggle } from 'model/ui'
import { trackFx } from 'utils/amplitude'

const WELCOME_MODAL_PASSED_KEY = 'WELCOME_MODAL_PASSED'
const SKILL_LEVEL_KEY = 'SKILL_LEVEL'
const AFTER_TASK_ONBOARDING_PASSED_KEY = 'AFTER_TASK_ONBOARDING_PASSED'

export const updateSteps = createEvent<StepType[]>()
export const $steps = createStore<StepType[]>([]).on(updateSteps, (_, val) => val)

export const nextStepOnboarding = createEvent()
export const $clockValForNextStep = createStore(0).on(nextStepOnboarding, (s) => s + 1)

export const openOnboarding = createEvent()
export const closeOnboarding = createEvent()
export const $isOnboardingOpened = createStore<boolean>(false)
  .on(openOnboarding, T)
  .on(closeOnboarding, F)

export const openWelcomeModal = createEvent()
export const closeWelcomeModal = createEvent()
export const setWelcomeOnboardingPassed = createEvent()
export const $isWelcomeModalOpened = createStore<boolean>(false)
  .on(openWelcomeModal, T)
  .on(closeWelcomeModal, F)

export const $wasWelcomePassed = createStore<boolean>(false).on(setWelcomeOnboardingPassed, T)

const afterFirstTaskOnboardingPassed = createEvent()
export const $wasAfterFirstTaskOnboardingShown = createStore<boolean>(false).on(
  afterFirstTaskOnboardingPassed,
  T,
)

export const updateSkillLevel = createEvent<number>()
export const $skillLevel = createStore<number | null>(null).on(updateSkillLevel, (_, v) => v) // при обновлении этого стора вызвать ивент который обновит steps с нужными значениями

persist({ store: $wasWelcomePassed, key: WELCOME_MODAL_PASSED_KEY })
persist({ store: $skillLevel, key: SKILL_LEVEL_KEY })
persist({ store: $wasAfterFirstTaskOnboardingShown, key: AFTER_TASK_ONBOARDING_PASSED_KEY })

sample({
  clock: setWelcomeOnboardingPassed,
  fn: () => ({ name: 'endWelcomeOnboarding' }),
  target: trackFx,
})

sample({
  source: $wasWelcomePassed,
  clock: init,
  filter: (wasWelcomePassed) => !wasWelcomePassed,
  target: openWelcomeModal,
})

sample({
  clock: updateSkillLevel,
  target: closeWelcomeModal,
})

sample({
  clock: openWelcomeModal,
  fn: () => onboardingWithUnselectedLevel,
  target: updateSteps,
})

sample({
  source: { $wasAfterFirstTaskOnboardingShown, skillLevel: $skillLevel },
  clock: guard(resolveTaskFx, { filter: $wasAfterFirstTaskOnboardingShown.map((val) => !val) }),
  target: afterFirstTaskOnboardingPassed,
})

sample({
  source: $skillLevel,
  clock: afterFirstTaskOnboardingPassed,
  fn: ($skillLevel) => (!$skillLevel ? beginnerAfterFirstTask : advancedAfterFirstTask),
  target: updateSteps,
})

sample({
  source: $skillLevel,
  filter: ($skillLevel) => $skillLevel === 0,
  fn: F,
  target: isHardModeMistakesToggle.toggle,
})

sample({
  source: $skillLevel,
  filter: ($skillLevel) => $skillLevel !== 0,
  fn: T,
  target: isHardModeMistakesToggle.toggle,
})

sample({
  clock: afterFirstTaskOnboardingPassed,
  fn: T,
  target: isHardModeMistakesToggle.toggle,
})

sample({
  clock: resolveFx,
  filter: $isOnboardingOpened,
  target: nextStepOnboarding,
})

const isChordsOnboardingPassedKey = 'isChordsOnboardingPassed'
export const chordsOnboardingPassed = createEvent()
const $isChordsOnboardingPassed = createStore(false).on(chordsOnboardingPassed, T)

persist({ store: $isChordsOnboardingPassed, key: isChordsOnboardingPassedKey })

sample({
  source: { $isChordsKeyboardsActive, $isChordsOnboardingPassed },
  clock: [$isChordsKeyboardsActive, init],
  filter: (p) => p.$isChordsKeyboardsActive && !p.$isChordsOnboardingPassed,
  fn: () => chordsOnboardingSteps,
  target: updateSteps
})

