import styled from "styled-components"
import { QuintoQuarticCircle, innerQuintoQuarticCircleNotes, outerQuintoQuarticCircleNotes } from "./QuintoQuarticCircle"
import { useState } from "react"

const QuintoQuarticWrapper = styled.div`
  margin: 0 25% 5rem;
  padding: 10rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const QuintoQuartic = () => {
    const [selectedNote, setSelectedNote] = useState('')
    const onNoteSelect = (note: string): void => {
        console.log('selected', note)
        setSelectedNote(note)
    }
    return (
        <QuintoQuarticWrapper>
            <QuintoQuarticCircle
                buttons={outerQuintoQuarticCircleNotes}
                rad={150}
                onSelect={onNoteSelect}
                selectedNote={selectedNote}
            />
            <QuintoQuarticCircle
                buttons={innerQuintoQuarticCircleNotes}
                rad={75}
                onSelect={onNoteSelect}
                selectedNote={selectedNote}
            />
        </QuintoQuarticWrapper>
    )
}