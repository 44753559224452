import { WebApp } from '@grammyjs/web-app'

const initSentry = async () => {
  if (import.meta.env.PROD) {
    const Sentry = await import(`@sentry/react`)
    const { httpClientIntegration } = await import('@sentry/integrations')

    Sentry.setUser({
      id: WebApp?.initDataUnsafe?.user?.id,
    })

    Sentry.init({
      dsn: 'https://2eedff35304d4d55b53ddcd41f1a283c@o238767.ingest.sentry.io/4505528971755520',
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ['localhost', 'https:yourserver.io/api/'],
        }),
        httpClientIntegration(),
        // если добавылять то только динамически
        // new Sentry.Replay(),
      ],
      sendDefaultPii: true,
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  }
}

initSentry()
