import { Tooltip } from '@mui/material'
import { attach, createEvent, createStore, sample } from 'effector'
import { useStore } from 'effector-react'
import { F, T } from 'lodash/fp'
import { $isConfettiShown, toggleConfetti } from 'model/ui'
import { useEffect, useRef } from 'react'
import Confetti from 'react-confetti'
import styled from 'styled-components'
import { generateTask } from './utils'

const AnswerInput = styled.input<{ error: boolean; transitionDelay: boolean }>`
  transition-delay: ${(p) => (p.transitionDelay ? '0.4s' : 0)};
  transition-property: background-color;
  transition-duration: 0.4s;
  background-color: ${(p) => (p.error ? 'rgba(255, 0, 0, 0.5)' : 'transparent')};
`

const Container = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  flex-grow: 1;

  .content {
    width: 320px;
  }

  input {
    width: 120px;
    border-radius: 4px;
    border: 1px solid grey;
  }

  .red {
    color: red;
  }

  .help {
    filter: grayscale(0.5);
  }
`

const onChangeAnswer = createEvent('onChangeAnswer')
const next = createEvent('next')
const showHint = createEvent('showHint')
const $isHintShowed = createStore(false).on(next, F).on(showHint, T)
const $currentTask = createStore(generateTask()).on(next, () => generateTask())
const $answerInput = createStore('')
  .on(onChangeAnswer, (_, val) => val)
  .on(next, () => '')

const enterFx = attach({
  source: $isHintShowed,
  effect: (isHintShowed) => {
    if (isHintShowed) next()
    else showHint()
  },
})

const checkAnswerFx = attach({
  source: { $currentTask, $answerInput },
  effect: (p) => {
    if (p.$answerInput == p.$currentTask.answer) {
      toggleConfetti(true)
      console.log('Молодец!')
      next()
    }
  },
})

sample({ clock: onChangeAnswer, target: checkAnswerFx })

const getCard = (name) => (
  <span>
    {{ T: 10 }[name[0]] || name[0]}
    {
      { s: '♠', h: <span className="red">♥</span>, d: <span className="red">♦</span>, c: '♣' }[
        name[1]
      ]
    }{' '}
  </span>
)

export const Poker = () => {
  const inputRef = useRef<HTMLInputElement>(null)
  const task = useStore($currentTask)
  const isConfettiShown = useStore($isConfettiShown)
  const answer = useStore($answerInput)
  const isHintShowed = useStore($isHintShowed)

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'ArrowRight') next()
      else if (e.key === 'Enter') enterFx()
      else inputRef.current?.focus()
    }

    document.addEventListener('keydown', listener)

    return () => document.removeEventListener('keydown', listener)
  }, [])

  const renderedAnswer = isHintShowed ? task.answer : answer

  return (
    <Container>
      <div className="content">
        <div>Рука: {task.hand.map(getCard)}</div>
        <div>Стол: {task.table.map(getCard)}</div>
        <div className="mt-6">
          Ауты:{' '}
          <AnswerInput
            transitionDelay={
              answer.length > 0 &&
              renderedAnswer != task.answer &&
              `${renderedAnswer}`?.length < `${task.answer}`?.length
            }
            error={answer.length > 0 && renderedAnswer != task.answer}
            ref={inputRef}
            pattern="\d*"
            className="pl-2"
            value={renderedAnswer}
            placeholder="..."
            onChange={(e) => onChangeAnswer(e.target.value)}
          ></AnswerInput>{' '}
          <Tooltip title="Press Enter">
            <button className="help w-12" onClick={isHintShowed ? next : showHint}>
              {isHintShowed ? '➡️' : '💡'}
            </button>
          </Tooltip>
        </div>
        <div className="h-2">{isHintShowed && task.outsCards.map(getCard)}</div>
      </div>
      {isConfettiShown && (
        <Confetti
          onConfettiComplete={() => toggleConfetti(false)}
          numberOfPieces={200}
          recycle={false}
          width={window.innerWidth}
          height={window.innerHeight}
          gravity={0.3}
        />
      )}
    </Container>
  )
}
