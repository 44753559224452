import { useStore } from 'effector-react'
import { isArray } from 'lodash/fp'
import styled from 'styled-components'
import { useUpdateEffect } from 'usehooks-ts'
import { enqueueSnackbar, ToggleModel } from 'utils/effector'

type IProps = { active: boolean; label: any }
export const EmojiButton = styled.button.attrs((props: IProps) => ({
  children: props.label,
  // onClick: () => console.log(666) || props.onClick(),
}))<IProps>`
  background: transparent;
  opacity: ${(p) => (p.active ? 1 : 0.5)};
  width: calc(20vw - 2px);
  max-width: 60px;
  height: 20vw;
  max-height: 60px;
  margin: 1px;
  padding: 0;
  font-size: 45px;
  overflow: visible;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  border: 0px;
  position: relative;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background: transparent;
  }

  sup {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 25px;
  }
`

export const ToggleButton = ({
  model,
  title,
  label,
  className,
}: {
  className: string
  title?: string[] | string
  model: ToggleModel
  label: any | any[]
}) => {
  const val = useStore(model.$val)
  const currentIndex = model.values.indexOf(val)
  const activeLabel = isArray(label) ? label[currentIndex] : label

  useUpdateEffect(() => {
    if (title) {
      enqueueSnackbar(
        isArray(title) ? title[currentIndex] : `${title} ${['on', 'off'][currentIndex]}`,
      )
    }
  }, [val])

  return (
    <EmojiButton
      className={className}
      label={activeLabel}
      title={title}
      active={val}
      onClick={() => {
        model.toggle()
      }}
    />
  )
}
