import { createEvent, createStore, sample } from 'effector'
import { createGate } from 'effector-react'
import { omit } from 'lodash/fp'
import { initMidi } from 'utils/midi'
import { cacheMidi } from './sound'

export const initGate = createGate('initApp')

export const onKeydownDurty = createEvent<KeyboardEvent>('keydownDurty')
export const onKeydown = createEvent<KeyboardEvent['code']>('keydown')
export const onKeyupDurty = createEvent<KeyboardEvent>('onkeyupDurty')
export const onKeyup = createEvent<KeyboardEvent['code']>('onkeyup')


// получать отдельно дату и отдельно число чтоб убрать json parse
export const handleIsAppActive = createEvent<boolean>('handleIsAppActive')
export const $isAppActive = createStore<boolean>(false).on(handleIsAppActive, (_, val) => val)

export const $pressedKeys = createStore<{ [key: string]: number }>({})
  .on(onKeydown, (state, code) => ({ ...state, [code]: Date.now() }))
  .on(onKeyup, (state, code) => omit(code, state))

sample({
  clock: onKeydownDurty,
  source: $pressedKeys,
  filter: (pressedKeys, { code }) => !pressedKeys[code],
  fn: (_, e) => e.code,
  target: onKeydown,
})

sample({
  source: onKeyupDurty,
  fn: (e) => e.code,
  target: onKeyup,
})

initGate.open.watch(() => {
  handleIsAppActive(true)

  cacheMidi()
  initMidi()

  document.addEventListener('keydown', onKeydownDurty)
  document.addEventListener('keyup', onKeyupDurty)
})

initGate.close.watch(() => {
  document.removeEventListener('keydown', onKeydownDurty)
  document.removeEventListener('keyup', onKeyupDurty)
})

document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible') {
    handleIsAppActive(true)
    console.log($isAppActive.getState(), 'app is active and visible')
  } else {
    handleIsAppActive(false)
    console.log($isAppActive.getState(), 'app is not active')
  }
})

// https://richtr.github.io/NoSleep.js/example/
// const noSleep = new NoSleep()

// document.addEventListener(
//   'click',
//   function enableNoSleep() {
//     alert(1)
//     document.removeEventListener('click', enableNoSleep, false)
//     noSleep.enable()
//   },
//   false,
// )
