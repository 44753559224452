import { Button, Typography } from '@mui/material'
import styled from 'styled-components'
import {
  $currentTimer,
  $isFirstTaskModalOpened,
  $streakDays,
  closeSuccessFirstTask,
} from '../../features/timer/model'
import { useStore } from 'effector-react'
import { Trans } from 'react-i18next'
import { PopupWindow } from 'components/PopupWindow/PopupWindow'

const AchievementStreak = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 20px;
  text-align: center;
`

export const PraiseAfterFirstTask = () => {
  const streakDays = useStore($streakDays)
  const isFirstTaskSuccessModal = useStore($isFirstTaskModalOpened)
  const currentTimeInSeconds = useStore($currentTimer)

  return (
    <PopupWindow isOpen={isFirstTaskSuccessModal} mesToAmpl='showAfterFirstTaskModal'>
      <div style={{ fontSize: 40, textAlign: 'center' }}>🚀</div>
      <Typography variant="body1">
        Отлично! Вы решили первое задание за сегодня, осталось позаниматься{' '}
        <b>
          меньше <Trans count={Math.floor(currentTimeInSeconds / 60) + 1}>less_minutes</Trans>
        </b>{' '}
        чтобы не терять прогресс
      </Typography>
      {/* <AchievementStreak>
        Твоя полоса <Trans count={streakDays}>days</Trans>
      </AchievementStreak> */}
      <Button
        sx={{ mt: 2, mb: 2 }}
        size="large"
        variant="contained"
        color="success"
        onClick={() => {
          closeSuccessFirstTask()
        }}
      >
        Я смогу 💪
      </Button>
    </PopupWindow>
  )
}
