import { BuySubscribeContent } from "./BuySubscribeContent"
import { FiveFailedTasksContent } from "./FiveFailedTasksContent"
import { PraiseAfterFirstTask } from "./PraiseAfterFirstTask"
import { PraiseContent } from "./PraiseContent"

export const ModalWindows = () => {


    return (
        <>
            <PraiseContent />
            <BuySubscribeContent />
            <PraiseAfterFirstTask />
            <FiveFailedTasksContent />
        </>
    )
}