import { replace } from 'lodash/fp'
import { Chord, Note } from '@tonaljs/tonal'
import { scientificToAbcNotation } from '@tonaljs/abc-notation'

export const tonalToAbc = (note: string) =>
  scientificToAbcNotation(note).replace(/[a-g]/g, (match) => match.toUpperCase() + `'`)

export const getChordName = (notes: string[]) =>
  Chord.detect(notes.map(replace(/[',]/g, '')))[0].replace('M', '')

export const getCleanChordName = (notes: string[]) => {
  const chords = Chord.detect(notes.map(replace(/[',]/g, '')))
  return chords
    ?.find((c) => !/\#5/.test(c))
    ?.replace?.('M', '')
    .replace?.(/\/.+/, '')
}

export const getChordNotes = (chord: string) =>
  Chord.chord(chord)
    ?.notes.map((note) => (note.includes('b') ? Note.enharmonic(note) : note))
    .map((note) => tonalToAbc(`${note}4`))
