import { keys, pipe, values, times, range, last } from 'lodash/fp'
import { createEffect } from 'effector'
import { isArray } from 'lodash/fp'
import { WebApp } from '@grammyjs/web-app'
import dayjs from 'dayjs'

export const pause = (time: number) => new Promise((res) => setTimeout(res, time))

export const deepMap =
  <T extends Function>(mapper: T) =>
  (array: Array<any>): Array<any> => {
    return array.map((el) => (isArray(el) ? deepMap(mapper)(el) : mapper(el)))
  }

export const median = (values: number[]) => {
  if (values.length === 0) throw new Error('No inputs')

  values.sort(function (a, b) {
    return a - b
  })

  const half = Math.floor(values.length / 2)

  if (values.length % 2) return values[half]

  return (values[half - 1] + values[half]) / 2.0
}

export const getTopNIndices = (arr: number[], N: number) => {
  const topIndices = new Array(N).fill(-1)
  const topValues = new Array(N).fill(-Infinity)

  for (let i = 0; i < arr.length; i++) {
    const currentVal = arr[i]
    const minTopValue = Math.min(...topValues)
    const minTopValueIndex = topValues.indexOf(minTopValue)

    if (currentVal > minTopValue) {
      topValues[minTopValueIndex] = currentVal
      topIndices[minTopValueIndex] = i
    }
  }

  topIndices.sort((a, b) => arr[b] - arr[a])

  return topIndices
}

export const getLastKeyFromAnyObj = pipe(keys, last)

export const getDateFromDateStr = (dateStr: string) => {
  const splittedDate = dateStr.split('.')
  const day = parseInt(splittedDate[0])
  const month = parseInt(splittedDate[1]) - 1 // Месяцы начинаются с 0
  const year = parseInt(splittedDate[2])
  const date = new Date(year, month, day)
  return date
}

export const DATE_FORMAT = 'YYYY-MM-DD'
export const getISODate = (...value) => dayjs(new Date(...value)).format(DATE_FORMAT)
export const getISODateNow = () => dayjs().format(DATE_FORMAT)

// вернет обьект с промежуточными датами и значениями 0
//identifyMissingDays("11.09", "14.09") => {"12.09": 0, "13.09": 0}
export const identifyMissingDays = (startDateStr: string, endDateStr: string, value = 0) => {
  // Создаем объекты Date для начальной и конечной дат
  const startDate = getDateFromDateStr(startDateStr)
  const endDate = getDateFromDateStr(endDateStr)

  // Создаем объект для хранения промежуточных дат
  const intermediateDates: { [x: string]: number } = {}

  // Устанавливаем текущую дату на начальную дату и итерируемся до конечной даты
  const currentDate = new Date(startDate)
  currentDate.setDate(currentDate.getDate() + 1) // Начинаем со следующей даты после начальной

  while (currentDate < endDate) {
    const currentDay = currentDate.getDate().toString().padStart(2, '0')
    const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0')
    const currentYear = currentDate.getFullYear()
    const currentDateStr = `${currentDay}.${currentMonth}.${currentYear}`

    intermediateDates[currentDateStr] = value

    currentDate.setDate(currentDate.getDate() + 1)
  }
  return intermediateDates
}

export const isAppInTelegram = () => {
  return !!WebApp.initData
}

export const hapticFeedbackError = createEffect(() => {
  if (isAppInTelegram()) {
    WebApp.HapticFeedback.notificationOccurred('error')
  }
})

export const getTgSafeAreaBottom = () => {
  return isAppInTelegram() ? window.innerHeight - WebApp?.viewportHeight : 0
}
