import { Button, Typography } from '@mui/material'
import { $isFiveTasksFailedModalOpen, resetFiveFailedTask } from '../../features/timer/model'
import { useStore } from 'effector-react'
import { PopupWindow } from 'components/PopupWindow/PopupWindow'

export const FiveFailedTasksContent = () => {
  const isFiveTasksFailedModalOpen = useStore($isFiveTasksFailedModalOpen)

  return (
    <PopupWindow isOpen={isFiveTasksFailedModalOpen} mesToAmpl='showFiveTasksFailedModal'>
      <div style={{ fontSize: 40, textAlign: 'center' }}>🎓</div>
      <Typography variant="body1">
        Ошибки — часть обучения, но нужно пройти хотя бы одно задание сегодня, чтобы был прогресс 😉
      </Typography>
      {/* TODO может несколько кнопок добавить, типо "я не расстраиваюсь", хотелось бы задания попроще */}
      <Button
        sx={{ mt: 2, mb: 2 }}
        size="large"
        variant="contained"
        color="success"
        onClick={() => resetFiveFailedTask()}
      >
        Сделать это 🎯
      </Button>
    </PopupWindow>
  )
}
