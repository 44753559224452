import { useStore } from 'effector-react'
import { $predicatedNotes } from 'utils/pitchdetect/pitchdetect'
import { $currentTask } from 'model/model'

export const DebugTop = () => {
  const currentTask = useStore($currentTask)
  const predicatedNotes = useStore($predicatedNotes)

  return (
    <div className="absolute top-4 left-4">
      {((currentTask?.progress * 100) >> 0) / 100} /{' '}
      {((currentTask?.targetProgress * 100) >> 0) / 100} {currentTask?.attempts}{' '}
      {currentTask?.traversalNumber} {predicatedNotes.join('|')}
    </div>
  )
}
