import { median } from 'utils/utils'

import { db, ITaskStatStandart, ITaskStat, ITaskState, isDbReady } from './db'
import { ITasksUniqName } from './types'
import { isNumber, last, sum, values } from 'lodash/fp'
import { track } from 'utils/amplitude'
import { parseTimeString } from './utils/parseTimeString'
import {
  AdjacencyList,
  getSimilarTasks,
  ITaskType,
  TaskId,
  tasksData,
  TASK_TYPES,
  getRootId,
} from './tasksUniqNames'
import DexieKeyValueSyncCache from 'utils/DexieKeyValueSyncCache'
import { ITask } from 'types'
import { toggleConfetti } from 'model/ui'
import { createEffect, createEvent, createStore, sample } from 'effector'
import { $isCurrentTaskLocked } from 'features/tasks-controls/model'
import { taskResolved } from './model.events'
import { saveProgress } from 'api'

export const TARGET_PROGRESS_ADD = 0.05
export const MIN_TARGET_PROGRESS = 0.1

let mainGroupTasksIds = tasksData[TASK_TYPES.SCREEN_KEYBOARD].mainGroupTasksIds

// const interval = luxon.Interval.fromISODuration(`P${timeString.replace(/ /g, 'T')}`);
// const milliseconds = interval.toMillis();
// console.log(milliseconds); // выведет 283020000 (3 дня + 3 часа + 2 минуты + 2 секунды в миллисекундах)

// TODO возможно в математике это тоже пригодится, у меня сейчас чередование одинаковых нот
// а в математике может быть чередование одинаковых заданий
// и два задания на сложение подряд будут решаться хуже чем сложение-умножение-сложение

// сейчас пока вижу два варик
// ПРОСТОЙ(возможно): для каждого задания сделать коеффициент, например в простом задании - прогресс каждой ноты будет умножаться на 0.5
// СЛОЖНЫЙ: для каждого задания тоже задать айдишник, и для каждой ноты записывать стату с учётом айдишника задания
//   и похожие по сложности задания объединять в группы

// при генерации заданий давать каждому айдишник

// учитывать время между нажатиями одной ноты

let lastTasksStatsSumCache: number

let traversalNumber: number

export const getTraversalNumber = () => traversalNumber
export const setTraversalNumber = (val: number) => {
  traversalNumber = val
}

export const getCurrentRootTaskId = (taskId: TaskId): TaskId => getRootId(adjacencyList, taskId)

export const getInitTaskState = (taskId: TaskId): ITaskState => ({
  progress: 0,
  groupProgress: 0,
  targetProgress: MIN_TARGET_PROGRESS,
  taskId,
  traversalNumber: 0,
  timestamp: Date.now(),
  spacedRepetitionGroup: 0,
})

let taskStates: DexieKeyValueSyncCache<ITaskState>
export const getTaskState = (taskId: number) => taskStates.get(taskId) || getInitTaskState(taskId)

export const calcTimeProgress = (time: number, standartTime: number[]) => {
  const [start, end] = standartTime
  const normalizedTime = Math.max(Math.min(time, end), start)

  return 1 - (normalizedTime - start) / (end - start)
}

// TODO
export const calcTimeByTimeProgress = (progress: number, partsCount: number) => {
  const [start, end] = getTaskStandard().time.map((t) => t * partsCount)

  const time = (1 - progress) * (end - start) + start

  return time
}

export const calcProgress = (stat: ITaskStat, standart: ITaskStatStandart): number => {
  // TODO доработать алгоритм подсчёта
  // TODO тесты что значение не может быть больше 1 и меньше 0
  // TODO задавать минимальное и максимальное время в стандарте
  // TODO мб задавать интервалы [100, 500, 1000, 5000] для нелинейной сложности

  if (stat.percents) {
    return stat.percents
  }

  const modifier = stat.modifier || 1

  return (
    (calcTimeProgress(stat.time, standart.time) / 2 +
      (1 - Math.min(stat.mistakes || 0, 2) / 2) / 2) *
    modifier
  )
}

export const getProgressSpeed = (progress: number[]) =>
  progress.reduce((sum, current, i) => (i > 0 ? sum + current - progress[i - 1] : 0), 0)

const medianProgress = (arr: number[]): number => (arr.length ? median(arr) : 0)

// TODO test intervals
// TODO test with small intervals
// чем больше будет заданий, тем точнее нужно выбирать текущий интервал, чтобы все задания не частились
const SPACED_REPETITION_INTERVALS = '10m|30m|1h|8h|1d|2d|5d|10d|20d|30d'.split('|')
const getDurationForSpacedRepetitionGroup = (group: number) =>
  parseTimeString(SPACED_REPETITION_INTERVALS[group] || '90y')

export const getIsStateActual = ({ timestamp, spacedRepetitionGroup }: ITaskState) =>
  timestamp > Date.now() - getDurationForSpacedRepetitionGroup(spacedRepetitionGroup || 0)

// должно принимать старые состояния и возвращать дифф
export const getNewTaskStates = () => {}

const TASK_LEVELS = [
  { noteTime: 2000, mistakes: 0 },
  { noteTime: 1000, mistakes: 0 },
  { noteTime: 700, mistakes: 0 },
  { noteTime: 500, mistakes: 0 },
  // TODO всё таки отдельное задание, чтобы алгоритм мог выбрать что давать ученику по его способностям
  { noteTime: 1500, mistakes: 0, modifier: 'hide1Note' },
]

let saveStates: (states: ITaskState[], statistic: ITaskStat) => void
let adjacencyList: AdjacencyList

const MAX_ATTEMPTS = 7
const GOOD_PROGRESS_DIFF = 0.2

// TODO typefix
export const updateWithSimilarTasks = (taskId: number, update: Partial<ITaskState>) => {
  taskStates.set(taskId, { ...getTaskState(taskId), ...update })

  getSimilarTasks(adjacencyList, taskId)?.forEach((depsTaskId) => {
    if (depsTaskId === taskId) return

    taskStates.set(depsTaskId, { ...getTaskState(depsTaskId), ...update })
  })
}

export const failTaskFx = createEffect(async (taskId: number) => {
  const lastState = getTaskState(taskId)

  let spacedRepetitionGroup = lastState?.spacedRepetitionGroup || 0
  spacedRepetitionGroup = Math.max(0, spacedRepetitionGroup - 1)

  const state: ITaskState = {
    ...lastState,
    timestamp: Date.now(),
    taskId,
    spacedRepetitionGroup,
    attempts: (lastState?.attempts || 0) + 1,
  }

  // TODO доработать, сейчас просто уменьшаем таргет прогресс
  if (state.attempts == MAX_ATTEMPTS - 2) {
    state.targetProgress = Math.min(
      state.targetProgress,
      (state.progress || 0) + TARGET_PROGRESS_ADD / 2,
    )
  }

  if ((state.attempts || 0) >= MAX_ATTEMPTS) {
    state.attempts = 0
    if (!$isCurrentTaskLocked.getState()) {
      state.traversalNumber = getTraversalNumber() + 1
    }
  }

  const updates: ITaskState[] = [state]

  getSimilarTasks(adjacencyList, taskId)?.forEach((depsTaskId) => {
    const lastState = getTaskState(depsTaskId)
    if (depsTaskId === taskId) return
    const predictatedState = {
      ...lastState,
      taskId: depsTaskId,
      attempts: state.attempts,
      spacedRepetitionGroup,
      traversalNumber: state.traversalNumber,
    }

    updates.push(predictatedState)
  })

  track('failTask', state)
  updates.forEach((state) => taskStates.set(state.taskId, state))

  // TODO если это приживётся то нужно в статистику что-то отправлять мб
  // db.tasksStats.add(statistic)
})

// весь камень преткновения здесь
// окей, есть
// если показывать только в конце игры что пользователь уже не мог победить - ему
// с ошибками ок, всё понятно, можно просто от 3 до 1
// но со временем хуй знает, на сколько нужно, на 10мс ускорять, или больше
// ну вот прошел человек задание, пусть даже 0.5 порог оставляем, а что дальше то делать
// на сколько его можно ускорить
// но и совсем нубов нужно порадовать, когда они прошли задание

// ЗА ПЕРВУЮ ТОЧКУ ОТСЧЁТА БРАТЬ ПРОХОЖДЕНИЕ БЕЗ КУРСОРА! и всегда показывать конфетти
export const resolveTaskFx = createEffect(async (stats: Omit<ITaskStat, 'timestamp' | 'id'>) => {
  // TODO если медианное значение лучше стандартного, можно править стандартное
  // и можно только у доверенных учеников
  // t.e. стандарты тоже нужно хранить в бд

  const { taskId } = stats

  const statistic = { taskId, timestamp: Date.now(), ...stats }

  const lastState = getTaskState(taskId)
  const prevProgress = lastState?.progress || 0
  const newProgress = getTaskProgressByStat(taskId, statistic) || 0

  // ВАЖНО ПОМНИТЬ что от интервала зависит только начало повторения задания, остальное считается по прогрессу
  // и если в какой-то момент алгоритм перестал выдавать задание с большим прогрессом, то интервал просто вернет его
  // и нужно не забыть уменьшить интервал, если алгоритм его вернет и прогресс упал

  // КАКИЕ ПОКАЗУТИЛИ у нас есть?
  // все прохождения за весь срок, но нужно уметь как-то быстро определять нужно ли делать большую паузу, чтобы для профи было норм
  // последний прогресс
  // в идеале нужно типо проанализировать весь график и по нему принять решение что пора паузу поставить
  // по последний значениям могу пока попробовать определять?
  // в стейты наверно нужно прогрессы складывать, можно несколько видов, последний и средний за N времени

  const targetProgress = lastState?.targetProgress || MIN_TARGET_PROGRESS
  let spacedRepetitionGroup = lastState?.spacedRepetitionGroup || 0
  // TODO возможно, интервал менять после НЕСКОЛЬКИХ прохождений задания
  if (
    lastState &&
    prevProgress > targetProgress &&
    newProgress > targetProgress &&
    !getIsStateActual(lastState)
  ) {
    spacedRepetitionGroup++
  }
  // базовая проверка чтобы делать паузы больше при хороших показателях
  // но не учитывается сложность задания ( или учитывается неправильно
  // и вообще хороший результат нужно закрепить наверно парой проигрываний, но это уже не работа интервального метода а что-то другое
  // нужно опираться на targetProgress и его подкручивать а GOOD_PROGRESS убрать
  // TODO ЗАСЧИТЫВАТЬ СТАТУ ИЗ БОЛЕЕ СЛОЖНЫХ ЗАДАНИЙ В ПРОСТЫЕ, и прибавлять там интервалы
  if (newProgress > targetProgress + GOOD_PROGRESS_DIFF && spacedRepetitionGroup < 4) {
    spacedRepetitionGroup++
  }

  // TODO возможно не всегда стоит уменьшать группу
  if (newProgress < targetProgress) {
    spacedRepetitionGroup = Math.max(0, spacedRepetitionGroup - 1)
  }

  lastTasksStatsSumCache += newProgress - (lastState?.groupProgress || 0)

  const state: ITaskState = {
    ...lastState,
    timestamp: Date.now(),
    taskId,
    progress: newProgress,
    groupProgress: newProgress,
    targetProgress,
    spacedRepetitionGroup,
    attempts: (lastState?.attempts || 0) + 1,
  }

  // TODO не понятно что делать когда targetProgress будет равно 1, возможно нужно добавить понятие level
  if (state.progress >= state.targetProgress) {
    toggleConfetti(true)
    // TODO возможно не менять traversalNumber, тогда ученик сразу усложненное задание пройдет

    if (!$isCurrentTaskLocked.getState()) {
      state.traversalNumber = getTraversalNumber() + 1
    }
    state.targetProgress = Math.min(state.progress + TARGET_PROGRESS_ADD, 1)
    // TODO сбрасываем прогресс, т.к. задание теперь может быть с усложнением
    // state.progress = 0
    // state.attempts = 0
  }

  if ((state.attempts || 0) >= MAX_ATTEMPTS) {
    // TODO traversalNumber увеличивается, попытки обновляются, но задание повторяется (((
    // TODO traversalNumber увеличивается, попытки обновляются, но задание повторяется (((
    // TODO traversalNumber увеличивается, попытки обновляются, но задание повторяется (((
    state.attempts = 0
    if (!$isCurrentTaskLocked.getState()) {
      state.traversalNumber = getTraversalNumber() + 1
    }
  }

  const updates: ITaskState[] = [state]

  getSimilarTasks(adjacencyList, taskId)?.forEach((depsTaskId) => {
    const lastState = getTaskState(depsTaskId)
    if (depsTaskId === taskId) return
    const predictatedState = {
      ...lastState,
      taskId: depsTaskId,
      groupProgress: newProgress,
      spacedRepetitionGroup,
      attempts: state.attempts,
      targetProgress: state.targetProgress,
      traversalNumber: state.traversalNumber,
      // TODO
      progress: newProgress,
    }

    updates.push(predictatedState)
  })

  // TODO эта таблица не только к статистике, но и к рекомендеру относится, мб её туда перенести
  updates.forEach((state) => taskStates.set(state.taskId, state))
  const scores = (getLastTasksStatsSum() / mainGroupTasksIds.length) * 100
  saveProgress(scores)
  track('resolveTask', { ...state, scores })

  db.tasksStats.add(statistic)
})

// TODO mb использовать для подсчёта сложности заданий
const getMaxInterval = (tasksStats: ITaskStat[]) => {
  let max = 0

  for (let i = 1; i < tasksStats.length; i++) {
    max = Math.max(max, tasksStats[i].timestamp - tasksStats[i - 1].timestamp)
  }

  return max
}

export const getMelodyTaskTargetTimeByProgress = (
  progress: number,
  mistakes: number,
  partsCount: number,
  step?: number,
): number => {
  const minTime = 200
  const maxTime = 2000
  const mistakesProgress = Math.max(0, 1 - 0.2 * mistakes)

  // возможно не получится отнимать сразу по немногу, т.к. можно одну ноту за 300 мс пройти, а другую за 100,
  // и в среднем получается ничего отнимать не нужно было, ну или прогресс тогда будет немного скакать вверх, но в редких случаях

  // if (step === 0) return mistakesProgress
  // if (step !== undefined) {
  //   const partTimeProgress =
  //     (calcTimeProgress(resolveTime, [minTime * step, maxTime * step]) / partsCount) * step

  //   const restLength = partsCount - step
  //   const restPartTimeProgress =
  //     (calcTimeProgress(minTime * restLength, [minTime * restLength, maxTime * restLength]) /
  //       partsCount) *
  //     restLength

  //   return (partTimeProgress + restPartTimeProgress) * mistakesProgress
  // }

  return (
    calcTimeProgress(resolveTime, [minTime * partsCount, maxTime * partsCount]) * mistakesProgress
  )
}

export const getMelodyTaskProgressByStat = (
  resolveTime: number,
  mistakes: number,
  partsCount: number,
  step?: number,
): number => {
  const [minTime, maxTime] = getTaskStandard().time.map((t) => t * partsCount)
  const mistakesProgress = Math.max(0, 1 - 0.2 * mistakes)

  // возможно не получится отнимать сразу по немногу, т.к. можно одну ноту за 300 мс пройти, а другую за 100,
  // и в среднем получается ничего отнимать не нужно было, ну или прогресс тогда будет немного скакать вверх, но в редких случаях

  // if (step === 0) return mistakesProgress
  // if (step !== undefined) {
  //   const partTimeProgress =
  //     (calcTimeProgress(resolveTime, [minTime * step, maxTime * step]) / partsCount) * step

  //   const restLength = partsCount - step
  //   const restPartTimeProgress =
  //     (calcTimeProgress(minTime * restLength, [minTime * restLength, maxTime * restLength]) /
  //       partsCount) *
  //     restLength

  //   return (partTimeProgress + restPartTimeProgress) * mistakesProgress
  // }

  return calcTimeProgress(resolveTime, [minTime, maxTime]) * mistakesProgress
}

export const getTaskProgressByStat = (taskId: number, stat: ITaskStat): number => {
  const standart = getTaskStandard(taskId)

  return calcProgress(stat, standart)
}

type IGetTaskStatsOptions = {
  from: number
}

const getTaskProgress = async (): Promise<number[]> => {
  // TODO в подсчётё учитывать время мужду исполнениями
  // и чем свежее результат тем больше он должен влиять на прогресс
  // возможно как-то последовательно обрабатывать результаты
  // но учитывать погрешность
  // ДАНО
  // время выполнения
  // ближайший локальный максимум (медиана средней длины)
  // максимум за всё время (медиана побольше, мб 10 значений)
  // дата и время последнего выполнения
  // время предыдущего выполнения
  // эталонное время выполнения
  // считаем прогресс каждого задания по отдельности ✓
  // чем дальше N от последнего выполнения тем меньше влияет на прогресс
  // чем ближе N к последнему выполнению, тем меньше влияет на прогресс (но возможно нужно как-то группировать)
  // возможно стоит нарисовать графики по заданиям, и самому посмотреть тренды
  // подкинуть в нужный момент, чтобы восстановить прогресс, и отслеживать время косле которого забывается конкретная нота
  // т.е. можно накидывать одно и то же задание пока есть прогресс, потом все реже и реже его давать, отслеживать чтобы
  // прогресс не уменьшался, когда уменьшается, опять давать чаще
}

export const getTaskStats = async (
  taskId: number,
  options: IGetTaskStatsOptions,
): Promise<ITaskStat[]> => {
  return await db.tasksStats
    .where({ taskId })
    .and(({ timestamp }) => timestamp > options.from)
    .toArray()
}

export const getLastTasksStatsSum = (): number => {
  if (lastTasksStatsSumCache) return lastTasksStatsSumCache
  const firstGroupsElements = mainGroupTasksIds.map((id) => getTaskState(id))

  const progresses = firstGroupsElements.map((state) => state?.groupProgress || 0)

  return (lastTasksStatsSumCache = sum(progresses))
}

export const getTaskStandard = (): ITaskStatStandart => {
  // TODO mb return standarts to DB, when they will be dynamic
  // TODO для настоящего пианино сделать жоще
  return { time: [250, 3000] }
}

export const updateStat = async (mode: ITaskType) => {
  adjacencyList = tasksData[mode].adjacencyList
  mainGroupTasksIds = tasksData[mode].mainGroupTasksIds
  lastTasksStatsSumCache = 0
}

export const initStat = async (
  adjacencyListInit: AdjacencyList,
  initTaskStates: DexieKeyValueSyncCache<ITaskState>,
) => {
  adjacencyList = adjacencyListInit
  // в каком модуле хранить задания и связи? наверно это отдельный модуль
  console.log('start initStat')
  taskStates = initTaskStates

  // TODO между перезагрузками страницы - формула подсчёта прогресса врядли будет меняться даже в будущем
  // можно прогресс запихивать в taskState, но только локальный, без сохранения в базу, но может это и лишнее

  await taskStates.fillCache()
}

export const clearStats = () => {
  db.delete()
  window.location = window.location
}

sample({ clock: resolveTaskFx, fn: ({ taskId }) => taskId, target: taskResolved })
