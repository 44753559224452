import { useStore } from 'effector-react'
import { useSnackbar } from 'notistack'
import { useUpdateEffect } from 'usehooks-ts'
import { $snackbarStore } from 'utils/effector'

export const EffectorSnackbarProvider = () => {
  const snackbarStore = useStore($snackbarStore)
  const { enqueueSnackbar } = useSnackbar()

  useUpdateEffect(() => {
    const { val: snack } = snackbarStore
    if (snack) {
      if (typeof snack === 'string') {
        enqueueSnackbar(snack, { key: Math.random() })
      } else {
        enqueueSnackbar(snack?.message, snack?.options)
      }
    }
  }, [snackbarStore])

  return null
}
