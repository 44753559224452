import { AssistantButton } from 'shared/components/assistant/AssistantButton'
import { BarChart } from './BarChart'
import { LineChart } from './LineChart'
import { DetailsScoresChart } from './DetailsScoresChart'
import { Button, ButtonGroup } from '@mui/material'
import { $statisticsFormat, changeStatisticsFormat } from './model'
import React from 'react'
import { useStore } from 'effector-react'
import { DailyGoal } from './DailyGoal'

export type ChartPropType = {
  format: string
}
const MODAL_SCORES_HEIGHT = 500
const MODAL_SCORES_WIDTH = 500

export const Statistics = () => {
  const statisticsFormat = useStore($statisticsFormat)
  const handleFormat = (event: React.MouseEvent<HTMLButtonElement>) => {
    changeStatisticsFormat(event.currentTarget.value)
  }

  return (
    <AssistantButton height={MODAL_SCORES_HEIGHT} width={MODAL_SCORES_WIDTH}>
      <ButtonGroup
        size="small"
        variant="text"
        sx={{ position: 'absolute', top: '5px', left: '5px' }}
      >
        {/* <Button onClick={handleFormat} value={'day'}>day</Button> */}
        <Button onClick={handleFormat} value={'week'}>
          week
        </Button>
        <Button onClick={handleFormat} value={'month'}>
          month
        </Button>
        <Button onClick={handleFormat} value={'year'}>
          year
        </Button>
      </ButtonGroup>
      {/* <LineChart format={statisticsFormat} /> */}
      <DailyGoal />
      {/* <DetailsScoresChart /> */}
      {/* <BarChart format={statisticsFormat} /> */}
    </AssistantButton>
  )
}
