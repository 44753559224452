import { createEvent, createStore, sample } from 'effector'
import { persist } from 'effector-storage/local'
import { capitalize, isUndefined } from 'lodash/fp'
import { SnackbarMessage, OptionsObject } from 'notistack'
import { interval } from 'patronum'
import { getISODateNow } from './utils'

export const dayChanged = createEvent()
export const dayChangedAfterInit = createEvent()
export const startGlobalTimer = createEvent()

const currentDateKey = 'currentDate'
export const $currentDate = createStore(new Date(0).toISOString().substring(0, 10)).on(dayChanged, () =>
  new Date().toISOString().substring(0, 10),
)

persist({ store: $currentDate, key: currentDateKey })

export const { tick } = interval({ timeout: 1000, start: startGlobalTimer })

sample({
  clock: startGlobalTimer,
  source: $currentDate,
  filter: (currentDate) => currentDate !== new Date().toISOString().substring(0, 10),
  target: dayChangedAfterInit,
})

// Костыль, мб сделать иначе
export const $isCurrentDateSynced = $currentDate.map(
  (currentDate) => currentDate === getISODateNow(),
)

sample({
  clock: tick,
  source: $currentDate,
  filter: (currentDate) => currentDate !== new Date().toISOString().substring(0, 10),
  target: dayChanged,
})

// TODO improve types
export const createToggle = <V extends unknown = boolean>(
  name: string,
  // @ts-ignore
  defaultValue?: any = false,
  values?: any[] = [true, false],
  config = { persist: true },
) => {
  const toggle = createEvent<V | undefined>('toggle' + capitalize(name))

  const $val = createStore<V>(defaultValue, { name: `is${capitalize(name)}Enabled` }).on(
    toggle,
    (value, param?: V) => {
      if (!isUndefined(param)) {
        return param
      }

      return values[(values.indexOf(value) + 1) % values.length]
    },
  )

  if (config.persist) {
    persist({ store: $val, key: `is${capitalize(name)}Enabled` })
  }

  return { toggle, $val, values } as const
}

export type ToggleModel = ReturnType<typeof createToggle>

type SnackbarPayload = {
  message: SnackbarMessage
  options?: OptionsObject | undefined
}

export const enqueueSnackbar = createEvent<SnackbarPayload | string>()

export const $snackbarStore = createStore<{ val: SnackbarPayload | string | null }>({
  val: null,
}).on(enqueueSnackbar, (_, val) => ({ val }))
