import { createEffect } from 'effector'
import type { MidiBuffer as MidiBufferType } from 'abcjs'
import { pause } from 'utils/utils'
// https://github.com/swevans/unmute TODO
const INSTRUMENT = 2

const midiBuffers: MidiBufferType[] = []

export const cacheMidi = async () => {
  const { synth, renderAbc } = await import(`abcjs`)
  const midi = new synth.CreateSynth()
  await midi.init({
    visualObj: renderAbc(
      '*',
      `CDEFGAB C,D,E,F,G,A,B, C,,D,,E,,F,,G,,A,,B,, C,,,D,,,E,,,F,,,G,,,A,,,B,,, C'D'E'F'G'A'B' C''D''E''F''G''A''B'' C'''D'''E'''F'''G'''A'''B'''`,
    )[0],
    options: { program: INSTRUMENT },
  })

  midiBuffers.push(midi)
}

export const playAbcStringFx = createEffect(async (string: string) => {
  const { synth, renderAbc } = await import(`abcjs`)

  const midiBuffer = midiBuffers.pop() || new synth.CreateSynth()

  await midiBuffer.init({
    visualObj: renderAbc('*', string)[0],
    millisecondsPerMeasure: 3000,
    options: {
      program: INSTRUMENT,
      onEnded: () => midiBuffers.push(midiBuffer),
    },
  })

  await Promise.any([midiBuffer.prime(), pause(100)])
  await midiBuffer.start()
})
