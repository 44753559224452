// TODO этот файл создан из-за циклических зависимостей между next и ./model
import { sample, createEffect } from 'effector'
import { next, $currentTaskId } from 'model/model'
import { saveSkipTask, skipTask, undoTaskData } from './model'
import * as stat from 'statistics/statistics'

const skipTaskFx = createEffect((taskId: number) => {
  const rootId = stat.getCurrentRootTaskId(taskId)
  const lastState = stat.getTaskState(rootId)
  saveSkipTask({
    id: rootId,
    traversal: lastState.traversalNumber || 0,
    timestamp: lastState.timestamp,
  })

  stat.updateWithSimilarTasks(rootId, {
    // TODO timestamp для похожих не передавать
    timestamp: lastState.timestamp || Date.now(),
    traversalNumber: stat.getTraversalNumber() + 1,
  })

  setTimeout(next)
})

sample({
  clock: undoTaskData,
  target: createEffect(() => setTimeout(next)),
})

sample({
  clock: skipTask,
  source: $currentTaskId,
  target: skipTaskFx,
})
