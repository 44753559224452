import { WebApp } from '@grammyjs/web-app'
import { Button, Typography, CircularProgress } from '@mui/material'
import { track } from 'utils/amplitude'
import { useEffect, useState } from 'react'
import { $isSubscribeModalAfterTimerOpened } from '../../features/timer/model'
import { useStore } from 'effector-react'
import { sendCallback } from 'api/index'
import { PopupWindow } from 'components/PopupWindow/PopupWindow'

export const BuySubscribeContent = () => {
  const isSubscribeModalAfterTimerOpened = useStore($isSubscribeModalAfterTimerOpened)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  return (
    <PopupWindow isOpen={isSubscribeModalAfterTimerOpened} mesToAmpl='showSubscribeModal'>
      <div style={{ fontSize: 40, textAlign: 'center' }}>🚀</div>
      <Typography variant="body1">
        В бесплатной версии вы можете тренироваться 5 минут в день. Чтобы практиковаться больше,
        оформите, пожалуйста, подписку.
      </Typography>
      <Button
        sx={{ mt: 2 }}
        size="large"
        variant="contained"
        color="success"
        disabled={isLoading}
        onClick={() => {
          setIsLoading(true)
          Promise.all([
            track('pressSubscribe').catch(() => { }),
            sendCallback('pay_from_webapp'),
          ]).then(() => WebApp.close())
        }}
      >
        Оформить подписку
        {isLoading && (
          <CircularProgress
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px', // Половина высоты CircularProgress
              marginLeft: '-12px', // Половина ширины
            }}
            size={24}
          />
        )}
      </Button>
      <Button
        disabled={isLoading}
        onClick={() => {
          setIsLoading(true)
          Promise.all([
            track('pressNotSubscribe').catch(() => { }),
            sendCallback('not_pay_from_webapp'),
          ]).then(() => WebApp.close())
        }}
        sx={{ mt: 2, mb: 2 }}
      >
        Нет, спасибо
      </Button>
    </PopupWindow>
  )
}

// {
//   "Authorization": "Basic MjM2ODU4OmxpdmVfNmFOZWxBSXNKUGFfekNLSUdtb1NDRWxSbmVnOWNwMFpXUnJMM2RYdFFabw==",
//   "Idempotence-Key": "#{random(1, 9999999999999)}",
//   "Content-Type": "application/json"
// }

// https://api.yookassa.ru/v3/payments

// const amount = '99.00'
// const receipt_description = 'пиано-бот #ИграйCразу'
// const body = {
//   amount: { currency: 'RUB', value: '#{amount}' },
//   payment_method_id: '#{yandex_payment_method_id}',
//   capture: true,
//   description: `${receipt_description}`,
//   receipt: {
//     customer: { phone: '#{phone}', email: '#{email}' },
//     items: [
//       {
//         amount: { currency: 'RUB', value: `${amount}` },
//         description: `${receipt_description}`,
//         payment_mode: 'full_payment',
//         payment_subject: 'commodity',
//         quantity: '1.0',
//         vat_code: 1,
//       },
//     ],
//     phone: '#{phone}',
//   },
// }

// tg_send_message(224769697, 'АВТОоплаточка ' + tg_username + ' ' + amount)

// {
// 	"amount": {
// 		"currency": "RUB",
// 		"value": "99.00"
// 	},
// 	"capture": true,
// 	"confirmation": {
// 		"return_url": "https://chatter.salebot.pro/yandex_kassa_callback/success?client_id=228258738--10870",
// 		"type": "redirect"
// 	},
// 	"description": "Подписка на пиано-бот #ИграйCразу",
// 	"metadata": {
// 		"order_id": "228258738--10870"
// 	},
// 	"receipt": {
// 		"customer": {
// 			"email": "gogazas@mail.ru"
// 		},
// 		"email": "gogazas@mail.ru",
// 		"items": [
// 			{
// 				"amount": {
// 					"currency": "RUB",
// 					"value": "99.00"
// 				},
// 				"description": " на пиано-бот #ИграйCразу",
// 				"payment_mode": "full_payment",
// 				"payment_subject": "commodity",
// 				"quantity": "1.0",
// 				"vat_code": 1
// 			}
// 		]
// 	},
// 	"save_payment_method": true,
// 	"transfers": []
// }
