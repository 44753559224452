import { EmojiButton } from 'components/EmojiButton';
import React, { useEffect } from 'react';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { useStore } from 'effector-react';
import { $isFullscreen, setIsFullscreen } from './model';

function FullscreenButton() {
    const isFullscreen = useStore($isFullscreen)

    const toggleFullscreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    };

    useEffect(() => {
        // Функция для обнаружения изменений в полноэкранном режиме
        const onFullscreenChange = () => {
            setIsFullscreen(!!document.fullscreenElement);
        };

        document.addEventListener("fullscreenchange", onFullscreenChange);

        // Очистка слушателя при размонтировании компонента
        return () => {
            document.removeEventListener("fullscreenchange", onFullscreenChange);
        };
    }, []);

    return (
        <EmojiButton active onClick={toggleFullscreen} label={!isFullscreen ? <FullscreenIcon /> : <FullscreenExitIcon />} />
    );
}

export default FullscreenButton;
