import styled from "styled-components"
import { countDaysAboveGoal } from "./auxiliaryFn";
import { LocalFireDepartment } from '@mui/icons-material';
import { mainCircularColor, secondaryCircularColor } from "./../../constants";

const Container = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
     justify-content: center;
`

const LabelsDayContainer = styled.div`
    display: flex;
    justify-content: space-around;
`

const LabelDay = styled.div<{ isDayCompleted: boolean }>`
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: medium;
    border: 3px solid;
    border-color: ${(p) => (p.isDayCompleted ? mainCircularColor : secondaryCircularColor)};
    color: ${(p) => (p.isDayCompleted ? mainCircularColor : secondaryCircularColor)};
`;

const AchievementStreak = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 20px;
    text-align: center;
`

type LabelDayProps = {
    time: number
    name: string
}
export const LabelsDay = ({ weekStat, goal }: { weekStat: LabelDayProps[], goal: number }) => {

    const achievementStreak = countDaysAboveGoal(weekStat, goal)

    return (
        <Container>
            <LabelsDayContainer>{weekStat.map((day, i) => (
                <LabelDay isDayCompleted={day.time >= goal} key={i}>{day.name}</LabelDay>
            ))}
            </LabelsDayContainer>
            <AchievementStreak>
                <LocalFireDepartment />
                {`Моя полоса ${achievementStreak} ${achievementStreak === 1 ? 'день' : 'дня'}`}
            </AchievementStreak>
        </Container>
    )
}