import {
  Avatar,
  Button,
  FormControlLabel,
  FormLabel,
  IconButton,
  MobileStepper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  Box,
  useRadioGroup,
  FormLabelBaseProps,
  FormControlLabelProps,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { updateSkillLevel, updateSteps } from './model'
import {
  firstSkillLevelOnboarding,
  secondSkillLevelOnboarding,
  thirdSkillLevelOnboarding,
  zeroSkillLevelOnboarding,
} from './onboardingSteps'
import { components, StylesObj } from '@reactour/tour'
import { MaskStylesObj } from '@reactour/mask'
import { PopoverStylesObj } from '@reactour/popover'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { useState } from 'react'
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew'
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement'
import GirlIcon from '@mui/icons-material/Girl'
import styled from 'styled-components'
import { track } from 'utils/amplitude'

export const styles: StylesObj & PopoverStylesObj & MaskStylesObj = {
  popover: (base) => ({
    ...base,
    borderRadius: 10,
    padding: 30,
  }),
  maskArea: (base) => ({ ...base, rx: 10 }),
  maskWrapper: (base) => ({ ...base, color: '#000000', opacity: 0.3 }),
  close: (base) => ({ ...base, left: 'auto', right: 8, top: 8, height: '11px', width: '11px' }),
}

type OptionItemProps = {
  title: string
  label: string
  icon?: React.ReactNode
  value: any
}

export const AbilsList = styled.ul`
  li {
    margin-top: 18px;
  }
`

const OptionEmoji = styled.div`
  /* margin-right: 2px; */
  font-size: 20px;
`

const OptionItem = (props: OptionItemProps) => {
  const radioGroup = useRadioGroup()
  const { title, label, icon } = props

  let checked = false

  if (radioGroup) {
    checked = radioGroup.value === props.value
  }

  return (
    <FormControlLabel
      disableTypography={true}
      value={props.value}
      sx={{ mx: '-20px' }}
      control={<Radio sx={{ display: 'none' }} />}
      label={
        <Box
          sx={{
            my: '2px',
            width: '100%',
            p: '12px',
            display: 'flex',
            backgroundColor: checked ? '#bfffde' : 'transparent',
            borderRadius: 3,
            boxSizing: 'border-box',
            border: checked ? '1px solid gray' : '1px solid transparent',
            ':hover': {
              backgroundColor: checked ? '#bfffde' : '#e9f3ff',
              border: '1px solid gray',
            },
          }}
        >
          <Stack spacing={2} direction="row" alignItems="center">
            <OptionEmoji>{icon}</OptionEmoji>
            <Stack spacing={0} direction="column" alignItems="left">
              <Typography sx={{ fontSize: 'large' }}>{title}</Typography>
              <Typography sx={{ fontSize: 'smaller' }}>{label}</Typography>
            </Stack>
          </Stack>
        </Box>
      }
    />
  )
}

// eslint-disable-next-line react/prop-types
export const Close: typeof components.Close = ({ onClick, disabled }) => {
  return disabled ? null : (
    <IconButton onClick={onClick} sx={{ position: 'absolute', right: '5px', top: '5px' }}>
      <CloseIcon />
    </IconButton>
  )
}

export const Navigation: typeof components.Navigation = ({
  currentStep,
  setCurrentStep,
  steps,
  setIsOpen,
}) => {
  const handleNext = () => {
    if (currentStep === steps.length - 1) {
      setIsOpen(false)
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const handleBack = () => {
    setCurrentStep(currentStep - 1)
  }
  return (
    <MobileStepper
      variant="progress"
      steps={steps.length}
      position="static"
      activeStep={currentStep}
      sx={{ marginTop: 2, padding: 0 }}
      LinearProgressProps={{ sx: { margin: 1 } }}
      nextButton={
        <Button
          onClick={handleNext}
          disabled={steps[1]?.content === 'it is fake'}
          variant="contained"
          color="success"
        >
          {currentStep === steps.length - 1 ? 'Полетели!' : 'Далее'}
        </Button>
      }
      backButton={
        <IconButton size="small" onClick={handleBack} disabled={currentStep === 0}>
          <KeyboardArrowLeft />
        </IconButton>
      }
    />
  )
}

// TODO возможно нужно сделать если Close отсутствует то падинг меньше ставить
// eslint-disable-next-line react/prop-types
export const Content: typeof components.Content = ({ content }) => {
  return <div style={{ paddingTop: 10 }}>{content}</div>
}

export const ChooseYourSkillLevel = () => {
  const [level, setLevel] = useState<number | null>(null)

  const handleChangeLevel = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value)
    const level = [
      zeroSkillLevelOnboarding,
      firstSkillLevelOnboarding,
      secondSkillLevelOnboarding,
      thirdSkillLevelOnboarding,
    ][value]

    track(`selectSkillLevel_${value}`)
    setLevel(value)
    updateSkillLevel(value)
    updateSteps(level)
  }

  return (
    <div className="flex flex-col">
      {/* <div style={{ fontSize: 40, textAlign: 'center' }}>👋</div> */}
      <Typography variant="h6" sx={{ textAlign: 'center' }}>
        Привет 👋 Как хорошо вы читаете ноты?
      </Typography>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={level}
        onChange={handleChangeLevel}
      >
        <OptionItem
          title={'Не знаю что это такое'}
          label={`Что это вообще за точечки и палочки?`}
          value={0}
          icon="🐣"
        />
        <OptionItem
          title={'Немного ориентируюсь'}
          label={'Могу медленно разобрать несложную мелодию'}
          value={1}
          icon="🌱"
        />
        <OptionItem
          title={'Хорошо понимаю'}
          label={'Медленно играю новые мелодии читая с листа'}
          value={2}
          icon="🚀"
        />
        <OptionItem
          title={'Уверенно читаю с листа'}
          label={'Играю сложные произведения без подготовки'}
          value={3}
          icon="🏆"
        />
      </RadioGroup>
    </div>
  )
}
