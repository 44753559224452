import { takeRight, values } from "lodash/fp";

export const getDaysInMonth = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const lastDay = new Date(year, month, 0).getDate();
    const daysArray = [];

    for (let day = 1; day <= lastDay; day++) {
        const formattedDate = `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}`;
        daysArray.push(formattedDate);
    }

    return daysArray;
}

type data = {
    [x: string]: number
}

export const formateWeekStat = (dataStat: data, todayData: number) => {
    const today = new Date().getDay()
    const numOfDay = today === 0 ? 7 : today
    const data = values(dataStat)
    if (!data.length) {
        const prevDays = Array(numOfDay - 1).fill(0)
        const nextDays = Array(7 - numOfDay).fill(0)
        const newData = [...prevDays, todayData, ...nextDays]
        return newData
    }
    if (data.length > numOfDay) {
        const newData = takeRight(numOfDay - 1, data)
        return [...newData, todayData]
    } else {
        const prevDays = Array(numOfDay - data.length - 1).fill(0)
        const nextDays = Array(7 - numOfDay).fill(0)
        const newData = [...prevDays, ...data, todayData, ...nextDays]
        return newData
    }
}

// сделать в статистике чтоб каждое первое число месяца статистика за месяц считалась, записывалась в краце и обнулялся обьект
export const formatMonthStat = (dataStat: data, todayData: number) => {
    const today = new Date();
    const numOfToday = today.getDate()
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const data = values(dataStat)
    const nextDays = Array(daysInMonth - numOfToday).fill(0);
    const formattedData = [...data, todayData, ...nextDays]
    return formattedData
};

export const  countDaysAboveGoal = (data, goal) =>  {
    let consecutiveDays = 0;
    let currentConsecutive = 0;

    for (let i = 0; i < data.length; i++) {
        if (data[i].time >= goal) {
            currentConsecutive++;
        } else {
            currentConsecutive = 0;
        }

        if (currentConsecutive > consecutiveDays) {
            consecutiveDays = currentConsecutive;
        }
    }

    return consecutiveDays;
}
