import { get, orderBy, sum, uniq, values } from 'lodash/fp'
import { random } from 'utils/random'

export function generateAllHands() {
  const suits = ['c', 'd', 'h', 's']
  const values = ['2', '3', '4', '5', '6', '7', '8', '9', 'T', 'J', 'Q', 'K', 'A']
  const deck = []

  for (const s of suits) {
    for (const v of values) {
      deck.push(v + s)
    }
  }

  const allHands = []

  for (let i = 0; i < deck.length; i++) {
    for (let j = i + 1; j < deck.length; j++) {
      allHands.push([deck[i], deck[j]])
    }
  }

  return allHands
}

export function countOuts(hand) {
  const values = {
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    '6': 6,
    '7': 7,
    '8': 8,
    '9': 9,
    T: 10,
    J: 11,
    Q: 12,
    K: 13,
    A: 14,
  }

  const card1Value = values[hand[0][0]]
  const card2Value = values[hand[1][0]]

  const sameSuit = hand[0][1] === hand[1][1]

  // Пара
  if (card1Value === card2Value) {
    return 2
  }

  // Согласованные
  if (sameSuit) {
    return 9
  }

  // Остальные
  return 6
}

export function countAllOuts() {
  const allHands = generateAllHands()
  const outs = {}

  for (const hand of allHands) {
    const handOuts = countOuts(hand)
    if (!outs[handOuts]) {
      outs[handOuts] = 0
    }
    outs[handOuts]++
  }

  return outs
}

// ******

function generateDeck() {
  const suits = ['c', 'd', 'h', 's']
  const values = ['2', '3', '4', '5', '6', '7', '8', '9', 'T', 'J', 'Q', 'K', 'A']
  const deck = []

  for (const s of suits) {
    for (const v of values) {
      deck.push(v + s)
    }
  }

  return deck
}

function hasFlush(hand, table, card) {
  const newHand = [...hand, ...table, card]
  const suitCounts = newHand.reduce((acc, card) => {
    const suit = card[1]
    acc[suit] = (acc[suit] || 0) + 1
    return acc
  }, {})

  for (const suit in suitCounts) {
    if (suitCounts[suit] >= 5) return true
  }

  return false
}

function isMyFlush(hand, table, card) {
  const newHand = [...hand, ...table, card]
  const suitCounts = newHand.reduce((acc, card) => {
    const suit = card[1]
    acc[suit] = (acc[suit] || 0) + 1
    return acc
  }, {})

  for (const suit in suitCounts) {
    if (suitCounts[suit] === 5 && card[1] === suit && hand.some((c) => c[1] === suit)) return true
  }

  return false
}

function hasStraight(hand: string[], table, card) {
  const newHand = [...hand, ...table, card].map((c) => c[0])
  const values = 'A23456789TJQKA'
  const handRanks = uniq(
    newHand
      .map((card) => (card[0] === 'A' ? [0, 13] : values.indexOf(card[0])))
      .flat()
      .sort((a, b) => a - b),
  )

  for (let i = 0; i < handRanks.length - 4; i++) {
    if (handRanks[i + 4] - handRanks[i] === 4) {
      return true
    }
  }

  return false
}

function isMyStraight(hand: string[], table, card) {
  const withoutNew = [...hand, ...table].map((c) => c[0])
  const newHand = [...hand, ...table, card].map((c) => c[0])
  const hand0 = hand.map(get('0'))
  const values = 'A23456789TJQKA'
  const handRanks = uniq(
    newHand
      .map((card) => (card[0] === 'A' ? [0, 13] : values.indexOf(card[0])))
      .flat()
      .sort((a, b) => a - b),
  )

  for (let i = 0; i < handRanks.length - 4; i++) {
    if (handRanks[i + 4] - handRanks[i] === 4) {
      const straightCards = handRanks.slice(i, i + 5).map((i) => values[i])
      const isNewCardInStraight = straightCards.some(
        (c) => c === card[0] && !withoutNew.includes(card[0]),
      )

      const isSomeMyCardInStraight = hand0.some((card) =>
        straightCards.some((c) => c === card && table.every((c) => c !== card)),
      )

      if (isNewCardInStraight && isSomeMyCardInStraight) {
        return true
      }
    }
  }

  return false
}

function countOutsOnTable(hand, table) {
  const deck = generateDeck()
  const groupedOuts = {
    straight: 0,
    flush: 0,
    pair: 0,
    square: 0,
    trips: 0,
  }
  type TOutType = keyof typeof groupedOuts
  const outsCards: string[] = []

  const remainingDeck = deck.filter((card) => ![...hand, ...table].includes(card))

  for (const card of remainingDeck) {
    const addOut = (type: TOutType) => {
      groupedOuts[type]++
      outsCards.push(card)
    }

    const oldHandPlusFlopHand = [...hand, ...table]

    // TODO отдельно засчитать пересечения
    if (hasFlush(hand, table, card)) {
      if (isMyFlush(hand, table, card)) {
        addOut('flush')
      }
    } else if (hasStraight(hand, table, card)) {
      if (isMyStraight(hand, table, card)) {
        addOut('straight')
      }
    } else if (hand.some((h) => h[0] === card[0])) {
      const same = oldHandPlusFlopHand.filter((h) => h[0] === card[0])
      const sameCount = same.length
      if (sameCount === 3) {
        addOut('square')
      } else if (sameCount === 2) {
        addOut('trips')
      } else if (sameCount === 1) {
        addOut('pair')
      }
    }
  }

  const cardsOrder = '23456789TJQKA'
  const suitsOrder = 'scdh'
  return {
    groupedOuts,
    outsCards: orderBy(
      (c) => cardsOrder.indexOf(c[0]) * 100 + suitsOrder.indexOf(c[1]),
      'desc',
      outsCards,
    ),
  }
}

export const generateTask = () => {
  const deck = generateDeck()

  const [h1, h2, ...table] = random.sample(deck, random.integer(5, 6))
  const hand = [h1, h2]
  // const hand = ['2s', 'Ks'];
  // const table = ['Qs', 'Js', 'Ts']
  const { groupedOuts, outsCards } = countOutsOnTable(hand, table)

  const task = {
    hand,
    table,
    outsCards,
    groupedOuts,
    answer: sum(values(groupedOuts)),
  }

  console.log('task: ', task)

  return task
}
