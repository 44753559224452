// TODO refactor но не сломать на телефоне/маке
import { createEvent, createStore, combine } from 'effector'
import { enqueueSnackbar } from './effector'
import { Note } from '@tonaljs/tonal'
import { tonalToAbc } from './abcjs-helpers'
import { $isRecommenderInited } from 'model/tasks/recommender'
// https://github.com/mizuhiki/WebMIDIAPIShimForiOS/blob/master/WebMIDIAPIPolyfill/index.html
const START_CODE = 144
const END_CODE = 128

export const playNoteByMidi = createEvent<{ note: string; power: number }>('playNoteByMidi')
export const releaseNoteByMidi = createEvent<string>('releaseNoteByMidi')
export const toggleMidi = createEvent<boolean>('toggleMidi')
const setMidiName = createEvent<string>('setMidiName')
const $isMidiConnected = createStore<boolean>(false)
  .on(toggleMidi, (_, val) => val)
  .on(playNoteByMidi, () => true)

export const $isMidiEnabled = combine($isMidiConnected, $isRecommenderInited, (m, r) => m && r)

// TODO фикс бага на ios + mac, иногда мак считает что миди к нему подключена через IPhone
// можно фильтровать по имени
export const $midiName = createStore('').on(setMidiName, (_, val) => val)

$midiName.watch((val) => enqueueSnackbar('Midi name: ' + val))
$isMidiEnabled.watch((val) => enqueueSnackbar('Midi ' + (val ? 'connected' : 'disconnected')))

let midiAccess: WebMidi.MIDIAccess | null = null
let isMidiInputActivated = false

const handleMidiMessage = (data) => {
  const [code, midi] = data

  if (midi && code === START_CODE) {
    playNoteByMidi({
      note: tonalToAbc(Note.fromMidi(midi)),
      power: 1,
    })
  } else if (code === END_CODE) {
    releaseNoteByMidi(tonalToAbc(Note.fromMidi(midi)))
  }
}

function setup() {
  let i = 0
  if (window.isIosWebview) {
    const listener = (event) => {
      handleMidiMessage(event.detail)
    }
    document.addEventListener('midi-connect', () => toggleMidi(true))
    document.addEventListener('midi-disconnect', () => toggleMidi(false))
    document.addEventListener('midi-key', listener)
  } else if (window.navigator.requestMIDIAccess) {
    window.navigator.requestMIDIAccess({ sysex: false }).then(
      (access) => {
        const inputs = []

        midiAccess = access
        midiAccess.onstatechange = function (event) {
          const port = event.port
          if (i++ > 0) {
            toggleMidi(port.state === 'connected')
          }

          if (port.type == 'input') {
            port.onmidimessage = function (event) {
              handleMidiMessage(event.data)
              isMidiInputActivated = true
            }
          }
        }

        const iter = midiAccess.inputs.values()
        for (let o = iter.next(); !o.done; o = iter.next()) {
          inputs.push(o.value)
        }

        for (let port = 0; port < inputs.length; port++) {
          inputs[port].onmidimessage = function () {}

          inputs[port].onstatechange = function (event) {
            setMidiName('asd')
            toggleMidi(event.port.state === 'connected')
          }
        }
      },
      () => enqueueSnackbar('requestMIDIAccess() failed.'),
    )
  } else {
    console.error('Web MIDI API is not available on your browser.')
  }
}

export const initMidi = () => {
  setup()
}
