import { createEffect, createEvent, createStore, sample } from 'effector'
import { ComponentProps } from 'react'
import { createToggle, enqueueSnackbar } from 'utils/effector'
// TODO если нужно будет вернуть то только динамически
// import VConsole from 'vconsole'
import Confetti from 'react-confetti'

// TODO мб вернуть логику с циклом
export const toggleLoop = createEvent('toggleLoop')
export const toggleHint = createEvent<boolean>('toggleHint')

export const KEYBOARD_TYPES = [
  'piano',
  'chords',
  // 'pianoMini',
  // 'notes',
  // 'ruNotes',
  // 'quinto_quartic_circle',
] as const

export type IKeyboardsTypes = (typeof KEYBOARD_TYPES)[number]
export type ConfettiProps = ComponentProps<typeof Confetti>

export type IHintTypes = 'justHint' | 'hintWithDelay' | ''
export const HINT_TYPES: IHintTypes[] = ['justHint', 'hintWithDelay', '']
export const hintTypeToggle = createToggle<IHintTypes>('highlighted', '', HINT_TYPES)
export const isNotesVisibileToggle = createToggle('notesVisible')
export const isHideNoteModeToggle = createToggle('isHideNoteModeToggle', false)
export const isDeveloperModeToggle = createToggle('isDeveloperModeToggle', false)
export const isRuNotesMode = createToggle('isRuNotesMode', true)
export const isHardModeMistakesToggle = createToggle('isHardModeToggle', true, [true, false], { persist: false })
export const isDropNotesMode = createToggle('isDropNotesMode', true)
export const isProVersion = createToggle('proVersion', false)
export const isCatchUpMode = createToggle('isCatchUpMode', true, [true, false], { persist: false })
export const isConsoleOpenedToggle = createToggle('isConsoleOpenedToggle', false)
export const themeToggle = createToggle('theme', 'light', ['light', 'dark'])
export const keyboardToggle = createToggle<IKeyboardsTypes>('keyboard', 'piano', KEYBOARD_TYPES)
export const soundForCheckToggle = createToggle('soundForCheck')
export const { toggle: toggleSoundForCheck, $val: $soundForCheck } = soundForCheckToggle
export const debugToggle = createToggle('debug', false)
export const testTasksToggle = createToggle('testTasks', false)
export const cursorToggle = createToggle('cursor', true)
export const chartToggle = createToggle('chart', false)
export const soundToggle = createToggle('sound', true, [true, false], { persist: false })
export const micToggle = createToggle('microphone', false, [true, false], { persist: false })
export const secondChordNoteToggle = createToggle('secondChordNoteEnabled', false)
export const thirdChordNoteToggle = createToggle('thirdChordNoteEnabled', false)
export const forthChordNoteToggle = createToggle('ForthChordNoteEnabled', false)
export const $isHintActive = createStore(false).on(toggleHint, (_, val) => val)

export const toggleConfetti = createEvent<boolean>()
export const hideConfetti = createEvent()
export const $isConfettiShown = createStore(false)
  .on(toggleConfetti, (_, value) => value)
  .on(hideConfetti, () => false)

export const updateConfettiConfig =
  createEvent<Pick<ConfettiProps, 'gravity' | 'colors' | 'numberOfPieces'>>()
export const $confettiConfig = createStore<ConfettiProps>({
  onConfettiComplete: () => hideConfetti(),
  numberOfPieces: 200,
  recycle: false,
  gravity: 0.3,
})

soundToggle.$val.on(micToggle.$val, (_, micToggle) => !micToggle)

// let vConsole: VConsole | null = null

// isConsoleOpenedToggle.$val.watch((val) => {
//   if (val) {
//     if (vConsole) {
//       vConsole.show()
//     } else {
//       vConsole = new VConsole()
//       vConsole.hideSwitch()
//       vConsole.show()
//     }
//   } else {
//     vConsole?.destroy?.()
//   }
// })
