import { Overlay, Content } from "./ui";

export const Layout = ({ children, isOpen }) => {

  return (
    <Overlay show={isOpen}>
      <Content show={isOpen}>
        {children}
      </Content>
    </Overlay>
  );
};