import styled, { css } from 'styled-components'

export const AppWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${window.isIosWebview &&
  css`
    @media (orientation: portrait) {
      margin-top: 30px;
    }
  `}
`