import { SkipPrevious } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useStore } from 'effector-react'
import { $tasksQueue, undoTask } from './model'

export const UndoButton = () => {
  const tasksQueue = useStore($tasksQueue)

  return (
    <IconButton disabled={tasksQueue.length === 0}>
      <SkipPrevious onClick={() => undoTask()} fontSize="large" />
    </IconButton>
  )
}
