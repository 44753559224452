import React from 'react'
import { TourProvider } from '@reactour/tour'
import { styles } from 'features/onboarding/ui'
import { Close, Navigation, Content } from 'features/onboarding/ui'
import { $steps } from 'features/onboarding/model'
import { useStore } from 'effector-react'
import { OnboardingController } from './OnboardingController'
import { TrebleBasClefOnboarding } from './TrebleBasClefOnboarding'
import { startTimer, stopTimer } from 'features/timer/model'

export const TourProviderWrapper: React.FC<React.ReactNode> = ({ children }) => {
  const steps = useStore($steps)
  return (
    <TourProvider
      components={{ Close, Navigation, Content }}
      showBadge={false}
      styles={styles}
      beforeClose={() => startTimer()}
      afterOpen={() => stopTimer()}
      onClickClose={({ setIsOpen }) => {
        setIsOpen(false)
      }}
      Wrapper={React.Fragment}
      steps={[]}
      showNavigation={steps.length > 1}
    >
      <OnboardingController />
      <TrebleBasClefOnboarding />
      {children}
    </TourProvider>
  )
}
