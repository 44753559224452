import { useEffect, useState } from "react";
import { ANIMATION_TIME } from "./ui";

export const useMount = ({ isOpen }: { isOpen: boolean }) => {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if (isOpen && !mounted) {
            setMounted(true);
        } else if (!isOpen && mounted) {
            setTimeout(() => {
                setMounted(false);
            }, ANIMATION_TIME);
        }
    }, [isOpen]);

    return {
        mounted,
    };
};