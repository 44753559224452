import KNN from 'ml-knn'
import { euclidean as euclideanDistance } from 'ml-distance-euclidean'

import { ETALONS } from './constants'
import { keys } from 'lodash/fp'
import { getKnnNameByNotes, notesToFingerprint } from './utils.pitchdetect'

export const KNN_DATA_SETS = {}
export const KNN_LABELS_SETS = {}

// мб для распознавания октавы нужно будет использовать кнн с нужными октавами + кнн верхней октавы,
// чтобы если нажата только одна верхняя нота - не распознавалось как октава

export const notesFingerprints = {}

ETALONS.forEach((etalon) => {
  const knnSetName = getKnnNameByNotes(etalon.label.split('-'))
  KNN_DATA_SETS[knnSetName] = KNN_DATA_SETS[knnSetName] || []
  KNN_LABELS_SETS[knnSetName] = KNN_LABELS_SETS[knnSetName] || []
  notesFingerprints[etalon.label] = notesToFingerprint(
    etalon.data,
    etalon.amplitudes.map((a) => +a),
  )

  KNN_DATA_SETS[knnSetName].push(notesFingerprints[etalon.label])

  KNN_LABELS_SETS[knnSetName].push(etalon.label)
})

export const KNNSet: Record<string, KNN> = {}

const distance = (point1, point2) => {
  let distance = 0;
  for (let i = 0; i < point1.length; i++) {
    // if (point2[i] > 0) {
      distance += Math.pow(point1[i] - point2[i], 2);
    // }
  }

  return Math.sqrt(distance);
}

keys(KNN_DATA_SETS).forEach((knnName) => {
  KNNSet[knnName] = new KNN(KNN_DATA_SETS[knnName], KNN_LABELS_SETS[knnName], { k: 1, distance })
})
