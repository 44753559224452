import { Button, Typography } from '@mui/material'
import { StepType } from '@reactour/tour'
import { AbilsList, ChooseYourSkillLevel } from './ui'
import { track } from 'utils/amplitude'
import { updateSteps, closeOnboarding, setWelcomeOnboardingPassed, chordsOnboardingPassed } from './model'

const noSelectorStyles: StepType['styles'] = {
  maskArea: (base) => ({
    ...base,
    backgroundColor: '#000000',
    opacity: 0.3,
  }),
}

export const welcomeStep: StepType = {
  disableActions: true,
  content: <ChooseYourSkillLevel />,
  selector: 'body',
  position: 'center',
  styles: noSelectorStyles,
  action: () => track('startWelcomeOnboarding'),
}

export const onboardingWithUnselectedLevel: StepType[] = [
  welcomeStep,
  {
    disableActions: true,
    content: 'it is fake',
    selector: 'body',
  },
]

export const repeatButton: StepType[] = [
  {
    disableActions: false,
    selector: '.repeat-button',
    content: 'Я использую эту кнопку чтобы больше поиграть с интересным заданием 😉',
    styles: {
      dot: () => ({ display: 'none' }),
      arrow: () => ({ display: 'none' }), //TODO добавить стили чтоб не было пустого места под контентом
    },
  },
]

export const zeroSkillLevelOnboarding: StepType[] = [
  welcomeStep,
  {
    content: (
      <div>
        <Typography textAlign="center" variant="h6" gutterBottom>
          Отлично, +1 человек в сообществе музыкантов 🎉 скоро вы:
        </Typography>
        <AbilsList>
          <li>🎶 Познакомитесь с нотами</li>
          <li>🧠 Улучшите память и прокачаете ум (доказано научными исследованиями)</li>
          <li>🎹 Сможете играть свои любимые мелодии на пианино</li>
          <li>🎓 Сможете учиться где и когда угодно, даже без пианино под рукой</li>
          {/* <li>💸 Сэкономите деньги на частных уроках</li> */}
        </AbilsList>
      </div>
    ),
    selector: 'body',
    position: 'center',
    styles: noSelectorStyles,
  },
  {
    selector: '.abcjs-note',
    content: (
      <div>
        {/* <div style={{ fontSize: 40, textAlign: 'center' }}>🎶👉🎹</div> */}
        <div>
          Это нота. Каждая нота имеет название и обозначает одну клавишу на фортепиано. Например,
          эта нота соответствует мигающей клавише внизу.
        </div>
      </div>
    ),
  },
  {
    selector: '.first-step',
    content: (
      <div>
        <div style={{ fontSize: 40, textAlign: 'center' }}>🔤🎹</div>
        <div>
          На клавишах есть подсказки с названиями нот. Если вы не знаете ноту, я подсвечу вам нужную
          клавишу и напишу название прям на ноте:) Одна уже светится, жми на неё
        </div>
      </div>
    ),
  },
  {
    selector: 'body',
    content: (
      <div>
        <div style={{ fontSize: 40, textAlign: 'center' }}>💪🎶</div>
        <div>Так держать! А теперь попробуйте сами — сыграйте оставшиеся ноты.</div>
      </div>
    ),
    action: () => setWelcomeOnboardingPassed(),
    styles: noSelectorStyles,
  },
]

export const advancedOnboardingSteps: StepType[] = [
  {
    selector: '.cursor',
    content: (
      <div>
        <div style={{ fontSize: 40, textAlign: 'center' }}>🚀</div>
        <div>
          Красный курсор задает скорость. Ваша задача — сыграть ноту до того, как до нее дойдет
          курсор. По мере вашего обучения курсор ускоряется, чтобы вы учились читать ноты мгновенно!
        </div>
      </div>
    ),
  },
  {
    selector: '.scores',
    content: (
      <div>
        <div style={{ fontSize: 40, textAlign: 'center' }}>📈</div>
        <div>
          Это ваш прогресс — сначала он быстро будет расти до вашего уровня, а потом замедлится. Но
          не расстраивайтесь, значит я нашел задания которые принесут вам больше всего пользы.
          Сможете набрать 100%?😏
        </div>
      </div>
    ),
  },
  {
    selector: '.timer',
    content: (
      <div>
        <div style={{ fontSize: 40, textAlign: 'center' }}>⏱️</div>
        <div>
          А это таймер, не ленитесь заниматься хотябы 5-10 минут в день. И не забывайте хорошо
          отдыхать, дайте своему мозгу усвоить информацию. Каждый новый день у вас будут получаться
          те задания, которые не получались вчера 😲
        </div>
      </div>
    ),
  },
  {
    selector: 'body',
    content: (
      <div>
        <div style={{ fontSize: 40, textAlign: 'center' }}>🎓</div>
        <div>
          Теперь вы знаете как работает тренажер, если возникнут вопросы или предложения - пишите 😉
        </div>
      </div>
    ),
    styles: noSelectorStyles,
    action: () => setWelcomeOnboardingPassed(),
  },
]

export const firstSkillLevelOnboarding: StepType[] = [
  welcomeStep,
  {
    selector: 'body',
    content: (
      <div>
        <Typography textAlign="center" variant="h6" gutterBottom>
          Здорово, с тренажером вы:
        </Typography>
        <AbilsList>
          <li>
            🚀 Будете быстрее разбирать любимые мелодии и играть несложные произведения без
            подготовки
          </li>
          <li>🎶 Научитесь уверенно считывать скрипичный и басовый ключи</li>
          <li>🎓 Сможете тренироваться где и когда угодно, даже без пианино под рукой</li>
        </AbilsList>
      </div>
    ),
    styles: noSelectorStyles,
  },
  ...advancedOnboardingSteps,
]

export const secondSkillLevelOnboarding: StepType[] = [
  welcomeStep,
  {
    selector: 'body',
    content: (
      <div>
        <Typography textAlign="center" variant="h6" gutterBottom>
          Отлично, значит вы:
        </Typography>
        <AbilsList>
          <li>🚀 Будете быстрее разбирать любимые мелодии и даже играть их без подготовки</li>
          <li>
            🎶 Научитесь увереннее считывать одновременно скрипичный и басовый ключи и быстрее
            узнавать ноты даже в самых редких октавах
          </li>
          <li>🎓 Сможете поддерживать навыки где и когда угодно, даже без пианино под рукой</li>
        </AbilsList>
      </div>
    ),
    styles: noSelectorStyles,
  },
  ...advancedOnboardingSteps,
]

export const thirdSkillLevelOnboarding: StepType[] = [
  welcomeStep,
  {
    selector: 'body',
    content: (
      <div>
        <Typography textAlign="center" variant="h6" gutterBottom>
          Рад видеть профи, и буду для вас полезен, вы
        </Typography>
        <AbilsList>
          <li>🚀 Прокачаете навык чтения наперед и будете лучше запоминанать произведения</li>
          <li>🎶 Будете быстрее узнавать ноты даже в самых редких октавах</li>
          <li>🎓 Сможете поддерживать навыки где и когда угодно, даже без пианино под рукой</li>
        </AbilsList>
      </div>
    ),
    styles: noSelectorStyles,
  },
  ...advancedOnboardingSteps,
]

export const beginnerAfterFirstTask: StepType[] = [
  {
    action: () => track('startSecondOnboarding'),
    selector: 'body',
    content: (
      <div>
        <div style={{ fontSize: 40, textAlign: 'center' }}>🎉</div>
        <div>Отлично! Вы только что сыграли свои первые ноты, но рано расслабляться...</div>
      </div>
    ),
    styles: noSelectorStyles,
  },
  {
    selector: 'body',
    content: (
      <div>
        <div style={{ fontSize: 40, textAlign: 'center' }}>🎶🚀</div>
        <div>
          Ноты нужно читать быстро! Вы же не читаете по слогам? Пианисты читают музыку целыми
          словами и предложениями, этому вы здесь и научитесь
        </div>
      </div>
    ),
    styles: noSelectorStyles,
  },
  {
    selector: '.cursor',
    content: (
      <div>
        <div style={{ fontSize: 40, textAlign: 'center' }}>🚀</div>
        <div>
          Красный курсор задает скорость. Ваша задача — сыграть ноту до того, как до нее дойдет
          курсор. По мере вашего обучения курсор ускоряется, чтобы вы учились читать ноты быстрее.
        </div>
      </div>
    ),
  },
  {
    selector: '.scores',
    content: (
      <div>
        <div style={{ fontSize: 40, textAlign: 'center' }}>📈</div>
        <div>
          Это ваш прогресс — он растет вместе с вашим навыком, а с ним и сложность заданий.
          Оценивайте по нему свои достижения, соревнуйтесь и делитесь результатами с друзьями. Ходит
          легенда, что если набрать 100%, то можно научиться играть как Моцарт 🤫
        </div>
      </div>
    ),
  },
  {
    selector: '.timer',
    content: (
      <div>
        <div style={{ fontSize: 40, textAlign: 'center' }}>⏱️</div>
        <div>
          А это таймер, не ленитесь заниматься хотябы 5-10 минут в день. И не забывайте хорошо
          отдыхать, дайте своему мозгу усвоить информацию. Каждый новый день у вас будут получаться
          те задания, которые не получались вчера 😲
        </div>
      </div>
    ),
  },
  {
    selector: 'body',
    content: (
      <div>
        <div style={{ fontSize: 40, textAlign: 'center' }}>🎓</div>
        <div>Отлично, вы разобрались как работает тренажер. Веселого обучения!</div>
      </div>
    ),
    styles: noSelectorStyles,
    action: () => track('endSecondOnboarding'),
  },
]

export const advancedAfterFirstTask: StepType[] = [
  {
    action: () => track('startSecondOnboarding'),
    disableActions: false,
    selector: 'body',
    content: (
      <div className="flex flex-col items-center">
        <div style={{ fontSize: 40, textAlign: 'center' }}>🚀</div>
        <div>
          Отлично, вы разобрались с тем как работает тренажер! Дальше задания будут усложняться и
          подбираться под ваш уровень. Вы удивитесь какие задания вы вскоре сможете решать быстро 👻
        </div>
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          color="success"
          onClick={() => {
            updateSteps([])
            closeOnboarding()
          }}
        >
          Понятно 🙂
        </Button>
      </div>
    ),
    styles: noSelectorStyles,
    actionAfter: () => track('endSecondOnboarding'),
  },
]

export const trebleBassClefOnboardingSteps: StepType[] = [
  {
    selector: '.abcjs-staff-extra.abcjs-v0',
    content: 'это скрипичный ключ',
  },
  {
    disableActions: false,
    selector: '.abcjs-staff-extra.abcjs-v1',
    content: 'это басовый ключ',
  },
]
export const bassClefOnboardingStep: StepType[] = [
  {
    disableActions: false,
    selector: '.abcjs-staff-extra.abcjs-v1',
    content: 'это басовый ключ',
  },
]

export const chordsOnboardingSteps: StepType[] = [
  {
    selector: 'body',
    content: (
      <div>
        <div style={{ fontSize: 40, textAlign: 'center' }}>🎓</div>
        <div>Пора ознакомится с аккордами</div>
      </div>
    ),
    styles: noSelectorStyles,
    disableActions: true,
  },
  {
    selector: '.chords-keyboard',
    content: (
      <div>
        <div style={{ fontSize: 40, textAlign: 'center' }}>🔤🎹</div>
        <div>
          Это клавиатура с аккордами...
        </div>
      </div>
    ),
    actionAfter: () => chordsOnboardingPassed(),
    disableActions: false,
  },
]
