export const customNoteNumberToAbcdjs = (name: string) => {
  let res = name

  res = res?.replaceAll('6', `''`)
  res = res?.replaceAll('5', `'`)
  res = res?.replaceAll('4', '')
  res = res?.replaceAll('3', ',')
  res = res?.replaceAll('2', ',,')
  res = res?.replaceAll('1', ',,,')

  return res
}

export const customNoteToAbcdjs = (name: string) => {
  let res = name

  if (res?.includes('#')) {
    res = '^' + res.replaceAll('#', '')
  }

  if (res?.includes('b')) {
    res = '_' + res.replaceAll('b', '')
  }

  return customNoteNumberToAbcdjs(res)
}

export const NOTES_NAMES = ['C', 'D', 'E', 'F', 'G', 'A', 'B']

export const BEMOL_TO_DIEZ = {
  _C: 'B',
  _D: '^C',
  _E: '^D',
  _F: 'E',
  _G: '^F',
  _A: '^G',
  _B: '^A',
} as const
