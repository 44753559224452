import styled from 'styled-components'

export const outerQuintoQuarticCircleNotes = [
  'C',
  'G',
  'D',
  'A',
  'E',
  'B',
  'Gb',
  'Db',
  'Ab',
  'Eb',
  'Bb',
  'F',
]
export const innerQuintoQuarticCircleNotes = [
  'Am',
  'Em',
  'Bm',
  'F#m',
  'C#m',
  'G#m',
  'Ebm',
  'Bbm',
  'Fm',
  'Cm',
  'Gm',
  'Dm',
]

type QuintoQuarticCircleProps = {
  buttons: string[]
  rad: number
  onSelect: (note: string) => void
  selectedNote?: string
}

export const QuintoQuarticCircle: React.FC<QuintoQuarticCircleProps> = ({
  buttons,
  rad,
  onSelect,
  selectedNote,
}) => {
  return (
    <>
      {buttons.map((btn: string, idx: number) => {
        const Button = styled.button`
          position: absolute;
          width: ${rad / 2}px;
          height: ${rad / 2}px;
          background: ${selectedNote === btn ? '#808080' : '#f8f8f8'};
          color: black;
          border-radius: 2rem;
          cursor: pointer;
          transform: translate(
            ${Math.sin((Math.PI / 6) * idx) * rad}px,
            ${-Math.cos((Math.PI / 6) * idx) * rad}px
          );
        `
        return <Button onClick={() => onSelect(btn)}>{btn}</Button>
      })}
    </>
  )
}
