import { useStore } from 'effector-react'
import { $isTaskStarted } from 'model/model'

export const HideAfterTaskStartedWrapper: React.FC<{ className: string }> = ({ children, className }) => {
  const isTaskStarted = useStore($isTaskStarted)

  return (
    <div className={`${className} ${isTaskStarted ? 'opacity-10' : 'opacity-100'} duration-500`}>
      {children}
    </div>
  )
}