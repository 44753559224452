import { useStore } from "effector-react";
import { $dailyGoal, $trackedTime, addMinute, minusMinute } from "./model";
import { LabelsDay } from "./LabelsDay";
import { CustomCircularProgress } from "components/CustomCircularProgress";
import { TimePicker } from "shared/components/time-picker/TimePicker";
import styled from "styled-components";


const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
`

export const DailyGoal = () => {
    // const todayTime = useStore($trackedTime)
    const todayTime = 3
    const goal = useStore($dailyGoal)
    const progress = todayTime / (goal / 100)

    const weekStat = [
        { time: 2, name: 'П' },
        { time: 7, name: 'В' },
        { time: 0, name: 'С' },
        { time: 3, name: 'Ч' },
        { time: 5, name: 'П' },
        { time: 6, name: 'С' },
        { time: 12, name: 'В' },
    ]


    return (
        <Container>
            <CustomCircularProgress title={goal} description='мин' size={200} progress={progress} />
            <TimePicker add={addMinute} minus={minusMinute} defaultTime={goal} />
            <LabelsDay weekStat={weekStat} goal={goal} />
        </Container>
    );
};
