import {
  $currentTaskStep,
  $currentTaskStepNotes,
  $highlightedNotes,
  pressKey,
  releaseNote,
} from 'model/model'
import styled, { keyframes, css } from 'styled-components'
import cn from 'classnames'
import { BASE_NOTES, RU_BASE_NOTES_TRANSLATES } from './../constants'
import styledMap from 'styled-map'
import { clearOctave } from 'model/tasks/tasks.utils'
import { memo, useEffect, useState } from 'react'
import { Chord } from '@tonaljs/tonal'
import { useStore, useStoreMap } from 'effector-react'
import { getIsMobile } from 'hooks/useIsMobile'
import { identity } from 'lodash/fp'
import { $isHintActive } from 'model/ui'

const glowingAnimation = keyframes`
  0% { background-color: #eee;  }
  50% { background-color: #01ff5a;  }
  100% { background-color: #eee; }
`

export const ButtonsKeyboard = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
`

export const PianoKeyBaseContainer = styled.div<IPianoKeyProps>`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-shrink: 0;
  text-align: center;
  font-size: 25px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: ${styledMap`
    isHighlighted: pink;
    isPressed: #01ff01;
    isCorrectPressed: #01ff01;
    default: #eee;
  `};

  ${(p) =>
    p.isHighlighted &&
    !p.isPressed &&
    css`
      animation: ${glowingAnimation} 1000ms infinite;
    `}
  user-select: none;
  border-radius: 3px;
`

export const ChordsKeyboardContainer = styled.div`
  display: grid;
  gap: 1px;
  width: 100%;
  max-width: 500px;
  grid-template-areas:
    'a a a a'
    'a a a a'
    'a a a a'
    'a a a a';

  grid-template-rows: 49px 49px 49px calc(50px + 100px);
  @supports (grid-template-rows: env(safe-area-inset-bottom)) {
    grid-template-rows: 49px 49px 49px calc(50px + env(safe-area-inset-bottom));
  }
`

export const ChordsKeyboardButton = styled(PianoKeyBaseContainer)`
  padding: 8px;
  opacity: 0.5;
  display: flex;
  align-items: flex-start;

  /* &.n {
    grid-area: a;
  }

  &.c {
  } */
  grid-area: ${(p) => p.gridArea};
`

export const PianoKeyboardWrapper = styled.div`
  display: flex;
  max-width: 100%;
  overflow-x: hidden;
`

export const PianoKeyboardContainer = styled.div<{ isZoomed: boolean }>`
  display: flex;
  justify-content: flex-start;
  user-select: none;
  /* TODO возможно тут ошибка и нажимаются не те ноты? */
  zoom: ${(p) => (p.isZoomed ? 0.4 : 1)};
`

type INoteButtonProps = {
  isHighlighted?: boolean
  isPressed?: boolean
  name: string
  keyboardType: string
}

const NoteButtonContainer = styled.button<INoteButtonProps>`
  background-color: ${styledMap`
    isHighlighted: pink;
    isPressed: #01ff01;
  `};
  width: calc(100% / 4);
  height: 100px;
  flex-grow: 1;
  margin: 0;
  font-size: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

export const NoteButton = (p: INoteButtonProps) => {
  const label = p.keyboardType === 'ruNotes' ? RU_BASE_NOTES_TRANSLATES[p.name] : p.name

  return (
    <NoteButtonContainer {...p} onClick={() => pressKey(p.name)}>
      {label.replace('^', '#')}
    </NoteButtonContainer>
  )
}

const isSharp = (name: string) => name.includes('^')
type IPianoKeyProps = {
  name: string
  label: string
  keyName: string
  isHighlighted?: boolean
  isTwoOctavKeyboard?: boolean
  isPressed?: boolean
  isMobile?: boolean
  isCorrectPressed?: boolean
  isNotesOnKeysVisible: boolean
  isRuNotes: boolean
}

export const PianoKeyContainer = styled(PianoKeyBaseContainer)<IPianoKeyProps>`
  height: 200px;
  width: calc(min(100vw / 7, 60px));
  box-sizing: border-box;
  border-right: 1px;
  border-left: 1px;
  border-style: solid;
  border-color: white;
  line-height: ${(p) => (p.isMobile ? '80px' : 'unset')};
  user-select: none;
  -webkit-user-select: none;

  @supports (height: env(safe-area-inset-bottom)) {
    &:not(.sh) {
      height: calc(env(safe-area-inset-bottom, 0px) + 200px);
      padding-bottom: env(safe-area-inset-bottom);
    }
  }

  span {
    user-select: none;
    transition: opacity 2s;
    opacity: ${(p) => (p.isNotesOnKeysVisible ? 0.5 : 0)};
    font-size: ${(p) => (p.isRuNotes ? 'medium' : '')};
  }

  .laptopKeyName {
    font-size: small;
    user-select: none;
    transition: opacity 2s;
    opacity: ${(p) => (p.isNotesOnKeysVisible ? 0.2 : 0)};
    display: block;
    margin-bottom: 5px;
    /* position: absolute;
    bottom: -20px; */
  }

  &.sh {
    --width: calc(min(100vw / 7, 52px));
    width: var(--width);
    height: 110px;
    position: relative;
    color: white;
    background-color: black;
    border-top: none;
    margin-left: calc(var(--width) / -2);
    margin-right: calc(var(--width) / -2);
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    span {
      pointer-events: none;
      display: none;
    }
  }
`

const isMobile = getIsMobile()

export const PianoKey = memo((p: IPianoKeyProps) => {
  const notesAdapter = p.isTwoOctavKeyboard ? identity : clearOctave
  const isPressed = useStoreMap($highlightedNotes, (notes) =>
    notes.map(notesAdapter).includes(p.name),
  )
  const isCorrect = useStoreMap($currentTaskStepNotes, (notes) =>
    notes.map(notesAdapter).includes(p.name),
  )
  const isHintActive = useStoreMap($isHintActive, (val) => val && isCorrect)

  return (
    <PianoKeyContainer
      data-name={p.name}
      className={cn(`key`, p.name.replace('^', ''), isSharp(p.name) && 'sh')}
      isMobile={isMobile}
      onPointerDown={(e) => {
        e.preventDefault()
        e.stopPropagation()
        pressKey(clearOctave(p.name))
      }}
      onPointerUp={(e) => {
        e.preventDefault()
        e.stopPropagation()
        releaseNote(clearOctave(p.name))
      }}
      onTouchEnd={(e) => {
        e.preventDefault()
      }}
      isHighlighted={isHintActive && isCorrect}
      isPressed={isPressed}
      isCorrectPressed={isPressed && isCorrect}
      {...p}
    >
      <div>
        <span>{clearOctave(p.label)}</span>
        {!isMobile && <span className="laptopKeyName">{p.keyName}</span>}
      </div>
    </PianoKeyContainer>
  )
})

PianoKey.displayName = 'PianoKey'

const SIGNS = ['♭', '♯']

export const ChordsKeyboard = () => {
  const [selectedNote, setSelectedNote] = useState('')
  const [selectedChord, setSelectedChord] = useState('')
  const [selectedSign, setSelectedSign] = useState('')
  const currentTaskStep = useStore($currentTaskStep)
  // optimize with analog of useCallback
  const handlePress = (name: string) => {
    if (BASE_NOTES.includes(name)) setSelectedNote(name)
    else if (SIGNS.includes(name)) setSelectedSign(selectedSign === name ? '' : name)
    else setSelectedChord(name)
  }

  useEffect(() => {
    setSelectedNote('')
    setSelectedChord('')
  }, [currentTaskStep])

  useEffect(() => {
    if (selectedNote && selectedChord) {
      Chord.get(selectedNote + selectedChord.replace('M', '')).notes.forEach((note) =>
        pressKey(note),
      )
    }
  }, [selectedNote, selectedChord])

  return (
    <ChordsKeyboardContainer>
      {['C', 'F', 'M', 'm', 'D', 'G', 'dim', 'aug', 'E', 'A', 'maj7', 'm7', '♭', 'B', '7', '♯'].map(
        (name) => (
          <ChordsKeyboardButton
            isPressed={
              name && (name === selectedNote || name === selectedChord || name === selectedSign)
            }
            onPointerDown={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handlePress(name)
            }}
          >
            {name}
          </ChordsKeyboardButton>
        ),
      )}
    </ChordsKeyboardContainer>
  )
}
