import { useStore } from 'effector-react'
import { $nextChord, $pressedChords, pressChord, releaseChord } from 'model/model'
import { $isHintActive } from 'model/ui'
import styled from 'styled-components'
import { getTgSafeAreaBottom } from 'utils/utils'
import { PianoKeyBaseContainer } from './ButtonsKeyboard'

const pressedBG = '#01ff01'
const ChordsKeyboardWrapper = styled.div`
  display: flex;
  max-width: 100%;
`
const ChordButton = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: calc(200px + ${getTgSafeAreaBottom()}px);
  width: calc(min(100vw / 7 - 2px, 60px));
  margin-right: 1px;
  margin-left: 1px;
  background-color: #eee;
  user-select: none;
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.7);

  @supports (height: env(safe-area-inset-bottom)) {
    &:not(.sh) {
      height: calc(env(safe-area-inset-bottom, 0px) + 200px + ${getTgSafeAreaBottom()}px);
      padding-bottom: calc(env(safe-area-inset-bottom) + ${getTgSafeAreaBottom()}px);
    }
  }
  &.sh {
    --width: calc(min(100vw / 7, 52px));
    width: var(--width);
    height: 100px;
    position: relative;
    color: rgba(255, 255, 255, 0.8);
    background-color: black;
    border-top: none;
    margin-left: calc(var(--width) / -2);
    margin-right: calc(var(--width) / -2);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
`
type ChordMajorOrMinorProps = {
  isSharp?: boolean
  isPressed?: boolean
  disabled?: boolean
  isActive?: boolean
}

const Separator = styled.div<ChordMajorOrMinorProps>`
  width: 80%;
  border-bottom: 1px;
  border-style: solid;
  border-color: ${(p) => (p.isSharp ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.2)')};
`

const ChordMajor = styled(PianoKeyBaseContainer)<ChordMajorOrMinorProps>`
  font-size: 16px;
  font-weight: 500;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: background-color 0.2s;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.2)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

  .sh & {
    background-color: black;
  }
`
const ChordMinor = styled(PianoKeyBaseContainer)<ChordMajorOrMinorProps>`
  font-size: 16px;
  margin-top: auto;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  transition: background-color 0.2s;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.2)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

  .sh & {
    background-color: black;
  }
`
const chords = 'C|^C|D|^D|E|F|^F|G|^G|A|^A|B'.split('|')
const BASE_CORDS = 'C|Dm|Em|F|G|Am'.split('|')
const isSimpleModeActive = true

export const ChordsKeyboards = () => {
  const pressedChords = useStore($pressedChords)
  const nextChord = useStore($nextChord)
  const isHintActive = useStore($isHintActive)

  return (
    <ChordsKeyboardWrapper className="chords-keyboard">
      {chords.map((chord, i) => {
        const isSharp = chord.includes('^')
        return (
          <ChordButton key={i} className={isSharp ? 'sh' : ''}>
            <ChordMinor
              disabled={!BASE_CORDS.includes(`${chord}m`) && isSimpleModeActive}
              isActive={BASE_CORDS.includes(`${chord}m`)}
              isHighlighted={isHintActive && nextChord === `${chord}m`}
              onPointerDown={() => pressChord(`${chord}m`)}
              onPointerUp={() => releaseChord(`${chord}m`)}
              isPressed={pressedChords.includes(`${chord}m`)}
            >
              {/* {i < 1 ? '  Сm' : ''} */}
              {!isSharp && `${chord}m`}
            </ChordMinor>
            <Separator isSharp={isSharp} />
            <ChordMajor
              disabled={!BASE_CORDS.includes(chord) && isSimpleModeActive}
              isActive={BASE_CORDS.includes(chord)}
              isHighlighted={isHintActive && nextChord === chord}
              onPointerDown={() => pressChord(chord)}
              onPointerUp={() => releaseChord(chord)}
              isPressed={pressedChords.includes(chord)}
            >
              {!isSharp && `${chord}`}
              {/* {i < 1 ? 'С' : ''} */}
            </ChordMajor>
          </ChordButton>
        )
      })}
    </ChordsKeyboardWrapper>
  )
}
