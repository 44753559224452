export const OVERTONES = {
  C2: 'G3',
  'C#2': 'G#3',
  D2: 'A3',
  'D#2': 'A#3',
  E2: 'B3',
  F2: 'C4',
  'F#2': 'C#4',
  G2: 'D4',
  'G#2': 'D#4',
  A2: 'E4',
  // C6# - четвертый обертон для A3, сууукаааа
  'A#2': 'F4',
  B2: 'F#4',
}

export const NOTES_NAMES = ['C', 'D', 'E', 'F', 'G', 'A', 'B']
export const COLORS = {
  C: '#07E904',
  D: '#006BFB',
  E: '#8001CC',
  F: '#3F005B',
  G: '#D70302',
  A: '#FF8801',
  B: '#99F20C',
}
// } as const

export const NAME_BUTTON_NOTE = [
  '[Q]',
  '',
  '[W]',
  '',
  '[E]',
  '[R]',
  '',
  '[T]',
  '',
  '[Y]',
  '',
  '[U]',
]
export const BASE_NOTES = 'C|^C|D|^D|E|F|^F|G|^G|A|^A|B'.split('|')
export const CLEAR_BASE_NOTES = 'C|D|E|F|G|A|B'.split('|')
export const RU_BASE_NOTES = 'До|Ре|Ми|Фа|Соль|Ля|Си'.split('|')
export const RU_NOTES = 'До|^До|Ре|^Ре|Ми|Фа|^Фа|Соль|^Соль|Ля|^Ля|Си'.split('|')
export const RU_BASE_NOTES_TRANSLATES = {
  C: 'До',
  D: 'Ре',
  E: 'Ми',
  F: 'Фа',
  G: 'Со',
  A: 'Ля',
  B: 'Си',
}

export const DaysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun',]
export const MonthOfYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',]
export const KEYBOARD = [
  [
    'Backquote',
    'Digit1',
    'Digit2',
    'Digit3',
    'Digit4',
    'Digit5',
    'Digit6',
    'Digit7',
    'Digit8',
    'Digit9',
    'Digit0',
    'Minus',
    'Equal',
  ],
  // [
  //   'KeyC',
  //   'KeyD',
  //   'KeyE',
  //   'KeyF',
  //   'KeyG',
  //   'KeyA',
  //   'KeyB',
  // ],
  [
    'KeyQ',
    'KeyW',
    'KeyE',
    'KeyR',
    'KeyT',
    'KeyY',
    'KeyU',
    'KeyI',
    'KeyO',
    'KeyP',
    'BracketLeft',
    'BracketRight',
  ],
  // [
  //   'KeyA',
  //   'KeyS',
  //   'KeyD',
  //   'KeyF',
  //   'KeyG',
  //   'KeyH',
  //   'KeyJ',
  //   'KeyK',
  //   'KeyL',
  //   'Semicolon',
  //   'Quote',
  //   'Backslash',
  // ],
  // [
  //   'IntlBackslash',
  //   'KeyZ',
  //   'KeyX',
  //   'KeyC',
  //   'KeyV',
  //   'KeyB',
  //   'KeyN',
  //   'KeyM',
  //   'Comma',
  //   'Period',
  //   'Slash',
  // ],
  ['KeyV', 'KeyB', 'KeyN', 'KeyM', 'Comma', 'Period', 'Slash'],
]

export const PIANO_KEYS_MAP: Record<string, string> = {}
KEYBOARD.forEach((line) =>
  line.forEach((key, index) => {
    PIANO_KEYS_MAP[key] = NOTES_NAMES[index % 7]
  }),
)

// PIANO_KEYS_MAP['Digit2'] = '^C'
// PIANO_KEYS_MAP['Digit3'] = '^D'
// PIANO_KEYS_MAP['Digit5'] = '^F'
// PIANO_KEYS_MAP['Digit6'] = '^G'
// PIANO_KEYS_MAP['Digit7'] = '^A'

export const progressValues = [0, 12, 20, 50]
export const mainCircularColor = '#01c72b'
export const secondaryCircularColor = '#b7dfd0'
