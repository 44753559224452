import { createStore, createEvent } from 'effector'

export const toggleChord = createEvent<string>('settings/toggleChord')

export const $activeChords = createStore<Record<string, boolean>>({}).on(
  toggleChord,
  (state, name) => ({
    ...state,
    [name]: !state[name],
  }),
)

export const $settings = createStore({})
