import { RepeatOneOnOutlined, RepeatOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useStore } from 'effector-react'
import { $isCurrentTaskLocked, $isRepeatOnboardingButtonShown, toggleIsCurrentTaskLocked } from './model'
import { repeatButton } from 'features/onboarding/onboardingSteps'
import { updateSteps } from 'features/onboarding/model'

export const RepeatButton = () => {
  const isCurrentTaskLocked = useStore($isCurrentTaskLocked)
  const isRepeatButtonShown = useStore($isRepeatOnboardingButtonShown)

  const toggleTaskLock = () => {
    if (isRepeatButtonShown) {
      toggleIsCurrentTaskLocked()
    } else {
      updateSteps(repeatButton)
      toggleIsCurrentTaskLocked()
    }
  }

  return (
    <IconButton className='repeat-button' onClick={toggleTaskLock}>
      {isCurrentTaskLocked ? (
        <RepeatOneOnOutlined sx={{ color: 'red' }} fontSize="large" />
      ) : (
        <RepeatOutlined fontSize="large" />
      )}
    </IconButton>
  )
}
