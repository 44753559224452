import { WebApp } from '@grammyjs/web-app'

export const saveProgress = (progress: number) =>
  fetch('https://mypiano.ai/api/users/updateProgress', {
    method: 'POST',
    // mode: 'cors',
    // credentials: 'include',
    body: JSON.stringify({
      initData: WebApp.initData,
      progress,
    }),
  }).then((res) => {
    console.log(res, 'res from saveProgress')
    alert(`status: ${res.status}`)
  }).catch((e) => {
    alert(e)
    console.log(e)
    throw e
  })

export const sendCallback = (callback_text: string) => {
  fetch('https://mypiano.ai/api/sendCallbackFromWebApp', {
    method: 'POST',
    // mode: 'cors',
    // credentials: 'include',
    body: JSON.stringify({
      initData: WebApp.initData,
      callback_text,
    }),
  }).catch((e) => {
    console.log(e)
    throw e
  })
}
