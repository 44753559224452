import { useStore, useGate, useStoreMap } from 'effector-react'
import * as model from './model/model'
import { initGate } from './model/model'
import { VscDebugConsole } from '@react-icons/all-files/vsc/VscDebugConsole'
import { EmojiButton, ToggleButton } from 'components/EmojiButton'
import { ProgressChart } from 'components/ProgressChart/ProgressChart'
import { useState, useEffect, lazy, Suspense } from 'react'
import { initAmplitude } from 'utils/amplitude'
import './input.css'
import { Scores } from 'components/Scores'
import { clearStats } from 'statistics/statistics'
import { ErrorShutter } from 'components/ErrorShutter'
import { GlobalStyle } from 'components/GlobalStyle'
import {
  themeToggle,
  keyboardToggle,
  debugToggle,
  chartToggle,
  soundToggle,
  micToggle,
  isDeveloperModeToggle,
  isHardModeMistakesToggle,
  isConsoleOpenedToggle,
  testTasksToggle,
  isCatchUpMode,
  isDropNotesMode,
  isProVersion,
  isRuNotesMode,
  cursorToggle,
} from 'model/ui'
import { SiMidi } from '@react-icons/all-files/si/SiMidi'
import { MdMic } from '@react-icons/all-files/md/MdMic'
import { MdMicOff } from '@react-icons/all-files/md/MdMicOff'
import { TaskProgress } from 'components/ProgressBar'
import { $isMidiEnabled, toggleMidi } from 'utils/midi'
import { Poker } from './Poker/Poker'
import PianoIcon from '@mui/icons-material/Piano'
// TODO если подгружать то динамически
// import { TasksGraph } from './TasksGraph/TasksGraph'
import { TASK_CONTROL_TYPES } from 'statistics/tasksUniqNames'
import {
  AccountTree,
  DeveloperMode,
  VolumeOffRounded,
  VolumeUpRounded,
  LockReset,
  RestartAlt,
  MusicNote,
  MusicOff,
  AttachMoney,
  MoneyOff,
  Language,
  Keyboard,
} from '@mui/icons-material'
import { FullscreenButton } from 'features/fullscreen'
import { ConfettiAnimation } from 'components/ConfettiAnimation'
import { UndoButton } from 'features/tasks-controls/UndoButton'
import { RepeatButton } from 'features/tasks-controls/RepeatButton'
import { RedoButton } from 'features/tasks-controls/RedoButton'
import 'features/tasks-controls/model.init'
import { Timer } from 'features/timer/Timer'
import { GiMusicalNotes } from '@react-icons/all-files/gi/GiMusicalNotes'
import { MdTimer } from '@react-icons/all-files/md/MdTimer'
import { Badge, Button, CircularProgress } from '@mui/material'
import { AssistantButton } from 'shared/components/assistant/AssistantButton'
import { AppWrapper } from 'components/AppWrapper'
import { PianoKeyboard } from 'components/PianoKeyboard'
import { HideAfterTaskStartedWrapper } from 'components/HideAfterTaskStartedWrapper'
import { DebugTop } from 'components/Debug'
import { QuintoQuartic } from 'components/QuintoQuartic'
import { ChordsKeyboards } from 'components/ChordsKeyboards'
import { saveProgress } from './api'
import { enqueueSnackbar } from 'utils/effector'

const LazyMusicNotation = lazy(async () => {
  const [component] = await Promise.all([
    import('./components/MusicNotation/MusicNotation'),
    new Promise((res) => setTimeout(res, 1000)),
  ])
  return component
})

export default function App() {
  useGate(initGate)
  const isMidiConnected = useStore($isMidiEnabled)
  const theme = useStore(themeToggle.$val)
  const currentTaskControls = useStoreMap(model.$currentTask, (s) => s.controls)
  const keyboardType = useStore(keyboardToggle.$val)
  const isDebugEnabled = useStore(debugToggle.$val)
  const isDevModeEnabled = useStore(isDeveloperModeToggle.$val)
  const isChartEnabled = useStore(chartToggle.$val)
  const isDropNoteModeEnable = useStore(isDropNotesMode.$val)
  const isProVersionEnable = useStore(isProVersion.$val)
  // const isSecondChordNoteEnabled = useStore(model.secondChordNoteToggle.$val)
  // const isThirdChordNoteEnabled = useStore(model.thirdChordNoteToggle.$val)
  // const isForthChordNoteEnabled = useStore(model.forthChordNoteToggle.$val)
  const isPokerEnabled = document.location.pathname === '/p'
  const isTasksGraphEnabled = document.location.pathname === '/g'

  useEffect(() => {
    // TODO move to model
    initAmplitude()
  }, [])

  if (isPokerEnabled) {
    return <Poker />
  }

  // if (isTasksGraphEnabled) {
  //   return <TasksGraph />
  // }

  return (
    <AppWrapper>
      {isDebugEnabled && <DebugTop />}
      {/* TODO return later */}
      {/* <TaskProgress /> */}
      {/* <audio loop src="http://www.sousound.com/music/healing/healing_01.mp3"></audio> */}
      <GlobalStyle theme={theme} />
      <Scores />
      {/* <InteractiveBadge /> */}
      <Timer />
      {/* TODO показывать список имен заданий и время их выполнения */}
      {/* {isDebugEnabled && tasksList?.map((t) => JSON.stringify(t))} */}
      {/* {currentPitch} */}
      <Suspense fallback={<CircularProgress sx={{ marginTop: 'auto', marginBottom: 'auto' }} />}>
        <LazyMusicNotation />
      </Suspense>
      {/* return after */}
      {/* <div className="landscape:absolute landscape:right-0 landscape:bottom-0"> */}
      {/* <EmojiButton
          className="!opacity-50"
          active
          onClick={() => model.playFullTask()}
          label={<IoMdArrowRoundBack />}
        /> */}
      {/* <EmojiButton
          className="!opacity-50"
          active
          onClick={() => model.skipTaskFx()}
          label={<IoMdArrowRoundForward />}
        />
      </div> */}
      <HideAfterTaskStartedWrapper className={`flex self-center justify-end p-2`}>
        {/* <Chip color="primary" label="Free"/> */}
        <UndoButton />
        <RepeatButton />
        <RedoButton />
      </HideAfterTaskStartedWrapper>
      {currentTaskControls === TASK_CONTROL_TYPES.CHORDS_KEYBOARD && <ChordsKeyboards />}
      {/* пофиксить клавиатуру на андроиде, походу большая клава включается */}
      {currentTaskControls === TASK_CONTROL_TYPES.PIANO_KEYBOARD && <PianoKeyboard />}
      {/* {['notes', 'ruNotes'].includes(keyboardType) && (
        <ButtonsKeyboard>
          {
            // @ts-ignore
            { notes: BASE_NOTES, ruNotes: CLEAR_BASE_NOTES }[keyboardType].map((name) => (
              <NoteButton
                key={name}
                keyboardType={keyboardType}
                name={name}
                isHighlighted={
                  isHintActive &&
                  currentStepNotes.map((e) => e.replace(/[',^]+/g, '')).includes(name)
                }
              />
            ))
          }
        </ButtonsKeyboard>
      )} */}
      {['quinto_quartic_circle'].includes(keyboardType) && <QuintoQuartic />}
      {isChartEnabled && <ProgressChart />}
      <div style={{ overflowX: 'scroll', display: 'flex', maxWidth: '100%' }}>
        {isDebugEnabled && (
          <div style={{ display: 'flex' }}>
            v27
            {/* <ToggleButton model={isHideNoteModeToggle} label={[<FaRegEyeSlash />, <FaRegEye />]} /> */}
            {/* <EmojiButton active onClick={() => model.playFullTask()} label="▶️" /> */}
            {/* <EmojiButton active onClick={() => toggleConfetti(true)} label="🎉" /> */}
            <ToggleButton model={themeToggle} label="🌈" />
            <EmojiButton active onClick={model.resolveFx} label="🃏" />
            {/* <EmojiButton disabled={!prevNote} onClick={model.prev} label="⬅️️" /> */}
            {/* <EmojiButton onClick={playCurrentNote} label="🔁" />
            <EmojiButton onClick={model.check} label="✅" /> */}
            <EmojiButton onClick={model.debugSkipTask} label="➡️" />
            <EmojiButton onClick={clearStats} label="🗑️" />
            <ToggleButton model={keyboardToggle} label="🎹" />
            <button onClick={() => {
              enqueueSnackbar('update progress')
              saveProgress(10)
            }}>S/P</button>
            <ToggleButton
              model={isDeveloperModeToggle}
              label={<DeveloperMode />}
              title={'Dev mode'}
            />
            <ToggleButton model={isRuNotesMode} label={<Language />} title={'ru mode'} />
            <ToggleButton model={isHardModeMistakesToggle} label={<LockReset />} />
            <ToggleButton model={testTasksToggle} label={<GiMusicalNotes />} title="test tasks" />
            <ToggleButton model={cursorToggle} label={<MdTimer />} title="cursor" />
            <ToggleButton
              model={isProVersion}
              label={isProVersionEnable ? <AttachMoney /> : <MoneyOff />}
            />
            <ToggleButton model={isCatchUpMode} label={<RestartAlt />} />
            <ToggleButton
              model={isDropNotesMode}
              label={isDropNoteModeEnable ? <MusicNote /> : <MusicOff />}
            />
            <EmojiButton
              active={isMidiConnected}
              onClick={() => toggleMidi(true)}
              title="midi"
              label={<SiMidi />}
            />
            <EmojiButton
              active={!isMidiConnected}
              onClick={() => toggleMidi(false)}
              title="piano"
              label={<PianoIcon />}
            />
            <FullscreenButton />
            <Button>
              ???
              <AssistantButton>
                <div>something ...</div>
              </AssistantButton>
            </Button>
            <a href="/g">
              <EmojiButton label={<AccountTree />} />
            </a>
            {/* <EmojiButton
                active={!isMidiConnected}
                onClick={() => toggleMidi(false)}
                title="piano"
                label={<MdMic />}
              /> */}
            <ToggleButton model={isConsoleOpenedToggle} label={<VscDebugConsole />} />
          </div>
        )}
        {/* TODO сделать задания в которых видно только аккорды без нот,
       чисто прятать их в MusiqNotation - было хуёвой идеей */}
        {/* <ToggleButton model={model.isNotesVisibileToggle} label="🎼" /> */}

        {/* <ToggleButton model={model.chartToggle} label="📈" /> */}
        {/* TODO возможно кнопку шейр не прятать и поместить сверху, но возможно прям сверху до неё сложно будет достать, мб какие-то контролы переместить вниз */}
        <HideAfterTaskStartedWrapper className="top-0 right-0 !absolute flex portrait:flex-col items-center p-2 flex-row for-example">
          <ToggleButton
            className={`landscape:!mr-3`}
            model={soundToggle}
            label={[
              <VolumeUpRounded sx={{ fontSize: 46 }} />,
              <VolumeOffRounded sx={{ fontSize: 46 }} />,
            ]}
          />
          <Badge
            sx={{ userSelect: 'none' }}
            badgeContent="beta"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            overlap="circular"
            color="primary"
          >
            <ToggleButton model={micToggle} label={[<MdMic />, <MdMicOff />]} />
          </Badge>
          {/* <ShareButton
            shareContent="Learn piano fast and funny"
            button={<FaRegShareSquare className="text-4xl text-gray-500" />}
          /> */}
        </HideAfterTaskStartedWrapper>
        {/* <ToggleButton title="just 2 note from chord" model={model.secondChordNoteToggle} label="2" />
      <ToggleButton model={model.thirdChordNoteToggle} label="3" />
      <ToggleButton model={model.forthChordNoteToggle} label="4" /> */}
        {/* <ToggleButton
          className="top-0 left-0 !absolute !opacity-0"
          model={debugToggle}
          label="🐞"
          title="Debug"
        /> */}
      </div>
      <ErrorShutter />
      <ConfettiAnimation />
    </AppWrapper>
  )
}
