// TODO refactor
import { Chord, Mode, Note } from '@tonaljs/tonal'
import {
  chunk,
  pipe,
  values,
  orderBy,
  map,
  add,
  range,
  keys,
  last,
  keyBy,
  cloneDeep,
  flattenDeep,
  join,
} from 'lodash/fp'
import {
  BASE_NOTES,
  CHORDS,
  FIVE_NOTES_SEQUENCES_GROUPED,
  minorSubsequences,
  subsequences,
} from 'model/tasks/tasks.constants'
import {
  addOctaves,
  stringifyTreble,
  stringifyTask,
  stringifyBass,
  subOctave,
  getSeqFromChord,
} from 'model/tasks/tasks.utils'
import { tonalToAbc } from 'utils/abcjs-helpers'
import { NOTES_NAMES } from 'utils/notesAdapters'
import { TASKS_IDS } from './generateIds/tasksIds'

export type TaskId = number
export type SimilarityPercentage = number
export type Task = { id: TaskId }

export type AdjacencyList = {
  [key: TaskId]: {
    [connectedKey: TaskId]: SimilarityPercentage
  }
}

const filter = (list: AdjacencyList[number], f: (n: number) => boolean) =>
  pipe(keys, map(Number))(list).filter(f)

export const getRootId = (list: AdjacencyList, id: TaskId) => {
  const vals = values(list[id])
  if (vals.length === 1 && vals[0] === 0.95) {
    return Number(keys(list[id])[0])
  }
  return id
}

// TODO сделать кластеры и убрать костыли
export const getSimilarTasks = (list: AdjacencyList, id: TaskId) => {
  const rootId = getRootId(list, id)
  if (rootId !== id) {
    return [rootId, ...filter(list[rootId], (n) => list[rootId][n] === list[n][rootId] && n !== id)]
  }
  return filter(list[id], (n) => list[id][n] === list[n][id])
}

export const getNextTasks = (list: AdjacencyList, id: TaskId) => {
  const rootId = getRootId(list, id)

  return filter(list[rootId], (n) => list[rootId][n] < list[n][rootId]).flatMap((n) => [
    n,
    ...getSimilarTasks(list, n),
  ])
}

export const getPrevTasks = (list: AdjacencyList, id: TaskId) => {
  const rootId = getRootId(list, id)

  return filter(list[rootId], (n) => list[rootId][n] > list[n][rootId])
}

export const getMainGroupTasksIds = (tasksGroupsVectors: (IMelodyTask[] | ITasksWithDeps)[][]) => {
  const ids = []
  const idsMap = {}

  tasksGroupsVectors.forEach((tasksGroups) =>
    tasksGroups.forEach((tasksOrGroup, groupIndex) => {
      const tasks = tasksOrGroup?.tasks || tasksOrGroup

      if (!idsMap[tasks[0].id]) {
        idsMap[tasks[0].id] = true
        ids.push(tasks[0].id)
      }
    }),
  )

  return ids
}

// TODO добавить модификаторы и зависимости для них
export const generateAdjacencyList = (tasksGroupsVectors: (IMelodyTask[] | ITasksWithDeps)[][]) => {
  const adjacencyList: AdjacencyList = {}

  tasksGroupsVectors.forEach((tasksGroups) =>
    tasksGroups.forEach((tasksOrGroup, groupIndex) => {
      const tasks = tasksOrGroup?.tasks || tasksOrGroup
      const nextGroup = tasksGroups[groupIndex + 1]?.tasks || tasksGroups[groupIndex + 1]

      const extraDeps = tasksOrGroup?.deps || []

      tasks.slice(0, 1).forEach((task) => {
        adjacencyList[task.id] = adjacencyList[task.id] || {}
        extraDeps.forEach(([dep]) => {
          adjacencyList[task.id][dep.id] = 0.5
          adjacencyList[dep.id][task.id] = 0.1
        })

        // add adjacent nodes
        // similarTasks[task.id] = tasks.slice(1).map(t => t.id)
        tasks.slice(1).forEach((adjacentNode) => {
          adjacencyList[adjacentNode.id] = adjacencyList[adjacentNode.id] || {}
          adjacencyList[task.id][adjacentNode.id] = 0.95
          adjacencyList[adjacentNode.id][task.id] = 0.95
        })
        nextGroup?.slice(0, 1)?.forEach((nextTask) => {
          adjacencyList[nextTask.id] = adjacencyList[nextTask.id] || {}

          adjacencyList[task.id][nextTask.id] = 0.1
          adjacencyList[nextTask.id][task.id] = 0.5
        })
      })
    }),
  )

  return adjacencyList
}

export const FIVE_NOTES_MELODIES: [number, string[], number[]][] = []

const invertChord = (notes: string[], inversion: number, octave: number) => [
  ...notes.slice(inversion).map((n) => n + octave),
  ...notes.slice(0, inversion).map((n) => n + (octave + 1)),
]

const applySubsequences = (chordsArr: any[], subsequences: any[]) =>
  subsequences.map((seq) => seq.map((s) => chordsArr[s - 1]))

const chordsToSeq = (chords: string[], octave: number, subsequences: any[]) =>
  applySubsequences(
    chords
      .map(Chord.get)
      .map((chord) =>
        chord.intervals.map((i) => tonalToAbc(Note.transpose(chord.tonic + octave, i))),
      ),
    subsequences,
  )

// так, что если попробовать задания вручную прописать
// должны ли тут быть аккорды без обращений?
// можно попробовать прописать разные уровни сложности
// например без обращений, все с 1 обращением, все с 2 обращением, все с разными
// потом все с несколькими октавами
// для начала нужно сделать варик с тестами

// TODOTODO!!!! возможно сгенерить все возможноы комбинации обращений
const transposeChordsToSeq = (
  chords: string[],
  octave: number,
  chordInversion: number,
  subsequences: any[],
) => {
  return applySubsequences(
    chords.map((chordName) => {
      const { notes } = Chord.get(chordName)
      const sortedNotes = orderBy((noteName) => Note.get(noteName).step, 'asc', notes)

      const chordNotes = invertChord(sortedNotes, chordInversion, octave)
      // 4 нотой добавляется тоника из следующей октавы
      return [...chordNotes, notes[0] + octave].map(tonalToAbc)
    }),
    subsequences,
  )
}

export const minorAGammaChords: Record<number, string[][]> = {}
export const majorCGammaChords: Record<number, string[][]> = {}
export const whiteKeysGammaChords: Record<number, string[][]> = {}
export const minorACleanGammaChords: Record<number, string[][]> = {}
export const majorCCleanGammaChords: Record<number, string[][]> = {}
export const whiteKeysCleanGammaChords: Record<number, string[][]> = {}
export const seventhGammaChords: Record<number, string[][]> = {}

const seqToNotes = (seqString: string, octave: number) =>
  seqString.split('').map((i) => addOctaves(octave - 4)(BASE_NOTES[i]))

// const DO = [[['E', 'G', 'B']]]
const DO = [[['C', 'E', 'G']]]
// const DO = [[['C', 'E', 'A']]]
// const DO = [[[ 'A,' , "C", "E"]]]

/*
уровни:
вообще без обращений
все с 1 обращением
все с 2 обращением
смесь из всех
*/

const BASE_COMBOS = chunk(3, `012120'20'1'`.split(/(?=[012])/))
const PRO_COMBOS = chunk(3, `021'012'01'2'10'2'`.split(/(?=[012])/))
// const BASE_COMBOS = chunk(3, `012021'012'01'2'120'20'1'10'2'0'1'2'`.split(/(?=[012])/))
const allCombos = [
  ...BASE_COMBOS,
  ...BASE_COMBOS.map(([a, b, c]) => [a, c, b]),
  ...BASE_COMBOS.map(([a, b, c]) => [c, b, a]),
  ...BASE_COMBOS.map(([a, b, c]) => [c, a, b]),
  ...BASE_COMBOS.map(([a, b, c]) => [b, a, c]),
  ...BASE_COMBOS.map(([a, b, c]) => [b, c, a]),
].map((arr) => 't' + arr.join(''))

const getBaseChordTasks = (o: number, t: 't' | 'b') => [
  // todo разные обращения
  // [
  //   ...b(getBaseOneHandFormulas(type), whiteKeysGammaChords[octave], chordsOpts),
  //   ...b(getBaseOneHandFormulasRevert(type), whiteKeysGammaChords[octave], chordsOpts),
  // ],
  // b(getBaseOneHandFormulasMiddle(type), whiteKeysGammaChords[octave], chordsOpts),
  // b([[1, `${type}021'`]], whiteKeysGammaChords[octave], chordsOpts),
  // b([[1, `${type}021'`]], whiteKeysCleanGammaChords[octave], chordsOpts),
  // b([[1, `${type}2'10`]], whiteKeysCleanGammaChords[octave], chordsOpts),

  // ********
  b([`${t}[012]`], whiteKeysCleanGammaChords[o], chordsOpts),
  b([`${t}[0'12]`], whiteKeysCleanGammaChords[o], chordsOpts),
  b([`${t}[0'1'2]`], whiteKeysCleanGammaChords[o], chordsOpts),
  b([`${t}[012]`, `${t}[0'12]`, `${t}[0'1'2]`], whiteKeysGammaChords[o], chordsOpts),
  b([`${t}[012']`, `${t}[01'2']`], whiteKeysGammaChords[o], chordsOpts),
  b([`${t}[021']`, `${t}[10'2']`], whiteKeysGammaChords[o], chordsOpts),
  // ********
  // b([[1, `t${BASE_COMBOS.map(arr => `[${arr.join('')}]`).join('')}`]], DO, chordsOpts),
  // b([[1, `t${PRO_COMBOS.map(arr => `[${arr.join('')}]`).join('')}`]], DO, chordsOpts),
  // и еще 6 вариаций этого говна 012 021 201 210 102 120
  // и собсно вопрос, что из этого использовать а что нет?
  // b([[1, `t012021'012'01'2'120'20'1'10'2'0'1'2'`]], DO, chordsOpts),

  // b(
  //   allCombos.map((combo) => [1, combo]),
  //   whiteKeysGammaChords[octave],
  //   // DO,
  //   chordsOpts,
  // ),
]

export const getBaseTasks = (octave: number, type: 't' | 'b'): IMelodyTask[][] => [
  ...FIVE_NOTES_SEQUENCES_GROUPED.map((group) =>
    group.map(
      ([complexity, seqString, fingers]): IMelodyTask => ({
        notes: seqToNotes(seqString, octave).map(type === 'b' ? stringifyBass : stringifyTreble),
        complexity,
        fingers: type === 'b' ? fingers?.map?.((f) => 6 - f) : fingers,
      }),
    ),
  ),
  [
    ...b(getBaseOneHandFormulas(type), whiteKeysGammaChords[octave]),
    ...b(getBaseOneHandFormulasRevert(type), whiteKeysGammaChords[octave]),
  ],
  b(getBaseOneHandFormulasMiddle(type), whiteKeysGammaChords[octave]),
]

// TODO прегенерить чтобы не тормозили мобилки
// хотя на айфоне это всего 8 милисек
console.time('gen')
// TODO тест на телефоне
for (const o of [4, 3, 5, 6, 2, 1]) {
  minorAGammaChords[o] = []
  majorCGammaChords[o] = []
  minorACleanGammaChords[o] = chordsToSeq(CHORDS.Am, o, minorSubsequences)
  majorCCleanGammaChords[o] = chordsToSeq(CHORDS.C, o, subsequences)
  seventhGammaChords[o] = []

  // тут у всех заданий такое обращение чтобы аккорд был в текущей октаве
  for (let inversion = 0; inversion <= 0; inversion++) {
    minorAGammaChords[o].push(...transposeChordsToSeq(CHORDS.Am, o, inversion, minorSubsequences))
    majorCGammaChords[o].push(...transposeChordsToSeq(CHORDS.C, o, inversion, subsequences))
    seventhGammaChords[o].push(...transposeChordsToSeq(CHORDS.C7, o, inversion, subsequences))
    seventhGammaChords[o].push(...transposeChordsToSeq(CHORDS.Am7, o, inversion, minorSubsequences))
  }

  whiteKeysGammaChords[o] = [...minorAGammaChords[o], ...majorCGammaChords[o]]
  whiteKeysCleanGammaChords[o] = [...minorACleanGammaChords[o], ...majorCCleanGammaChords[o]]
}

type IFormula =
  | ((notes: (indexes?: number | string | ReadonlyArray<string>) => any) => any)
  | string

type Complexity = number

// можно задавать несколько Complexity, или Complexity + другой параметр, чтобы сначала можно было пройти
// на 50%, а потом, после других заданий, на 80% прокачав скорость
// TODO рефакторинг, а то как-то сложно и не понятно получается

type IFormulaArr = readonly [Complexity, IFormula, any?] | IFormula

export const getBaseOneHandFormulas = (t: 't' | 'b'): IFormulaArr[] => [
  [0.9, `${t}021`, t === 't' ? '100' : '500'],
  [0.9, `${t}012`, t === 't' ? '100' : '500'],
]

export const getBaseOneHandFormulasRevert = (t: 't' | 'b'): IFormulaArr[] => [
  [0.9, `${t}210`, t === 't' ? '500' : '100'],
  [0.9, `${t}201`, t === 't' ? '500' : '100'],
]

export const getBaseOneHandFormulasMiddle = (t: 't' | 'b'): IFormulaArr[] => [
  [0.9, `${t}120`],
  [0.9, `${t}102`],
]

// нужно как-то сгенерить задания для клавы с аккордами

// TODO ВОЗМОЖНО на этом этапе добавить фильтры по midi/touch/PC keyboard можно оставить без черных клавиш, и это решит проблему двух рук!!!!
const f = {
  chordsTrebleInversions: [1, `t[012][0'12][0'1'2][0'1'2']`],
  chordsBassInversions: [1, `b[012][0'12][0'1'2][0'1'2']`],
  chordsTreble: [1, `t[012]`, '100'],
  chordsBass: [1, `b[012]`, '500'],
  chordsTreble1: [1, `t[0'12]`, '100'],
  chordsTreble2: [1, `t[0'1'2]`, '100'],
  chordsBass1: [1, `[b0'1'2]`, '500'],
  chordsBass2: [1, `[b012]`, '500'],
  chordsTrebleWithOctave: [1, `t[0120']`, '100'],
  chordsBassWithOctave: [1, `[b0120']`, '500'],
  // TODO
  hardTaskForTouchDevices: [1, `t0b2,t1b0,t2b1,`],
  base7chords: [1, `t0123`],
  wideBass: [1, `b0,01210`, '543000'],
  bassWithout3: [1, `b020'`, '501'],
  wideTreble: [1, `t0,01210`, '110000'],
  octaveTreb: [1, `t[00'][22'][11'][22']`],
  octaveBass: [1, `b[00,][22,][11,][22,]`],
  wideArpegioBass: [1, `b3,2,010'210`, '50000000'], // TODO fingers
  trebleAndOneBass: [1, `b3,t01210`, '010000'],
  trebleArpeggio: [1, `t0120'210`],
  trebleAndBassArpeggio: [1, `[t0b0,][t1b1,][t2b2,][t0'b0][t2b2,][t1b1,][t0b0,]`],
  bassArpeggio: [1, `b0120'210`],
  trebleHalfArpeggio: [1, `t0120'`],
  trebleHalfArpeggioBass2Parts: [1, `[t0b0,2,][t1b1,][t2b0,2,][t0'b1,]`],
  trebleHalfArpeggioBass2PartsSkips: [1, `[t0b0,2,][t1][t2b1,][t0']`],
  trebleAndBassHalfArpeggio: [1, `[t0b0,][t1b1,][t2b2,][t0'b0]`],
  bassHalfArpeggio: [1, `b0120'`],
  trebleAndBassOctave: [1, `[b3,3,,]t01210`, '010000'],
  treblePairs: [1, `t[02][1]`],
  bassPairs: [1, `b[02][1]`],
  twoHandsSevens: [1, `[t0b2,][t1b3,][t2b0,][t3b1,]`],
  twoHandsSevensReverse: [1, `[t3b1,][t2b0,][t1b3,][t0b2,]`],
  twoHandsSevens3Octaves: [1, `[t0b2,,][t1b3,,][t2b0,,][t3b1,,]`],
  twoHandsSevens4Octaves: [1, `[t0b2,,,][t1b3,,,][t2b0,,,][t3b1,,,]`],
  twoHandsDiffRhythmMobile0: [1, `[b0,][b0,][b0,t1]`],
  twoHandsDiffRhythmMobile1: [1, `[b0,][b0,t1]`],
  twoHandsDiffRhythmMobile2: [1, `[b0,][b1,][b0,t1][b1,t2]`],
  twoHandsDiffRhythmMobile3: [1, `[b0,][b1,][b0,][b0,t1][b1,t2][b0,t1]`],
  twoHandsDiffRhythmMobile0treb: [1, `[t1][t1][b0,t1]`],
  twoHandsDiffRhythmMobile1treb: [1, `[t1][b0,t1]`],
  twoHandsDiffRhythmMobile2treb: [1, `[t1][t2][b0,t1][b1,t2]`],
  twoHandsDiffRhythmMobile3treb: [1, `[t1][t2][t1][b0,t1][b1,t2][b0,t1]`],
  chordToTrebleBassCombosLevel1: [1, `[t0b0,][t1b1,][t2b2,]`],
  chordToTrebleBassCombosLevel2: [1, `[t0b1,][t2b0,][t1b2,]`],
  twoOctavsTrebleBase: [1, `t01'20'12'`],
  twoOctavsBassBase: [1, `b01'20'12'`],
  threeOctavsTrebleRepeat: [1, `t00''0'11''1'22''2'`],
  threeOctavsBassRepeat: [1, `b00''0'11''1'22''2'`],
  twoHandsOneBass: [1, `TODO`],
  twoHandsBassOctave: [1, `TODO`],
  ganon: [1, `TODO`],
} satisfies Record<string, IFormulaArr>

// задание на две руки с разными ритмами

export type ITaskType = (typeof TASK_TYPES)[keyof typeof TASK_TYPES]

export type IMelodyTask = {
  notes: string[]
  complexity: number
  fingers?: number[] | string
  type: ITaskType
}

const getGanonNotes = (num) => {
  const arr = range(0, num).flatMap((diff) => [0, 2, 3, 4, 5, 4, 3, 2].map(add(diff)))
  arr.push(arr[arr.length - 1] - 1)
  return arr.map((num) => tonalToAbc(NOTES_NAMES[num % 7] + (4 + Math.floor(num / 7))))
}

const getGanonReverceNotes = (num) => {
  const arr = range(0, num).flatMap((diff) => [5, 3, 2, 1, 0, 1, 2, 3].map(add(num - diff - 1)))
  arr.push(arr[arr.length - 1] + 1)
  return arr.map((num) => tonalToAbc(NOTES_NAMES[num % 7] + (4 + Math.floor(num / 7))))
}

export const ganonTasks: IMelodyTask[] = (
  [
    [0.4, getGanonNotes(2).map(stringifyTreble), `1234543212`],
    [0.5, getGanonReverceNotes(2).map(stringifyTreble), '5432123454'],
    [0.4, getGanonNotes(2).map(subOctave).map(stringifyBass), `5432123454`],
    [0.5, getGanonReverceNotes(2).map(subOctave).map(stringifyBass), '1234543212'],
    [0.4, getGanonNotes(2).map((n) => stringifyTask({ bass: [subOctave(n)], treble: [n] }))],
    [0.5, getGanonReverceNotes(2).map((n) => stringifyTask({ bass: [subOctave(n)], treble: [n] }))],
    [0.4, getGanonNotes(9).map((n) => stringifyTask({ bass: [subOctave(n)], treble: [n] }))],
    [0.5, getGanonReverceNotes(9).map((n) => stringifyTask({ bass: [subOctave(n)], treble: [n] }))],
  ] as const
).map(([complexity, notes, fingers]) => ({ complexity, notes, fingers: fingers?.split('') }))

/**
 * build task TODO comment and optimize
 */
export const b = (formulas: IFormulaArr[], chordsSeqs: any[], additional = {}) => {
  const tasks: IMelodyTask[] = []

  formulas.forEach((formula) => {
    formula = typeof formula === 'string' ? [1, formula] : formula
    const [complexity, f, fingers] = formula
    let fingersMap = () => fingers
    if (typeof fingers === 'function') fingersMap = fingers
    if (typeof fingers === 'string') fingersMap = () => fingers.split('').map(Number)

    chordsSeqs.forEach((chords) =>
      tasks.push({
        notes: chords.flatMap(getSeqFromChord(typeof f === 'string' ? (n) => n(f) : f)),
        complexity,
        fingers: chords.flatMap(fingersMap),
        ...additional,
      }),
    )
  })

  return tasks
}

console.time('generateTasks')

export const prepareTask = (type: ITaskType) => (task: IMelodyTask) => {
  task.type = type
  task.fingers = type === TASK_TYPES.SCREEN_KEYBOARD ? undefined : task.fingers
  task.controls = task.controls ?? TASK_CONTROL_TYPES.PIANO_KEYBOARD

  return task
}

export const prepareTasks = (tasksMap: any, type: ITaskType) =>
  flattenDeep(values(tasksMap)).map(prepareTask(type))

// возможно не получится задавать самому коэффициенты похожести заданий, и можно будет просто пробовать
// например задание В зависит от А и Б, даем по немногу А и Б, переходим к В, если ученик его плохо решает - даем задание А, ждём улучшения
// даём задание В, если всё еще плохо - даем задание Б, ждём улучшения, даём опять В, и смотрим есть ли прогресс
// и запоминаем улучшение какого задание дало пользу для задания В, и тогда можно будет опираться на опыт других учеников если собирать стату
// но не на 100% т.к. все люди разные
// [задание, зависящее от него задание, зависящее от него задание, ...]
// const tasksVertexSequences: TaskNames[][] = [
//   // отлично, ганон, получается, будет тупиковой ветвью, от которого особо не зависят другие задания, но алгоритм будет добиваться 100% прогресса
//   [T.baseFiveNotes4Octave, T.ganonTasks1, T.ganonTasks2, T.ganonTasks3, T.ganonTasks4],
//   [T.baseFiveNotes4Octave, T.baseBassFiveNotes3Octave, T.baseBassFiveNotes2Octave],
// ]

// получается rest будет значить остановку в ветке,
// а repeatInterval - идём дальше, но кладём задание в очередь и через время вернем на повторение
// визуализировать это пздц хочется

// нужно задание на аккорды где нужно все кнопки прожать всё таки
// и зависимость прогресса будет неправильная
// 0.5 прогресса у задания с обычной клавой будет набрать намнооого проще чем 0.5 в клаве с аккордами
// а может быть такое что задание будет дальше в графе, но у него независимо можно набрать 100 прогресса, а у предыдущего будет только 50

// console.log(tasksVertexSequences)

export const TASK_CONTROL_TYPES = {
  PIANO_KEYBOARD: 0,
  CHORDS_KEYBOARD: 1,
} as const

export const TASK_TYPES = {
  SCREEN_KEYBOARD: 0,
  MIC: 1,
  MIDI: 2,
  TEST: 9,
} as const

const chordsOpts = { controls: TASK_CONTROL_TYPES.CHORDS_KEYBOARD }

// TODO сделать стабильные ID (сортировать потом)
// ВАЖНО! новые задания только в конец
export const t = {
  base3Treble: getBaseTasks(3, 't'),
  base4Treble: getBaseTasks(4, 't'),
  base5Treble: getBaseTasks(5, 't'),
  base6Treble: getBaseTasks(6, 't'),
  base3Bass: getBaseTasks(3, 'b'),
  base2Bass: getBaseTasks(2, 'b'),
  baseBass1: getBaseTasks(1, 'b'),
  ganonTasksFirst: ganonTasks.slice(0, 1),
  ganonTasks2: ganonTasks.slice(0, 2),
  ganonTasks24: ganonTasks.slice(2, 4),
  ganonTasks46: ganonTasks.slice(4, 6),
  trebleAndOneBass: b([f.trebleAndOneBass], whiteKeysGammaChords[4]),
  trebleAndBassOctave: b([f.trebleAndBassOctave], whiteKeysGammaChords[4]),
  hardTaskForTouchDevices: b([f.hardTaskForTouchDevices], whiteKeysGammaChords[4]),
  octaveTreb: b([f.octaveTreb], whiteKeysCleanGammaChords[4]),
  wideTreble: b([f.wideTreble], whiteKeysGammaChords[5]),
  bassWithout3_3: b([f.bassWithout3], whiteKeysCleanGammaChords[3]),
  bassWithout3_2: b([f.bassWithout3], whiteKeysCleanGammaChords[2]),
  wideArpegioBass: b([f.wideArpegioBass], whiteKeysGammaChords[3]),
  octaveBass: b([f.octaveBass], whiteKeysCleanGammaChords[3]),
  wideBass: b([f.wideBass], whiteKeysCleanGammaChords[3]),
  treblePairs4: b([f.treblePairs], whiteKeysGammaChords[4]),
  bassPairs3: b([f.bassPairs], whiteKeysGammaChords[3]),
  bassPairs2: b([f.bassPairs], whiteKeysGammaChords[2]),
  chordToTrebleBassCombosLevel1: b([f.chordToTrebleBassCombosLevel1], whiteKeysGammaChords[4]),
  chordToTrebleBassCombosLevel2: b([f.chordToTrebleBassCombosLevel2], whiteKeysGammaChords[4]),
  twoHandsSevens: b([f.twoHandsSevens], seventhGammaChords[4]),
  twoHandsSevensReverse: b([f.twoHandsSevensReverse], seventhGammaChords[4]),
  twoHandsSevens3Octaves: b([f.twoHandsSevens3Octaves], seventhGammaChords[4]),
  twoHandsSevens3Octaves5: b([f.twoHandsSevens3Octaves], seventhGammaChords[5]),
  twoHandsSevens4Octaves: b([f.twoHandsSevens4Octaves], seventhGammaChords[5]),
  base7chords4Octaves: b([f.base7chords], seventhGammaChords[4]),
  // TODO мб как-то добавить повтор
  chordsTreble4: b([f.chordsTreble], whiteKeysGammaChords[4], chordsOpts),
  chordsBass3: b([f.chordsBass], whiteKeysGammaChords[3], chordsOpts),
  treblePairs5: b([f.treblePairs], whiteKeysGammaChords[5]),
  treblePairs6: b([f.treblePairs], whiteKeysGammaChords[6]),
  bassWithout3: b([f.bassWithout3], whiteKeysCleanGammaChords[1]),
  bassPairs: b([f.bassPairs], whiteKeysGammaChords[1]),
  chordsTreble5: b([f.chordsTreble], whiteKeysGammaChords[5], chordsOpts),
  chordsBass2: b([f.chordsBass], whiteKeysGammaChords[2], chordsOpts),
  twoOctavsTrebleBase3: b([f.twoOctavsTrebleBase], whiteKeysGammaChords[3]),
  twoOctavsTrebleBase4: b([f.twoOctavsTrebleBase], whiteKeysGammaChords[4]),
  twoOctavsTrebleBase5: b([f.twoOctavsTrebleBase], whiteKeysGammaChords[5]),
  twoOctavsBassBase1: b([f.twoOctavsBassBase], whiteKeysGammaChords[1]),
  twoOctavsBassBase2: b([f.twoOctavsBassBase], whiteKeysGammaChords[2]),
  twoOctavsBassBase3: b([f.twoOctavsBassBase], whiteKeysGammaChords[3]),
  twoOctavsBassBase4: b([f.twoOctavsBassBase], whiteKeysGammaChords[4]),
  threeOctavsBassRepeat2: b([f.threeOctavsBassRepeat], whiteKeysGammaChords[2]),
  threeOctavsBassRepeat1: b([f.threeOctavsBassRepeat], whiteKeysGammaChords[1]),
  threeOctavsTrebleRepeat4: b([f.threeOctavsTrebleRepeat], whiteKeysGammaChords[4]),
  threeOctavsTrebleRepeat3: b([f.threeOctavsTrebleRepeat], whiteKeysGammaChords[3]),
  twoHandsDiffRhythmMobile0: b([f.twoHandsDiffRhythmMobile0], whiteKeysGammaChords[4]),
  twoHandsDiffRhythmMobile1: b([f.twoHandsDiffRhythmMobile1], whiteKeysGammaChords[4]),
  twoHandsDiffRhythmMobile2: b([f.twoHandsDiffRhythmMobile2], whiteKeysGammaChords[4]),
  twoHandsDiffRhythmMobile3: b([f.twoHandsDiffRhythmMobile3], whiteKeysGammaChords[4]),
  twoHandsDiffRhythmMobile0treb: b([f.twoHandsDiffRhythmMobile0treb], whiteKeysGammaChords[4]),
  twoHandsDiffRhythmMobile1treb: b([f.twoHandsDiffRhythmMobile1treb], whiteKeysGammaChords[4]),
  twoHandsDiffRhythmMobile2treb: b([f.twoHandsDiffRhythmMobile2treb], whiteKeysGammaChords[4]),
  twoHandsDiffRhythmMobile3treb: b([f.twoHandsDiffRhythmMobile3treb], whiteKeysGammaChords[4]),
  // test: b([[1, `t0,0'01,1'12,2'2`]], whiteKeysGammaChords[4]),
  testTask: [
    {
      notes: 'CCCCCDDDDDDEEEEEFFFGGG'.split('').map((n) => `{"treble":["${n}"]}`),
      name: 'base4Treble',
      controls: 0,
    },
  ],
  baseChordTreble4: getBaseChordTasks(4, 't'),
  baseChordBass3: getBaseChordTasks(3, 'b'),
  //   // TODO в анбординге обьяснить что такое аккорд базово, что это 3 ноты подряд
  // // а потом обьямснтить что эти ноты можно переставлять и это будет тот же аккорд
  // chordsTreble40: b(
  //   [f.chordsTreble, f.chordsTreble1, f.chordsTreble2],
  //   whiteKeysGammaChords[4],
  //   chordsOpts,
  // ),
  // chordsTreble41: b([f.chordsTreble1, f.chordsTreble2], whiteKeysGammaChords[4], chordsOpts),
  // chordsTrebleClean4: b([f.chordsTreble], whiteKeysCleanGammaChords[4], chordsOpts),
  // // аккорды с обращением
  // chordsTrebleClean41: b([f.chordsTreble1], whiteKeysCleanGammaChords[4], chordsOpts),
  // chordsTrebleClean42: b([f.chordsTreble2], whiteKeysCleanGammaChords[4], chordsOpts),
  // в анбординге написать подсказку как определять обращение какого аккорда это

  // впринципе если тут порядок не менять то пока с id всё норм будет
}

// set names for graph
keys(t).forEach((key) =>
  t[key].flat().forEach((task) => {
    task.name = key
  }),
)

// micTasks
// TODO обьеденить micTasks и midiTasks, просто для микрофона фильтровать то, что пока не распознается
export const micTasks = {
  ...cloneDeep(t),
  // TODO передавать в формулу октаву и баса и скрипичного, мб просто [...whiteKeysCleanGammaChords[4], whiteKeysCleanGammaChords[2]]
  trebleAndBassArpeggio4: b([f.trebleAndBassArpeggio], whiteKeysCleanGammaChords[4]),
  trebleAndBassHalfArpeggio4: b([f.trebleAndBassHalfArpeggio], whiteKeysCleanGammaChords[4]),
  trebleHalfArpeggioBass2PartsSkips4: b(
    [f.trebleHalfArpeggioBass2PartsSkips],
    whiteKeysCleanGammaChords[4],
  ),
  trebleHalfArpeggioBass2Parts4: b([f.trebleHalfArpeggioBass2Parts], whiteKeysCleanGammaChords[4]),
  trebleArpeggio4: b([f.trebleArpeggio], whiteKeysCleanGammaChords[4]),
  trebleArpeggio5: b([f.trebleArpeggio], whiteKeysCleanGammaChords[5]),
  bassArpeggio3: b([f.bassArpeggio], whiteKeysCleanGammaChords[3]),
  bassArpeggio2: b([f.bassArpeggio], whiteKeysCleanGammaChords[2]),
}
export const midiTasks = {
  ...cloneDeep(micTasks),
  chordsTreble5: b([f.chordsTreble], whiteKeysGammaChords[5]),
  chordsBass2: b([f.chordsBass], whiteKeysGammaChords[2]),
  chordsTreble4: b([f.chordsTreble], whiteKeysGammaChords[4]),
  chordsBass3: b([f.chordsBass], whiteKeysGammaChords[3]),
  chordsTrebleWithOctave5: b([f.chordsTrebleWithOctave], whiteKeysCleanGammaChords[5]),
  chordsBassWithOctave2: b([f.chordsBassWithOctave], whiteKeysCleanGammaChords[2]),
  chordsTrebleWithOctave4: b([f.chordsTrebleWithOctave], whiteKeysCleanGammaChords[4]),
  chordsBassWithOctave3: b([f.chordsBassWithOctave], whiteKeysCleanGammaChords[3]),
  chordsTrebleInversions: b([f.chordsTrebleInversions], whiteKeysCleanGammaChords[4]),
  chordsBassInversions: b([f.chordsBassInversions], whiteKeysCleanGammaChords[3]),
}

export const MELODY_TASKS: IMelodyTask[] = [
  ...prepareTasks(t, TASK_TYPES.SCREEN_KEYBOARD),
  ...prepareTasks(micTasks, TASK_TYPES.MIC),
  ...prepareTasks(midiTasks, TASK_TYPES.MIDI),
]

// TODO мб проверять лучше, заданий может быть столько же, но айдишники поменяются
// например от перестановки или изменения формулы
if (MELODY_TASKS.length !== TASKS_IDS.length && typeof alert === 'function') {
  alert('Regenerate TASKS_IDS! npm run generateTasksIds')
}

MELODY_TASKS.forEach((task, i) => {
  task.id = TASKS_IDS[i]
})

export const MELODY_TASKS_BY_ID = keyBy('id', MELODY_TASKS)

// может при переходе из одного задания к другому - нужно смотреть разницу в прогрессе двух предыдущих, и смотреть чтобы она
// не превышала лимит? и тогда в графе будут тянуться ветки с плавно затухающим прогрессом, и тогда и старые задание нужно будет
// прокачивать по программе до максимума, и новые кое как выполнять, такой градиент
export type ITasksWithDeps = { tasks: IMelodyTask[]; deps: IMelodyTask[][] }
export const withDeps = (tasks: IMelodyTask[], deps: IMelodyTask[][]): ITasksWithDeps => ({
  tasks,
  deps,
})

export const testTasksSequenceTestVectors: (IMelodyTask[] | ITasksWithDeps)[][] = [
  // [t.chordsTreble41],
  // [t.chordsTrebleClean4, t.chordsTrebleClean41, t.chordsTrebleClean42, t.chordsTreble4],
  [...t.baseChordTreble4, ...t.baseChordBass3],
]

export const mobileTasksSequenceTestVectors: (IMelodyTask[] | ITasksWithDeps)[][] = [
  // [t.base4Treble[0], t.chordToTrebleBassCombosLevel2],
  // [t.base4Treble[0], t.chordsTreble4, t.chordsBass3],
  // 1 hand treble
  // [t.threeOctavsBassRepeat2],
  [
    ...t.base4Treble,
    ...t.base3Bass,
    ...t.base5Treble,
    t.twoOctavsTrebleBase4,
    t.threeOctavsTrebleRepeat4,
    ...t.base6Treble,
    t.twoOctavsTrebleBase5,
  ],
  [last(t.base5Treble)!, ...t.base3Treble, t.twoOctavsTrebleBase3, t.threeOctavsTrebleRepeat3],
  [
    // TODO навести порядок, возможно сделать базовую тренировку для одной октавы и переиспользовать
    withDeps(t.ganonTasksFirst, [last(t.base4Treble)!]),
    withDeps(t.octaveTreb, [last(t.base5Treble)!]),
    t.wideTreble,
    t.treblePairs4,
    withDeps(t.treblePairs5, [last(t.base5Treble)!]),
    withDeps(t.treblePairs6, [last(t.base6Treble)!]),
  ],
  // ganon full TODO
  [last(t.base4Treble)!, t.ganonTasksFirst],
  // 1 hand bass
  [
    last(t.base3Bass)!,
    ...t.base2Bass,
    t.twoOctavsBassBase2,
    t.threeOctavsBassRepeat2,
    t.octaveBass,
    t.bassWithout3_3,
    t.bassWithout3_2,
    t.bassPairs3,
    t.bassPairs2,
    t.wideBass,
    t.wideArpegioBass,
    ...t.baseBass1,
    t.twoOctavsBassBase1,
    t.threeOctavsBassRepeat1,
    t.bassWithout3,
    t.bassPairs,
  ],
  // [t.treblePairs4, t.chordsTreble4, t.chordsTreble5],
  // [t.bassPairs3, t.chordsBass3, t.chordsBass2],
  // two hand
  [
    withDeps(t.trebleAndOneBass, [last(t.base4Treble)!, last(t.base3Bass)!]),
    withDeps(t.trebleAndBassOctave, [t.octaveBass]),
    t.twoHandsDiffRhythmMobile0treb,
    // t.chordToTrebleBassCombosLevel1,
    // t.chordToTrebleBassCombosLevel2,
    t.hardTaskForTouchDevices,
    t.twoHandsSevens,
    t.twoHandsSevensReverse,
    t.twoHandsSevens3Octaves,
    t.twoHandsSevens3Octaves5,
    t.twoHandsSevens4Octaves,
  ],
  [
    withDeps(t.twoHandsDiffRhythmMobile0treb, [t.trebleAndOneBass]),
    t.twoHandsDiffRhythmMobile1treb,
    t.twoHandsDiffRhythmMobile2treb,
    t.twoHandsDiffRhythmMobile3treb,
  ],
  [
    withDeps(t.twoHandsDiffRhythmMobile0, [t.trebleAndOneBass]),
    t.twoHandsDiffRhythmMobile1,
    t.twoHandsDiffRhythmMobile2,
    t.twoHandsDiffRhythmMobile3,
  ],
]

export const micTasksSequenceTestVectors: (IMelodyTask[] | ITasksWithDeps)[][] = ((t) => {
  return [
    [...t.base4Treble, ...t.base3Bass, ...t.base5Treble, ...t.base6Treble],
    [
      // TODO навести порядок, возможно сделать базовую тренировку для одной октавы и переиспользовать
      withDeps(t.ganonTasksFirst, [last(t.base4Treble)!]),
      withDeps(t.octaveTreb, [last(t.base5Treble)!]),
      t.trebleArpeggio4,
      withDeps(t.trebleArpeggio5, [last(t.base5Treble)!]),
      t.wideTreble,
      t.treblePairs4,
      withDeps(t.treblePairs5, [last(t.base5Treble)!]),
      withDeps(t.treblePairs6, [last(t.base6Treble)!]),
    ],
    // ganon full TODO
    [last(t.base4Treble)!, t.ganonTasksFirst],
    // 1 hand bass
    [
      last(t.base3Bass)!,
      ...t.base2Bass,
      t.octaveBass,
      t.bassWithout3_3,
      t.bassPairs3,
      t.bassWithout3_2,
      t.bassArpeggio3,
      t.bassArpeggio2,
      t.wideBass,
      t.wideArpegioBass,
    ],
    // [t.treblePairs4, t.chordsTreble4, t.chordsTreble5],
    // [t.bassPairs3, t.chordsBass3, t.chordsBass2],
    // two hand
    [
      withDeps(t.trebleAndOneBass, [last(t.base4Treble)!, last(t.base3Bass)!]),
      withDeps(t.trebleAndBassOctave, [t.octaveBass]),
      // t.chordToTrebleBassCombosLevel1,
      // t.chordToTrebleBassCombosLevel2,
      t.hardTaskForTouchDevices,
      t.twoHandsSevens,
      t.twoHandsSevensReverse,
      t.twoHandsSevens3Octaves,
      t.twoHandsSevens3Octaves5,
      t.twoHandsSevens4Octaves,
    ],
  ]
})(micTasks)

export const midiTasksSequenceTestVectors: (IMelodyTask[] | ITasksWithDeps)[][] = ((t) => {
  return [
    [...t.base4Treble, ...t.base3Bass, ...t.base5Treble, ...t.base6Treble],
    [
      // TODO навести порядок, возможно сделать базовую тренировку для одной октавы и переиспользовать
      withDeps(t.ganonTasksFirst, [last(t.base4Treble)!]),
      withDeps(t.octaveTreb, [last(t.base5Treble)!]),
      t.trebleArpeggio4,
      withDeps(t.trebleArpeggio5, [last(t.base5Treble)!]),
      t.wideTreble,
      t.treblePairs4,
      withDeps(t.treblePairs5, [last(t.base5Treble)!]),
      withDeps(t.treblePairs6, [last(t.base6Treble)!]),
    ],
    // ganon full TODO
    [last(t.base4Treble)!, t.ganonTasksFirst],
    // 1 hand bass
    [
      last(t.base3Bass)!,
      ...t.base2Bass,
      t.octaveBass,
      t.bassWithout3_3,
      t.bassPairs3,
      t.bassArpeggio3,
      t.bassWithout3_2,
      t.bassArpeggio2,
      t.wideBass,
      t.wideArpegioBass,
      ...t.baseBass1,
      t.bassWithout3,
      t.bassPairs,
    ],
    [
      t.treblePairs4,
      t.chordsTreble4,
      t.chordsTreble5,
      t.chordsTrebleInversions,
      t.chordsTrebleWithOctave4,
      t.chordsTrebleWithOctave5,
    ],
    [
      t.bassPairs3,
      t.chordsBass3,
      t.chordsBass2,
      t.chordsBassInversions,
      t.chordsBassWithOctave3,
      t.chordsBassWithOctave2,
    ],
    // two hand
    [
      withDeps(t.trebleAndOneBass, [last(t.base4Treble)!, last(t.base3Bass)!]),
      withDeps(t.trebleAndBassOctave, [t.octaveBass]),
      t.trebleAndBassArpeggio4,
      t.trebleAndBassHalfArpeggio4,
      t.trebleHalfArpeggioBass2PartsSkips4,
      t.trebleHalfArpeggioBass2Parts4,
      // t.chordToTrebleBassCombosLevel1,
      // t.chordToTrebleBassCombosLevel2,
      t.hardTaskForTouchDevices,
      t.twoHandsSevens,
      t.twoHandsSevensReverse,
      t.twoHandsSevens3Octaves,
      t.twoHandsSevens3Octaves5,
      t.twoHandsSevens4Octaves,
    ],
  ]
})(midiTasks)

// возможно в этом всё таки нет особого смысла
// слишком много ресурсов и дублей, может можно как-то обойтись на уровне одного задания с уровнями
// по времени ускорение тоже стоит делать с пропаданием нот, если юзер не успел, так намного понятнее что нужно ускориться
// а проблему с тем что скорость и память могут у всех в разных пропорциях тренироваться - может как-то учесть без графа
// хотя всё есть граф - норм тема, кроме ресурсов всем удовлетворяет
// но возможно 22 не должен зависеть от 11, т.к.
// const extra = []
// mobileTasksSequenceTestVectors.forEach((arr) => {
//   arr.forEach((tasks) => {
//     const t = tasks?.tasks || tasks
//     extra.push([t, t.map((el) => ({ ...el, modifier: 'hide2note' }))])
//   })
// })

export const tasksData: Record<
  ITaskType,
  { START_TASK: number; adjacencyList: any; mainGroupTasksIds: number[] }
> = {
  [TASK_TYPES.TEST]: {
    START_TASK: Number(testTasksSequenceTestVectors[0][0][0].id),
    adjacencyList: generateAdjacencyList(testTasksSequenceTestVectors),
    mainGroupTasksIds: getMainGroupTasksIds(testTasksSequenceTestVectors),
  },
  [TASK_TYPES.MIDI]: {
    START_TASK: Number(midiTasks.base4Treble[0][0].id),
    adjacencyList: generateAdjacencyList(midiTasksSequenceTestVectors),
    mainGroupTasksIds: getMainGroupTasksIds(midiTasksSequenceTestVectors),
  },
  [TASK_TYPES.SCREEN_KEYBOARD]: {
    START_TASK: Number(mobileTasksSequenceTestVectors[0][0][0].id),
    adjacencyList: generateAdjacencyList(mobileTasksSequenceTestVectors),
    mainGroupTasksIds: getMainGroupTasksIds(mobileTasksSequenceTestVectors),
  },
  [TASK_TYPES.MIC]: {
    START_TASK: Number(micTasks.base4Treble[0][0].id),
    // START_TASK: Number(mt.testTask[0].id),
    adjacencyList: generateAdjacencyList(micTasksSequenceTestVectors),
    mainGroupTasksIds: getMainGroupTasksIds(micTasksSequenceTestVectors),
  },
}

// 'одна нота пропадает',
// 'две ноты пропадают',
// 'длительности?'
const modifiers = []

// может просто лимит по прогрессу устанавливать? полоску рисовать чисто до достижения лимита, тогда структура не усложнится
// это какраз targetProgress,
// хороше прошел - targetProgress увеличил, несколько раз плохо прошел - уменьшил, стоишь на месте - откладываем задание
// но после усложнения задания и неудачи - нет смысла давать задание без усложнения, т.к. оно легко выполнится
// возможно у задания будет какой-то progressUbterval, и например не прячем ноты от 0 до 0.2, потом прячем одну от 0.4 до 0.6
// но по сути это то же самое что и разделить задания на несколько
//  и не понятно в какой момент усложнять задание, нет смысла ждать 100% прогресса
// получается в задание с усложнением на 100% входит задание без усложнения, и если его прошли на 80%, то и заданию без усложнению можно прописать 80%
// ЦЕЛЬ АЛГОРИТМА БУДЕТ В ТОМ, чтобы проходить по всем веткам графа и искать на каком задании у человека сейчас появится прогресс

console.timeEnd('generateTasks')

// console.log(generateAdjacencyList(mobileTasksSequenceTestVectors))
// console.log('MELODY_TASKS', MELODY_TASKS)

// TODO
// подумать избавляться ли от дублей заданий
// export const mt = { ...cloneDeep(t) } пока работает, но заданий станет сиииильно больше
