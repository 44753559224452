import styled, { keyframes } from "styled-components";

export const ANIMATION_TIME = 300

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const scaleIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.01);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const scaleOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.01);
  }
`;

export const Overlay = styled.div<{ show: boolean }>`

z-index: 3;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  cursor: pointer;
  opacity: ${({ show }) => (show ? 1 : 0)};
  animation: ${({ show }) => (show ? fadeIn : fadeOut)} ${ANIMATION_TIME}ms ease-out;
`;

export const Content = styled.div<{ show: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 300px;
  text-align: center;
  background: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 10px;
  z-index: 10;
  overflow: auto;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transform: scale(${({ show }) => (show ? 1 : 0.01)});
  animation: ${({ show }) => (show ? scaleIn : scaleOut)} ${ANIMATION_TIME}ms ease-out;
`;
